angular
  .module("mongoose")
  .controller(
    "LegacyNewUserController",
    function (
      $scope,
      $uibModalInstance,
      Restangular,
      growl,
      existingUsers,
      config,
      $timeout,
      profile,
      reservedNumbers
    ) {
      var vm = this;

      vm.profile = profile;
      vm.selectedAuthMethodName = selectedAuthMethodName;

      activate();

      function activate() {
        Restangular.all("departments")
          .getList()
          .then(function (d) {
            vm.departments = _.map(d, function (dept) {
              dept.authorizedFeatures = [];
              dept.segmentsAuthorized = true;
              dept.manualContactAddAuthorized = true;
              return dept;
            });
          });

        Restangular.all("samlconfiguration")
          .getList()
          .then(function (results) {
            vm.authMethods = results;
          });

        vm.reservedNumbers = reservedNumbers;
        vm.hasValidationErrors = {};
        vm.numberLookup = {
          areaCode: config.areaCode,
          prefix: "",
        };
        vm.step = 1;
        vm.phoneType = "new";
        vm.user = {
          campusUniqueId: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          selectedDepartments: vm.departments,
          forwardingNumber: "",
          isOwner: false,
          manualContactAddAuthorized: true,
          sendWelcomeEmail: true,
          virtualOptions: null,
          highVolumeOptions: null,
          landlineOptions: null,
          samlConfigurationId: null,
        };

        $scope.$watch("vm.phoneType", togglePhoneType);
      }

      function goToStep(step) {
        setHeight();
        vm.slideDirection = step < vm.step ? "right" : "left";
        $timeout(function () {
          vm.step = step;
        });
        $timeout(function () {
          setHeight("auto");
          vm.slideDirection = "";
        }, 500);
      }

      function selectedAuthMethodName() {
        if (
          !vm.user.samlConfigurationId ||
          vm.user.samlConfigurationId == "null"
        )
          return "Cadence";

        if (vm.authMethods.length > 0) {
          var method = _.find(vm.authMethods, {
            id: vm.user.samlConfigurationId,
          });
          return method ? method.friendlyName : "";
        }
      }

      function setHeight(height) {
        var modal = $(".modal-slide-container"),
          newHeight = height ? height : modal[0].scrollHeight;
        modal.height(newHeight);
      }

      function togglePhoneType(phoneType) {
        if (phoneType === "landline") {
          vm.user.virtualOptions = null;
          vm.user.forwardingNumber = "";
          vm.user.landlineOptions = {
            phoneNumber: "",
          };
        } else {
          vm.user.landlineOptions = null;
          vm.user.virtualOptions = {
            isReserved: false,
            numberSid: "",
            phoneNumber: "",
          };
        }
      }

      vm.accessFormInvalid = function () {
        if (vm.loading) return true;
        if (vm.user.isOwner) return false;
        return !_.some(vm.departments, { enabled: true });
      };

      vm.close = $uibModalInstance.dismiss;

      vm.finish = function () {
        if (!vm.user.isOwner) {
          vm.user.selectedDepartments = _.filter(vm.departments, {
            enabled: true,
          });
        }

        if (vm.phoneType === "reserved") {
          vm.user.virtualOptions.isReserved = true;
          vm.user.virtualOptions.numberSid =
            vm.user.virtualOptions.phoneNumber.sid;
          vm.user.virtualOptions.phoneNumber = "";
        }

        if (vm.user.virtualOptions && vm.phoneType !== "reserved") {
          vm.user.virtualOptions.isReserved = false;
          vm.user.virtualOptions.numberSid =
            vm.user.virtualOptions.phoneNumber.sid;
          vm.user.virtualOptions.phoneNumber =
            vm.user.virtualOptions.phoneNumber.phoneNumber;
        }

        if (vm.user.samlConfigurationId == "null")
          vm.user.samlConfigurationId = null;

        vm.busy = true;

        //Set authorizable features
        _.each(vm.departments, function (dept) {
          if (dept.segmentsAuthorized) {
            dept.authorizedFeatures.push("Segments");
          }

          if (dept.manualContactAddAuthorized) {
            dept.authorizedFeatures.push("ManualContactAdd");
          }
        });

        Restangular.all("admin/users")
          .post(vm.user)
          .then(
            function () {
              growl.success("Your user has been created!");
              mixpanel.track("Created New User");
              $uibModalInstance.close();
            },
            function (errResponse) {
              vm.serverValidationError = errResponse.data;
              vm.busy = false;
            }
          );
      };

      vm.goToStep = goToStep;

      vm.lookupNumber = function () {
        vm.isSearching = true;
        vm.user.virtualOptions.phoneNumber = "";

        Restangular.all("twilio")
          .all("numberlookup")
          .getList(vm.numberLookup, { "x-disable-loading": "true" })
          .then(function (n) {
            vm.isSearching = false;
            vm.availableNumbers = _.chunk(n.plain(), Math.ceil(n.length / 2));
            vm.resultNumbers = {
              hasNumbers: n.length > 0,
              number: n.length,
              areaCode: vm.numberLookup.areaCode,
            };
          });
      };

      vm.numberFormInvalid = function () {
        if (vm.loading) return true;
        if (
          vm.phoneType === "landline" &&
          vm.user.landlineOptions.phoneNumber &&
          !vm.landlineVerification
        )
          return true;
        if (vm.phoneType !== "landline" && !vm.user.virtualOptions.phoneNumber)
          return true;
        return false;
      };

      vm.searchNumbers = function (areaCode) {
        Restangular.all("twilio")
          .customPOST(
            { areaCode: areaCode, numberToReserve: 1 },
            "reservenumbers"
          )
          .then(function () {
            vm.searchStarted = true;
          });
      };

      vm.validateUser = function () {
        vm.hasValidationErrors = {};
        vm.hasValidationErrors.firstName = !vm.user.firstName;
        vm.hasValidationErrors.lastName = !vm.user.lastName;
        vm.hasValidationErrors.campusUniqueId =
          !vm.user.campusUniqueId ||
          _.includes(existingUsers.campusIdentifiers, vm.user.campusUniqueId);
        vm.hasValidationErrors.emailAddress =
          !vm.user.emailAddress ||
          _.includes(existingUsers.emailAddresses, vm.user.emailAddress);

        if (!_.includes(vm.hasValidationErrors, true)) {
          goToStep(2);
        }
      };

      vm.verifyLandline = function () {
        vm.isVerifying = true;

        Restangular.all("twilio")
          .one("verifylandline")
          .get(
            { phonenumber: vm.user.landlineOptions.phoneNumber },
            { "x-disable-loading": "true" }
          )
          .then(function (result) {
            vm.isVerifying = false;
            vm.landlineOptions.numberSid = result.number.sid;
            vm.landlineVerification = result.success;
          });
      };
    }
  );
