angular.module('mongoose').filter('campaignNameById', function () {
    return function (campaignId, campaigns) {
        if (!campaignId || !campaigns) { return ''; }

        var campaign = _.find(campaigns, {id: parseInt(campaignId)});

        if(!campaign) return 'Campaign not found';

        return campaign.name;
    };
});