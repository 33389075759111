angular
  .module("mongoose")
  .controller("ReportsController", function ($uibModal, profile) {
    var vm = this;

    vm.hasAccess = profile.isOwner || profile.isAdministrator;
    vm.hasChat = profile.currentDepartment.teamHasChat;

    vm.export = function (type) {
      $uibModal.open({
        controller: "ExportModalController as vm",
        templateUrl: "reports/exports/export-modal.html",
        ariaLabelledBy: "export-modal",
        resolve: {
          profile: function () {
            return profile;
          },
          reportType: function () {
            return type;
          },
        },
      });
    };
  });
