angular.module('mongoose').config(function ($stateProvider){
   $stateProvider
       .state('app.salesforce', {
           url: '/salesforce',
           abstract: true,
           templateUrl: 'salesforce/salesforce.html',
           pageTitle: "Salesforce Integration"
       })
       .state('app.salesforce.configuration', {
           url: '/configuration',
           templateUrl: 'salesforce/configuration.html',
           controller: 'SalesforceConfigurationController as vm',
           pageTitle: "Salesforce Integration Configuration"
       })
       .state('app.salesforce.fields', {
           url: '/fields',
           templateUrl: 'admin/department/integration/fields/view.html',
           controller: 'FieldsController as vm',
           pageTitle: "Salesforce Integration Fields"
       })
       .state('app.salesforce.importConfig', {
           url: '/import-configuration',
           templateUrl: 'salesforce/import-configuration.html',
           controller: 'SalesforceImportConfigurationController as vm',
           pageTitle: "Salesforce Integration Import"
       })
       .state('app.salesforce.messageConfig', {
           url: '/message-configuration',
           templateUrl: 'salesforce/message-configuration.html',
           controller: 'SalesforceMessageConfigurationController as vm',
           pageTitle: "Salesforce Integration Message Configuration"
       })
       .state('app.salesforce.import', {
           url: '/import-history',
           templateUrl: 'salesforce/import-history.html',
           controller: 'SalesforceImportHistoryController as vm',
           pageTitle: "Salesforce Integration Import History"
       })
       .state('app.salesforce.errors', {
           url: '/import-errors',
           templateUrl: 'salesforce/import-errors.html',
           controller: 'SalesforceImportErrorsController as vm',
           pageTitle: "Salesforce Integration Import Errors"

       });
});