(function() {
    'use strict';

    angular
        .module('mongoose')
        .directive('appReload', appReload);

    function appReload($rootScope, $sce, $timeout, $window, appVersion) {

        return {
            restrict: 'E',
            controller: controllerFunc,
            bindToController: true,
            controllerAs: 'vm',
            scope: {
                message: '@'
            },
            templateUrl: 'core/appReload.html'
        };

        function controllerFunc(){

            var vm = this,
                unsubscribeWatch;

            vm.reload = reload;
            vm.showReload = false;


            activate();

            function activate(){
                var firestore = firebase.firestore();

                unsubscribeWatch = firestore.collection("app").doc("web-version").onSnapshot(function(doc){
                    var version = doc.exists ? doc.data() : null;

                   if(!version)
                       return;

                    $timeout(function(){
                        checkVersion(version);
                    }, 1000);
                });
            }

            function checkVersion(version){
                var latestVersion = version.number;

                $rootScope.current = appVersion;
                $rootScope.latest =  latestVersion;

                vm.message = $sce.trustAsHtml(twemoji.parse(version.message, {base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/'}));
                vm.showReload = appVersion < latestVersion;
            }

            function reload(){
                $window.location.reload();
            }

        }

    }
})();