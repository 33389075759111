angular
  .module("mongoose")
  .controller(
    "SmartMessagesController",
    function (
      $rootScope,
      $state,
      $scope,
      $filter,
      $window,
      $q,
      config,
      profile,
      Restangular,
      $uibModal,
      growl
    ) {
      var vm = this,
        filter = $window.sessionStorage.getItem("smartmessage-filters"),
        defaultFilter = {
          staff: profile.id,
          status: "active",
        };

      vm.filter = !_.isNull(filter) ? JSON.parse(filter) : defaultFilter;
      vm.filterGrid = filterGrid;
      vm.gridOptions = {
        appScopeProvider: vm,
        paginationPageSize: 50,
        rowHeight: 60,
        columnDefs: [
          {
            name: "Name",
            field: "name",
            cellTemplate:
              '<div class="ui-grid-cell-contents"><a href ng-click="grid.appScope.view(row.entity)" ng-bind="row.entity.name"></a></div>',
          },
          {
            name: "Owner",
            field: "staffDisplayName",
          },
          {
            name: "Created",
            field: "createdOn",
            cellFilter: 'date : "M/dd/yy"',
          },
          {
            name: "Updated",
            field: "lastUpdatedOn",
            cellFilter: 'date : "M/dd/yy"',
          },
          {
            name: "Actions",
            cellClass: "table-actions text-center",
            headerCellTemplate: '<div class="sr-only">Actions</div>',
            cellTemplate: "menuTemplate.html",
            width: 60,
          },
        ],
      };

      vm.config = config;
      vm.hasGroupAccounts = false;
      vm.hasHighSpeed = false;
      vm.profile = profile;
      vm.sendMessage = sendMessage;
      vm.showEmpty = showEmpty;
      vm.showGrid = showGrid;
      vm.delete = deleteMessage;
      vm.view = viewMessage;
      vm.edit = editMessage;
      vm.archive = archiveMessage;

      activate();

      function activate() {
        load();
        if (profile.inboxes.length) {
          for (var i = 0; i < profile.inboxes.length; i++) {
            if (profile.inboxes[i].type === "group") {
              vm.hasGroupAccounts = true;
            }
            if (profile.inboxes[i].type === "highspeed") {
              vm.hasHighSpeed = true;
            }
          }
        }
      }

      function load() {
        vm.loaded = false;
        var archived = vm.filter.status === "archived",
          user = vm.filter.staff === "null" ? null : vm.filter.staff,
          query = {
            archived: archived,
            userId: user,
          },
          staffPromise = Restangular.all("staff").getList(),
          messagePromise = Restangular.all("smartmessages").getList(query);

        $q.all([staffPromise, messagePromise]).then(function (results) {
          $scope.$watch("vm.filter.searchText", searchGrid);

          vm.staff = results[0].plain();
          vm.messages = _.map(results[1], function (m) {
            m.staffDisplayName = getStaffDisplayName(m.createdById);
            return m;
          });
          vm.gridOptions.data = vm.messages;
          vm.loaded = true;
        });
      }

      function filterGrid() {
        $window.sessionStorage.setItem(
          "smartmessage-filters",
          JSON.stringify(vm.filter)
        );
        load();
      }

      function searchGrid() {
        if (_.isUndefined(vm.filter.searchText)) return;
        $window.sessionStorage.setItem(
          "smartmessage-filters",
          JSON.stringify(vm.filter)
        );
        vm.gridOptions.data = $filter("filter")(
          vm.messages,
          vm.filter.searchText
        );
      }

      function getStaffDisplayName(staffId) {
        var staff = _.find(vm.staff, {
          id: staffId,
        });

        if (!staff) {
          return "Unknown";
        }

        if (staff.fullName) {
          return staff.fullName;
        } else if (staff.firstName && staff.lastName) {
          return staff.firstName + " " + staff.lastName;
        } else {
          return staff.emailAddress;
        }
      }

      function sendMessage(smartMessage) {
        $rootScope.openComposeModal({ smartMessage: smartMessage });
        mixpanel.track("Clicked Message from Smart Message List");
      }

      function showEmpty() {
        return (
          vm.messages &&
          vm.messages.length === 0 &&
          vm.filter.status === "active" &&
          profile.id === vm.filter.staff &&
          vm.loaded
        );
      }

      function showGrid() {
        return (
          (vm.messages && vm.messages.length > 0) ||
          vm.filter.status === "archived" ||
          profile.id !== vm.filter.staff
        );
      }

      function deleteMessage(smartMessage) {
        var modalInstance = $uibModal.open({
          controller: "DeleteSmartMessageModalController as vm",
          templateUrl:
            "automation/smartmessages/delete-smartmessages-modal.html",
          ariaLabelledBy: "delete-smart",
          resolve: {
            smartMessage: function () {
              return smartMessage;
            },
          },
        });

        modalInstance.result.then(function () {
          activate();
        });
      }

      function viewMessage(smartMessage) {
        $uibModal.open({
          controller: "ViewSmartMessageModalController as vm",
          templateUrl: "automation/smartmessages/view-smartmessages-modal.html",
          ariaLabelledBy: "view-smart",
          resolve: {
            smartMessage: function () {
              return smartMessage;
            },
          },
        });
        mixpanel.track("Clicked View from Smart Message List");
      }

      function editMessage(smartMessage) {
        if (profile.id === smartMessage.createdById) {
          $state.go("app.automation.smartmessages.edit", {
            smartMessageId: smartMessage.id,
          });
        } else {
          var modalInstance = $uibModal.open({
            controller: "ViewSmartMessageModalController as vm",
            templateUrl:
              "automation/smartmessages/view-smartmessages-modal.html",
            ariaLabelledBy: "view-smart",
            resolve: {
              smartMessage: function () {
                return smartMessage;
              },
            },
          });

          modalInstance.result.then(function () {
            activate();
          });
        }

        mixpanel.track("Clicked Edit from Smart Message List");
      }

      function archiveMessage(smartMessage) {
        smartMessage.archived = !smartMessage.archived;

        smartMessage.save().then(
          function () {
            growl.success("Your Smart Message has been archived successfully!");
            mixpanel.track(
              (smartMessage.archived ? "Archived" : "Unarchived") +
                " Smart Message"
            );
            activate();
          },
          function (error) {
            growl.error(
              error && error.data
                ? error.data
                : "There was an issue updating your Smart Message."
            );
          }
        );
      }
    }
  );
