angular.module("mongoose").factory("viewportService", function ($window) {
  function isSize(condition) {
    return (
      $window.getComputedStyle(document.getElementById("viewport-" + condition))
        .display !== "none"
    );
  }

  return {
    isSize: isSize,
  };
});
