angular
  .module("mongoose")
  .controller(
    "CampaignReportListController",
    function (
      $rootScope,
      $scope,
      Restangular,
      profile,
      $q,
      $uibModal,
      $filter,
      securityService,
      FileSaver,
      $window
    ) {
      var vm = this,
        filter = $window.sessionStorage.getItem("campaign-reports-filters"),
        defaultFilter = {
          status: "active",
          staff: profile.id,
        };

      vm.filter = !_.isNull(filter) ? JSON.parse(filter) : defaultFilter;
      vm.gridOptions = {
        appScopeProvider: vm,
        paginationPageSize: 50,
        rowHeight: 60,
      };

      var load = function () {
          var archived = vm.filter.status === "archived",
            user = vm.filter.staff === "null" ? null : vm.filter.staff;

          var query = { isActive: !archived, userId: user };
          //filter campaigns by archived & user
          var campaignPromise = Restangular.all("campaigns").getList(query),
            staffPromise = Restangular.all("staff").getList();
          return $q.all([campaignPromise, staffPromise]);
        },
        setup = function (results) {
          $scope.$watch("vm.filter.searchText", search);

          vm.profile = profile;
          vm.staff = results[1].plain();
          vm.campaigns = _.map(results[0], function (c) {
            c.staffDisplayName = getStaffDisplayName(c.createdBy);
            c.ownedByCurrentUser = c.createdBy === profile.id;
            return c;
          });
          vm.gridOptions.columnDefs = [
            {
              name: "Name",
              field: "name",
              cellTemplate:
                '<div class="ui-grid-cell-contents"><a ui-sref="app.reports.campaign({id: row.entity.id})" ng-bind="row.entity.name"></a></div>',
            },
            { name: "Owner", field: "staffDisplayName" },
            {
              name: "Created",
              field: "createdOn",
              cellFilter: 'date : "M/dd/yy"',
            },
            {
              name: "Actions",
              cellClass: "table-actions text-center",
              headerCellTemplate: '<div class="sr-only">Actions</div>',
              cellTemplate: "menuTemplate.html",
              width: 60,
            },
          ];
          vm.gridOptions.data = vm.campaigns;
          vm.loaded = true;
        },
        getStaffDisplayName = function (staffId) {
          var staff = _.find(vm.staff, { id: staffId });

          if (!staff) {
            return "Unknown";
          }

          if (staff.fullName) {
            return staff.fullName;
          } else if (staff.firstName && staff.lastName) {
            return staff.firstName + " " + staff.lastName;
          } else {
            return staff.emailAddress;
          }
        },
        search = function () {
          if (_.isUndefined(vm.filter.searchText)) return;
          $window.sessionStorage.setItem(
            "campaign-reports-filters",
            JSON.stringify(vm.filter)
          );
          vm.gridOptions.data = $filter("filter")(
            vm.campaigns,
            vm.filter.searchText
          );
        };

      vm.exportActivity = function (campaign) {
        Restangular.one("campaigns", campaign.id)
          .all("usage")
          .all("export")
          .getList()
          .then(function (ex) {
            var data = new Blob([ex], { type: "text/csv;charset=utf-8" });
            FileSaver.saveAs(data, "campaign-activity-" + campaign.id + ".csv");
            mixpanel.track("Exported Campaign Report");
          });
      };

      vm.delete = function (campaign) {
        var modalInstance = $uibModal.open({
          controller: "DeleteCampaignModalController as vm",
          templateUrl: "campaigns/delete-campaign-modal.html",
          ariaLabelledBy: "delete-campaign",
          resolve: {
            campaign: function () {
              return campaign;
            },
          },
        });

        modalInstance.result.then(function () {
          load().then(setup);
        });

        mixpanel.track("Clicked Delete from Campaign List");
      };

      vm.edit = function (campaign) {
        var modalInstance = $uibModal.open({
          controller: "EditCampaignModalController as vm",
          templateUrl: "campaigns/edit-campaign-modal.html",
          ariaLabelledBy: "edit-campaign",
          resolve: {
            campaignId: function () {
              return campaign.id;
            },
          },
        });

        modalInstance.result.then(function () {
          load().then(setup);
        });

        mixpanel.track("Clicked Edit from Campaign List");
      };

      vm.filterByUser = function (item) {
        return vm.showAll || (!vm.showAll && item.createdBy === profile.id);
      };

      vm.isAuthorized = function (role) {
        return securityService.isAuthorized(role);
      };

      vm.search = function () {
        $window.sessionStorage.setItem(
          "campaign-reports-filters",
          JSON.stringify(vm.filter)
        );
        load().then(setup);
      };

      vm.share = function (campaign) {
        var modalInstance = $uibModal.open({
          controller: "ShareCampaignModalController as vm",
          templateUrl: "campaigns/share-campaign-modal.html",
          ariaLabelledBy: "share-campaign",
          resolve: {
            campaign: function () {
              return campaign;
            },
            profile: function () {
              return profile;
            },
          },
        });

        modalInstance.result.then(function () {
          load().then(setup);
        });

        mixpanel.track("Clicked Share from Campaign List");
      };

      vm.sendMessage = function (campaign) {
        $rootScope.openComposeModal({ campaign: campaign });
        mixpanel.track("Clicked Message from Campaign List");
      };

      vm.newCampaign = function () {
        var modalInstance = $uibModal.open({
          controller: "NewCampaignModalController as vm",
          templateUrl: "campaigns/new-campaign-modal.html",
          ariaLabelledBy: "new-campaign",
        });

        modalInstance.result.then(function () {
          load().then(setup);
        });
      };

      load().then(setup);
    }
  );
