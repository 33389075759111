angular
  .module("mongoose")
  .factory("notificationService", function ($rootScope, $timeout) {
    var notifications = [],
      profile = {};

    function init(prof) {
      notifications = [];
      profile = prof;
    }

    function get() {
      return notifications;
    }

    function add(notification) {
      if (!_.isEmpty(profile) && !showToUser(profile, notification)) {
        remove(notification.id);
        return false;
      }

      var index = _.findIndex(notifications, "id", notification.id);
      if (index == -1) {
        notifications.push(notification);
      } else {
        _.merge(notifications[index], notification);
      }
      $timeout(function () {
        $rootScope.$broadcast("notificationUpdate");
      });
    }

    function remove(id) {
      notifications = _.reject(notifications, "id", id);
      $rootScope.$broadcast("notificationUpdate");
    }

    function showToUser(profile, notification) {
      var userType = profile.isAdministrator ? "Admin" : "Staff";
      return (
        notification.userType === userType || notification.userType === "All"
      );
    }

    return {
      init: init,
      get: get,
      add: add,
      remove: remove,
    };
  });
