(function() {
    angular.module('mongoose').config(config);

    function config($stateProvider) {
        var modalInstance;

        $stateProvider.state('app.owner.data', {
            url: '/data',
            abstract: true,
            templateUrl: 'owner/data/data.html',
            pageTitle: "Data"
        });

       /* $stateProvider.state('app.owner.data.studentdeletion', {
            url: '/studentdeletion',
            controller: 'OwnerStudentDeletionController',
            templateUrl: 'owner/data/studentdeletion/upload.html',
            controllerAs: 'vm'
        });*/
    }
})();
