(function() {
'use strict';

angular.module('mongoose').controller('EditMessageTypeController', EditMessageTypeController);

function EditMessageTypeController($scope, $uibModalInstance, Restangular, growl, messageType) {

    var vm = this;
    vm.messageType = messageType;

    vm.dismiss = $uibModalInstance.dismiss;

    vm.update = function() {
        Restangular.all('admin/messageTypes').customPUT(vm.messageType).then(function() {
            growl.success("Your message type has been updated!");
            $uibModalInstance.close();
        },
        function(errResponse) {
            $scope.serverValidationError = errResponse.data;
        });
    };
}

})();
