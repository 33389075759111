angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.reporting", {
      url: "/reporting",
      templateUrl: "reporting/reporting.html",
      abstract: true,
    })
    .state("app.reporting.main", {
      controller: "ReportingPageController",
      templateUrl: "reporting/index.html",
      controllerAs: "vm",
    })
    .state("app.reporting.main.analytics", {
      url: "/sms",
      controller: "ReportingPageController",
      templateUrl: "reporting/text-analytics.html",
      controllerAs: "vm",
      pageTitle: "Analytics",
    })
    .state("app.reporting.main.chat-analytics", {
      url: "/chat",
      controller: "ReportingPageController",
      templateUrl: "reporting/chat-analytics.html",
      controllerAs: "vm",
      pageTitle: "Analytics",
    })
    .state("app.reporting.main.exports", {
      url: "/exports",
      templateUrl: "reporting/exports.html",
      controllerAs: "vm",
      pageTitle: "Exports",
    })
    .state("app.reporting.shared", {
      url: "/:teamId/shared/:inboxId",
      templateUrl: "reporting/inbox-detail.html",
      params: { inbox: "shared" },
      controller: "InboxDetailController",
      controllerAs: "vm",
      pageTitle: "Analytics - Shared Inbox Detail",
    })
    .state("app.reporting.highspeed", {
      url: "/:teamId/highspeed/:inboxId",
      templateUrl: "reporting/inbox-detail.html",
      params: { inbox: "highspeed" },
      controller: "InboxDetailController",
      controllerAs: "vm",
      pageTitle: "Analytics - Shared Inbox Detail",
    });
});
