(function() {
'use strict';

angular.module('mongoose').controller('NewMessageTypeController', NewMessageTypeController);

function NewMessageTypeController($scope, $uibModalInstance, Restangular, growl) {

    var vm = this;

    vm.messageType = {
        isEnabled: true
    };

    vm.dismiss = $uibModalInstance.dismiss;

    vm.create = function() {
        Restangular.all('admin/messageTypes').post(vm.messageType).then(function() {
            growl.success("Your message type has been created!");
            $uibModalInstance.close();
        },
        function(errResponse) {
            $scope.serverValidationError = errResponse.data;
        });
    };
}

})();
