/**
 * @desc checklist for message sending logic
 * @example <div compose-checklist></div>
 */
angular.module("mongoose").directive("composeChecklist", composeChecklist);

function composeChecklist(Restangular, $timeout, securityService) {
  return {
    link: linkFunc,
    templateUrl: "messages/modals/compose-checklist.html",
    restrict: "EA",
  };

  function linkFunc(scope) {
    securityService.getProfile().then(function (response) {
      scope.messageSendSpeed = response.currentDepartment.messageSendSpeed;
    });

    var estimate = scope.estimate,
      count = estimate.uniqueCount,
      minStartTime = _.min(
        _.map(estimate.details, function (d) {
          return new Date(d.estimatedStartTime);
        })
      ),
      maxEndTime = _.max(
        _.map(estimate.details, function (d) {
          return new Date(d.estimatedEndTime);
        })
      ),
      longestQueue = _.max(_.map(estimate.details, "queuedMessages")),
      duration = moment.duration(moment(maxEndTime).diff(moment(minStartTime))),
      formattedDuration = duration.format("h[h] m[m]"),
      startTime = moment(minStartTime || new Date()),
      formattedStartTime = startTime.calendar(null, {
        sameDay: "h:mm a",
        nextDay: "[tomorrow at] h:mm a",
        sameElse: "M/D/YY",
      }),
      endTime = moment(maxEndTime),
      formattedEndTime = endTime.calendar(null, {
        sameDay: "h:mm a",
        nextDay: "[tomorrow at] h:mm a",
        sameElse: "M/D/YY",
      }),
      sendingToStudents = scope.message.students && !scope.selectSegments,
      validStartTime = moment().startOf("day").set("hour", 8), // 8am
      validEndTime = moment().startOf("day").set("hour", 20), // 8pm
      doNotDisturbStartTime = moment().startOf("day").set("hour", 22), // 10pm
      doNotDisturbEndTime = moment().startOf("day").set("hour", 6), // 6am
      validSendDuration = moment.duration(doNotDisturbStartTime.diff(doNotDisturbEndTime)),
      durationMessage =
        sendingToStudents && count <= 5
          ? "less than 1 minute"
          : scope.isScheduledMessage
          ? formattedDuration
          : longestQueue > 0
          ? formattedDuration + " (" + formattedStartTime + " - " + formattedEndTime + ")"
          : formattedDuration + " (now - " + formattedEndTime + ")";

    scope.showDeliveryEstimate = showDeliveryEstimate;
    scope.showMMSDisclaimer = showMMSDisclaimer;

    scope.recipients = {
      autoOpenTooltip: false,
      status: "success",
      count: count,
      tooltipIsOpen: false,
      showSenders: false,
      senderList: {
        isLoading: true,
        isOpen: false,
        senders: estimate.details,
        toggle: function () {
          $("body").trigger("click.popover");
          scope.recipients.senderList.isOpen = !scope.recipients.senderList.isOpen;
          if (scope.recipients.senderList.isOpen) {
            $("body").bind("click.popover", function (e) {
              if ($(e.target).parents(".popover").length === 0 && !$(e.target).attr("uib-popover-template")) {
                $("body").unbind("click.popover");
                $timeout(function () {
                  scope.recipients.senderList.isOpen = false;
                });
              }
            });
          }
        },
      },
    };

    scope.duration = {
      autoOpenTooltip: false,
      status: "success",
      message: durationMessage,
      queueWarning: longestQueue > 0,
      tooltip: null,
      tooltipIsOpen: false,
      dismissAction: function () {
        scope.duration.isDismissed = true;
        scope.duration.tooltipIsOpen = false;
        scope.disableSend = false;
        scope.disableSchedule = false;

        mixpanel.track("Dismissed Send Duration Warning");
      },
    };

    if (count === 0) {
      scope.disableSend = true;
      scope.disableSchedule = true;
      scope.recipients.status = "danger";
      scope.recipients.tooltip =
        "There must be at least one recipient to send this message." +
        (estimate.restrictToAssignment
          ? " You&rsquo;ve selected &ldquo;Send to my assigned contacts only&rdquo; so it is possible that no contacts are assigned to you."
          : "");

      scope.duration.status = "warning";
      scope.duration.message = "to be determined";
      scope.duration.tooltip =
        "Delivery duration cannot be determined until the number of recipients issue is resolved.";
    } else {
      if (scope.message.targets.length || scope.message.filters.length) {
        scope.recipients.tooltip =
          'It&rsquo;s possible that messages may not be sent to some contacts. <a href="http://help.mongooseresearch.com/article/' +
          (scope.message.targets.length ? "198-send-to-a-radius-target" : "199-send-to-a-connect-filter") +
          '" target="_blank">Learn more</a>';
        scope.recipients.autoOpenTooltip = true;
        scope.recipients.trigger = "click";
      }

      if (estimate.staffSend) {
        scope.recipients.showSenders = true;
        Restangular.all("staff")
          .getList()
          .then(function (staff) {
            _.each(scope.recipients.senderList.senders, function (s) {
              var user = _.find(staff, { id: s.staffId });
              s.name = user ? user.displayName : "Unknown";
            });
            scope.recipients.senderList.isLoading = false;
          });
      }

      if (!scope.isScheduledMessage) {
        if (duration.asHours() > validSendDuration.asHours() && !scope.disableQuietHoursSafeguard) {
          // Wont finish in a whole day :(
          scope.disableSend = true;
          scope.disableSchedule = true;
          scope.duration.status = "danger";
          scope.duration.disabledMessage =
            "This message has too many recipients to finish sending between the valid hours of " +
            doNotDisturbEndTime.format("h:mm a") +
            " and " +
            doNotDisturbStartTime.format("h:mm a") +
            ".";
        } else {
          // Send Time Warning!
          if (startTime.isBefore(validStartTime) && scope.account.type !== "high-speed") {
            scope.disableSend = true;
            scope.duration.status = "warning";
            scope.duration.tooltip =
              "It is not recommended to send messages before " + validStartTime.format("h:mm a") + ".";
            scope.duration.autoOpenTooltip = true;
            scope.duration.dismissRequired = true;
          } else if (endTime.isAfter(validEndTime) && scope.account.type !== "high-speed") {
            scope.disableSend = true;
            scope.duration.status = "warning";
            scope.duration.tooltip =
              "Please note that some of your messages may not be delivered until after " +
              validEndTime.format("h:mm a") +
              ".";
            scope.duration.autoOpenTooltip = true;
            scope.duration.dismissRequired = true;
          }

          // Send Time Alert!!!
          if (
            !scope.disableQuietHoursSafeguard &&
            !scope.isMongooseAdministrator &&
            (startTime.isBefore(doNotDisturbEndTime) || endTime.isAfter(doNotDisturbStartTime))
          ) {
            scope.disableSend = true;
            scope.duration.status = "danger";
            scope.duration.disabledMessage =
              "Message sending is not allowed between " +
              doNotDisturbStartTime.format("h:mm a") +
              " and " +
              doNotDisturbEndTime.format("h:mm a") +
              " to prevent disturbing recipients overnight.&ensp;\uD83D\uDE34";
            scope.duration.autoOpenTooltip = false;
            scope.duration.dismissRequired = false;
            mixpanel.track("Prevented From Sending Night Message", {
              "Start Time": moment(startTime).format("h:mm a"),
              "End Time": moment(endTime).format("h:mm a"),
            });
          }
        }
      }
    }

    $timeout(function () {
      if (scope.recipients.autoOpenTooltip) {
        scope.recipients.tooltipIsOpen = true;
      }
      if (scope.duration.autoOpenTooltip) {
        scope.duration.tooltipIsOpen = true;
      }
    }, 800);

    function showDeliveryEstimate() {
      return scope.account.type !== "high-speed";
    }

    function showMMSDisclaimer() {
      return scope.messageSendSpeed && [2, 3].includes(scope.messageSendSpeed) && scope.message.mediaUris.length > 0;
    }
  }
}
