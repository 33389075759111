angular.module('mongoose').controller('OutOfOfficeController', function ($rootScope, $state, profile, Restangular) {
    var vm = this,
        defaultConfig = {
            enabled: false,
            replyMessage: ''
        };

    vm.submit = submit;

    $rootScope.$on('reloadOutOfOffice', activate);


    activate();

    function activate(){
        vm.options = profile.outOfOfficeConfig ? _.cloneDeep(profile.outOfOfficeConfig) : defaultConfig;
    }

    function submit() {
        Restangular.all('profile/outofoffice').post(vm.options).then(function () {
                $rootScope.$broadcast('profileChanged');
                if(vm.options.enabled){
                    mixpanel.track("Turned Out of Office On", {
                        'Out of Office Character Count': vm.options.replyMessage.length
                    });
                } else {
                    mixpanel.track("Turned Out of Office Off", {
                        'Via': 'Page'
                    });
                }
            },
            function (error) {
                growl.error(error && error.data ? error.data : "There was an issue updating your Out of Office settings.");
            });
    }
});