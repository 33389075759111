angular.module('mongoose').controller('SlateConfigurationController', function ($rootScope, $scope, $state, growl, slateService) {

    var vm = this;

    function initialize() {
        slateService.getConfiguration().then(function (configuration) {
            if (configuration) {
                vm.configuration = configuration;
            } else {
                vm.configuration = {
                }
            }
        });
    }

    vm.save = function () {
        slateService.saveConfiguration(vm.configuration).then(function(response) {
            growl.success("Slate integration configuration updated!");
            initialize();
        });
    };

    vm.cancel = function () {
        initialize();
    };

    initialize();
});