angular.module("mongoose").controller("SalesforceMessageConfigurationController", function (Restangular, $q, growl) {
  var vm = this;

  vm.fieldTypes = [
    { name: "<Message Text>", value: "#MessageText#" },
    { name: "<Message Date>", value: "#MessageDate#" },
    { name: "<Message Date Time>", value: "#MessageDateTime#" },
    { name: "<Message Type Code>", value: "#MessageType#" },
    { name: "Custom", value: "Custom" },
  ];

  vm.messageTypes = ["All", "Incoming", "Outgoing"];

  function getRelationshipFields() {
    var relationObject = vm.messageConfig.salesforceObject;

    Restangular.all("salesforce/relationshipFields")
      .getList({ relationshipObject: relationObject })
      .then(function (fields) {
        vm.relationFields = fields;
      });
  }

  var load = function () {
      vm.loaded = false;

      var requests = [
        Restangular.one("salesforce/messageConfiguration").get(),
        Restangular.one("salesforce/configuration").get(),
        Restangular.all("salesforce/contactRelationships").getList(),
      ];

      return $q.all(requests).then(function (results) {
        var messageConfig = results[0];
        vm.config = results[1].plain();
        vm.contactRelationships = results[2];

        vm.newMessageField = { messageType: vm.messageTypes[0] };

        if (messageConfig) {
          vm.messageConfig = messageConfig.plain();
          getRelationshipFields();
        } else {
          vm.messageConfig = {};
        }

        vm.showScreen = vm.config.apiUsername && vm.config.instanceUrl && vm.config.clientId;
        vm.loaded = true;
      });
    },
    setup = function () {
      vm.save = function () {
        var config = {
          messageExportConfiguration: angular.toJson(vm.messageConfig),
        };
        Restangular.all("salesforce/messageConfiguration")
          .post(config)
          .then(function () {
            growl.success("Messaging configuration updated!");
            load();
          });
      };

      vm.cancel = function ($event) {
        $event.preventDefault();
        load();
      };

      vm.getRelationshipFields = getRelationshipFields;

      vm.getMessageFieldLabel = function (id) {
        var label = _.find(vm.relationFields, { name: id });
        return label ? label.label : id;
      };

      vm.getValue = function (fieldValue) {
        var label = _.find(vm.fieldTypes, { value: fieldValue });
        return label ? label.name : fieldValue;
      };

      vm.removeMessageField = function ($event, field) {
        $event.preventDefault();
        _.pull(vm.messageConfig.messageFields, field);
      };

      vm.addMessageField = function ($event, field) {
        $event.preventDefault();
        if (
          !field ||
          !field.name ||
          !field.messageType ||
          (field.fieldType.value === "Custom" && !field.customFieldValue)
        )
          return;

        field.value = field.fieldType.value === "Custom" ? field.customFieldValue : field.fieldType.value;

        if (!vm.messageConfig.messageFields) vm.messageConfig.messageFields = [];

        vm.messageConfig.messageFields.push(_.clone(field));

        field.fieldName = "";
        field.fieldType = {};
        field.value = "";
        field.customFieldValue = "";
        field.messageType = vm.messageTypes[0];
      };
    };

  load().then(setup);
});
