(function() {
'use strict';
angular.module('mongoose').controller('DeleteDepartmentController', DeleteDepartmentController);

function DeleteDepartmentController($uibModalInstance, Restangular, growl, department, departments) {
    var vm = this;

    vm.delete = deleteDept;
    vm.dismiss = $uibModalInstance.dismiss;


    activate();

    function activate() {
        vm.department = department;
        vm.departments = _.filter(departments,function(d) {return d.id !== department.id;});
    }

    function deleteDept() {
        vm.loading = true;
        vm.validationMessage = '';

        var deleteRequest = { departmentId: vm.department.id };

        Restangular.one('departments').customPOST(deleteRequest,'delete').then(function () {
            growl.success("Department has been deleted successfully!");
            $uibModalInstance.close();
        }, function(error) {
            vm.loading = false;
            vm.validationMessage = error.data ? error.data : 'Error deleting your apartment';
        });
    }

}
})();