angular
  .module("mongoose")
  .controller(
    "TemplatesListController",
    function ($scope, $uibModal, Restangular) {
      $scope.delete = deleteFunc;
      $scope.edit = edit;

      activate();

      function activate() {
        Restangular.one("templates")
          .getList("all")
          .then(function (templates) {
            $scope.templates = templates;
          });
      }

      function deleteFunc(template) {
        var modalInstance = $uibModal.open({
          templateUrl: "admin/department/templates/delete-template-modal.html",
          controller: "DeleteTemplateModalController",
          controllerAs: "vm",
          ariaLabelledBy: "delete-template",
          resolve: {
            template: function () {
              return template;
            },
          },
        });

        modalInstance.result.then(
          function () {
            activate();
          },
          function () {}
        );
      }

      function edit(template) {
        var modalInstance = $uibModal.open({
          templateUrl: "admin/department/templates/new.html",
          controller: "NewTemplateController",
          ariaLabelledBy: "new-template",
          resolve: {
            template: function () {
              return template;
            },
          },
        });
        modalInstance.result.then(function () {
          activate();
        });
      }
    }
  );
