angular
  .module("mongoose")
  .controller("SharedInboxController", function (profile, $rootScope) {
    let vm = this;
    vm.orgId = profile.currentDepartment.organizationId;
    vm.reloadState = refreshState;

    function refreshState() {
      $rootScope.reloadState();
    }
  });
