angular
  .module("mongoose")
  .controller(
    "SalesforceImportConfigurationController",
    function ($scope, $q, Restangular, config, salesforceService, growl) {
      var vm = this;
      vm.showMongooseFieldChooser = config.customFields.enabled;
      vm.optOutOptions = ["Picklist", "Checkbox"];
      vm.customField = {};

      $scope.$watchCollection("vm.importConfig.contacts.firstNameFields", firstNameContactFieldChange);
      $scope.$watchCollection("vm.importConfig.leads.firstNameFields", firstNameLeadFieldChange);

      function filterCustomFields() {
        var usedFields;

        if (vm.showMongooseFieldChooser) {
          usedFields = _.pluck(vm.importConfig.contacts.customFields, "mongooseFieldName");
          vm.contactCustomFields = _.filter(vm.allCustomFields, function (f) {
            return !_.includes(usedFields, f.displayName);
          });

          usedFields = _.pluck(vm.importConfig.leads.customFields, "mongooseFieldName");
          vm.leadCustomFields = _.filter(vm.allCustomFields, function (f) {
            return !_.includes(usedFields, f.displayName);
          });
        }
      }

      function firstNameContactFieldChange(newValue) {
        if (!newValue) return;

        if (_.isNull(newValue[0])) {
          vm.importConfig.contacts.firstNameFields = [];
        }
      }

      function firstNameLeadFieldChange(newValue) {
        if (!newValue) return;

        if (_.isNull(newValue[0])) {
          vm.importConfig.leads.firstNameFields = [];
        }
      }

      var load = function () {
          vm.loaded = false;

          vm.sourcePreview = "";

          var requests = [
            Restangular.one("salesforce/configuration").get(),
            Restangular.all("customFields").getList(),
            Restangular.one("salesforce/importConfiguration").get(),
            Restangular.all("salesforce/listViews").getList(),
            Restangular.all("salesforce/reports").getList(),
          ];

          return $q.all(requests).then(function (results) {
            vm.config = results[0];
            var importConfig = results[2];
            vm.listViews = results[3];
            vm.reports = results[4];

            vm.allCustomFields = _.sortBy(results[1].plain(), function (f) {
              return f.displayName;
            });

            var metaContactReq,
              metaLeadReq,
              blankConfig = {
                firstNameFields: [],
                optOut: {
                  fieldType: vm.optOutOptions[0],
                  salesforceField: null,
                },
                customFields: [],
                sourceType: "ListView",
              };

            vm.importConfig = {};

            if (importConfig.contactConfiguration) {
              vm.importConfig.contacts = importConfig.plain().contactConfiguration;

              if (vm.importConfig.contacts.report == null && vm.importConfig.contacts.listView == null) {
                vm.importConfig.contacts = angular.copy(blankConfig);
                metaLeadReq = $q.when(null);
              } else {
                if (vm.importConfig.contacts.sourceType === "ListView") {
                  metaContactReq = Restangular.one("salesforce/listview/" + vm.importConfig.contacts.listView).get({
                    importType: "Contact",
                  });
                } else {
                  metaContactReq = Restangular.one("salesforce/report/" + vm.importConfig.contacts.report).get();
                }
              }
            } else {
              vm.importConfig.contacts = angular.copy(blankConfig);
              metaContactReq = $q.when(null);
            }

            if (importConfig.leadConfiguration) {
              vm.importConfig.leads = importConfig.plain().leadConfiguration;

              if (vm.importConfig.leads.report == null && vm.importConfig.leads.listView == null) {
                vm.importConfig.leads = angular.copy(blankConfig);
                metaLeadReq = $q.when(null);
              } else {
                if (vm.importConfig.leads.sourceType === "ListView") {
                  metaLeadReq = Restangular.one("salesforce/listview/" + vm.importConfig.leads.listView).get({
                    importType: "Lead",
                  });
                } else {
                  metaLeadReq = Restangular.one("salesforce/report/" + vm.importConfig.leads.report).get();
                }
              }
            } else {
              vm.importConfig.leads = angular.copy(blankConfig);
              metaLeadReq = $q.when(null);
            }

            filterCustomFields();

            vm.showScreen = vm.config.apiUsername && vm.config.instanceUrl && vm.config.clientId;

            $q.all([metaContactReq, metaLeadReq]).then(function (results) {
              vm.sourceContactMeta = results[0];
              vm.sourceLeadMeta = results[1];
              vm.loaded = true;

              if (
                vm.sourceContactMeta &&
                !_.find(vm.sourceContactMeta.fields, { name: vm.importConfig.contacts.optOut.salesforceField })
              ) {
                vm.importConfig.contacts.optOut.salesforceField = null;
              }

              if (
                vm.sourceLeadMeta &&
                !_.find(vm.sourceLeadMeta.fields, { name: vm.importConfig.leads.optOut.salesforceField })
              ) {
                vm.importConfig.leads.optOut.salesforceField = null;
              }
            });
          });
        },
        setup = function () {
          vm.save = function () {
            var config = {
              studentImportConfiguration: angular.toJson(vm.importConfig.contacts),
              studentContactImportConfiguration: angular.toJson(vm.importConfig.contacts),
              studentLeadImportConfiguration: angular.toJson(vm.importConfig.leads),
            };
            Restangular.all("salesforce/importConfiguration")
              .post(config)
              .then(function () {
                salesforceService.saveConfiguration(vm.config).then(function () {
                  growl.success("Salesforce integration configuration updated!");
                  load();
                });
              });
          };

          vm.getSalesforceFieldLabel = function (name) {
            var label = _.find(vm.salesforceFields, { name: name });
            return label ? label.label : name;
          };

          vm.removeCustomField = function ($event, field, config) {
            $event.preventDefault();
            _.pull(config.customFields, field);
            filterCustomFields();
          };

          vm.getContactMeta = function () {
            if (vm.importConfig.contacts.sourceType === "ListView" && vm.importConfig.contacts.listView) {
              Restangular.one("salesforce/listview/" + vm.importConfig.contacts.listView)
                .get({ importType: "Contact" })
                .then(function (response) {
                  vm.sourceContactMeta = response;
                });
            } else if (vm.importConfig.contacts.sourceType === "Report" && vm.importConfig.contacts.report) {
              Restangular.one("salesforce/report/" + vm.importConfig.contacts.report)
                .get()
                .then(function (response) {
                  vm.sourceContactMeta = response;
                });
            } else {
              vm.sourceContactMeta = null;
            }
          };

          vm.getLeadMeta = function () {
            if (vm.importConfig.leads.sourceType === "ListView" && vm.importConfig.leads.listView) {
              Restangular.one("salesforce/listview/" + vm.importConfig.leads.listView)
                .get({ importType: "Lead" })
                .then(function (response) {
                  vm.sourceLeadMeta = response;
                });
            } else if (vm.importConfig.leads.sourceType === "Report" && vm.importConfig.leads.report) {
              Restangular.one("salesforce/report/" + vm.importConfig.leads.report)
                .get()
                .then(function (response) {
                  vm.sourceLeadMeta = response;
                });
            } else {
              vm.sourceLeadMeta = null;
            }
          };

          vm.addCustomField = function ($event, field, config) {
            $event.preventDefault();
            if (!field || !field.mongooseFieldName || !field.salesforceFieldName) return;
            if (!config.customFields) config.customFields = [];
            config.customFields.push(_.clone(field));
            field.mongooseFieldName = "";
            field.salesforceFieldName = "";
            filterCustomFields();
          };

          vm.cancel = function ($event) {
            $event.preventDefault();
            load();
          };

          vm.invalidConfig = function () {
            var contactsConfig = vm.importConfig.contacts;
            if (contactsConfig.report != null) {
              if (
                contactsConfig.idField == null ||
                contactsConfig.lastModifiedDateField == null ||
                contactsConfig.firstNameFields[0] == null ||
                contactsConfig.lastNameField == null ||
                contactsConfig.mobileNumberField == null
              ) {
                return true;
              }
            }
            if (contactsConfig.listView != null) {
              if (
                contactsConfig.firstNameFields[0] == null ||
                contactsConfig.lastNameField == null ||
                contactsConfig.mobileNumberField == null
              ) {
                return true;
              }
            }

            var leadsConfig = vm.importConfig.leads;
            if (leadsConfig.report != null) {
              if (
                leadsConfig.idField == null ||
                leadsConfig.lastModifiedDateField == null ||
                leadsConfig.firstNameFields[0] == null ||
                leadsConfig.lastNameField == null ||
                leadsConfig.mobileNumberField == null
              ) {
                return true;
              }
            }
            if (leadsConfig.listView != null) {
              if (
                leadsConfig.firstNameFields[0] == null ||
                leadsConfig.lastNameField == null ||
                leadsConfig.mobileNumberField == null
              ) {
                return true;
              }
            }

            return false;
          };
        };

      load().then(setup);
    }
  );
