angular.module('mongoose').controller('MediaModalController', function ($scope, $uibModalInstance, messageId, media, Restangular) {
    var initialize = function() {
        $scope.photos = [];
        $scope.videos = [];
        var photoMimeTypes = [
            'image/jpeg', 'image/gif','image/png'
        ];

        if(media){
            if(new RegExp("^image\/*").test(media.mimeType)) {
                $scope.photos.push({mediaUrl: media.url});
            } else if (new RegExp("^video\/*").test(media.mimeType)){
                $scope.videos.push({mediaUrl: media.url});
            }
        } else {
            Restangular.one("messages", messageId).all("media").getList().then(function(results) {
                $scope.photos = _.filter(results, function(r) {
                    if (_.includes(photoMimeTypes, r.mediaContentType))
                        return r;
                });
            });
        }
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };
    
    $scope.openOriginal = function(url) {
        window.open(url,'_blank');
    };

    initialize();
});