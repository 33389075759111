angular
  .module("mongoose")
  .factory(
    "securityService",
    function (
      $rootScope,
      $q,
      $window,
      $location,
      $cookies,
      $timeout,
      $state,
      firebaseService,
      Restangular,
      configurationService
    ) {
      var profile,
        returnUrl = $location.search().returnUrl,
        profilePromise;

      function clearStorage() {
        $window.sessionStorage.removeItem("campaigns-filters");
        $window.sessionStorage.removeItem("compose-options");
        $window.sessionStorage.removeItem("segments-filters");
        $window.sessionStorage.removeItem("student-search-params");
        $window.sessionStorage.removeItem("users-filters");
        $window.sessionStorage.removeItem("analytics-date-picker");
        $window.sessionStorage.removeItem("analytics-team-filter");
      }

      function killTokens() {
        delete $window.localStorage.token;
        delete $window.localStorage.profile;
        delete $window.localStorage.cadenceUserAvailability;
        delete $window.localStorage.selectedBotId;
        $cookies.remove("mg_help");
      }

      function processToken(token, redirectOnAuthState, disableReload, changeDepartment, caretaking) {
        killTokens();
        $window.localStorage.token = token;
        configurationService.get().then(function (config) {
          $rootScope.config = config;
          executeTokenProcess(token, redirectOnAuthState, disableReload, changeDepartment, caretaking);
        });
      }

      function executeTokenProcess(token, redirectOnAuthState, disableReload, changeDepartment, caretaking) {
        loadProfile().then(function () {
          if (returnUrl) {
            $window.location.href = returnUrl;
          } else {
            if (redirectOnAuthState) {
              //***Be very careful before changing anything inside this block***
              //This block is responsible for redirecting user to the correct state after login
              let isChatOnlyUser =
                profile.currentDepartment.userIsChatAgent && !profile.currentDepartment.userHasTexting;
              let isChatOnlyTeam = profile.currentDepartment.teamHasChat && !profile.currentDepartment.teamHasSms;

              if ($rootScope.config.features["rollout-organizational-dashboard"] && $rootScope.config.aiEnabled) {
                redirectOnAuthState = "app.organizationDashboard";
              }
              if (isChatOnlyUser || isChatOnlyTeam) {
                redirectOnAuthState = "app.webchat.list";
              }
              $state.go(redirectOnAuthState, { isLoginRedirect: !changeDepartment }, { reload: !disableReload });
              if (
                caretaking ||
                (changeDepartment &&
                  redirectOnAuthState !== "app.webchat.list" &&
                  $state.current.name !== "app.webchat.list")
              ) {
                //need extra reload to start up single spa app
                $window.location.reload();
              }
              return;
            }
          }
        });
      }

      function setHelpCenterCookie(prof) {
        var helpCenterData = {
          name: prof.displayName,
          email: prof.emailAddress,
        };
        $cookies.putObject("mg_help", helpCenterData, {
          secure: false,
          domain: ".mongooseresearch.com",
        });
      }

      function signOut(manual, expiredFirebaseToken) {
        mixpanel.track("Signed Out", {
          "Sign Out Reason": manual ? "Manual" : expiredFirebaseToken ? "Firebase Token Expired" : "Other",
        });

        $timeout(function () {
          clearStorage();
          killTokens();

          firebaseService.signOut();

          $window.location.href = "/";
        });
        return $q.reject();
      }

      function loadProfile(disableLoading) {
        return (profilePromise = Restangular.all("profile")
          .customGET("", {}, { "x-disable-loading": !!disableLoading })
          .then(function (response) {
            $rootScope.isPublic = false;
            profile = response.plain();
            profile.displayName = profile.firstName + " " + profile.lastName;
            profile.isAdministrator = profile.currentDepartment.isAdministrator || profile.isOwner;
            profile.isChatAdministrator = profile.currentDepartment.isChatAdministrator;
            profile.avatar = "https://www.gravatar.com/avatar/" + md5(profile.emailAddress) + "?r=pg&d=blank";
            profile.serverTimeOffset = new Date(profile.utcServerTime).getTime() - new Date().getTime() || 0;
            profile.inboxes = _.filter(profile.inboxes, (inbox) => inbox.type !== "high-speed");

            $window.localStorage.profile = JSON.stringify(profile);
            setHelpCenterCookie(profile);

            return firebaseService.auth(profile).then(
              function () {
                return profile;
              },
              function () {
                return profile;
              }
            );
          }));
      }

      function getProfile() {
        return _.isUndefined(profilePromise) ? loadProfile() : profilePromise;
      }

      function authorizeRoute(state) {
        var allPermissionedStates = [
            ".owner.",
            ".admin.",
            ".ethos.",
            ".radius",
            ".connect.",
            ".mongoose.",
            ".slate.",
            ".salesforce.",
            ".workday.",
          ],
          verifyPermission = false;

        _.forEach(allPermissionedStates, function (ps) {
          if (_.includes(state, ps)) {
            verifyPermission = true;
          }
        });

        if (!verifyPermission) {
          return $q.when(true);
        }

        return getProfile().then(function (p) {
          if (_.includes(state, ".owner.") && !p.isOwner) return false;
          if (
            (_.includes(state, ".admin.") ||
              _.includes(state, ".radius") ||
              _.includes(state, ".connect.") ||
              _.include(state, ".ethos.") ||
              _.includes(state, ".slate.") ||
              _.include(state, ".salesforce.") ||
              _.include(state, ".workday.")) &&
            !p.isAdministrator
          )
            return false;
          return !(_.includes(state, ".mongoose.") && !p.isMongooseAdministrator);
        });
      }

      function isAuthorized(roles) {
        var authorized = false;
        if (roles && roles.length > 0) {
          if (_.contains(roles, "mongoose") && profile.isMongooseAdministrator) authorized = true;
          if (_.contains(roles, "admin") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "chatAdmin") && profile.isChatAdministrator) authorized = true;
          if (_.contains(roles, "owner") && profile.isOwner) authorized = true;
          if (_.contains(roles, "ethos") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "ftp") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "radius") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "slate") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "salesforce") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "sso") && profile.isOwner) authorized = true;
          if (_.contains(roles, "connect") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "workday") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "recruit") && profile.isAdministrator) authorized = true;
          if (_.contains(roles, "raisersEdgeNxt") && profile.isAdministrator) authorized = true;
        } else {
          authorized = true;
        }
        return authorized;
      }

      return {
        clearStorage: clearStorage,
        loadProfile: loadProfile,
        getProfile: getProfile,
        authorizeRoute: authorizeRoute,
        isAuthorized: isAuthorized,
        signOut: signOut,
        processToken: processToken,
      };
    }
  );
