angular.module('mongoose').controller('SlateExportConfigurationController', function ($scope, growl, slateService, config) {

    var vm = this;

    function initialize() {
        slateService.getConfiguration().then(function (configuration) {
            configuration.optOutsScheduleTime = slateService.timeToLocalDate(configuration.optOutsScheduleTime, config);
            configuration.messageActivityScheduleTime = slateService.timeToLocalDate(configuration.messageActivityScheduleTime, config);
            configuration.deliveryFailuresScheduleTime = slateService.timeToLocalDate(configuration.deliveryFailuresScheduleTime, config);
            vm.configuration = configuration;
        });
    };

     vm.save = function() {
        
        if (!vm.configuration.optOutsScheduled) {
            vm.configuration.optOutsScheduleTime = null;
        } else {
            vm.configuration.optOutsScheduleTime = slateService.localDateToUtcTimespan(vm.configuration.optOutsScheduleTime);
        }

        if (!vm.configuration.messageActivityScheduled) {
            vm.configuration.messageActivityScheduleTime = null;
        } else {
            vm.configuration.messageActivityScheduleTime = slateService.localDateToUtcTimespan(vm.configuration.messageActivityScheduleTime);
        }
        
        if (!vm.configuration.deliveryFailuresScheduled) {
            vm.configuration.deliveryFailuresScheduleTime = null;
        } else {
            vm.configuration.deliveryFailuresScheduleTime = slateService.localDateToUtcTimespan(vm.configuration.deliveryFailuresScheduleTime);
        }

        slateService.saveConfiguration(vm.configuration).then(function(response) {
            growl.success("Slate export configuration updated!");
            initialize();
        });
    };

    vm.cancel = function () {
        initialize();
    };

    initialize();
});