angular.module("mongoose").factory("caretakerService", function ($rootScope, $q, Restangular, securityService, growl) {
  function assign(caretakerId, targetId) {
    var deferred = $q.defer(),
      body = {
        CaretakerStaffId: caretakerId,
        TargetStaffId: targetId,
      };

    Restangular.one("caretakers")
      .customPOST(body, "")
      .then(
        function (response) {
          $rootScope.$broadcast("caretakersUpdated");
          deferred.resolve(response);
        },
        function (error) {
          growl.error(error.data);
          deferred.reject(error);
        }
      );

    return deferred.promise;
  }

  function get() {
    return Restangular.all("caretakers/all").getList();
  }

  function getForUser() {
    var deferred = $q.defer();

    Restangular.all("caretakers")
      .getList("", { "x-disable-loading": "true" })
      .then(
        function (response) {
          var targets = response.plain();
          targets.unreadMessagesTotal = _.sum(targets, "unreadMessages");
          deferred.resolve(targets);
        },
        function (error) {
          growl.error(error.message);
          deferred.reject(error);
        }
      );

    return deferred.promise;
  }

  function impersonate(id) {
    var body = {
      TargetStaffId: id,
    };
    return Restangular.one("auth/caretake")
      .customPOST(body)
      .then(function (response) {
        securityService.clearStorage();
        securityService.processToken(response, "app.dashboard", false, true, true);
      });
  }

  function remove(caretakerId, targetId) {
    var deferred = $q.defer(),
      body = {
        CaretakerStaffId: caretakerId,
        TargetStaffId: targetId,
      };

    Restangular.one("caretakers/remove")
      .customPOST(body, "")
      .then(
        function (response) {
          $rootScope.$broadcast("caretakersUpdated");
          growl.success("The Account Caretaker has been removed from this user.");
          deferred.resolve(response);
        },
        function (error) {
          growl.error(error.message);
          deferred.reject(error);
        }
      );

    return deferred.promise;
  }

  function revert() {
    return Restangular.all("auth/revertcaretaking")
      .post()
      .then(function (response) {
        securityService.processToken(response, "app.dashboard", false, true, true);
      });
  }

  return {
    assign: assign,
    get: get,
    getForUser: getForUser,
    impersonate: impersonate,
    remove: remove,
    revert: revert,
  };
});
