(function () {
  "use strict";

  var comparisons = [
    {
      label: "Equals",
      value: "equals",
    },
    {
      label: "Starts With",
      value: "startswith",
    },
    {
      label: "Is Not Empty",
      value: "isnotempty",
    },
    {
      label: "Is Empty",
      value: "isempty",
    },
    {
      label: "Not Equals",
      value: "notequals",
    },
    {
      label: "Is Greater Than Or Equal To",
      value: "isgreaterthan",
    },
    {
      label: "Is Less Than Or Equal To",
      value: "islessthan",
    },
    {
      label: "Equals",
      value: "with",
    },
    {
      label: "Does Not Equal",
      value: "without",
    },
  ];

  angular.module("mongoose").constant("comparisons", comparisons);
})();
