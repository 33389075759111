/**
 * @desc 
 * @example <label-tags-input></label-tags-input>
 */
angular
    .module('mongoose')
    .directive('labelTagsInput', labelTagsInput);

function labelTagsInput() {

    return {
        link: linkFunc,
        template: template,
        restrict: 'E',
        replace: true
    };

    function template(element, attrs) {
        var html = '<div class="sr-only" id="' + attrs.for + '">' + attrs.label + '</div>';
        return html;
    }

    function linkFunc(scope, el) {
        var input = $(el[0]).parent('.host').find('input');
        input.attr('aria-labelledby', el[0].attributes["id"].value);
    }
}