/**
 * @desc drag overlay & file select
 * @example <media-picker-overlay></media-picker-overlay>
 */
angular
    .module('mongoose')
    .directive('mediaPickerOverlay', mediaPickerOverlay);

function mediaPickerOverlay() {
    return {
        link: linkFunc,
        templateUrl: 'core/compose-toolbar/media-picker-overlay.html',
        restrict: 'E'
    };

    function linkFunc(scope) {
    }
}