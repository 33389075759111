angular.module('mongoose').config(function ($stateProvider) {

    $stateProvider.state('app.notifications', {
            url: '/notifications',
            templateUrl: 'notifications/notifications.html',
            controller: 'UserNotificationsController',
            controllerAs: 'vm',
            pageTitle: "Notifications"
        });
});
