/**
 * @desc used to set popover-trigger dynamically and recompile popover
 * @example <div custom-popover-trigger></div>
 */
angular
    .module('mongoose')
    .directive('customPopoverTrigger', customPopoverTrigger);

function customPopoverTrigger($compile) {

    return {
        link: linkFunc
    };

    function linkFunc(scope, element, attrs) {

        element.removeAttr('custom-popover-trigger');

        attrs.$observe('customPopoverBehavior', function(){
            element.attr('popover-trigger', "'" + attrs.customPopoverBehavior + "'");
            $compile(element)(scope);
        });
    }
}