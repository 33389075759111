angular
  .module("mongoose")
  .controller(
    "MainController",
    function (
      $rootScope,
      $scope,
      $q,
      growl,
      $window,
      $state,
      $timeout,
      $uibModal,
      $interval,
      caretakerService,
      Restangular,
      config,
      profile,
      helpContent,
      configurationService,
      securityService,
      appVersion,
      partnerUrl,
      $mdSidenav,
      $mdComponentRegistry,
      notificationService,
      statusService,
      $mdMedia,
      viewportService
    ) {
      var configPoll,
        composeModal,
        config = configurationService.setPermissionFeatureFlags(profile),
        isPolling = false;

      let webChatStatuses = [
        {
          name: "Available",
          value: 1,
          icon: "check-circle",
          iconColor: "#2DAD00",
          toggleIcon: "comment-check",
          description: "Ready to chat with site visitors",
        },
        {
          name: "Away",
          value: 0,
          icon: "minus-circle",
          iconColor: "#7C7979",
          toggleIcon: "comment-minus",
          description: "Not available to chat",
        },
      ];

      $scope.logoSref =
        profile.hasTexting && profile.currentDepartment.teamHasSms ? "app.dashboard" : "app.webchat.list";
      $scope.webChatStatuses = webChatStatuses;
      $scope.caretake = caretake;
      $scope.changeDepartment = changeDepartment;
      $scope.isAuthorized = isAuthorized;
      $scope.openBeacon = openBeacon;
      $scope.openCadenceChat = openCadenceChat;
      $scope.revertCaretaking = revertCaretaking;
      $scope.toggleNavigation = toggleNavigation;
      $scope.$state = $state;
      $scope.unreadCount = 0;
      $scope.$on("$destroy", configPollingStop);
      $scope.showSidenav = true;
      $rootScope.config = config;
      $rootScope.isPublic = false;
      $rootScope.loadScript = loadScript;
      $rootScope.profile = profile;
      $rootScope.helpContent = helpContent;
      $rootScope.partnerUrl = partnerUrl;
      $rootScope.closeComposeModal = closeComposeModal;
      $rootScope.openComposeModal = openComposeModal;
      $rootScope.openMigrationModal = openMigrationModal;
      $rootScope.reloadState = reloadState;
      $rootScope.showHelpArticle = showHelpArticle;
      $rootScope.storeConfig = getStoreConfig();
      $rootScope.unreadWhatsAppConversationsCount = 0;
      $rootScope.unreadWebChatConversationsCount =
        parseInt(localStorage.getItem("unreadWebChatConversationsCount")) || 0;
      $rootScope.cadenceUserAvailability = JSON.parse(localStorage.getItem("cadenceUserAvailability")) || false;
      $rootScope.$on("messageScheduledEvent", messageScheduledEvent);
      $rootScope.$on("caretakersUpdated", getCaretakerTargets);
      $rootScope.$on("signOut", signOut);
      $rootScope.$on("startPollConfig", configPollingStart);
      $rootScope.$on("accountUnreadChanged", updateTitle);
      $rootScope.$on("profileChanged", reloadProfile);
      $rootScope.$on("titleUpdate", updateTitle);
      $rootScope.$on("groupAccountTitle", updateGroupName);
      $rootScope.updateWebChatAvailability = updateWebChatAvailability;
      $rootScope.skipToMain = skipToMain;
      $rootScope.hasWhatsApp = profile.currentDepartment.userHasWhatsApp;
      $scope.newMongooseLogo = $rootScope.config.features["rollout-mongoose-branding-updates"];

      $mdComponentRegistry.when("main-nav").then(onSidenavReady);

      if (window.eventBus) {
        window.eventBus.addEventListener("authRequired", redirectToLogin);
        window.eventBus.addEventListener("open-compose-modal", openComposeModal);
        window.eventBus.addEventListener("sidenav-reload", reloadProfile);
        window.eventBus.addEventListener("get-caretakers-targets", getCaretakerTargets);
        window.eventBus.addEventListener("compose-success-toast", composeSuccessToast);
        window.eventBus.addEventListener("compose-error-toast", composeErrorToast);
        window.eventBus.addEventListener("shared-inbox-reload");
        window.eventBus.addEventListener("cw-update-whatsapp-unread-count", updateWhatsAppUnreadCount);
      }

      window.addEventListener("storage", (e) => {
        // Listen for token changes on other tabs/windows
        if (e.key === "token") {
          window.location.reload();
        }
      });

      activate();

      function activate() {
        $scope.displayName = profile.firstName + " " + profile.lastName + " - " + profile.currentDepartment.name;

        $scope.profile = profile;
        $scope.showFtp = config.integrations.ftp && isAuthorized("ftp");
        $scope.showRadius = config.integrations.radius && isAuthorized("radius");
        $scope.showConnect = config.integrations.connect && isAuthorized("connect");
        $scope.showEthos = config.integrations.ethos && isAuthorized("ethos");
        $scope.showSlate = config.integrations.slate && isAuthorized("slate");
        $scope.showSalesforce = config.integrations.salesforce && isAuthorized("salesforce");
        $scope.showWorkday = config.integrations.workday && isAuthorized("workday");
        $scope.showRecruit =
          config.isEnabled("integrations--recruit--viewable-in-cadence") &&
          config.integrations.recruit &&
          isAuthorized("recruit");
        $scope.showRaisersEdgeNxt = config.integrations.raisersEdgeNxt && isAuthorized("raisersEdgeNxt");
        $scope.showSso = config.hasSso && isAuthorized("sso");
        $scope.showNotificationsTooltip = false;
        $scope.chatAnalytics = profile.currentDepartment.teamHasChat && profile.currentDepartment.userIsChatAgent;

        getCaretakerTargets();
        initHelp();
        initGoogleTagManager();
        initNotifications();
        initUserTracking();
        updateMessageCount();
        getWhatsAppInboxIds();

        if (profile.requirePasswordChange) {
          $uibModal.open({
            templateUrl: "core/modals/update-password.html",
            controller: "UpdatePasswordController",
            ariaLabelledBy: "update-password",
            backdrop: "static",
            keyboard: false,
          });
        } else {
          if (profile.unreadNotifications && viewportService.isSize("gt-xs")) {
            $timeout(function () {
              $scope.showNotificationsTooltip = true;
              $timeout(function () {
                $scope.showNotificationsTooltip = false;
              }, 4000);
            }, 1000);
          }
        }

        if (profile.isCaretaking) {
          $rootScope.$on("accountUnreadChanged", getCaretakerTargets);
        }
      }

      function composeSuccessToast() {
        growl.success("Your message is on its way!");
      }

      function composeErrorToast() {
        growl.error("Something went wrong. Sorry!");
      }

      function updateWhatsAppUnreadCount(event) {
        $timeout(function () {
          $rootScope.unreadWhatsAppConversationsCount = event.detail.unreadCount;
          updateTitle();
        });
      }

      function beforeContextSwitch() {
        window.eventBus.dispatchEvent("cw-clear-stores");
        return $state.current.name === "app.groups.conversations" ? $rootScope.unlockConversation() : $q.resolve();
        // clear out profile and config in Pinia before switch
      }

      function caretake(targetId) {
        beforeContextSwitch().then(function () {
          caretakerService.impersonate(targetId);
        });
      }

      function changeDepartment(departmentId) {
        if (departmentId === $scope.profile.currentDepartment.id) return;

        beforeContextSwitch().then(function () {
          Restangular.all("profile")
            .customPOST({ departmentId: departmentId }, "changeDepartment")
            .then(
              function (response) {
                resetEventListeners();
                securityService.clearStorage();
                securityService.processToken(response.access_token, "app.dashboard", false, true);
              },
              function (response) {
                if (response.status === 400) {
                  $scope.validationMessage = response.data;
                }
              }
            );
        });
      }

      function checkCustomFields() {
        // Migrate Data Notification
        if (config.customFields.setupRequired && config.hasStudents) {
          var notification = {
            id: "migration",
            type: "warning",
            userType: "Admin",
            message:
              "<b>An update is required for the student import process.</b> If you are the administrator that imports students for this team, please take a couple minutes and complete the update.",
            action: {
              label: "Update",
              state: "app.admin.integration.import.students",
            },
          };
          notificationService.add(notification);
        } else if (config.customFields.inProgress) {
          configPollingStart();
        } else {
          notificationService.remove("migration");
        }
      }

      function configPollingStart() {
        if (isPolling) return;

        configPoll = $interval(function () {
          configurationService.get().then(function (response) {
            $rootScope.config = response;
            if (response.customFields.enabled) {
              configPollingStop();
            }
          });
          isPolling = true;
        }, 5000);
      }

      function configPollingStop() {
        $interval.cancel(configPoll);
        isPolling = false;
      }

      function getStoreConfig() {
        return {
          areaCode: config.areaCode,
          features: config.features,
          hasConnect: config.hasConnect,
          hasRadius: config.hasRadius,
          isAdmin: profile.isAdministrator,
          orgId: profile.currentDepartment.organizationId,
          team: profile.currentDepartment,
          timeZone: config.timeZone,
          userId: profile.id,
          serverTimeOffset: profile.serverTimeOffset,
          plan: config.plan,
          integrations: config.integrations,
        };
      }

      function loadScript(url) {
        if (url) {
          var script = document.querySelector("script[src*='" + url + "']");
          if (!script) {
            var heads = document.getElementsByTagName("head");
            if (heads && heads.length) {
              var head = heads[0];
              if (head) {
                script = document.createElement("script");
                script.setAttribute("src", url);
                head.appendChild(script);
              }
            }
          }
          return script;
        }
      }

      function updateTitle(event, data) {
        $rootScope.unreadWebChatConversationsCount =
          parseInt(localStorage.getItem("unreadWebChatConversationsCount")) || 0;
        const totalCount =
          (parseInt($rootScope.departmentUnreadCountTotal) || 0) +
          $rootScope.unreadWebChatConversationsCount +
          $rootScope.unreadWhatsAppConversationsCount;
        var title = totalCount && totalCount > 0 ? "(" + totalCount + ") " : "";

        if ($scope.groupId) {
          var group = _.find($rootScope.profile.inboxes, {
              groupId: $scope.groupId,
            }),
            highSpeed = _.find($rootScope.profile.inboxes, {
              highSpeedId: $scope.groupId,
            }),
            name = group ? group.name : highSpeed ? highSpeed.name : "";
          title += name + " ";
        }

        title += $state.current.pageTitle ? $state.current.pageTitle : "";

        document.title = (title ? title + " | " : "") + "Mongoose Cadence";

        mixpanel.register({ "Unread Count": totalCount });
        mixpanel.people.set({ "Unread Count": totalCount });
      }

      function updateGroupName(event, id) {
        $scope.groupId = id;
      }

      function getCaretakerTargets() {
        caretakerService.getForUser().then(function (response) {
          $scope.caretakerTargets = response;
          mixpanel.register({ "Caretaking Count": response.length });
          mixpanel.people.set({ "Caretaking Count": response.length });
        });
      }

      function getWhatsAppInboxIds() {
        $rootScope.whatsAppInboxIds = profile.inboxes
          .filter((inbox) => inbox.type === "whatsapp")
          .map((inbox) => inbox.id);
      }

      function isAuthorized(roles) {
        return securityService.isAuthorized(roles);
      }

      function initHelp() {
        if (!config.showHelpLink) return;

        initBeacon();
        showBeacon();
      }

      function initGoogleTagManager() {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-KKRMXKZ");
      }

      function initBeacon() {
        !(function (e, t, n) {
          function a() {
            var e = t.getElementsByTagName("script")[0],
              n = t.createElement("script");
            (n.type = "text/javascript"),
              (n.async = !0),
              (n.src = "https://beacon-v2.helpscout.net"),
              e.parentNode.insertBefore(n, e);
          }
          if (
            ((e.Beacon = n = function (t, n, a) {
              e.Beacon.readyQueue.push({ method: t, options: n, data: a });
            }),
            (n.readyQueue = []),
            "complete" === t.readyState)
          )
            return a();
          e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1);
        })(window, document, window.Beacon || function () {});
      }

      function initNotifications() {
        checkCustomFields();
        notificationService.init(profile);
        statusService.init(profile);
      }

      function onSidenavReady() {
        if ($mdMedia("gt-sm")) {
          $mdSidenav("main-nav").open();
        } else {
          $mdSidenav("main-nav").close();
        }

        $scope.$watch(function () {
          return ($scope.showSidenav = $mdSidenav("main-nav").isOpen());
        });
      }

      function openBeacon() {
        Beacon("toggle");
      }

      function openCadenceChat() {
        $window.botpressWebChat.sendEvent({ type: "show" });
      }

      function reloadState() {
        $state.reload();
      }

      function showBeacon() {
        Beacon("init", "18bb1590-3b94-4360-82b0-28ce34a8c1cd");
        Beacon("config", {
          display: {
            style: "manual",
            zIndex: 1000,
          },
        });
        Beacon("identify", {
          name: profile.displayName,
          email: profile.emailAddress,
          company: config.organization,
        });
      }

      function initUserTracking() {
        if (profile.isImpersonating) return;
        if (profile.emailAddress === "cypresse2e@mongooseresearch.com") return;

        var userType = profile.isOwner ? "Owner" : profile.isAdministrator ? "Admin" : "Staff";

        var inboxes = profile.inboxes.length > 0 ? "" : "No Shared Inboxes";
        if (profile.inboxes.length > 0) {
          for (var i = 0; i < profile.inboxes.length; i++) {
            if (inboxes.length === 0 && profile.inboxes[i].groupId) {
              inboxes = profile.inboxes[i].name;
            } else if (inboxes.length > 0 && profile.inboxes[i].groupId) {
              inboxes = inboxes + ", " + profile.inboxes[i].name;
            }
          }
        }

        mixpanel.identify(profile.id);

        let userTeams = profile.departments.map((t) => t.id);

        mixpanel.people.set({
          $email: profile.emailAddress,
          $first_name: profile.firstName,
          $last_name: profile.lastName,
          $created: profile.createdOn,
          $phone: profile.number,
          "Email Notifications": profile.sendEmailOnIncomingMessage,
          "Push Notifications": profile.sendMobilePushOnIncomingMessage,
          Organization: config.organization,
          Partner: config.organizationSource === 2 ? "Campus Management" : null,
          Plan: config.plan,
          "Unread Notifications": profile.unreadNotifications,
          "Web App Version": appVersion,
          Number: !profile.isNumberlessUser ? "Has Number" : "Numberless",
          "Shared Inboxes": inboxes,
          "User Type": userType,
          Teams: userTeams,
        });

        mixpanel.register({ "User Type": userType });
        mixpanel.register({ Platform: "Web" });
        mixpanel.register({ Department: profile.currentDepartment.name });
        mixpanel.register({
          CSL: config.csl ? config.csl.firstName + " " + config.csl.lastName : "",
        });
        mixpanel.register({
          "Department Category": profile.currentDepartmentCategory,
        });

        if ($rootScope.previousState && $rootScope.previousState.name === "account.login") {
          mixpanel.track("Signed In", {
            "Auth Type": "Cadence",
          });
        }

        if (profile.isCaretaking) {
          mixpanel.register({ "Account Management": "Caretaken" });
        } else {
          mixpanel.unregister("Account Management");
        }

        if ($window.Appcues) {
          let noSharedOrWhatsAppInboxes = profile.inboxes.filter(
            (inbox) => inbox.type.includes("group") || inbox.type.includes("whatsapp")
          );
          $window.Appcues.identify(profile.id, {
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.emailAddress,
            createdDate: profile.createdOn ? moment(profile.createdOn).format("X") : null, // Unix timestamp
            organization: config.organization,
            partner: config.organizationSource === 2 ? "Campus Management" : null,
            planType: config.plan,
            role: userType,
            chatEnabled: profile.currentDepartment.teamHasChat,
            textEnabled: profile.currentDepartment.teamHasSms,
            userHasChat: profile.currentDepartment.userIsChatAgent,
            userHasText: profile.currentDepartment.userHasTexting,
            currentDepartmentCategory: profile.currentDepartmentCategory,
            userIsTextAdmin: profile.currentDepartment.isAdministrator,
            userIsChatAdmin: profile.currentDepartment.isChatAdministrator,
            userHasWhatsApp: profile.currentDepartment.userHasWhatsApp,
            userHasPersonalInboxOnly:
              profile.currentDepartment.personalInboxEnabled && !noSharedOrWhatsAppInboxes.length,
            bulkMmsEnabled: profile.currentDepartment.bulkMmsEnabled, // legacy
            bulkMmsMethod: config.bulkMmsMethod,
            ssoEnabled: profile.ssoEnabled,
            features: config.releaseFlagString,
            number: !profile.isNumberlessUser ? "Has Number" : "Numberless",
            sharedInboxes: inboxes,
            smsIntegration: _.findKey(config.integrations) || "",
          });
        }
      }

      function messageScheduledEvent() {
        $timeout(function () {
          updateMessageCount();
        });
      }

      function closeComposeModal() {
        composeModal.close();
      }

      function openComposeModal(options) {
        if (!config.features["migrate-compose-modal"]) {
          var options = _.get(options, "detail", options) || {};
          composeModal = $uibModal.open({
            templateUrl: "messages/modals/compose.html",
            backdrop: "static",
            controller: "ComposeMessageModalController",
            ariaLabelledBy: "compose",
            resolve: {
              presetData: options,
              config: config,
              profile: profile,
            },
          });
        } else {
          var options = _.get(options, "detail", options) || {};
          window.eventBus.dispatchEvent("cc_showModal", options);
        }
      }

      function openMigrationModal() {
        $rootScope.$broadcast("openMigrationModal");
      }

      function redirectToLogin() {
        delete $window.localStorage.token;
        $window.location.href = "/";
      }

      function reloadProfile() {
        return securityService.loadProfile(true).then(function (p) {
          _.merge(profile, p);
          $rootScope.profile = profile;
        });
      }

      function revertCaretaking() {
        beforeContextSwitch().then(function () {
          caretakerService.revert();
        });
      }

      function showHelpArticle(id) {
        Beacon("article", id, { type: "modal" });
      }

      function signOut(events, args) {
        securityService.signOut(args.manual, args.expiredFirebaseToken);
      }

      function toggleNavigation() {
        return $mdSidenav("main-nav").toggle();
      }

      function resetEventListeners() {
        window.eventBus.removeEventListener("open-compose-modal", openComposeModal);
        window.eventBus.removeEventListener("shared-inbox-reload");
      }

      function updateMessageCount() {
        Restangular.one("messages")
          .getList("scheduled")
          .then(function (messages) {
            $scope.scheduledMessageCount = messages.length;
            $scope.scheduledMessageCount = messages.length;
          });
      }

      function updateWebChatAvailability() {
        let cadenceUserAvailabilityVal = JSON.parse(localStorage.getItem("cadenceUserAvailability"));
        $rootScope.cadenceUserAvailability = cadenceUserAvailabilityVal;
      }

      function skipToMain() {
        let element = document.querySelector('[role="main"]');
        element.setAttribute("tabindex", "-1");
        element.focus();
      }
    }
  );
