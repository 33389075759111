angular
  .module("mongoose")
  .controller("EnableServiceController", function ($uibModalInstance, service) {
    var vm = this;

    vm.service = service;

    vm.enable = $uibModalInstance.close;
    vm.close = $uibModalInstance.dismiss;
  });
