(function() {
    'use strict';

    angular
        .module('mongoose')
        .directive('checkbox', checkbox);

    function checkbox() {

        return {
            restrict: 'AE',
            template: template,
            transclude: true,
            replace: true
        };

        function template(element, attrs){
            var html = '';
            html += '<span class="i-checkbox"';
            html +=   attrs.ngModel ? ' ng-click="' + attrs.disabled + ' ? ' + attrs.ngModel + ' : ' + attrs.ngModel + '=!' + attrs.ngModel + (attrs.ngChange ? '; ' + attrs.ngChange + '()"' : '"') : '';
            html +=   '>';
            html +=   attrs.label ? '<label>' : '';
            html +=   attrs.labelsr ? '<label class="sr-only" for="'+ attrs.id + '">' + attrs.labelsr + '</label>' : '';
            html += '<span';
            html +=   ' class="i-checkbox-check' + (attrs.class ? ' ' + attrs.class : '') + (attrs.size ? ' ' + attrs.size : '') + '"';
            html +=   ' ng-class="{ checked:' + attrs.ngModel + ', disabled:' + attrs.disabled + ' }"';
            html +=   '>';
            html +=   '<i ng-show="' + attrs.ngModel + '" class="fa fa-check"></i>';
            html +=   '<input type="checkbox"';
            html +=     attrs.id ? ' id="' + attrs.id + '"' : '';
            html +=     attrs.name ? ' name="' + attrs.name + '"' : '';
            html +=     attrs.ngModel ? ' ng-model="' + attrs.ngModel + '"' : '';
            html +=     attrs.ngModel ? ' ng-checked="' + attrs.ngModel + '"' : '';
            html +=     attrs.disabled ? ' disabled' : '';
            html +=     ' style="display:none" />';
            html += '</span>';
            html +=    attrs.label ? '<span class="i-checkbox-label">' + attrs.label + '</span>' : '';
            html +=    attrs.label ? '</label>' : '';
            html += '</span>';
            return html;
        }

    }

})();