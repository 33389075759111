angular
  .module("mongoose")
  .factory("statusService", function ($interval, notificationService) {
    var profile = {},
      interval;

    function init(prof) {
      if (interval) $interval.cancel(interval);
      profile = prof;
      checkSystemStatus();
      interval = $interval(checkSystemStatus, 60000);
    }

    function checkSystemStatus() {
      var sp = new StatusPage.page({ page: "84dhg3mxpfdm" });
      sp.incidents({
        filter: "unresolved",
        success: function (data) {
          updateStatus(data);
        },
      });

      function updateStatus(status) {
        if (status.incidents.length > 0) {
          let incident = status.incidents[0],
            notification = {
              id: "status",
              type: "info",
              userType: "All",
              message: incident.incident_updates[0].body,
              statusPage: true,
            };
          notificationService.add(notification);
        } else {
          notificationService.remove("status");
        }
      }
    }

    return {
      init: init,
    };
  });
