(function() {
'use strict';
    angular.module('mongoose').controller('NewSSOController', NewSSOController);

    function NewSSOController($uibModalInstance, Restangular, growl) {
        var vm = this;

        vm.close = $uibModalInstance.dismiss;
        vm.create = create;


        activate();

        function activate() {
            vm.ssoConfig = {
                friendlyName: '',
                entityId: '',
                signInUrl: '',
                protocolBinding: "0",
                certificate: ''
            };
            vm.hasValidationErrors = {};
        }

        function create() {
            if (isValid()) {
                vm.loading = true;
                Restangular.all('samlconfiguration').post(vm.ssoConfig).then(function () {
                    growl.success("Your SSO Configuration has been created!");
                    $uibModalInstance.close();
                },
                function(errResponse) {
                    vm.loading = false;
                    vm.serverValidationError = errResponse.data;
                });
            }
        }

        function isValid() {
            vm.hasValidationErrors = {};
            vm.hasValidationErrors.name = !vm.ssoConfig.friendlyName;
            vm.hasValidationErrors.entityID = !vm.ssoConfig.entityId;
            vm.hasValidationErrors.signInUrl = !vm.ssoConfig.signInUrl;
            vm.hasValidationErrors.certificate = !vm.ssoConfig.certificate;

            return !_.includes(vm.hasValidationErrors,true)
        }
    }
})();
