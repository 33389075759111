angular
  .module("mongoose")
  .controller(
    "WorkdayExportsController",
    function ($interval, $scope, growl, Restangular, config, FileSaver, Blob) {
      var vm = this;
      var interval;

      vm.logs = [];

      activate();

      function activate() {
        getHistory();

        interval = $interval(function () {
          getHistory();
        }, 5000);

        $scope.$on("$destroy", function () {
          $interval.cancel(interval);
        });
      }

      function getHistory() {
        Restangular.one("workday/errors")
          .get({}, { "x-disable-loading": "true" })
          .then(
            function (response) {
              vm.logs = _.map(response.plain(), function (item) {
                item.date = item.date
                  ? moment
                      .tz(moment.utc(item.date), config.timeZone)
                      .format("M/D/YY, h:mma")
                  : "";
                return item;
              });
            },
            function (error) {
              $interval.cancel(interval);
              growl.error(
                "An error has occurred loading the export error log. Please reload the page."
              );
            }
          );
      }
    }
  );
