angular.module('mongoose').controller('ImportController', function ($scope, Restangular, FileSaver, Blob) {

    var importScope = this;

    importScope.requiredFields = function() {
        Restangular.all("studentImports/requiredFields").getList().then(function(usage) {
            var data = new Blob([usage], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(data, 'required-fields.csv');
        });
    };

    importScope.allFields = function() {
        Restangular.all("studentImports/allFields").getList().then(function(usage) {
            var data = new Blob([usage], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(data, 'all-fields.csv');
        });
    };

});