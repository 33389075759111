angular.module('mongoose').controller('RenameFieldController', function ($uibModalInstance, fieldService, field) {

    var vm = this,
    revertField = angular.copy(field);

    vm.dismiss = $uibModalInstance.dismiss;
    vm.fieldName = angular.copy(field.displayName);
    vm.rename = rename;

    function rename() {
        vm.serverValidationError = '';

        if(_.isUndefined(vm.fieldName) || _.isEmpty(vm.fieldName)) return;

        field.displayName = vm.fieldName;

        fieldService.update(field).then(function(response){
            $uibModalInstance.close(response);
        }, function(response){
            field.displayName = revertField.displayName;
            vm.serverValidationError = response.data;
        });
    }
});