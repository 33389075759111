(function () {
  "use strict";

  angular.module("mongoose").controller("SSOListController", SSOListController);

  function SSOListController($state, $uibModal, Restangular, FileSaver) {
    var vm = this;

    vm.createSSO = createSSO;
    vm.deleteSSO = deleteSSO;
    vm.editSSO = editSSO;
    vm.downloadMetadata = downloadMetadata;

    activate();

    function activate() {
      Restangular.all("samlconfiguration")
        .getList()
        .then(function (results) {
          vm.ssoConfigs = results;
        });
    }

    function createSSO() {
      $uibModal
        .open({
          templateUrl: "owner/organization/sso/new-sso.html",
          controller: "NewSSOController",
          controllerAs: "vm",
          ariaLabelledBy: "new-sso",
          resolve: {
            ssoConfigs: function () {
              return vm.ssoConfigs;
            },
          },
        })
        .result.then(function () {
          $state.reload();
        });
    }

    function deleteSSO(ssoConfig) {
      $uibModal
        .open({
          templateUrl: "owner/organization/sso/delete-sso.html",
          controller: "DeleteSSOController",
          controllerAs: "vm",
          ariaLabelledBy: "delete-sso",
          resolve: {
            ssoConfig: function () {
              return ssoConfig;
            },
            ssoConfigs: function () {
              return vm.ssoConfigs;
            },
          },
        })
        .result.then(function () {
          $state.reload();
        });
    }

    function downloadMetadata() {
      Restangular.one("samlconfiguration/metadata")
        .get()
        .then(function (ex) {
          var data = new Blob([ex], { type: "application/xml" });
          FileSaver.saveAs(data, "metadata.xml");
        });
    }

    function editSSO(ssoConfig) {
      $uibModal
        .open({
          templateUrl: "owner/organization/sso/edit-sso.html",
          controller: "EditSSOController",
          controllerAs: "vm",
          ariaLabelledBy: "edit-sso",
          resolve: {
            ssoConfig: function () {
              return ssoConfig;
            },
            ssoConfigs: function () {
              return vm.ssoConfigs;
            },
          },
        })
        .result.then(function () {
          $state.reload();
        });
    }
  }
})();
