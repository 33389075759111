angular.module("mongoose").factory("fieldService", function (Restangular) {
  var tempFields = [];

  function getBaseFields() {
    return [
      {
        displayName: "ContactId",
        isRequired: true,
        canMailMerge: false,
        isVisible: true,
      },
      {
        displayName: "FirstName",
        isRequired: true,
        canMailMerge: true,
        isVisible: true,
      },
      {
        displayName: "LastName",
        isRequired: true,
        canMailMerge: true,
        isVisible: true,
      },
      {
        displayName: "MobileNumber",
        isRequired: true,
        canMailMerge: true,
        isVisible: true,
      },
      {
        displayName: "OptedOut",
        isRequired: false,
        canMailMerge: false,
        isVisible: true,
      },
      {
        displayName: "StaffId",
        isRequired: false,
        canMailMerge: false,
        isVisible: true,
      },
    ];
  }

  function get(includeSystemFields) {
    if (!includeSystemFields) {
      includeSystemFields = false;
    }
    return Restangular.all("customfields").getList({
      includeSystemFields: includeSystemFields,
    });
  }

  function initialize(fields) {
    return Restangular.all("customfields").customPOST({ fields: fields }, "initialize");
  }

  function add(field) {
    return Restangular.all("customfields").post(field);
  }

  function deleteField(field) {
    return Restangular.one("customfields", field.id).remove();
  }
  function update(field) {
    if (field.id) {
      return field.put();
    }
  }

  function getTempFields() {
    return tempFields;
  }

  function setTempFields(fields) {
    return (tempFields = fields);
  }

  return {
    add: add,
    get: get,
    initialize: initialize,
    update: update,
    deleteField: deleteField,

    getBaseFields: getBaseFields,
    getTempFields: getTempFields,
    setTempFields: setTempFields,
  };
});
