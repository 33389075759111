angular.module('mongoose').controller('DeleteSegmentModalController', function (Restangular, $uibModalInstance, segment) {

    var vm = this;

    vm.delete = function(){
        segment.remove().then(function(){
            mixpanel.track('Deleted Segment');
            $uibModalInstance.close();
        });
    };

    vm.close = $uibModalInstance.dismiss;

    activate();
    function activate(){

        vm.segment = segment;

        Restangular.one("messages").getList("scheduled").then(function(response){

            var lists = response.plain(),
                segments = [],
                scheduledCount = 0;

            _.forEach(lists, function(list){
                if(segment.type == 'Static'){
                    segments = list.sendSmsRequest.staticSegments;
                } else if(segment.type == 'Auto-Update') {
                    segments = list.sendSmsRequest.autoUpdateSegments;
                }

                if(_.indexOf(segments, segment.id) > -1){
                    scheduledCount++;
                }
            });

            vm.scheduledCount = scheduledCount;
        });
    }
});