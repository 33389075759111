angular.
    module('mongoose')
    .component('switcher', {
        templateUrl: 'dashboard/switcher.html',
        controllerAs: "vm",
        bindings: {
            profile: '<',
            getFilters: '<',
            component: '<',
            heading: '<',
            altHeading: '<'
        },
        controller: function SwitcherController($rootScope, $scope, $window) {
            var vm = this,
                isAdmin = vm.profile.currentDepartment.isAdministrator == true;
                hasPersonalMessaging = $rootScope.config.isEnabled('personal-messaging');
            
            vm.filters = getFilters();
            vm.groupAccounts = vm.profile.groupAccounts;
            vm.selectGroup = selectGroup;
            vm.show = {
                department: isAdmin,
                mine: hasPersonalMessaging,
                groups: !!(vm.component === 'messages' && vm.groupAccounts.length)
            };
            vm.showUserFilter = _.values(vm.show).filter(Boolean).length > 1 || (!isAdmin && vm.groupAccounts.length > 1);

            $scope.$watchCollection('vm.filters', filterChange);

            function filterChange() {
                $window.localStorage.setItem('dashboard-' + vm.component, JSON.stringify(vm.filters));
                vm.getFilters(vm.filters);
            }

            function getFilters() {
                var defaults = {
                        'time': 'day'
                    },
                    selected,
                    filters;

                selected = JSON.parse($window.localStorage.getItem('dashboard-' + vm.component)),
                filters = !_.isNull(selected) ? selected : defaults;

                if (vm.component === 'messages' || vm.component == 'campaigns') {
                    if (isAdmin && hasPersonalMessaging) {
                        // console.log('admin and personal messaging');

                        defaults.groupId = 'admin'
                    }
                    else if (!isAdmin && hasPersonalMessaging) {
                        // console.log('has Personal Messaging');

                        defaults.groupId = 'mine';
                    } else if (!isAdmin && !hasPersonalMessaging) {
                        // console.log('group account only');

                        defaults.groupId = vm.profile.groupAccounts[0].id;
                        defaults.groupName = vm.profile.groupAccounts[0].name;
                        
                        if (filters.groupId == 'mine') {
                            filters.groupId = vm.profile.groupAccounts[0].id;
                            filters.groupName = vm.profile.groupAccounts[0].name;
                        }
                    } else {
                        // console.log('admin');

                        defaults.groupId = 'admin';
                        if (filters.groupId == 'mine') {
                            filters.groupId = 'admin';
                        }
                    }
                }

                if (vm.component === 'campaigns') {
                    defaults.sortBy = 'reached';
                    defaults.sortOrder = 'desc';
                }

                if (!isAdmin && filters.groupId === 'admin') {
                    filters.groupId = defaults.groupId;
                    filters.groupName = defaults.groupName;
                }

                if (typeof filters.groupId === 'number') {
                    if (_.isUndefined(_.find(vm.profile.groupAccounts, {'id': filters.groupId}))) {
                        filters.groupId = defaults.groupId;
                        filters.groupName = defaults.groupName;
                    }
                }

                return filters;
            }

            function selectGroup(group) {
                if (group == 'admin' || group == 'mine') {
                    vm.filters.groupId = group;
                    vm.filters.groupName = null;
                } else {
                    vm.filters.groupId = group.id;
                    vm.filters.groupName = group.name;
                }
            }
        }

});
