(function() {
'use strict';
angular.module('mongoose').controller('EditDepartmentController', NewDepartmentController);

function NewDepartmentController($uibModalInstance, Restangular, growl, categories, department, departments) {
    var vm = this;

    vm.categories = categories;
    vm.close = $uibModalInstance.dismiss;
    vm.department = department;
    vm.departments = departments;
    vm.hasValidationErrors = {};
    vm.update = update;


    activate();

    function activate() {
        if(vm.department.departmentCategoryId === 0)
            vm.department.departmentCategoryId = null;
    }

    function isValid() {
        vm.hasValidationErrors = {};
        vm.hasValidationErrors.name = !vm.department.name || _.some(departments,function(d) {return d.name.toLowerCase() === vm.department.name.toLowerCase() && d.id !== vm.department.id;});
        vm.hasValidationErrors.code = !vm.department.code || _.some(departments,function(d) {return d.code.toLowerCase() === vm.department.code.toLowerCase() && d.id !== vm.department.id;});
        return !_.includes(vm.hasValidationErrors,true)
    }

    function update() {
        if (isValid()) {
            vm.busy = true;
            Restangular.all('departments').post(vm.department).then(function () {
                growl.success("Your team has been updated!");
                $uibModalInstance.close();
            },
            function(errResponse) {
                vm.busy = false;
                vm.serverValidationError = errResponse.data;
            });
        }
    }
}
})();
