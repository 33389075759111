angular.module('mongoose').config(function ($stateProvider) {
    $stateProvider.state('app.slate', {
        url: '/slate',
        abstract: true,
        templateUrl: 'slate/slate.html',
        pageTitle: "Slate Integration"
    })
    .state('app.slate.fields', {
        url: '/fields',
        templateUrl: 'admin/department/integration/fields/view.html',
        controller: 'FieldsController as vm',
        pageTitle: "Slate Integration Fields"
    })
    .state('app.slate.configuration', {
        url: '/configuration',
        templateUrl: 'slate/configuration.html',
        controller: 'SlateConfigurationController as vm',
        pageTitle: "Slate Integration Configuration"
    })
    .state('app.slate.import-configuration', {
        url: '/import-configuration',
        templateUrl: 'slate/import-configuration.html',
        controller: 'SlateImportConfigurationController as vm',
        pageTitle: "Slate Integration Import Configuration"
    })
    .state('app.slate.export-configuration', {
        url: '/export-configuration',
        templateUrl: 'slate/exports.html',
        controller: 'SlateExportConfigurationController as vm',
        pageTitle: "Slate Integration Export Configuration"
    })
    .state('app.slate.import-log', {
        url: '/import-log',
        templateUrl: 'slate/import-log.html',
        controller: 'SlateImportLogController as vm',
        pageTitle: "Slate Integration Import Log"
    })
    .state('app.slate.export-log', {
        url: '/export-log',
        templateUrl: 'slate/export-log.html',
        controller: 'SlateExportLogController as vm',
        pageTitle: "Slate Integration Export Log"
    });
});
