(function() {
    'use strict';

    angular
        .module('mongoose')
        .directive('textareaAutoheight', textareaAutoheight);

    function textareaAutoheight($rootScope) {

        function linkFunc(scope, el, attr, ctrl) {
            el.addClass('autoheight');
            el[0].style.height = el[0].scrollHeight + 'px';

            el.bind('keyup', keyup);

            scope.$watch(function() {
                return el.attr('count');
            }, function(newValue){
                if(newValue == 0) {
                    el[0].style.height = 'auto';
                } else {
                    resize();
                }
            });

            function keyup(){
                resize();
                $rootScope.$broadcast('messageKeyup');
            }

            function resize(){
                if(!attr.count || attr.count == 0){
                    el[0].style.height = 'auto';
                } else {
                    el[0].style.height = el[0].scrollHeight + 'px';
                }
            }
        }

        return {
            restrict: 'A',
            link: linkFunc
        };

    }
})();