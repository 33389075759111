angular.module('mongoose').controller('EthosDashboardController', function ($scope, $interval, $state, ethosConfig, ethosService, config) {

    var vm = this,
        interval;

    vm.open = open;


    activate();

    function activate() {
        if(!ethosConfig.status.connectionIsConfigured)
            return vm.loaded = true;

        getHistory();

        interval = $interval(function() {
            getHistory(true);
        }, 5000);

        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    }

    function getHistory(disableLoading){
        ethosService.getDashboardData({limit: 3, timezone: config.timeZone, disableLoading: disableLoading}).then(function(response){
            //vm.exports = response.exports;
            vm.loaded = true;
            vm.imports = response.imports;
        }, function(){
            vm.loaded = true;
            $interval.cancel(interval);
        });
    }

    function open(state){
        $state.go(state);
    }

});