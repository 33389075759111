angular
  .module("mongoose")
  .controller("FtpController", function ($uibModal, ftpConfig, ftpService) {
    var ftpScope = this;

    ftpScope.config = ftpConfig;
    ftpScope.openConnectionSettings = openConnectionSettings;

    activate();

    function activate() {
      if (ftpConfig.status.connectionIsConfigured) checkConnectionStatus();
    }

    function checkConnectionStatus() {
      ftpScope.connectionErrorMessage = "";
      ftpScope.checkingConnection = true;

      ftpService.getConnectionStatus().then(
        function (response) {
          ftpScope.isConnected = response.status === "Valid";
          ftpScope.connectionErrorMessage = !ftpScope.isConnected
            ? response.message
            : "";
          ftpScope.checkingConnection = false;
        },
        function () {
          ftpScope.isConnected = false;
          ftpScope.connectionErrorMessage = "Connection Error";
          ftpScope.checkingConnection = false;
        }
      );
    }

    function openConnectionSettings() {
      var modal = $uibModal.open({
        templateUrl: "integrations/ftp/ftp-connection-settings.html",
        controller: "FtpConnectionSettingsController",
        controllerAs: "vm",
        ariaLabelledBy: "ftp",
      });

      modal.result.then(function () {
        ftpService.config().then(function (response) {
          ftpScope.config = response;
          checkConnectionStatus();
        });
      });
    }
  });
