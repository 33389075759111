(function () {
    'use strict';
    angular.module('mongoose').controller('DeleteSSOController', DeleteSSOController);

    function DeleteSSOController($uibModalInstance, Restangular, growl, ssoConfig) {
        var vm = this;
        vm.ssoConfig = ssoConfig;

        vm.dismiss = function () {
            $uibModalInstance.dismiss();
        };

        vm.delete = function () {
            Restangular.one('samlconfiguration', vm.ssoConfig.id).remove().then(function () {
                growl.success("Your SSO Configuration has been deleted!");
                mixpanel.track('Deleted SSO Configuration', {
                    'SSO Configuration': ssoConfig.friendlyName
                });
                $uibModalInstance.close();
            });
        };
    }
})();