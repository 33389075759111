angular.module('mongoose').controller('LoginController', function ($rootScope, $scope, $timeout, $location, $http, $window, $stateParams, securityService, passwordService, tokenUrl, Restangular, authReturnUrl,samlBaseUrl) {

    var vm = this,
        authMethod = {
            method: '',
            provider: '',
            tenant: ''
        },
        credentials = {
            email: '',
            password: ''
        },
        redirectOnAuthState = 'app.dashboard';

    vm.authMethod = authMethod;
    vm.buttonDisabled = buttonDisabled;
    vm.credentials = credentials;
    vm.emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    vm.showPasswordField = false;
    vm.submit = submit;
    vm.validationMessage = '';

    $scope.$watch('vm.credentials.email', emailChange);


    activate();

    function activate() {
        vm.validationMessage = '';

        if ($window.localStorage.token) {
            securityService.processToken($window.localStorage.token, redirectOnAuthState, true);
            return;
        }
        if($stateParams.error){
            vm.validationMessage = $stateParams.error;
        }
    }

    function buttonDisabled() {
        if (!vm.validEmail)
            return true;

        if(!vm.authMethod.method)
            return true;

        if (vm.authMethod.method === 'cadence' && !vm.credentials.password)
            return true;

        if(vm.loading)
            return true;

        return false;
    }

    function getAuthMethod() {
        vm.validatingEmail = true;
        vm.credentials.password = null;
        vm.validationMessage = '';
        Restangular.one('auth', 'method').get({ 'email': vm.credentials.email }).then(function (response) {
                vm.validatingEmail = false;
                vm.authMethod = response;
                vm.showPasswordField = vm.authMethod.method === 'cadence';
            },
            function () {
                vm.validationMessage = 'There was an issue validating your email. Please try again.';
            });
    }

    function loginCadence() {
        if (!validate())
            return false;

        vm.loading = true;
        $http({
            method: 'POST',
            url: tokenUrl,
            data: $.param({ username: vm.credentials.email, password: vm.credentials.password, grant_type: 'password', passwordIsStrong: (passwordService.validatePassword(vm.credentials.password).score === 100) }),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        .success(function (data) {
            securityService.processToken(data.access_token, redirectOnAuthState, true);
        })
        .error(function (data) {
            activate();
            delete $window.localStorage.token;
            vm.validationMessage = data.invalid_grant;
            vm.loading = false;
        });
    }

    function loginSaml() {
        vm.loading = true;

        var authUrl = [],
            returnUrl = $stateParams.returnUrl,
            authCallbackUrl = '/#/auth-callback' + (returnUrl ? '?returnUrl=' + returnUrl : '');

        authUrl.push(
            samlBaseUrl,
            vm.authMethod.tenant,
            '/initiatesinglesignon?provider=',
            vm.authMethod.provider,
            '&returnUrl=',
            authReturnUrl,
            encodeURIComponent(authCallbackUrl)
        );
        $window.location.href = authUrl.join("");
    }

    function emailChange() {
        vm.validEmail = vm.credentials.email;
        if(vm.validEmail)
            getAuthMethod();
    }

    function validate() {
        vm.validationMessage = '';
        if (!(vm.credentials.email && vm.credentials.password)) {
            vm.validationMessage = 'E-mail address and password are required.';
        }
        return !vm.validationMessage;
    }

    function submit() {
        if (vm.authMethod.method === 'saml') {
            loginSaml();
        } else {
            loginCadence();
        }
    }
});
