(function () {
  "use strict";
  angular
    .module("mongoose")
    .controller("UserNotificationsController", UserNotificationsController);

  function UserNotificationsController(
    $scope,
    $timeout,
    $window,
    Restangular,
    profile
  ) {
    var vm = this;

    vm.isVisible = isVisible;
    vm.toggle = toggle;
    vm.trackNotificationLinkClicks = trackNotificationLinkClicks;

    activate();

    function activate() {
      Restangular.all("inproductnotification")
        .getList()
        .then(function (notifications) {
          vm.notifications = _.map(notifications, function (n) {
            n.summaryHeader = twemoji.parse(n.summaryHeader, {base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/'});
            n.summarySubHeader = twemoji.parse(n.summarySubHeader, {base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/'});
            n.content = twemoji.parse(n.content, {base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/'});
            return n;
          });
        });

      if (profile.unreadNotifications) {
        mixpanel.track("Read Notification");
      }

      $timeout(function () {
        Restangular.one("systemnotifications")
          .post(undefined, undefined, undefined, {
            "x-disable-loading": "true",
          })
          .then(function () {
            profile.unreadNotifications = false;
            $window.localStorage.profile = JSON.stringify(profile);
          });
      }, 1000);
    }

    function isVisible(notification) {
      if (notification.userVisible) return true;

      if (notification.adminVisible && $scope.isAuthorized("admin"))
        return true;

      return notification.ownerVisible && $scope.isAuthorized("owner");
    }

    function toggle(element) {
      element.visible = !element.visible;
      if (element.visible) {
        mixpanel.track("Expanded Notification", {
          "Notification Type": element.type,
          Header: element.summaryHeader,
          Subheader: element.summarySubHeader,
          Content: element.content,
          "Publish Date": element.publishedOn,
        });
      }
    }

    function trackNotificationLinkClicks(element) {
      mixpanel.track("Clicked Notification Link", {
        "Notification Type": element.type,
        Header: element.summaryHeader,
        Subheader: element.summarySubHeader,
        Content: element.content,
        "Publish Date": element.publishedOn,
      });
    }
  }
})();
