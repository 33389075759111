/**
 * @desc display media in convo message
 * @example <message-media></message-media>
 */
angular.module("mongoose").directive("messageMedia", messageMedia);

function messageMedia($uibModal, utilitiesService) {
  return {
    link: linkFunc,
    templateUrl: "messages/messageMedia.html",
    restrict: "E",
  };

  function linkFunc(scope) {
    scope.getMediaType = utilitiesService.getMediaType;
    scope.mediaIconClass = mediaIconClass;
    scope.openMediaModal = openMediaModal;

    function mediaIconClass(mimeType) {
      var icon = new RegExp("pdf").test(mimeType)
        ? "file-pdf-o"
        : new RegExp("vcard|directory").test(mimeType)
        ? "vcard-o"
        : new RegExp("csv|excel").test(mimeType)
        ? "file-excel-o"
        : new RegExp("image").test(mimeType)
        ? "file-image-o"
        : new RegExp("audio").test(mimeType)
        ? "file-audio-o"
        : new RegExp("video").test(mimeType)
        ? "file-video-o"
        : new RegExp("text").test(mimeType)
        ? "file-text-o"
        : "file-o";
      return "fa-" + icon;
    }

    function openMediaModal(messageId, media) {
      $uibModal.open({
        templateUrl: "messages/modals/media.html",
        controller: "MediaModalController",
        ariaLabelledBy: "media",
        resolve: {
          messageId: function () {
            return messageId;
          },
          media: function () {
            return media;
          },
        },
      });
    }
  }
}
