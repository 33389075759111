angular.module("mongoose").controller("MessagingIndexController", function ($rootScope, $state, $uibModal, inbox) {
  var vm = this;

  vm.conversationId = $state.params.id;
  vm.inbox = inbox;
  vm.inboxName = inboxName();
  vm.updateHighSpeed = updateHighSpeed;
  vm.appCueId = getAppCueId();
  vm.automationStateName = getAutomationStateName();
  vm.v2Inbox = $rootScope.config.features["rollout-migrate-sms-inbox-to-vue-3"];

  activate();

  function activate() {
    setCurrentTab(null, $state.current);

    $rootScope.$on("$stateChangeSuccess", function (event, toState) {
      return setCurrentTab(event, toState);
    });
  }

  function getAppCueId() {
    if (inbox.type === "group") {
      return "d8ee376f-1acb-442f-91d7-83fc3567ba45";
    }
    return "-M9ONFMP02rDkFNY2RkL";
  }

  function getAutomationStateName() {
    if (inbox.type === "personal") {
      return "app.messages.main.automation";
    }
    if (inbox.type === "group") {
      return "app.shared.main.automation";
    }
  }

  function setCurrentTab(event, toState) {
    if (toState.name.indexOf(".inbox") !== -1) {
      var pos = toState.name.indexOf(".inbox");
      vm.currentTab = toState.name.substring(0, pos) + ".inbox.list";
    } else if (toState.name.indexOf(".archived.") !== -1) {
      var pos = toState.name.indexOf(".archived.");
      vm.currentTab = toState.name.substring(0, pos) + ".archived.list";
    } else {
      vm.currentTab = toState.name;
    }
  }

  function updateHighSpeed() {
    $uibModal.open({
      templateUrl: "admin/department/users/edit-high-speed.html",
      controller: "EditHighSpeedController",
      controllerAs: "vm",
      ariaLabelledBy: "edit-highspeed",
      resolve: {
        highSpeedId: function () {
          return inbox.highSpeedId;
        },
      },
    });
  }

  function inboxName() {
    return inbox.type === "personal" ? "Messages" : inbox.name;
  }
});
