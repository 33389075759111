function metricBar() {

    return {
        restrict: 'EA',
        template: '<div class="metric-bar"><div ng-style="{width: vm.width + \'%\'}"></div></div>',
        scope: {
            value: '='
        },
        controllerAs: 'vm',
        controller: MetricBarController
    };
}

function MetricBarController($scope, $timeout){

    var vm = this;

    vm.width = 0;

    $scope.$watch('value', updateBar);

    function updateBar(){
        $timeout(function(){
            vm.width = $scope.value;
        }, 100);
    }
}

angular
    .module('mongoose')
    .directive('metricBar', metricBar);