(function() {
'use strict';
angular.module('mongoose').controller('DeleteTemplateModalController', DeleteTemplateModalController);

function DeleteTemplateModalController($uibModalInstance, Restangular, growl, template) {
    var vm = this;
    vm.template = template;

    vm.dismiss = function() {
        $uibModalInstance.dismiss();
    };

    vm.delete = function() {
         Restangular.one('templates', vm.template.id).remove().then(function () {
            growl.success("Your template has been deleted!");
            mixpanel.track('Deleted Template');
            $uibModalInstance.close();
        });
    };
}
})();