/**
 * @desc button for selecting a file to upload
 * @example <media-picker></media-picker>
 */
angular.module("mongoose").directive("legacyMediaPickerDisabled", legacyMediaPickerDisabled);

function legacyMediaPickerDisabled($timeout, Upload) {
  return {
    link: linkFunc,
    templateUrl: "core/compose-toolbar/legacyMediaPickerDisabled.html",
    restrict: "E",
  };

  function linkFunc(scope, el, attrs) {}
}
