angular
  .module("mongoose")
  .controller("PublicController", function ($rootScope, $scope, $state, notificationService, statusService) {
    let vm = this;
    vm.currentYear = new Date().getFullYear();

    $rootScope.isPublic = true;

    $rootScope.$on("titleUpdate", updateTitle);

    function updateTitle() {
      document.title = $state.current.pageTitle ? $state.current.pageTitle + " | Mongoose Cadence" : "Mongoose Cadence";
    }

    notificationService.init();
    statusService.init();
  });
