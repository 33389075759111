angular.module('mongoose').controller('UpdatePasswordController', function ($uibModalInstance, $scope, $http, $window, $stateParams, $rootScope, apiBaseUrl, passwordService) {
    var initialize = function () {
        resetPasswordFields();
    },
        
    validate = function () {
        $scope.validationMessage = '';
        if (!($scope.credentials.passwordConfirmation && $scope.credentials.password)) {
            $scope.validationMessage = 'Password and confirmation are required.';
        }
        if ($scope.passwordStrengthScore!==100) {
            $scope.validationMessage = 'Password does not meet strength requirements.';
        }
        return !$scope.validationMessage;
    },
    resetPasswordFields = function() {
        $scope.credentials = {
            passwordConfirmation :'',
            password : ''
        };
        $scope.passwordStrengthMessage = '';
        $scope.passwordStrengthScore = 0;
        $scope.passwordStrengthType = '';
    };

    $scope.checkPassword = function() {
       var result = passwordService.validatePassword($scope.credentials.password);
        $scope.passwordStrengthMessage = result.feedback;
        $scope.passwordStrengthScore = result.score;
        $scope.passwordStrengthType = result.type;
    }

    $scope.update = function() {
        if (validate()) {
            $http.post(apiBaseUrl + '/profile/updatePassword', { password: $scope.credentials.password, passwordConfirmation: $scope.credentials.passwordConfirmation})
            .success(function (data) {
                $scope.loading = false;
                $window.location.href = '#/messages/inbox';
                $uibModalInstance.close();
            })
            .error(function (error) {
                $scope.loading = false;
                $scope.validationMessage = error;
            });
        } else {
            resetPasswordFields();
        }
    };

    initialize();
});
