angular
  .module("mongoose")
  .controller(
    "MessagingBaseController",
    function ($scope, $state, inbox, growl) {
      $scope.$on("$destroy", destroy);

      activate();

      function activate() {
        if (!inbox) {
          growl.error(
            "This conversation is in an inbox from another department. Please switch departments."
          );
          return $state.go("app.dashboard");
        }

        mixpanel.register({
          "Account Type":
            inbox.type === "personal"
              ? "Personal"
              : inbox.type === "group"
              ? "Group"
              : inbox.type === "high-speed"
              ? "High Speed"
              : "",
          "Account Name": inbox.name,
        });
      }

      function destroy() {
        mixpanel.unregister("Account Type");
        mixpanel.unregister("Account Name");
      }
    }
  );
