(function() {
    'use strict';

    angular
        .module('mongoose')
        .directive('inboxEmptyMessage', inboxEmptyMessage);

    function inboxEmptyMessage($sce, $timeout, inboxEmptyMessageService) {

        return {
            restrict: 'EA',
            link: linkFunc,
            scope: {
                archivedView: '=',
                showUnread: '='
            },
            template:   '<div class="empty-message">' +
                            '<div ng-show="!showUnread">' +
                                '<div ng-if="!archivedView">' +
                                    '<div class="empty-message-text" ng-bind-html="emptyMessage.text"></div>' +
                                    '<div class="empty-message-icon animated {{emptyMessage.animation}}" ng-show="emptyMessage.animation" ng-bind-html="emptyMessage.icon"></div>' +
                                '</div>' +
                                '<div ng-show="archivedView">' +
                                    '<div class="empty-message-text small"><em>Archived folder is empty</em></div>' +
                                '</div>' +
                            '</div>' +
                            '<div ng-show="showUnread">' +
                                '<div class="empty-message-text small"><em>No unread messages</em></div>' +
                            '</div>' +
                        '</div>'
        };

        function linkFunc(scope) {
            var message = inboxEmptyMessageService.get();

            scope.emptyMessage = {
                text: $sce.trustAsHtml(message.text),
                icon: twemoji.parse(message.icon)
            };

            $timeout(function () {
                scope.emptyMessage.animation = message.animation;
            }, 300);

        }
    }
})();