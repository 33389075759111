(function () {
  "use strict";
  angular
    .module("mongoose")
    .controller("ApiSettingsController", ApiSettingsController);

  function ApiSettingsController($uibModal, Restangular) {
    var vm = this;

    initialize();

    vm.revoke = function (credentialId) {
      var modalInstance = $uibModal.open({
        templateUrl: "owner/api/credentials/invalidate-credentials-modal.html",
        controller: "InvalidateCredentialsModalController",
        controllerAs: "vm",
        ariaLabelledBy: "invalidate-api",
        resolve: {
          credentialId: function () {
            return credentialId;
          },
        },
      });

      modalInstance.result.then(function () {
        initialize();
      }, angular.noop);
    };

    vm.createCredential = function () {
      var modalInstance = $uibModal.open({
        templateUrl: "owner/api/credentials/generate-credentials-modal.html",
        controller: "GenerateCredentialsModalController",
        controllerAs: "vm",
        ariaLabelledBy: "generate-cred",
        resolve: {
          departmentId: function () {
            return null;
          },
        },
      });

      modalInstance.result.then(function () {
        initialize();
      }, angular.noop);
    };

    function initialize() {
      Restangular.all("apiCredentials")
        .getList()
        .then(function (creds) {
          vm.credentials = creds;
          vm.loaded = true;
        });
    }
  }
})();
