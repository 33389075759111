angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.dashboard", {
      url: "/dashboard",
      templateUrl: "dashboard/dashboard.html",
      controller: "DashboardController",
      controllerAs: "vm",
      pageTitle: "Dashboard",
    })
    .state("app.organizationDashboard", {
      url: "/v2/dashboard",
      templateUrl: "dashboard/organization-dashboard.html",
      controllerAs: "vm",
      pageTitle: "Organization Dashboard",
    });
});
