(function() {
    'use strict';
    angular.module('mongoose').controller('AssignDepartmentCaretakerController', AssignDepartmentCaretakerController);
    
    function AssignDepartmentCaretakerController($uibModalInstance, caretakerService, growl, user, users) {
        var vm = this;
        
        vm.assignCaretaker = assignCaretaker;
        vm.dismiss = $uibModalInstance.dismiss;
        vm.users = getAvailableUsers(users);
        
        function assignCaretaker(caretaker) {
            var caretakerId = caretaker.id,
                targetId = user.id;
            
            caretakerService.assign(caretakerId, targetId).then(function () {
                growl.success(caretaker.firstName + ' is now an Account Caretaker for ' + user.firstName);
                $uibModalInstance.close();
            });
        }
        
        function getAvailableUsers(users){
            return _.map(_.filter(users, function(u) {
                    return !u.caretakerId && user.id !== u.id;
                }), function(n) {
                    n.displayName = n.firstName + ' ' + n.lastName;
                    return n;
                });
        }
    }
})();