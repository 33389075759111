angular
  .module("mongoose")
  .controller(
    "EthosController",
    function ($uibModal, ethosConfig, ethosService) {
      var ethosScope = this;

      ethosScope.config = ethosConfig;
      ethosScope.openConnectionSettings = openConnectionSettings;

      activate();

      function activate() {
        if (ethosScope.config.status.connectionIsConfigured)
          checkConnectionStatus();
      }

      function checkConnectionStatus() {
        ethosScope.connectionErrorMessage = "";
        ethosScope.checkingConnection = true;

        ethosService.verifyConnection().then(
          function () {
            ethosScope.isConnected = true;
            ethosScope.checkingConnection = false;
          },
          function (response) {
            ethosScope.isConnected = false;
            ethosScope.connectionErrorMessage = response.data
              ? response.data.message
              : "Connection Error";
            ethosScope.checkingConnection = false;
          }
        );
      }

      function openConnectionSettings() {
        $uibModal
          .open({
            templateUrl: "integrations/ethos/ethos-connection-settings.html",
            controller: "EthosConnectionSettingsController",
            controllerAs: "vm",
            ariaLabelledBy: "ethos",
          })
          .result.then(function () {
            ethosService.config().then(function (response) {
              ethosScope.config = response;
              checkConnectionStatus();
            });
          });
      }
    }
  );
