(function () {
  "use strict";

  angular.module("mongoose").config(function ($stateProvider) {
    $stateProvider.state("app.admin.department", {
      url: "/department",
      abstract: true,
      template: "<ui-view/>",
    });

    $stateProvider.state("app.admin.department.users", {
      url: "/users",
      templateUrl: "admin/department/users/users.html",
      controller: "DepartmentUsersController as vm",
      pageTitle: "Users",
      resolve: {
        redirect: function ($rootScope) {
          // Wait for rootscope to become available.
          setTimeout(function () {
            if ($rootScope.config && $rootScope.config.features["rollout-migrate-users-page-to-vue-3"])
              window.location.replace("/#/v2/admin/department/users/team");
          }, 500);
        },
      },
    });

    $stateProvider.state("app.admin.users", {
      url: "/users",
      abstract: true,
      templateUrl: "admin/department/users/users-view.html",
      controller: "UsersController as vm",
    });

    $stateProvider.state("app.admin.users.team", {
      url: "",
      templateUrl: "admin/department/users/team-users.html",
      controller: "TeamUsersController as vm",
      pageTitle: "Users",
      resolve: {
        redirect: function ($rootScope) {
          // Wait for rootscope to become available.
          setTimeout(function () {
            if ($rootScope.config && $rootScope.config.features["rollout-migrate-users-page-to-vue-3"])
              window.location.replace("/#/v2/admin/department/users/team");
          }, 500);
        },
      },
    });

    $stateProvider.state("app.admin.users.organization", {
      url: "/organization",
      templateUrl: "admin/department/users/organization-users.html",
      controller: "OrganizationUsersController as vm",
      pageTitle: "Organization Users",
      resolve: {
        redirect: function ($rootScope) {
          // Wait for rootscope to become available.
          setTimeout(function () {
            if ($rootScope.config && $rootScope.config.features["rollout-migrate-users-page-to-vue-3"])
              window.location.replace("/#/v2/admin/department/users/organization");
          }, 500);
        },
      },
    });
    $stateProvider.state("app.admin.department.groups", {
      url: "/groups",
      templateUrl: "admin/department/users/shared/shared-inbox-page.html",
      controller: "SharedInboxController as vm",
      pageTitle: "Shared Inboxes",
      resolve: {
        redirect: function ($rootScope) {
          // Wait for rootscope to become available.
          setTimeout(function () {
            if ($rootScope.config && $rootScope.config.features["rollout-migrate-shared-inboxes-page-to-vue-3"])
              window.location.replace("/#/v2/admin/department/groups");
          }, 500);
        },
      },
    });
    $stateProvider.state("app.admin.department.groupsOld", {
      url: "/groupsOld",
      templateUrl: "admin/department/users/groups.html",
      controller: "DepartmentGroupsController as vm",
      pageTitle: "Shared Inboxes",
      resolve: {
        redirect: function ($rootScope) {
          // Wait for rootscope to become available.
          setTimeout(function () {
            if ($rootScope.config && $rootScope.config.features["rollout-migrate-shared-inboxes-page-to-vue-3"])
              window.location.replace("/#/v2/admin/department/groups");
          }, 500);
        },
      },
    });
    $stateProvider.state("app.admin.department.templates", {
      url: "/templates",
      templateUrl: "admin/department/templates/list.html",
      controller: "TemplatesListController",
      pageTitle: "Templates",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/admin/department/templates");
        },
      },
    });

    $stateProvider.state("app.admin.department.notifications", {
      url: "/notifications",
      templateUrl: "admin/department/notifications/notifications.html",
      controller: "NotificationsController",
      pageTitle: "Notification Emails",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/admin/department/notifications");
        },
      },
    });

    $stateProvider.state("app.admin.department.api", {
      url: "/api",
      templateUrl: "admin/department/api/api.html",
      controller: "ApiDepartmentController as vm",
      pageTitle: "API",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/admin/department/api");
        },
      },
    });

    $stateProvider.state("app.admin.department.webhooks", {
      url: "/webhooks",
      templateUrl: "admin/department/webhooks/webhooks.html",
      controller: "WebhooksController as vm",
      resolve: {
        departmentScope: function () {
          return true;
        },
      },
      pageTitle: "Webhooks",
    });
  });
})();
