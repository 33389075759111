
angular.module('mongoose').controller('RadiusImportConfigurationController', function ($scope, $timeout, $state, $q, growl, Restangular, config, $interval) {

    var vm = this;

    vm.addAdditionalField = addAdditionalField;
    vm.additionalField = {
        radiusFields: []
    };
    vm.addCriteria = addCriteria;
    vm.addFilter = addFilter;
    vm.cancel = cancel;
    vm.getMongooseAdditionalFields = getMongooseAdditionalFields;
    vm.getRadiusFieldLabel = getRadiusFieldLabel;
    vm.removeAdditionalField = removeAdditionalField;
    vm.removeField = removeField;
    vm.save = save;
    vm.staffFieldIsCustom = staffFieldIsCustom;
    vm.setStaffField = setStaffField;
    vm.showMongooseFieldChooser = config.customFields.enabled;
    vm.allowLoadCurrentEmployer = true;
    vm.allowLifeCycleRoleStage = true;
    vm.allowMostRecentlyAttendedSchool = true;
    vm.targetPreview = '';
    vm.executeTarget = executeTarget;
    vm.initiateImport = function () {
        initiateImport(false);
    };
    vm.initiateDifferentialImport = function () {
        initiateImport(true);
    };

    vm.staffFieldOptions = [
        "Contact Owner",
        "Full Name in Custom Field"
    ];

    vm.optOutOptions = [
        "Picklist",
        "Checkbox"
    ];

    $scope.$watch('vm.selectedRadiusField', additionalRadiusFieldChange);
    $scope.$watchCollection('vm.config.firstNameFields', firstNameFieldChange);


    activate();

    function activate() {

        vm.loaded=false;

        var requests = [
            Restangular.one("radius/importConfiguration").get(),
            Restangular.one("radius/settings").get(),
            Restangular.all("radius/contactFields").getList(),
            Restangular.all("radius/targets").getList({searchText: ''}),
            Restangular.all("customFields").getList()
        ];

        $q.all(requests).then(function (results) {
            var configuration = results[0];
            vm.settings = results[1].plain();
            vm.radiusFields = results[2].plain();
            vm.targets = results[3].plain();

            vm.allCustomFields = _.sortBy(results[4].plain(), function(f) {return f.displayName;});

            if (configuration) {
                vm.config = configuration.plain();

                // First Name Migration
                if(!vm.config.firstNameFields)
                    vm.config.firstNameFields = [];

                if(vm.config.firstNameField && !vm.config.firstNameFields.length){
                    vm.config.firstNameFields.push(getRadiusFieldId(vm.config.firstNameField));
                }

                // User Id Display
                if (!vm.config.staffField) {
                    vm.selectedStaffField = '';
                } else if (vm.staffFieldOptions.indexOf(vm.config.staffField)===-1)  {
                    vm.selectedStaffField = vm.staffFieldOptions[1];
                } else {
                    vm.selectedStaffField = vm.config.staffField;
                }


            } else {
                vm.config = {
                    firstNameFields: [],
                    optOut: {
                        fieldType: vm.optOutOptions[0]
                    },
                    additionalFields:[],
                    criteria:[],
                    filters:[],
                    target: ''
                }
            }

            filterCustomFields();
            loadImports();

            interval = $interval(function() {
                loadImports();
            }, 10000);
            $scope.$on('$destroy', function () { $interval.cancel(interval); });

            vm.showTargets = !((vm.config.criteria && vm.config.criteria.length > 0) || (vm.config.filters && vm.config.filters.length > 0));
            vm.showScreen = vm.settings.apiPassword && vm.settings.apiUsername && vm.settings.apiUrl;
            vm.loaded=true;
        });
    }

    function filterCustomFields() {
        if (vm.showMongooseFieldChooser) {
            var currentEmployer = 'Current Employer';
            var lifeCycleRoleStage = 'Lifecycle Role/Stage';
            var mostRecentlyAttendedSchool = 'Most Recently Attended School';
            var specialFields = [currentEmployer, lifeCycleRoleStage, mostRecentlyAttendedSchool ];
            var usedFields = _.pluck(vm.config.additionalFields, 'mongooseField');
            vm.customFields = _.filter(vm.allCustomFields, function(f) {return !(_.includes(usedFields,f.displayName) || _.includes(specialFields,f.displayName));});
            vm.allowLoadCurrentEmployer = _.some(vm.allCustomFields, {'displayName' : currentEmployer});
            vm.allowLifeCycleRoleStage = _.some(vm.allCustomFields, {'displayName' : lifeCycleRoleStage});
            vm.allowMostRecentlyAttendedSchool = _.some(vm.allCustomFields, {'displayName' : mostRecentlyAttendedSchool});
        }
    }
    function addAdditionalField($event, field){
        $event.preventDefault();
        if(!field || !field.mongooseField || !field.radiusFields.length)
            return;
        if (!vm.config.additionalFields)
            vm.config.additionalFields = [];
        vm.config.additionalFields.push(_.clone(field));
        field.mongooseField = '';
        field.radiusFields = [];
        filterCustomFields();
    }

    function addCriteria($event, field){
        $event.preventDefault();
        if(!field || !field.radiusField || !field.value)
            return;

        if (!vm.config.criteria)
            vm.config.criteria = [];
        vm.config.criteria.push(_.clone(field));
        field.radiusField = '';
        field.value = '';
    }

    function addFilter($event, field){
        $event.preventDefault();
        if(!field || !field.mongooseField || !field.value)
            return;

        field.values = _.map(field.value.split(','), _.trim);
        field.value = undefined;


        if (!vm.config.filters)
            vm.config.filters = [];
        vm.config.filters.push(_.clone(field));
        field.mongooseField = '';
        field.value = '';
    }

    function additionalRadiusFieldChange(newValue){
        if(!newValue) return;
        if(vm.additionalField.radiusFields.indexOf(newValue) !== -1) {
            vm.selectedRadiusField = '';
            return;
        }

        vm.additionalField.radiusFields.push(newValue);
        vm.selectedRadiusField = '';
    }

    function cancel($event) {
        $event.preventDefault();
        activate();
    }

    function firstNameFieldChange(newValue){
        if(!newValue) return;

        if(_.isNull(newValue[0])){
            vm.config.firstNameFields = [];
        }
    }

    function getMongooseAdditionalFields(){
        if(!vm.config || !vm.config.additionalFields)
            return;

        var additionalFields = _.pluck(vm.config.additionalFields, 'mongooseField');
        if (vm.config.loadLifecycleRoleStage) {
            additionalFields.push('Lifecycle Role/Stage');
        }
        var usedFields = _.pluck(vm.config.filters, 'mongooseField');

        _.forEach(usedFields, function(n){
            _.pull(additionalFields, n);
        });

        return additionalFields;
    }

    function getRadiusFieldLabel(id){
        var label = _.find(vm.radiusFields, { 'fieldId': id });
        return label ? label.fieldLabel : id;
    }

    function getRadiusFieldId(label){
        var field = _.find(vm.radiusFields, { 'fieldLabel': label });
        return field ? field.fieldId : label;
    }

    function initiateImport(differential) {
        Restangular.all("radius/initiatestudentimport").post({differential: differential}).then(function () {
            growl.success("Your import has been initiated!");
        });
    }

    function loadImports() {
        Restangular.all("radius/imports").getList({limit: 1},{'x-disable-loading': 'true'}).then(function(results) {
            vm.imports = results.map(function(i) {
                i.dateStarted = moment.tz(i.dateStarted, config.timeZone).format('M/D/YY, h:mma z');
                i.dateCompleted = i.dateCompleted ? moment.tz(i.dateCompleted, config.timeZone).format('M/D/YY, h:mma z') : '';
                return i;
            });
        }, function(error) {
            $interval.cancel(interval);
            growl.error("An error has occurred updating import status.  Please reload the page.");
        });
    }

    function removeAdditionalField($event, field){
        $event.preventDefault();
        _.pull(vm.config.additionalFields, field);
        filterCustomFields();
    }

    function removeField($event, fields, field){
        $event.preventDefault();
        _.pull(fields, field);
    }

    function setStaffField(){
        if(staffFieldIsCustom()) {
            vm.config.staffField = '';
        } else {
            vm.config.staffField = vm.selectedStaffField;
        }
    }

    function save() {
        var config = {
            studentImportConfiguration: angular.toJson(vm.config)
        };
        Restangular.all("radius/importConfiguration").post(config).then(function() {
            Restangular.all("radius/settings").post(vm.settings).then(function() {
                growl.success("Radius integration configuration updated!");
                activate();
            });
        });
    }

    function executeTarget($event) {
        $event.preventDefault();
        Restangular.one("radius/targets/" + vm.config.target + '/count').get().then(function(response) {
            vm.targetPreview = 'This target will bring ' + response.count + ' student' + (response.count===1?'':'s') + ' into Mongoose.';
        });
    }

    function staffFieldIsCustom(){
        return vm.selectedStaffField === vm.staffFieldOptions[1];
    }
});