angular.module("mongoose").component("outOfOffice", {
  templateUrl: "core/outOfOffice.html",
  controllerAs: "vm",
  bindings: {
    profile: "<",
  },
  controller: function OutOfOfficeController($rootScope, $timeout) {
    var vm = this;

    vm.close = close;
    vm.isEnabled = false;
    vm.showNotification = showNotification;

    $rootScope.$on("outOfOffice", updateOutOfOffice);

    $timeout(activate, 1000);

    function activate() {
      vm.isEnabled = vm.profile.outOfOfficeConfig ? vm.profile.outOfOfficeConfig.enabled : false;
    }

    function showNotification() {
      return vm.isEnabled;
    }

    function updateOutOfOffice(e, data) {
      $timeout(function () {
        vm.isEnabled = data.enabled;
      }, 1000);
    }

    function close() {
      vm.isEnabled = false;
    }
  },
});
