angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.automation", {
      abstract: true,
      url: "/automation",
      template: "<ui-view />",
      pageTitle: "Automation",
    })
    .state("app.automation.smartmessages", {
      abstract: true,
      url: "/smartmessages",
      template: "<ui-view />",
      pageTitle: "Smart Messages",
    })
    .state("app.automation.smartmessages.list", {
      url: "",
      templateUrl: "automation/smartmessages/smartmessages.html",
      controller: "SmartMessagesController as vm",
      pageTitle: "Smart Messages",
      resolve: {
        redirect: function ($rootScope) {
          window.location.replace("/#/v2/automation/smartmessages");
        },
      },
    })
    .state("app.automation.smartmessages.new", {
      url: "/new",
      templateUrl: "automation/smartmessages/new-smartmessage.html",
      controller: "NewSmartMessagesController as vm",
      pageTitle: "New Smart Message",
    })
    .state("app.automation.smartmessages.edit", {
      url: "/:smartMessageId/edit",
      templateUrl: "automation/smartmessages/new-smartmessage.html",
      controller: "EditSmartMessagesController as vm",
      pageTitle: "Edit Smart Message",
    });
});
