angular
  .module("mongoose")
  .factory("segmentsService", function ($q, $rootScope, Restangular, fieldService, comparisons) {
    function get(searchText, archived, userId) {
      var segments = [];
      archived = !_.isUndefined(archived) ? archived : false;
      searchText = !_.isUndefined(searchText) ? searchText : "";
      userId = !_.isUndefined(userId) ? userId : "";

      var query = {
        searchText: searchText,
        archived: archived,
        userId: userId,
      };

      var statics = Restangular.all("staticsegments")
        .getList(query)
        .then(function (staticSegments) {
          _.forEach(staticSegments, function (seg) {
            seg.type = "Static";
            seg.tagText = seg.name + " (" + seg.type + ")";
            seg.text = seg.tagText;

            segments.push(seg);
          });
        });

      var dynamics = Restangular.all("autoupdatesegments")
        .getList(query)
        .then(function (dynamicSegments) {
          _.forEach(dynamicSegments, function (seg) {
            seg.type = "Auto-Update";
            seg.tagText = seg.name + " (" + seg.type + ")";
            seg.text = seg.tagText;
            seg.showingPreview = false;

            segments.push(seg);
          });
        });
      var automated = Restangular.all("segments/automated/search")
            .customPOST({ name: query.searchText, messagingEnabled: true })
            .then(function (automated) {
              _.forEach(automated, function (seg) {
                seg.type = "Automated";
                seg.tagText = seg.name + " (" + seg.type + ")";
                seg.text = seg.tagText;
                seg.showingPreview = false;
                seg.createdBy = seg.createdById;

                segments.push(seg);
              });
            });
      var fieldsPromise = fieldService.get(true);

      return $q.all([statics, dynamics, fieldsPromise, automated]).then(function (results) {
        var fields = results[2].plain();
        _.forEach(segments, function (segment) {
          if (segment.conditions) {
            segment.conditions = JSON.parse(segment.conditions);
            segment.conditions = _.map(segment.conditions, function (c) {
              c.field = _.find(fields, { id: c.field });
              c.comparison = _.find(comparisons, { value: c.comparison });

              if (c.field && c.field.displayName === "Tags") {
                Restangular.all("tags")
                  .get(c.value)
                  .then(function (response) {
                    c.value = response.name;
                  });
              }

              return c;
            });
          }
        });

        return _.sortBy(segments, "name");
      });
    }

    return {
      get: get,
    };
  });
