angular
  .module("mongoose")
  .controller(
    "EditUserController",
    function (
      $uibModalInstance,
      $q,
      $filter,
      $state,
      $timeout,
      Restangular,
      growl,
      config,
      userId,
      profile,
      $rootScope
    ) {
      let vm = this;

      vm.assignNumber = assignNumber;
      vm.close = $uibModalInstance.dismiss;
      vm.enabledMessaging = enabledMessaging;
      vm.goToStep = goToStep;
      vm.invalidAccountSetup = invalidAccountSetup;
      vm.profile = profile;
      vm.selectNumber = selectNumber;
      vm.sendWelcomeEmail = sendWelcomeEmail;
      vm.step = 1;
      vm.update = update;
      vm.updatePermissions = updatePermissions;
      vm.updateAuthorizedFeatures = updateAuthorizedFeatures;
      vm.showRolePicker = showRolePicker;
      vm.toggleTextFields = toggleTextFields;
      vm.textEnabled = false;

      vm.isAccountChecked = isAccountChecked;
      vm.toggleAccount = toggleAccount;
      vm.isA2pVerified =
        $rootScope.config.features["a2p-verification"] === true
          ? $rootScope.profile.currentDepartment.campaignStatus === "VERIFIED"
          : true;

      activate();

      function activate() {
        vm.loading = true;

        const requests = [Restangular.one("admin/users", userId).get(), Restangular.all("samlconfiguration").getList()];

        if (!config.isEnabled("chat-only-team") && vm.profile.currentDepartment.isAdministrator) {
          requests.push(Restangular.one("admin/users", userId).getList("numbers"));
          requests.push(Restangular.one("admin/users", userId).getList("groupAccounts"));
          requests.push(
            Restangular.all("admin/groupAccounts").getList({
              currentDepartmentOnly: true,
            })
          );
        }

        $q.all(requests).then(function (results) {
          const user = results[0];
          const authMethods = results[1];
          const currentDepartment = user.departments.find((x) => x.id === profile.currentDepartment.id);
          let phoneNumber = [];
          let selectedGroupAccountIds = [];

          if (!config.isEnabled("chat-only-team") && vm.profile.currentDepartment.isAdministrator) {
            phoneNumber = results[2][0];
            selectedGroupAccountIds = _.pluck(results[3], "id");
            vm.groupAccounts = results[4];
          }

          // OLD USER PICKER START
          user.segmentsAuthorized =
            currentDepartment.featureAccess &&
            currentDepartment.featureAccess.filter((x) => x.feature === 0).length === 1; //0 === Segments
          user.chatAgentAuthorized =
            currentDepartment.featureAccess &&
            currentDepartment.featureAccess.filter((x) => x.feature === 1).length === 1; //1 === ChatAgent
          user.manualContactAddAuthorized =
            currentDepartment.featureAccess &&
            currentDepartment.featureAccess.filter((x) => x.feature === 3).length === 1; //3 === ManualContactAdd
          // OLD USER PICKER END

          const userRole = currentDepartment.isAdministrator ? "administrator" : "user";
          user.selectedDepartments = [
            {
              id: currentDepartment.id,
              role: userRole,
              authorizedFeatures: currentDepartment.allowedFeatures,
              chatRole: currentDepartment.userIsChatAgent,
              personalInboxEnabled: currentDepartment.personalInboxEnabled,
              selectedGroupAccounts: selectedGroupAccountIds,
              selectedHighSpeedAccounts: [],
            },
          ];

          user.number = phoneNumber;
          user.forwardingNumber = $filter("phone")(user.forwardingNumber);

          vm.user = user;
          vm.authMethods = authMethods;

          vm.user.chatOnlyUser = !currentDepartment.userHasTexting && currentDepartment.userIsChatAgent;

          vm.user.chatOnlyTeam = !vm.profile.currentDepartment.teamHasSms;

          vm.permissions = {
            text: {
              access: currentDepartment.isAdministrator || currentDepartment.userHasTexting,
              contactCreation: currentDepartment.isManualContactAuthorized,
              segments: currentDepartment.isSegmentAuthorized,
              role: currentDepartment.isAdministrator ? "administrator" : "user",
            },
            chat: {
              access: currentDepartment.isChatAdministrator || currentDepartment.userIsChatAgent,
              role: currentDepartment.isChatAdministrator ? "administrator" : "user",
            },
          };
          vm.textEnabled = vm.permissions.text.access;
          vm.loading = false;
        });

        vm.hasValidationErrors = {};
      }

      function enabledMessaging() {
        vm.user.selectedDepartments[0].personalInboxEnabled = true;
      }

      function assignNumber() {
        goToStep(2);
      }

      function goToStep(step) {
        setHeight();
        vm.slideDirection = step < vm.step ? "right" : "left";
        $timeout(function () {
          vm.step = step;
        });
        $timeout(function () {
          setHeight("auto");
          vm.slideDirection = "";
        }, 500);
      }

      function invalidAccountSetup() {
        if (!vm.user) return true;
        var isUser = vm.user.selectedDepartments[0].role === "user",
          noGroups = !vm.user.selectedDepartments[0].selectedGroupAccounts.length,
          noHighSpeed = !vm.user.selectedDepartments[0].selectedHighSpeedAccounts.length,
          noInbox = !vm.user.selectedDepartments[0].personalInboxEnabled,
          chatOnly = vm.user.chatOnlyUser && vm.user.chatOnlyTeam;
        if (isUser && noGroups && noHighSpeed && noInbox && !chatOnly) return true;
        return false;
      }

      function isAccountChecked(type, id) {
        if (type === "group") {
          return vm.user.selectedDepartments[0].selectedGroupAccounts.indexOf(id) > -1;
        } else {
          return vm.user.selectedDepartments[0].selectedHighSpeedAccounts.indexOf(id) > -1;
        }
      }

      function isValid() {
        vm.hasValidationErrors = {};
        vm.hasValidationErrors.firstName = !vm.user.firstName;
        vm.hasValidationErrors.lastName = !vm.user.lastName;
        vm.hasValidationErrors.campusUniqueId = !vm.user.campusUniqueId;
        vm.hasValidationErrors.emailAddress = !vm.user.emailAddress;

        return !_.includes(vm.hasValidationErrors, true);
      }

      function selectNumber() {
        if (vm.user.phoneType === "reserved") {
          vm.user.virtualOptions.isReserved = true;
          vm.user.virtualOptions.numberSid = vm.user.virtualOptions.phoneNumber.sid;
          vm.user.virtualOptions.phoneNumber = vm.user.virtualOptions.phoneNumber.friendlyName;
        }
        if (vm.user.virtualOptions && vm.user.phoneType !== "reserved") {
          vm.user.virtualOptions.isReserved = false;
          vm.user.virtualOptions.numberSid = vm.user.virtualOptions.phoneNumber.sid;
          vm.user.virtualOptions.phoneNumber = vm.user.virtualOptions.phoneNumber.phoneNumber;
        }
        enabledMessaging();
        goToStep(1);
      }

      function sendWelcomeEmail() {
        vm.sendingEmail = true;
        Restangular.all("admin/users/sendEmail")
          .post(vm.user)
          .then(
            function () {
              growl.success("Welcome email has been sent!");
              $uibModalInstance.close();
            },
            function (errResponse) {
              vm.sendingEmail = false;
              vm.serverValidationError = errResponse.data;
            }
          );
      }

      function setHeight(height) {
        var modal = $(".modal-slide-container"),
          newHeight = height ? height : modal[0].scrollHeight;
        modal.height(newHeight);
      }

      function toggleAccount(type, accountId) {
        var selected =
            type === "group"
              ? vm.user.selectedDepartments[0].selectedGroupAccounts
              : vm.user.selectedDepartments[0].selectedHighSpeedAccounts,
          index = selected.indexOf(accountId);

        if (index > -1) {
          selected.splice(index, 1);
        } else {
          selected.push(accountId);
        }
      }

      function update() {
        if (isValid()) {
          vm.busy = true;

          if (vm.user.samlConfigurationId == "null") vm.user.samlConfigurationId = null;

          var mixpanelObject = {
            "User Type": vm.user.selectedDepartments[0].role,
            "Group Accounts Count": vm.user.selectedDepartments[0].selectedGroupAccounts.length,
            Numberless: !vm.user.landlineOptions && !vm.user.virtualOptions,
            "Existing User": true,
            "Contact Creation": vm.manualContactAddAuthorized ? "Enabled" : "Disabled",
          };

          if (vm.user.selectedDepartments[0].role === "user") {
            mixpanelObject["Segment Permission"] = vm.user.selectedDepartments[0].authorizedFeatures.includes(
              "Segments"
            );
          }
          Restangular.all("admin/users/update")
            .post(vm.user)
            .then(
              function () {
                growl.success("Your user has been updated!");
                mixpanel.track("Updated User", mixpanelObject);
                $uibModalInstance.close();

                if (vm.user.id === profile.id) $state.reload();
              },
              function (errResponse) {
                vm.serverValidationError = errResponse.data;
                vm.busy = false;
              }
            );
        }
      }
      function updatePermissions(permissions) {
        if (!vm.user) return;
        if (permissions.text.access) {
          vm.role = permissions.text.role;
          vm.user.selectedDepartments[0].role = permissions.text.role;
        } else {
          delete vm.user.selectedDepartments[0].role;
          vm.role = "none";
        }
        if (permissions.chat.access) {
          vm.chatRole = permissions.chat.role;
          vm.user.selectedDepartments[0].chatRole = permissions.chat.role;
        } else {
          delete vm.user.selectedDepartments[0].chatRole;
          vm.chatRole = "none";
        }
        vm.chatAgentAuthorized = permissions.chat.access;
        vm.manualContactAddAuthorized = permissions.text.contactCreation;

        vm.permissions = permissions;
      }

      function updateAuthorizedFeatures(authorizedFeatures) {
        if (vm.user && authorizedFeatures) {
          vm.user.selectedDepartments[0].authorizedFeatures = authorizedFeatures;
        }
      }
      function showRolePicker() {
        if (
          config.isEnabled("text-and-chat-team") &&
          vm.profile.currentDepartment.isAdministrator &&
          vm.profile.currentDepartment.isChatAdministrator
        ) {
          return "text-and-chat";
        }

        if (
          config.isEnabled("text-only-team") ||
          (vm.profile.currentDepartment.isAdministrator && !vm.profile.currentDepartment.isChatAdministrator)
        ) {
          return "text-only";
        }

        if (
          config.isEnabled("chat-only-team") ||
          (!vm.profile.currentDepartment.isAdministrator && vm.profile.currentDepartment.isChatAdministrator)
        ) {
          return "chat-only";
        }
      }

      function toggleTextFields(value) {
        vm.textEnabled = value;
      }
    }
  );
