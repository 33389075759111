angular
  .module("mongoose")
  .controller(
    "SentController",
    function (
      $scope,
      $timeout,
      $state,
      Restangular,
      firebaseService,
      config,
      profile,
      inbox
    ) {
      var vm = this,
        departmentId = profile.currentDepartment.id,
        collectionRef =
          firebaseService.getInboxRef(inbox, profile) + "/outgoing",
        unsubscribeInitialWatch,
        unsubscribeNextWatch,
        unsubscribePreviousWatch;

      vm.getDate = getDate;
      vm.open = open;

      $scope.$on("$destroy", destroy);

      activate();

      function activate() {
        firebaseService.isAuth().then(
          function () {
            load();
          },
          function () {
            vm.firebaseError = true;
          }
        );
      }

      function load() {
        vm.loading = true;

        var loaded = false,
          limit = 50,
          firestore = firebase.firestore(),
          query = firestore
            .collection(collectionRef)
            .where("isComplete", "==", true);

        if (inbox.type === "personal")
          query = query.where("departmentId", "==", departmentId);

        query = query.orderBy("composeDate", "desc").limit(limit);

        unsubscribeInitialWatch = query.onSnapshot(
          function (querySnapshot) {
            var currentSnapshot,
              previousPageReferences = [],
              nextPageReferences = [],
              nextQuery,
              previousQuery;

            if (!loaded) {
              loaded = true;
              if (querySnapshot.docs.length) {
                nextPageReferences.push(
                  querySnapshot.docs[querySnapshot.docs.length - 1]
                );
              }
              populateInbox(querySnapshot);
            } else {
              updateInbox(querySnapshot);
            }

            vm.next = getNext;
            vm.previous = getPrevious;

            function mapBatch(batch) {
              var doc = _.isUndefined(batch.doc) ? batch : batch.doc,
                item = doc.data(),
                params =
                  inbox.type === "group"
                    ? { groupAccountId: inbox.groupAccountId }
                    : {};

              item.id = doc.id;

              return Restangular.one("outgoingmessagebatch", item.batchId)
                .getList("counts", params, { "x-disable-loading": "true" })
                .then(function (counts) {
                  var res = counts[0],
                    totalSent = res.deliveredCount + res.undeliveredCount;

                  item.totalSent = totalSent;
                  item.delivered = res.deliveredCount;
                  item.deliveredPercent =
                    (item.delivered / totalSent) * 100 || "0";
                  item.repliedPercent =
                    (res.replyCount / totalSent) * 100 || "0";

                  return item;
                });
            }

            function populateInbox(snapshot) {
              var collection = [];
              snapshot.forEach(function (doc) {
                mapBatch(doc).then(function (result) {
                  collection.push(result);
                  vm.loading = false;
                });
              });

              if (snapshot.empty) vm.loading = false;

              currentSnapshot = snapshot;

              checkPrevious();
              checkNext();

              $timeout(function () {
                vm.inbox = collection;
              });
            }

            function updateInbox(snapshot) {
              snapshot.docChanges().forEach(function (change) {
                function update() {
                  if (change.type === "added") {
                    mapBatch(change).then(function (result) {
                      vm.inbox.push(result);
                    });
                  }
                  if (change.type === "modified") {
                    mapBatch(change).then(function (result) {
                      var i = _.findIndex(vm.inbox, { id: result.id });
                      _.merge(vm.inbox[i], result);
                    });
                  }
                  if (change.type === "removed") {
                    _.remove(vm.inbox, { id: change.doc.id });
                  }
                  checkPrevious();
                  checkNext();
                }
                $timeout(update);
              });
            }

            function checkNext() {
              if (!nextPageReferences.length) {
                vm.showNext = false;
                vm.inboxNext = [];
                return;
              }

              nextQuery = firestore
                .collection(collectionRef)
                .where("isComplete", "==", true)
                .orderBy("composeDate", "desc")
                .startAfter(
                  nextPageReferences[nextPageReferences.length - 1].data()
                    .composeDate
                )
                .limit(limit);

              nextQuery.get().then(function (querySnapshot) {
                $timeout(function () {
                  vm.showNext = !querySnapshot.empty;
                });
              });
            }

            function checkPrevious() {
              if (!previousPageReferences.length) {
                vm.showPrevious = false;
                return;
              }

              previousQuery = firestore
                .collection(collectionRef)
                .where("isComplete", "==", true)
                .orderBy("composeDate", "desc")
                .startAt(
                  previousPageReferences[
                    previousPageReferences.length - 1
                  ].data().composeDate
                )
                .limit(limit);

              previousQuery.get().then(function (querySnapshot) {
                $timeout(function () {
                  vm.showPrevious = !querySnapshot.empty;
                });
              });
            }

            function getNext() {
              var nextLoaded = false;
              vm.loading = true;
              unsubscribeInitialWatch();

              previousPageReferences.push(currentSnapshot.docs[0]);

              unsubscribeNextWatch = nextQuery.onSnapshot(function (
                querySnapshot
              ) {
                if (!nextLoaded) {
                  nextLoaded = true;
                  nextPageReferences.push(
                    querySnapshot.docs[querySnapshot.docs.length - 1]
                  );
                  populateInbox(querySnapshot);
                } else {
                  updateInbox(querySnapshot);
                }
                nextLoaded = true;
                $timeout(scrollToTop, 200);
                vm.loading = false;
              });
            }

            function getPrevious() {
              var previousLoaded = false;
              vm.loading = true;
              unsubscribeInitialWatch();

              previousPageReferences.pop();
              nextPageReferences.pop();

              unsubscribePreviousWatch = previousQuery.onSnapshot(function (
                querySnapshot
              ) {
                if (!previousLoaded) {
                  populateInbox(querySnapshot);
                } else {
                  updateInbox(querySnapshot);
                }
                previousLoaded = true;
                $timeout(scrollToTop, 200);
                vm.loading = false;
              });
            }
          },
          function (error) {
            vm.loading = false;
            vm.firebaseError = true;
            vm.firebaseErrorMessage =
              error.code == "permission-denied"
                ? "You do not have permission to view this account."
                : "";
          }
        );
      }

      function destroy() {
        if (_.isFunction(unsubscribeInitialWatch)) unsubscribeInitialWatch();

        if (_.isFunction(unsubscribeNextWatch)) unsubscribeNextWatch();

        if (_.isFunction(unsubscribePreviousWatch)) unsubscribePreviousWatch();
      }

      function getDate(utcDate) {
        var date = moment.tz(moment.utc(utcDate), config.timeZone);
        return date.format("M/D/YY[<br />]h:mm A");
      }

      function open(id) {
        var state =
          inbox.type === "personal"
            ? "app.personal.sent-detail"
            : "app.groups.sent-detail";
        mixpanel.track("Clicked to view Sent Detail");
        $state.go(state, { id: id });
      }

      function scrollToTop() {
        var top = angular.element(document.getElementById("top")),
          container = angular.element(document.getElementById("content"));
        container.scrollTo(top, 100, 400);
      }
    }
  );
