angular
  .module("mongoose")
  .controller("ContactProfileController", function ($state, $rootScope, $uibModal, config, profile, $window) {
    var vm = this;

    vm.compose = compose;
    vm.edit = edit;
    vm.goBack = goBack;
    vm.deleteContact = deleteContact;

    vm.contactId = $state.params.contactId;
    const fromV2 = $state.params.fromV2;

    function edit(contactId) {
      var options = {
        templateUrl: "students/modals/contact-modal.html",
        controller: "ContactModalController",
        controllerAs: "vm",
        resolve: {
          contactId: contactId,
          config: config,
          profile: profile,
          actionsEnabled: true,
          showEditViewOnly: true,
        },
        windowClass: "contact-modal",
      };

      $uibModal.open(options).result.then(function () {
        window.eventBus.dispatchEvent("contactUpdated");
      });
    }

    function goBack() {
      if (fromV2) {
        history.back();
      } else {
        const searchParams = JSON.parse($window.sessionStorage.getItem("student-search-params"));
        const searchText = _.get(searchParams, "criteria[0].value");

        var state = $rootScope.previousState.name ? $rootScope.previousState.name : "app.students.view";
        var params = $rootScope.previousState.name
          ? Object.assign($rootScope.previousParams, {
              search: searchText,
              fromProfile: true,
            })
          : { search: searchText, fromProfile: true };
        $state.go(state, params);
      }
    }

    function compose(options) {
      $rootScope.openComposeModal(options);
    }

    function deleteContact(contactId) {
      var modalInstance = $uibModal.open({
        templateUrl: "students/modals/delete-student.html",
        controller: "DeleteStudentModalController",
        ariaLabelledBy: "delete-student",
        resolve: {
          studentId: function () {
            return contactId;
          },
        },
      });

      modalInstance.result.then(function () {
        $state.go("app.students.view");
      });
    }
  });
