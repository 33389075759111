angular.module('mongoose').controller('SlateExportLogController', function ($scope, $state, $interval, growl, config, Restangular, FileSaver, Blob) {
    
    var vm = this,
        initialLoad = true,
        interval;

    vm.initiateMessageActivityExport = initiateMessageActivityExport;
    vm.initiateOptOutExport = initiateOptOutExport;
    vm.initiateDeliveryErrorsExport = initiateDeliveryErrorsExport;
    vm.viewStudents = viewStudents;

    function loadExports() {
        Restangular.all("slate/exports").getList({},{'x-disable-loading': !initialLoad}).then(function(results) {
            initialLoad = false;
            vm.exports = results.map(function(i) {
                i.dateStarted = moment.tz(i.dateStarted, config.timeZone).format('M/D/YY, h:mma z');
                i.dateCompleted = i.dateCompleted ? moment.tz(i.dateCompleted, config.timeZone).format('M/D/YY, h:mma z') : '';
                return i;
            });
        }, function(error) {
            $interval.cancel(interval); 
            growl.error("An error has occurred updating export status.  Please reload the page.");
        });
    }
    
    function initiateMessageActivityExport(exportType) {
        Restangular.all("slate/messageActivityExport").post().then(function () {
            growl.success("Your export has been initiated!");
        });
    }
     
    function initiateOptOutExport(exportType) {
        Restangular.all("slate/optOutExport").post().then(function () {
            growl.success("Your export has been initiated!");
        });
    }
     
    function initiateDeliveryErrorsExport(exportType) {
        Restangular.all("slate/deliveryErrorsExport").post().then(function () {
            growl.success("Your export has been initiated!");
        });
    }

    function initialize() {
        loadExports();
        
        interval = $interval(function() {
            loadExports();
        }, 10000);
        
        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    }
    
    function viewStudents(exportBatchId) {
        Restangular.one("slate/exports", exportBatchId).get().then(function(batch) {
            var records = batch.records.replace(/\t/g, ',');
            var data = new Blob([records], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(data, 'records_' + exportBatchId + '.csv');
        });
    }
    
    initialize();
});