angular.module("mongoose").component("composeCreditBalance", {
  templateUrl: "messages/modals/compose-credit-balance.html",
  bindings: {
    inbox: "<",
  },
  controllerAs: "vm",
  controller: function ($rootScope, Restangular) {
    var vm = this;

    vm.isAdmin = $rootScope.profile.isAdministrator;
    vm.isLow = isLow;
    vm.$onInit = activate;

    function activate() {
      vm.inbox.credits = {
        loaded: false,
        balance: null,
      };
      return Restangular.one("highspeedinbox", vm.inbox.highSpeedId)
        .customGET("creditbalance")
        .then(function (response) {
          if (response) {
            vm.inbox.credits.balance = response.balance;
          }
        })
        .finally(function () {
          vm.inbox.credits.loaded = true;
        });
    }

    function isLow() {
      return vm.inbox.credits.loaded && vm.inbox.credits.balance < 1;
    }
  },
});
