angular.module('mongoose').controller('DeleteUserModalController', function (user, $uibModalInstance, Restangular) {

    var vm = this;

    vm.canDelete = function() {
        return vm.userConfirm && vm.userConfirm.toLowerCase() === vm.user.displayName.toLowerCase();
    };

    vm.close = $uibModalInstance.dismiss;

    vm.delete = function () {
        vm.loading = true;
        Restangular.one('admin/users', user.id).remove().then(function () {
            mixpanel.track('Deleted User', {
                'User Name': user.displayName
            });
            $uibModalInstance.close();
        }, function(){
            vm.loading = false;
        });
    };

    vm.user = user;

});