/**
 * @desc button for selecting a template
 * @example <smart-message-picker></smart-message-picker>
 */
angular.module("mongoose").directive("smartMessagePicker", smartMessagePicker);

function smartMessagePicker($rootScope, $q, $state, $timeout, Restangular, securityService) {
  return {
    link: linkFunc,
    templateUrl: "core/compose-toolbar/smart-message-picker.html",
    restrict: "E",
    scope: {
      message: "=",
      disabled: "=",
      isGroup: "=",
    },
  };

  function linkFunc(scope, el, attr) {
    scope.smartMessagePicker = {
      create: create,
      data: [],
      isDisabled: false,
      isLoading: true,
      isOpen: false,
      select: select,
      toggle: toggle,
    };

    scope.$watch("disabled", function (disabled) {
      scope.smartMessagePicker.isDisabled = disabled;
    });

    activate();

    function activate() {
      var profilePromise = securityService.getProfile(),
        staffPromise = Restangular.all("staff").getList(),
        smartMessagePromise = Restangular.all("smartmessages").getList();

      $q.all([profilePromise, staffPromise, smartMessagePromise]).then(function (results) {
        var userId = results[0].id,
          staff = results[1];

        scope.smartMessagePicker.isLoading = false;
        scope.smartMessagePicker.data = _.map(results[2].plain(), function (message) {
          var createdBy = _.find(staff, { id: message.createdById }),
            name = !createdBy ? "Unknown" : message.createdById === userId ? "You" : createdBy.displayName;

          message.createdByName = name;
          return message;
        });
      });
    }

    function close() {
      $("body").unbind("click.popover");
      $timeout(function () {
        scope.smartMessagePicker.isOpen = false;
      });
    }

    function create() {
      $state.go("app.automation.smartmessages.new");
      $rootScope.closeComposeModal();
    }

    function select(smartMessage) {
      close();
      scope.message.smartMessage = smartMessage;
      scope.message.text = smartMessage.message;
      mixpanel.track("Inserted Smart Message");
    }

    function toggle() {
      if (scope.smartMessagePicker.isDisabled) return;

      $("body").trigger("click.popover");
      scope.smartMessagePicker.isOpen = !scope.smartMessagePicker.isOpen;
      if (scope.smartMessagePicker.isOpen) {
        $("body").bind("click.popover", function (e) {
          if (
            $(e.target).parents(".popover").length === 0 &&
            !($(e.target).attr("uib-popover-template") || $(e.target).parent().attr("uib-popover-template"))
          ) {
            close();
          }
        });
      }
    }
  }
}
