angular.module("mongoose").controller("ScheduledMessagesController", function ($rootScope, $uibModal, $state, config) {
  var vm = this;

  vm.edit = edit;
  vm.scheduleMessage = scheduleMessage;
  vm.viewSmartMessage = viewSmartMessage;

  activate();

  function activate() {
    vm.inboxes = $rootScope.profile.inboxes;
  }

  function edit(message) {
    $rootScope.openComposeModal(message);
  }

  function viewSmartMessage(smartMessage) {
    $uibModal.open({
      controller: "ViewSmartMessageModalController as vm",
      templateUrl: "automation/smartmessages/view-smartmessages-modal.html",
      ariaLabelledBy: "view-smart",
      resolve: {
        smartMessage: function () {
          return smartMessage;
        },
      },
    });
  }

  function scheduleMessage() {
    mixpanel.track("Clicked Create from Scheduled Messages Page Header");
    $rootScope.openComposeModal({ isScheduledMessage: true, modalTitle: "Schedule Message" });
  }
});
