angular.module("mongoose").controller("RecruitController", function ($state) {
  let vm = this;

  vm.currentTab = $state.params.currentTab;
  vm.handleTabChange = handleTabChange;

  function handleTabChange(data) {
    $state.go("app.integrations.recruit.view.tab", {
      currentTab: data.currentTab,
    });
  }
});
