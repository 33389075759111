
angular.module('mongoose').controller('EthosImportController', function ($interval,$scope, growl, Restangular, config, ethosService, FileSaver, Blob) {

    var vm = this;
    var interval;

    vm.initiateImport = initiateImport;
    
    activate();

    function activate() {
        getHistory();

        interval = $interval(function() {
            getHistory(true);
        }, 5000);
        
        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    }

    function getHistory(disableLoading){
        ethosService.getImports({timezone: config.timeZone, disableLoading: disableLoading}).then(function(imports){
            vm.imports = imports;
        }, function() {
            $interval.cancel(interval);
        });
    }

    function initiateImport(differential){
        Restangular.all('ethos/imports').post({differential: differential}).then(function(){
            growl.success("Your import has been initiated");
        });
    }
});