angular
  .module("mongoose")
  .controller(
    "ViewStaticSegmentController",
    function (
      Restangular,
      FileSaver,
      $stateParams,
      $q,
      $state,
      profile,
      $uibModal,
      $rootScope,
      $filter,
      config
    ) {
      var vm = this;

      var segmentId = $stateParams.id;

      if (!segmentId) {
        $state.go("app.segments.list");
      }

      function GetSegment() {
        return Restangular.one("staticsegments", segmentId)
          .get()
          .then(function (segment) {
            segment.tagText = segment.name + " (Static)";
            segment.text = segment.tagText;
            segment.type = "Static";

            if (segment.createdBy !== profile.id) {
              Restangular.all("staff")
                .get(segment.createdBy)
                .then(function (staff) {
                  segment.createdByName = staff.displayName;
                });
            }

            var studentsProm = segment
              .all("students")
              .getList()
              .then(function (students) {
                segment.students = _.map(students, function (student) {
                  student.lastModified = student.lastUpdatedOn;
                  return student;
                });
              });
            var statsProm = segment
              .one("statistics")
              .get()
              .then(function (stats) {
                segment.statistics = stats;
              });

            return $q.all([studentsProm, statsProm]).then(function () {
              return segment;
            });
          });
      }

      var load = function () {
          return GetSegment();
        },
        setup = function (segment) {
          vm.searchText = "";
          vm.segment = segment;
          vm.profile = profile;
          vm.isAdministrator =
            profile.isAdministrator ||
            profile.isOwner ||
            profile.isMongooseAdministrator;

          vm.remove = function (student) {
            Restangular.one("staticsegments", vm.segment.id)
              .one("students", student.id)
              .remove()
              .then(function () {
                return GetSegment().then(function (seg) {
                  vm.segment = seg;
                });
              });
          };

          vm.message = function (contact) {
            var contacts = [],
              formattedMobile = $filter("phone")(contact.mobileNumber);

            contact.tagText = contact.firstName
              ? contact.firstName + " " + contact.lastName
              : formattedMobile;
            contacts.push(contact);
            $rootScope.openComposeModal({ contacts: contacts });
          };

          vm.export = function () {
            vm.segment
              .all("students")
              .all("export")
              .getList()
              .then(function (ex) {
                var data = new Blob([ex], { type: "text/csv;charset=utf-8" });
                FileSaver.saveAs(data, "segment-" + vm.segment.id + ".csv");
              });
          };

          vm.addStudents = function () {
            var modalInstance = $uibModal.open({
              templateUrl:
                "segments/static/view/view-segment.static.addstudent.html",
              controller: "AddStaticSegmentStudentsController",
              ariaLabelledBy: "add-student",
              resolve: {
                segmentId: function () {
                  return vm.segment.id;
                },
              },
            });

            modalInstance.result.then(function () {
              return GetSegment().then(function (seg) {
                vm.segment = seg;
              });
            });
          };

          vm.copy = function (segment) {
            var modalInstance = $uibModal.open({
              controller: "CopyStaticSegmentModalController",
              templateUrl: "segments/static/copy/copy-segment.static.html",
              ariaLabelledBy: "copy-segment",
              resolve: {
                template: function () {
                  return segment;
                },
              },
            });

            modalInstance.result.then(function (newSegment) {
              $state.go("app.segments.view.static", { id: newSegment.id });
            });
          };

          vm.rename = function () {
            var modalInstance = $uibModal.open({
              controller: "RenameStaticSegmentModalController",
              templateUrl:
                "segments/static/view/view-segment.static.rename.html",
              ariaLabelledBy: "rename-segment",
              resolve: {
                segment: function () {
                  return vm.segment;
                },
              },
            });

            modalInstance.result.then(function (newName) {
              if (newName) {
                load().then(setup);
              }
            });
          };

          vm.resetSearch = function () {
            vm.searchText = "";
            vm.search();
          };

          vm.search = _.debounce(function () {
            vm.segment
              .all("students")
              .getList({ searchText: vm.searchText })
              .then(function (students) {
                vm.segment.students = _.map(students, function (student) {
                  student.lastModified = student.lastUpdatedOn;
                  return student;
                });
              });
          }, 500);

          vm.view = function (contact) {
            $state.go("app.students.contactProfile", {
              contactId: contact.id,
            });
          };

          vm.getOptedInStudentCount = function () {
            return _.filter(vm.segment.students, { optedIn: true }).length;
          };

          vm.getOptedOutStudentCount = function () {
            return _.filter(vm.segment.students, { optedIn: false }).length;
          };

          vm.sendMessage = function (segments) {
            $rootScope.openComposeModal({ segments: segments });
            mixpanel.track("Clicked Message from Segment Detail");
          };

          vm.archive = function (segment) {
            segment.archived = !segment.archived;
            segment.save().then(function () {
              mixpanel.track(
                (segment.archived ? "Archived" : "Unarchived") +
                  " Segment from Detail"
              );
              load().then(setup);
            });
          };

          vm.delete = function () {
            var modalInstance = $uibModal.open({
              controller: "DeleteSegmentModalController",
              controllerAs: "vm",
              templateUrl: "segments/delete-segment-modal.html",
              ariaLabelledBy: "delete-segment",
              resolve: {
                segment: function () {
                  return vm.segment;
                },
              },
            });

            modalInstance.result.then(function () {
              $state.go("app.segments.list");
            });
          };
        };

      load().then(setup);
    }
  );
