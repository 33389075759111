/**
 * @desc img preview after file selection
 * @example <media-picker-preview></media-picker-preview>
 */
angular
    .module('mongoose')
    .directive('mediaPickerPreview', mediaPickerPreview);

function mediaPickerPreview() {
    return {
        link: linkFunc,
        templateUrl: 'core/compose-toolbar/media-picker-preview.html',
        replace: true,
        restrict: 'E'
    };

    function linkFunc(scope) {
    }
}