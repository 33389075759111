angular.module("mongoose").factory("inboxEmptyMessageService", function () {
  var messages = [
      {
        text: "Enjoy this ten-second, zen moment of empty-inbox serenity.",
        icon: "🧘",
        animation: "fadeIn",
      },
      {
        text: "Everyone is talking about how clean your inbox is. Great work!",
        icon: "✨",
        animation: "zoomInDown",
      },
      {
        text: "Boom! Inbox is empty. Fist bump.",
        icon: "🤜",
        animation: "slideInLeft",
      },
      {
        text:
          "Now that your inbox is empty, do you kind of miss all those messages?",
        icon: "🥹",
        animation: "fadeIn",
      },
      {
        text: "Empty inbox? Totally rad! <br>Do kids still say “rad?”",
        icon: "🏄",
        animation: "lightSpeedIn",
      },
      {
        text: "They say an empty inbox is a sign of mental superiority.",
        icon: "🤯",
        animation: "fadeInUp",
      },
      {
        text: "Nothing like a clean inbox.<br/>Nice job, super&nbsp;star.",
        icon: "⭐",
        animation: "zoomInDown",
      },
      {
        text:
          "<b><i>BOOM</i></b>. No more messages.<br/>Give yourself a pat on the back.<br/> Hug a stranger.<br/>Whatever you’re into.",
        icon: "👍",
        animation: "jackInTheBox",
      },
      {
        text: "Nothing in your inbox.",
        icon: "🙌",
        animation: "fadeInUp",
      },
      {
        text: "Leave no message unread?<br />Check. Nice&nbsp;work!",
        icon: "🎉",
        animation: "tada",
      },
      {
        text:
          "Has anyone ever told you how good you are at reading incoming&nbsp;messages?",
        icon: "🤩",
        animation: "fadeInUp",
      },
      {
        text: "Something tells me you’re<br/>good at your&nbsp;job.",
        icon: "🍎",
        animation: "fadeIn",
      },
    ],
    index = 0;

  messages = _.shuffle(messages);

  function get() {
    var message = messages[index];
    index = index < messages.length - 1 ? index + 1 : 0;
    return message;
  }

  return {
    get: get,
  };
});
