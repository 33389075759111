angular.module("mongoose").controller("CadenceChatListController", function ($state) {
  window.singleSpa.unloadApplication("cadenceAi");
  let vm = this;

  vm.conversationSelected = conversationSelected;
  vm.selectedConversationId = $state.params.selectedConversationId;

  function conversationSelected(data) {
    $state.go("app.webchat.list.conversation", {
      selectedConversationId: data.conversationId,
    });
  }
});
