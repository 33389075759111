angular
  .module("mongoose")
  .controller(
    "StudentsViewController",
    function (
      $rootScope,
      $scope,
      $uibModal,
      $sce,
      $window,
      $filter,
      Restangular,
      config,
      profile,
      fieldService,
      $state,
      $stateParams
    ) {
      var baseFieldKeys = ["Contact Id", "Full Name", "First Name", "Last Name", "Mobile Number", "Opted In"],
        searchParams = $window.sessionStorage.getItem("student-search-params");

      $scope.delete = deleteStudent;
      $scope.emoji = $sce.trustAsHtml(
        twemoji.parse("\uD83E\uDD14", { base: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/" })
      );
      $scope.view = view;
      $scope.selectSearchField = selectSearchField;
      $scope.formatDate = formatDate;
      $scope.hasCustomFields = false;
      $scope.getSearchPlaceholder = getSearchPlaceholder;
      $scope.sendMessage = sendMessage;
      $scope.setPagination = setPagination;

      $scope.filterByStaff = false;
      $scope.filterAssignedTo = null;
      $scope.staff = [];
      $scope.params = {};
      $scope.pagination = { skip: 0, take: 50, sort: [] };
      $scope.totalContacts = totalContacts;
      $scope.showGrid = true;

      $scope.$watch("filterByStaff", toggleFilterByStaff);

      activate();

      function activate() {
        $scope.config = config;
        $scope.students = null;
        $scope.isAdministrator = profile.isAdministrator || profile.isMongooseAdministrator;
        $scope.isMongooseAdministrator = profile.isMongooseAdministrator;
        $scope.searchText = "";

        if ($rootScope.config.customFields.enabled) {
          $scope.hasCustomFields = true;
          $scope.search = searchFields;
          Restangular.all("staff")
            .getList()
            .then(function (s) {
              $scope.staff = s;
              getCustomFields();
            });

          $scope.gridOptions = {
            paginationPageSize: 50,
            rowHeight: 60,
          };
        } else {
          $scope.search = search;
        }
      }

      function getCustomFields() {
        fieldService.get(true).then(function (response) {
          var fields = _.sortBy(response.plain(), "displayName"),
            defaultField = _.find(fields, { displayName: "Full Name" });

          if (searchParams) {
            searchParams = JSON.parse(searchParams);
            var criteria = searchParams.criteria[0];
            defaultField = _.find(fields, { id: criteria.field });
            $scope.searchText = $stateParams.search;
            $scope.filterByStaff = searchParams.onlyUnassigned || !!searchParams.assignedTo;
            $scope.filterAssignedTo = searchParams.onlyUnassigned ? "unassigned" : searchParams.assignedTo;
          }

          $scope.customFields = fields;
          $scope.selectedSearchField = defaultField ? defaultField : response[0];

          $scope.search(true);
        });
      }

      function getSearchPlaceholder(label) {
        return $scope.hasCustomFields
          ? label
            ? "Search by " + label
            : ""
          : "Search by Name, Contact ID, or Mobile Number";
      }

      function deleteStudent(studentId) {
        var modalInstance = $uibModal.open({
          templateUrl: "students/modals/delete-student.html",
          controller: "DeleteStudentModalController",
          ariaLabelledBy: "delete-student",
          resolve: {
            studentId: function () {
              return studentId;
            },
          },
        });

        modalInstance.result.then(function () {
          $scope.search();
        });

        mixpanel.track("Clicked Delete from Student Search");
      }

      function formatDate(rawDate) {
        var date = moment.tz(rawDate, config.timeZone);
        return date.toDate();
      }

      function view(contactId) {
        $state.go("app.students.contactProfile", { contactId: contactId });
      }

      function saveSearchParams(params) {
        $window.sessionStorage.setItem("student-search-params", JSON.stringify(params));
      }

      function search() {
        Restangular.all("students")
          .getList({ searchText: $scope.searchText, includeOptOut: true })
          .then(function (results) {
            $scope.students = results.plain();
            $scope.searchedText = $scope.searchText;
          });
      }

      function sendMessage(contact) {
        var contacts = [],
          formattedMobile = $filter("phone")(contact.MobileNumber),
          displayText = contact.FirstName ? contact.FirstName + " " + contact.LastName : formattedMobile;

        contact.tagText = displayText;
        contact.id = contact.Id;
        contacts.push(contact);
        $rootScope.openComposeModal({ contacts: contacts });

        mixpanel.track("Clicked Message from Student Search");
      }

      function searchFields(autoSearch) {
        var customFields = $scope.customFields,
          selectedFieldId = $scope.selectedSearchField.id,
          displayKeys = [],
          includeKeys = [],
          includeIds = [];

        displayKeys.push($scope.selectedSearchField.displayName);
        includeKeys = _.difference(displayKeys, baseFieldKeys);

        _.each(customFields, function (n) {
          var index = _.indexOf(includeKeys, n.displayName);
          if (index !== -1) {
            includeIds.push(n.id);
          }
        });

        var params = {
          criteria: [
            {
              field: selectedFieldId,
              comparison: "startswith",
              value: $scope.searchText,
            },
          ],
          includeFields: includeIds,
          conjunction: "all",
        };

        if ($scope.filterAssignedTo === "unassigned") {
          params.onlyUnassigned = true;
        } else {
          params.assignedTo = $scope.filterAssignedTo ? parseInt($scope.filterAssignedTo) : null;
        }
        $scope.params = params;
        $scope.pagination.skip = 0;
        saveSearchParams(params);
      }

      function selectSearchField(field) {
        $scope.selectedSearchField = field;
        $scope.searchText = "";
        $("#searchText").focus();
        searchFields();
      }

      function toggleFilterByStaff(filter, oldFilter) {
        if (filter === oldFilter) return;
        if (!filter) {
          $scope.filterAssignedTo = null;
          $scope.search();
        }
        mixpanel.track("Toggled 'Filter by User Assignment' on Contacts Page");
      }
      function totalContacts(count) {
        $scope.showGrid = count > 0;
      }
      function setPagination(data) {
        if (data.sort !== $scope.pagination.sort) {
          data.skip = 0;
        }
        $scope.pagination = data;
      }

      mixpanel.track("Searched for Students using Fields");
    }
  );
