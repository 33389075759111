/**
 * @desc button for selecting a template
 * @example <template-picker></template-picker>
 */
angular
    .module('mongoose')
    .directive('templatePicker', templatePicker);

function templatePicker($timeout, Restangular) {

    return {
        link: linkFunc,
        templateUrl: 'core/compose-toolbar/template-picker.html',
        restrict: 'EA',
        scope: {
            message: '=',
            disabled: '='
        }
    };

    function linkFunc(scope, el, attr) {

        scope.templatePicker = {
            isDisabled: false,
            isLoading: true,
            isOpen: false,
            select: select,
            templates: '',
            toggle: toggle
        };

        scope.$watch('disabled', function(disabled){
            scope.templatePicker.isDisabled = disabled;
        });


        activate();

        function activate(){
            Restangular.all('templates').getList().then(function(templates) {
                scope.templatePicker.isLoading = false;
                scope.templatePicker.templates = templates;
            });
        }

        function close(){
            $('body').unbind('click.popover');
            $timeout(function(){
                scope.templatePicker.isOpen = false;
            });
        }

        function select(template){
            close();
            scope.message = template.message;
            mixpanel.track("Inserted Message Template");
        }

        function toggle(){
            if(scope.templatePicker.isDisabled) return;

            $('body').trigger('click.popover');
            scope.templatePicker.isOpen = !scope.templatePicker.isOpen;
            if(scope.templatePicker.isOpen){
                $('body').bind('click.popover', function(e){
                    if($(e.target).parents('.popover').length === 0 && !$(e.target).attr('uib-popover-template')){
                        close();
                    }
                });
            }
        }
    }
}