angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.integrations.renxt", {
      url: "/renxt",
      abstract: true,
      template: "<ui-view/>",
    })
    .state("app.integrations.renxt.view", {
      url: "",
      params: { currentTab: "dashboard" },
      templateUrl: "integrations/renxt/renxt.html",
      controller: "RenxtController",
      controllerAs: "vm",
      pageTitle: "Raiser's Edge NXT Integration",
    })
    .state("app.integrations.renxt.view.tab", {
      url: "/:currentTab/",
      params: { currentTab: null },
      templateUrl: "integrations/renxt/renxt.html",
      controller: "renxtController",
      controllerAs: "vm",
      pageTitle: "Raiser's Edge NXT Integration",
    })
    .state("app.integrations.renxt.importsettings", {
      url: "/imports/settings",
      templateUrl: "integrations/renxt/renxt-import-settings.html",
      controller: "RenxtImportSettingsController",
      controllerAs: "vm",
      pageTitle: "Raiser's Edge NXT Import Settings",
    })
    .state("app.integrations.renxt.exportsettings", {
      url: "/exports/settings",
      templateUrl: "integrations/renxt/renxt-export-settings.html",
      controller: "RenxtExportSettingsController",
      controllerAs: "vm",
      pageTitle: "Raiser's Edge Nxt Export Settings",
    });
});
