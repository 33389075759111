angular.module("mongoose").component("quickTipButton", {
  template:
    '<a class="quick-tip-button" href ng-click="$ctrl.showTip()" ng-style="{{$ctrl.addStyle}}">' +
    '<i class="tooltip-icon"></i>' +
    "<span>Show quick tips</span>" +
    "</a>",
  bindings: {
    addStyle: "@",
    tipId: "@",
  },
  controller: function ($rootScope) {
    var $ctrl = this;

    $ctrl.showTip = showTip;

    function showTip() {
      if (_.isUndefined(Appcues)) return;

      Appcues.show($ctrl.tipId);
    }
  },
});
