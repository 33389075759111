angular.module("mongoose").factory("firebaseService", function ($q) {
  var authPromise = $q.when(null);

  return {
    auth: auth,
    getAccountRef: getAccountRef,
    getInboxRef: getInboxRef,
    getOrgRef: getOrgRef,
    isAuth: isAuth,
    signOut: signOut,
  };

  function auth(profile) {
    var config = {
      apiKey: "AIzaSyDL-9f5yVBAza0u3psTkXF33QQFlDFPddI",
      authDomain: "mongoose-sms-dev.firebaseapp.com",
      databaseURL: "https://mongoose-sms-dev.firebaseio.com",
      projectId: "mongoose-sms-dev",
    };

    if (!firebase.apps.length) firebase.initializeApp(config);

    return (authPromise = firebase
      .auth()
      .signInWithCustomToken(profile.firestoreToken));
  }

  function getAccountRef(account, profile) {
    var orgRef = getOrgRef(profile);
    return orgRef + "/" + [account.documentKey] + "-" + account.id;
  }

  function getInboxRef(account, profile) {
    var orgRef = getOrgRef(profile);
    return orgRef + "/" + account.documentKey;
  }

  function getOrgRef(profile) {
    var orgId = profile.currentDepartment.organizationId,
      orgRef = "org-" + orgId + "-contacts";
    return orgRef;
  }

  function isAuth() {
    return authPromise;
  }

  function signOut() {
    return firebase.auth().signOut();
  }
});
