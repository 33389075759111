angular
  .module("mongoose")
  .controller(
    "NewSmartMessagesController",
    function ($rootScope, $state, Restangular, growl, smartMessageService, $scope, $window) {
      var vm = this,
        messageClosingOptions = smartMessageService.closingOptions,
        smartMessage = {
          name: "",
          message: "",
          messageClosing: messageClosingOptions[0].value,
          negativeReply: "",
          positiveReply: "",
        };

      vm.getMessageClosingLabel = getMessageClosingLabel;
      vm.isInvalid = isInvalid;
      vm.messageClosingOptions = messageClosingOptions;
      vm.smartMessage = smartMessage;
      vm.questionType = null;
      vm.submit = submit;

      $scope.templatePicker = getTemplates;
      $scope.addTemplate = addTemplate;
      $scope.templates = [];
      $scope.popoverState = popoverState;

      activate();

      function activate() {
        getTemplates();
      }

      function getMessageClosingLabel() {
        return smartMessageService.getMessageClosingLabel(vm.smartMessage.messageClosing);
      }

      function isInvalid() {
        return _.isUndefined(vm.smartMessage.message)
          ? false
          : vm.smartMessage.message.toLowerCase().indexOf("reply") !== -1 ||
              vm.smartMessage.message.toLowerCase().indexOf("respond") !== -1;
      }

      function submit() {
        Restangular.all("smartmessages")
          .post(vm.smartMessage)
          .then(
            function () {
              growl.success("Your Smart Message has been created!");
              mixpanel.track("Created Smart Message");
              window.location.replace("/#/v2/automation/smartmessages");
            },
            function (error) {
              growl.error(error && error.data ? error.data : "There was an issue creating your Smart Message.");
            }
          );
      }
      function getTemplates() {
        Restangular.all("templates")
          .getList()
          .then(function (templates) {
            $scope.templates = templates;
          });
      }

      function addTemplate(item) {
        vm.smartMessage.message = item.message;
        mixpanel.track("Inserted Message Template");
      }

      function popoverState(isPopoverOpen) {}
    }
  );
