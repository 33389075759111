(function () {
  "use strict";

  angular
    .module("mongoose")
    .controller("AccountSentDetailController", AccountSentDetailController);

  function AccountSentDetailController(
    $rootScope,
    $filter,
    $state,
    $stateParams,
    $q,
    $uibModal,
    FileSaver,
    firebaseService,
    config,
    profile,
    Restangular,
    utilitiesService,
    inbox
  ) {
    var vm = this,
      batchId = $stateParams.id,
      bulkActionMaxCount = 100,
      docId = "batch-" + batchId,
      statusFilterOptions = [
        { value: "delivered", label: "Delivered" },
        { value: "notDelivered", label: "Not Delivered" },
        { value: "replied", label: "Replied" },
        { value: "notReplied", label: "Did Not Reply" },
      ],
      firestore,
      collectionRef,
      docRef,
      recipientData;

    vm.export = exportRecipients;
    vm.filter = { status: null, searchText: "", category: null };
    vm.statusFilterOptions = statusFilterOptions;
    vm.getFilterLabel = getFilterLabel;
    vm.getMediaType = utilitiesService.getMediaType;
    vm.goBack = goBack;
    vm.gridOptions = {
      appScopeProvider: vm,
      paginationPageSize: 10,
      rowHeight: 40,
    };
    vm.loading = true;
    vm.documentLoaded = false;
    vm.openConversation = openConversation;

    vm.optOut = optOut;
    vm.recipientsLoaded = false;
    vm.reply = reply;
    vm.resetSearch = resetSearch;
    vm.search = getRecipients;
    vm.selectAllDisabled = selectAllDisabled;
    vm.selectAllDisabledMessage =
      "Use Export for operations of more than " +
      bulkActionMaxCount +
      " recipients. ";
    vm.selectAllRecipients = false;
    vm.selectRecipient = selectRecipient;
    vm.selectedRecipients = [];
    vm.timeZone = config.timeZone; //for use in cellFilter
    vm.toggleAllRecipients = toggleAllRecipients;
    vm.updateReplyCategory = updateReplyCategory;

    activate();

    function activate() {
      if (inbox.type !== "high-speed") {
        firestore = firebase.firestore();
        collectionRef =
          firebaseService.getInboxRef(inbox, profile) + "/outgoing";
        docRef = firestore.collection(collectionRef).doc(docId);
        firebaseService.isAuth().then(
          function () {
            getDocument().then(function () {
              var smartMessagePromise = $q.when(true);
              if (vm.batch.smartMessageId) {
                smartMessagePromise = Restangular.all("smartmessages")
                  .get(vm.batch.smartMessageId, { includeDeleted: true })
                  .then(function (message) {
                    vm.batch.smartMessageName = message.name;
                    vm.batch.positiveReply = message.positiveReply;
                    vm.batch.negativeReply = message.negativeReply;
                  });
              }
              return $q.all(smartMessagePromise, getCounts(), getRecipients());
            });
          },
          function () {
            vm.firebaseError = true;
            vm.loading = false;
          }
        );
      } else {
        vm.batch = {};
        return getCounts().then(getRecipients);
      }
    }

    function exportRecipients() {
      var params = getParams();

      if (vm.batch.smartMessageId) params.isSmartMessage = true;

      if (inbox.type !== "high-speed") {
        Restangular.one("outgoingmessagebatch", batchId)
          .getList("export", params)
          .then(function (ex) {
            var data = new Blob([ex], { type: "text/csv;charset=utf-8" });
            FileSaver.saveAs(
              data,
              "message-recipients-" + batchId + "-" + getFilterValue() + ".csv"
            );
          });
      }
    }

    function getAccountRestangularBase() {
      if (inbox.type === "group") {
        return Restangular.one("groupaccounts", inbox.groupId);
      }
      return Restangular.one("inbox", inbox.userId);
    }

    function getDocument() {
      return docRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            var batch = doc.data();
            batch.sendStart = $filter("messageDate")(
              batch.composeDate,
              config.timeZone,
              true
            );
            batch.sendComplete = moment(batch.composeDate).isSame(
              batch.completeDate,
              "minute"
            )
              ? null
              : $filter("utcToLocal")(
                  batch.completeDate,
                  config.timeZone,
                  "h:mm A"
                );
            batch.account = {
              name: inbox.name,
            };
            return (vm.batch = batch);
          } else {
            console.error("Batch document not found");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          vm.loading = false;
        });
    }

    function getFilterLabel() {
      var params = getParams();
      delete params.groupAccountId;
      delete params.departmentId;
      delete params.organizationId;
      return _.isEmpty(params) ? "All" : "Results";
    }

    function getFilterValue() {
      return getFilterLabel().toLowerCase();
    }

    function getParams() {
      var params =
        inbox.type === "group"
          ? { groupAccountId: inbox.groupId }
          : inbox.type === "high-speed"
          ? {
              departmentId: inbox.departmentId,
              organizationId:
                $rootScope.profile.currentDepartment.organizationId,
            }
          : {};

      if (vm.filter.searchText) params.queryText = vm.filter.searchText;

      if (!_.isNull(vm.filter.status)) {
        switch (vm.filter.status.value) {
          case "replied":
            params.hasReply = true;
            break;
          case "notReplied":
            params.hasReply = false;
            break;
          case "delivered":
            params.delivered = true;
            break;
          case "notDelivered":
            params.delivered = false;
            break;
        }
      }

      if (!_.isNull(vm.filter.category) && vm.filter.category != "null")
        params.replyCategory = vm.filter.category;

      return params;
    }

    function getCounts() {
      var params = getParams();

      if (inbox.type !== "high-speed") {
        return Restangular.one("outgoingmessagebatch", batchId)
          .getList("counts", params, { "x-disable-loading": "true" })
          .then(function (counts) {
            var res = counts[0],
              totalSent = res.deliveredCount + res.undeliveredCount;

            vm.batch.totalSent = totalSent;
            vm.batch.delivered = res.deliveredCount;
            vm.batch.deliveryFailures = res.undeliveredCount;
            vm.batch.totalReplies = res.replyCount;
            vm.batch.deliveredPercent =
              (vm.batch.delivered / totalSent) * 100 || "0";
            vm.batch.repliedPercent =
              (vm.batch.totalReplies / totalSent) * 100 || "0";
            vm.documentLoaded = true;
            vm.loading = false;
          });
      }
    }

    function getRecipients() {
      var params = getParams(),
        dateCellTemplate =
          '<div class="ui-grid-cell-contents">' +
          '{{COL_FIELD | utcToLocal :grid.appScope.timeZone :"M/D/YY, h:mma"}}' +
          '<span ng-if="row.entity.deliveryError || row.entity.cancelled">&emsp;<span class="delivery-error text-nowrap" uib-tooltip="{{::row.entity.deliveryError || \'Cancelled prior to delivery\'}}" tooltip-placement="left"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not delivered</span></span>' +
          "</div>",
        dateColumn = params.hasReply
          ? {
              name: "Replied",
              field: "replyDate",
              cellTemplate: dateCellTemplate,
            }
          : { name: "Sent", field: "sentDate", cellTemplate: dateCellTemplate };

      clearRecipientSelection();

      vm.recipientsLoaded = false;
      vm.searched = !!params.queryText;

      vm.gridOptions.columnDefs = [
        {
          name: "",
          field: "selected",
          headerCellTemplate:
            '<div class="ui-grid-cell-checkbox"><md-checkbox ng-model="grid.appScope.selectAllRecipients" ng-change="grid.appScope.toggleAllRecipients()" aria-label="Toggle All Recipients" uib-tooltip="{{grid.appScope.selectAllDisabled() ? grid.appScope.selectAllDisabledMessage : \'\'}}" tooltip-popup-delay="400" tooltip-placement="right" ng-disabled="grid.appScope.selectAllDisabled()"></md-checkbox></div>',
          cellTemplate:
            '<div class="ui-grid-cell-checkbox"><md-checkbox ng-model="row.entity.selected" ng-change="grid.appScope.selectRecipient(row.entity)" aria-label="Select Recipient"></md-checkbox></div>',
          enableSorting: false,
          width: 40,
        },
        {
          name: "Name",
          field: "fullName",
          cellTemplate:
            '<div class="ui-grid-cell-contents"><a href ng-click="grid.appScope.openConversation(row.entity)" ng-bind="COL_FIELD"></a></div>',
        },
        {
          name: "Number",
          field: "mobileNumber",
          cellTemplate:
            '<div class="ui-grid-cell-contents"><span ng-bind="COL_FIELD | phone"></span></div>',
          enableSorting: false,
        },
        dateColumn,
      ];

      vm.gridOptions.onRegisterApi = function (gridApi) {
        if (gridApi.pagination.getPage() > 1) gridApi.pagination.seek(1);
      };

      if (vm.batch.smartMessageId) insertSmartMessageColumns();

      if (inbox.type !== "high-speed") {
        return Restangular.one("outgoingmessagebatch", batchId)
          .getList("recipients", params, { "x-disable-loading": "true" })
          .then(function (res) {
            recipientData = res.plain();
            vm.recipientsLoaded = true;
            vm.gridOptions.data = recipientData;
          });
      }
    }

    function goBack() {
      var state = "";

      if ($state.current.data.isNewInbox) {
        if (inbox.type === "personal") state = "app.messages.main.sent";

        if (inbox.type === "group") state = "app.shared.main.sent";

        if (inbox.type === "high-speed") state = "app.highspeed.main.sent";
      } else {
        if (inbox.type === "personal") state = "app.personal.account.sent";

        if (inbox.type === "group") state = "app.groups.account.sent";
      }

      $state.go(state);
    }

    function insertSmartMessageColumns() {
      vm.replyCategoryOptions = ["Yes", "No", "Other"];

      var replyCategoryTemplate =
        '<div class="ui-grid-cell-contents">' +
        '<md-select ng-if="row.entity.replyCategory"  md-width="1" ng-model="row.entity.replyCategory" ng-click="row.entity.previousReplyCategory = row.entity.replyCategory" ng-change="grid.appScope.updateReplyCategory(row.entity, row.entity.replyCategory)" class="md-no-underline" aria-label="Change Reply Category">' +
        '<md-option ng-repeat="option in grid.appScope.replyCategoryOptions" ng-value="option">{{option}}</md-option>' +
        "</md-select>" +
        "</div>";

      vm.gridOptions.columnDefs.splice(2, 0, {
        name: "Response",
        field: "messageText",
        cellTemplate:
          '<div class="ui-grid-cell-contents"><em ng-bind="COL_FIELD"></em></div>',
      });
      vm.gridOptions.columnDefs.splice(3, 0, {
        name: "Category",
        field: "replyCategory",
        cellTemplate: replyCategoryTemplate,
      });
    }

    function openConversation(row) {
      if ($state.current.data.isNewInbox) {
        if (inbox.type === "personal")
          $state.go("app.messages.main.inbox.conversation", {
            id: row.studentId,
          });

        if (inbox.type === "group")
          $state.go("app.shared.main.inbox.conversation", {
            accountId: inbox.groupId,
            id: row.studentId,
          });

        if (inbox.type === "high-speed")
          $state.go("app.highspeed.main.inbox.conversation", {
            accountId: inbox.highSpeedId,
            id: row.contactId,
          });
      } else {
        if (inbox.type === "personal")
          $state.go("app.personal.conversations", {
            accountId: inbox.userId,
            id: row.studentId,
          });

        if (inbox.type === "group")
          $state.go("app.groups.conversations", {
            accountId: inbox.groupId,
            id: row.studentId,
          });
      }
    }

    function optOut() {
      $uibModal
        .open({
          templateUrl: "core/optOutConfirmModal.html",
          controller: "OptOutConfirmModalController as vm",
          resolve: {
            isBulk: true,
          },
        })
        .result.then(function () {
          getAccountRestangularBase()
            .post("optout", {
              studentIds: vm.selectedRecipients,
              optedOut: true,
              departmentId: profile.currentDepartment.id,
            })
            .then(function () {
              mixpanel.track("Bulk Opt-out from Sent Detail", {
                "Student Count": vm.selectedRecipients.length,
              });
            });
          clearRecipientSelection();
        });
    }

    function reply() {
      var contacts = _.map(vm.selectedRecipients, function (id) {
        var recipient = _.find(vm.gridOptions.data, { studentId: id });

        if (!recipient) return;

        return {
          id: recipient.studentId,
          tagText: recipient.fullName,
        };
      });

      $rootScope.openComposeModal({ contacts: contacts, inbox: inbox });

      mixpanel.track("Clicked Reply from Sent Detail", {
        "Recipient Count": vm.selectedRecipients.length,
      });

      clearRecipientSelection();
    }

    function resetSearch() {
      vm.filter.searchText = "";
      getRecipients();
    }

    function selectAllDisabled() {
      return vm.gridOptions.data.length > bulkActionMaxCount;
    }

    function selectRecipient(item) {
      if (item.selected) {
        vm.selectedRecipients.push(item.studentId);
      } else {
        _.pull(vm.selectedRecipients, item.studentId);
      }
    }

    function clearRecipientSelection() {
      vm.selectAllRecipients = false;
      toggleAllRecipients();
    }

    function toggleAllRecipients() {
      vm.selectedRecipients = [];

      _.forEach(vm.gridOptions.data, function (item) {
        if (vm.selectAllRecipients) {
          item.selected = true;
          vm.selectedRecipients.push(item.studentId);
        } else {
          item.selected = false;
        }
      });
    }

    function updateReplyCategory(row, newValue) {
      $uibModal
        .open({
          controller: "UpdateReplyCategoryModalController as vm",
          templateUrl: "accounts/update-reply-category-modal.html",
          resolve: {
            autoReplies: function () {
              return {
                No: vm.batch.negativeReply,
                Yes: vm.batch.positiveReply,
              };
            },
            batchId: function () {
              return batchId;
            },
            contacts: function () {
              return row ? [row.studentId] : vm.selectedRecipients;
            },
            groupAccountId: function () {
              return inbox.groupId;
            },
            newValue: function () {
              return newValue;
            },
            oldValue: function () {
              return row ? row.previousReplyCategory : false;
            },
            replyCategoryOptions: function () {
              return vm.replyCategoryOptions;
            },
            inbox: function () {
              return inbox;
            },
          },
        })
        .result.then(
          function () {
            if (!row) getRecipients();
          },
          function () {
            if (row) row.replyCategory = row.previousReplyCategory;
          }
        );
    }
  }
})();
