angular
  .module("mongoose")
  .controller(
    "EditHighSpeedController",
    function ($uibModalInstance, $q, $state, Restangular, growl, accountColors, highSpeedId) {
      var vm = this;

      vm.accessInvalid = accessInvalid;
      vm.close = $uibModalInstance.dismiss;
      vm.highSpeed = {};
      vm.selectedStaff = selectedStaff;
      vm.toggleAllStaff = toggleAllStaff;
      vm.update = update;

      activate();

      function activate() {
        var requests = [
          Restangular.one("admin/highspeedinboxes", highSpeedId).get(),
          Restangular.one("admin/highspeedInboxes", highSpeedId).getList("staff"),
        ];

        $q.all(requests).then(function (results) {
          vm.highSpeed = results[0];
          vm.highSpeed.staff = results[1];

          Restangular.all("staff")
            .getList({ departmentId: vm.highSpeed.departmentId })
            .then(function (res) {
              vm.staff = res;
              _.forEach(vm.staff, function (staff) {
                staff.selected = _.some(vm.highSpeed.staff, function (highSpeedStaff) {
                  return highSpeedStaff.id == staff.id;
                });
              });
              vm.staff = _.sortByOrder(vm.staff, [(staff) => staff.firstName.toLowerCase()], ["asc"]);
            });
        });

        vm.hasValidationErrors = {};
        vm.colorOptions = accountColors;
      }

      function accessInvalid() {
        if (vm.loading) return true;
        return vm.selectedStaff().length === 0;
      }

      function isValid() {
        vm.hasValidationErrors = {};
        vm.hasValidationErrors.name = !vm.highSpeed.name;
        vm.hasValidationErrors.color = !vm.highSpeed.color;

        return !_.includes(vm.hasValidationErrors, true);
      }

      function selectedStaff() {
        return _.pluck(_.filter(vm.staff, "selected", true), "id");
      }

      function toggleAllStaff() {
        _.forEach(vm.staff, function (staff) {
          staff.selected = vm.selectAll;
        });
      }

      function update() {
        if (isValid()) {
          vm.busy = true;

          vm.highSpeed.selectedStaff = vm.selectedStaff();

          vm.highSpeed.save().then(
            function () {
              growl.success("The toll-free inbox has been updated!");
              $uibModalInstance.close();
              $state.reload();
            },
            function (errResponse) {
              vm.serverValidationError = errResponse.data;
              vm.busy = false;
            }
          );
        }
      }
    }
  );
