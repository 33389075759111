angular.module('mongoose').controller('SalesforceConfigurationController', function (salesforceService, growl, Restangular) {

    var vm = this;

    function load(){
        salesforceService.getConfiguration().then(function (config){
            if(config){
                vm.configuration = config;
            } else {
                vm.configuration = {};
            }
        });
    }

    vm.save = function (){
        salesforceService.saveConfiguration(vm.configuration).then(function() {
            growl.success("Salesforce integration configuration updated!");
            load();
        });
    };

    vm.verify = function () {

        Restangular.all('salesforce/verify').post({configuration: vm.configuration}).then(function () {
            vm.showVerifySuccess = true;
            vm.showVerifyFailure = false;
        }, function () {
            vm.showVerifySuccess = false;
            vm.showVerifyFailure = true;
        });
    };

    vm.cancel = load;

    load();
});