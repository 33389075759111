angular.module('mongoose').controller('CopyStaticSegmentModalController', function ($scope, $uibModalInstance, Restangular, template) {

    $scope.close = $uibModalInstance.dismiss;

    $scope.segmentName = 'Copy of ' + template.name;

    $scope.copy = function () {

        if(_.isUndefined($scope.segmentName) || _.isEmpty($scope.segmentName)) return;

        var params = {
            name: $scope.segmentName
        };

        Restangular.all('staticsegments').post(params).then(function (segment) {

            Restangular.one('staticsegments', segment.id).all('students').customPOST({sourceSegmentId: template.id}, 'copy').then(function () {

                mixpanel.track('Copied Segment', {
                    "Segment Type": 'Manual'
                });

                $uibModalInstance.close(segment);
            });
        });
    };
});