
angular.module('mongoose').controller('RadiusErrorLogController', function ($scope, config, Restangular) {

    var initialize = function () {
        Restangular.all("radius/errors").getList().then(function(results) {
            $scope.errors = results.map(function(i) {
                i.errorDate = moment.tz(i.errorDate, config.timeZone).format('M/D/YY, h:mma z');
                return i;
            });
        });
    };
 
    initialize();
});