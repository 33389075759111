(function() {
'use strict';
    angular.module('mongoose').controller('EditSSOController', EditSSOController);

    function EditSSOController($uibModalInstance, growl, ssoConfig, ssoConfigs) {
        var vm = this;

        activate();

        vm.close = $uibModalInstance.dismiss;
        vm.update = update;
    
        function activate() {
            vm.ssoConfig = ssoConfig;
            vm.ssoConfigs = ssoConfigs;

            vm.hasValidationErrors = {};
        }

        function isValid() {
            vm.hasValidationErrors = {};
            vm.hasValidationErrors.name = !vm.ssoConfig.friendlyName;
            vm.hasValidationErrors.entityID = !vm.ssoConfig.entityId;
            vm.hasValidationErrors.signInUrl = !vm.ssoConfig.signInUrl;
            vm.hasValidationErrors.certificate = !vm.ssoConfig.certificate;

            return !_.includes(vm.hasValidationErrors, true)
        }

        function update() {
            if (isValid()) {
                vm.loading = true;
                vm.ssoConfig.save().then(function () {
                    growl.success("Your SSO Configuation has been updated!");
                    $uibModalInstance.close();
                },
                function(errResponse) {
                    vm.loading = false;
                    vm.serverValidationError = errResponse.data;
                });
            }
        }
}
})();
