 angular
    .module('mongoose')
    .directive('optOutField', optOutField);

function optOutField() {
    return {
        restrict: 'EA',
        controller: OptOutFieldController,
        scope: {
            student: '='
        },
        templateUrl: 'core/optOutField.html'
    };
}

function OptOutFieldController($scope, Restangular, securityService){

    var profile;

    $scope.update = update;

    activate();

    function activate(){
        securityService.getProfile().then(function(response){
            profile = response;
            $scope.studentIsInDepartment = studentIsInDepartment;
        });
    }

    function studentIsInDepartment(){
        return _.find($scope.student.departments, { id: profile.currentDepartment.id });
    }

    function update(department){
        if(department.autoOptOut || department.muted)
            return false;

        Restangular.one('students', $scope.student.id).post('optOut', {
            departmentId: department.id,
            optedOut: department.optedOut
        });
    }
}