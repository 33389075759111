angular
  .module("mongoose")
  .controller(
    "FieldsController",
    function ($scope, $uibModal, fieldService, config) {
      var vm = this;

      vm.addField = addField;
      vm.baseFields = fieldService.getBaseFields(config);
      vm.editable = true;
      vm.rename = rename;
      vm.update = update;
      vm.delete = deleteField;

      activate();

      function activate() {
        fieldService.get().then(function (fields) {
          vm.fields = fields;
        });
      }

      function addField() {
        var modalInstance = $uibModal.open({
          templateUrl: "admin/department/integration/fields/add-field.html",
          controller: "AddFieldController",
          controllerAs: "vm",
          ariaLabelledBy: "add",
        });

        modalInstance.result.then(
          function (response) {
            vm.fields.push(response);
          },
          function () {}
        );
      }

      function rename(field) {
        var modalInstance = $uibModal.open({
          templateUrl: "admin/department/integration/fields/rename-field.html",
          controller: "RenameFieldController",
          controllerAs: "vm",
          ariaLabelledBy: "rename",
          resolve: {
            field: field,
          },
        });

        modalInstance.result.then(
          function (response) {
            _.assign(field, {
              displayName: response.displayName,
            });
          },
          function () {}
        );
      }

      function deleteField(field) {
        var modalInstance = $uibModal.open({
          templateUrl: "admin/department/integration/fields/delete-field.html",
          controller: "DeleteFieldController",
          controllerAs: "vm",
          ariaLabelledBy: "delete-field",
          resolve: {
            field: field,
          },
        });

        modalInstance.result.then(
          function (response) {
            activate();
          },
          function () {}
        );
      }

      function update(field) {
        fieldService.update(field);
      }
    }
  );
