angular.module('mongoose').controller('StudentViewModalController', function ($rootScope, $scope, $state, studentService, studentId, $uibModalInstance, Restangular, profile) {

    var initialize = function () {
            studentService.get(studentId).then(function (student) {
                var currentDepartment = _.find(student.departments, function(department) {return department.id === profile.currentDepartment.id}),
                    staff = currentDepartment.staff;

                $scope.student = student;
                $scope.student.displayName = studentService.getDisplayName(student);
                $scope.student.staff = !_.isEmpty(staff) ? staff.firstName + " " + staff.lastName : "None";
                $scope.student.additionalFields = _.omit($scope.student.additionalFields, _.isEmpty);
            });
        };

    $rootScope.$on('reloadStudent', initialize);

    $scope.close = function() {
        $uibModalInstance.close();
    };

    initialize();
});