
angular.module('mongoose').controller('ConnectMessageConfigurationController', function ($scope, $timeout, $state, $q, growl, Restangular) {

    var vm = this;

    vm.cancel = cancel;
    vm.save = save;

    activate();

    function activate() {
        var requests = [
            Restangular.one("connect/messageConfiguration").get(),
            Restangular.all("connect/fields").getList()
        ];

         $q.all(requests).then(function (results) {
            vm.config = results[0] ? results[0].plain() : {};
            vm.fields = results[1];
        });
    }

    function cancel($event) {
        $event.preventDefault();
        activate();
    }

    function save() {
        var config = {
            messageConfiguration: angular.toJson(vm.config)
        };
        Restangular.all("connect/messageConfiguration").post(config).then(function() {
            growl.success("Message configuration updated!");
            activate();
        });
    }
});