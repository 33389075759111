angular.module("mongoose").component("outOfOfficeDialog", {
  templateUrl: "core/outOfOfficeDialog.html",
  controllerAs: "vm",
  bindings: {
    profile: "<",
  },
  controller: function OutOfOfficeController($rootScope, $scope, $timeout, $state, Restangular) {
    var vm = this,
      defaultConfig = {
        enabled: false,
      };

    vm.close = close;
    vm.isEnabled = false;
    vm.disable = disable;
    vm.loading = false;
    vm.profile.outOfOfficeConfig = vm.profile.outOfOfficeConfig ? vm.profile.outOfOfficeConfig : defaultConfig;
    vm.showNotification = showNotification;

    $timeout(activate, 1000);

    window.eventBus.addEventListener("close-ooo-dialog", closeEvent);
    window.eventBus.addEventListener("toggle-out-of-office", showNotificationEvent);

    function activate() {
      $scope.$watchCollection("vm.profile", setVisibility);
    }

    function close() {
      vm.isEnabled = false;
      mixpanel.track("Dismissed Out of Office Dialog");
      window.eventBus.removeEventListener("close-ooo-dialog");
    }

    function closeEvent() {
      vm.isEnabled = false;
      $scope.$apply();
      window.eventBus.removeEventListener("close-ooo-dialog");
    }

    function disable() {
      vm.loading = true;
      vm.profile.outOfOfficeConfig.enabled = false;

      Restangular.all("profile/outofoffice")
        .post(vm.profile.outOfOfficeConfig, {}, { "x-disable-loading": "true" })
        .then(
          function () {
            mixpanel.track("Turned Out of Office Off", {
              Via: "Dialog",
            });
            $rootScope.$broadcast("profileChanged");
            $rootScope.$broadcast("reloadOutOfOffice");
          },
          function (error) {
            growl.error(error && error.data ? error.data : "There was an issue updating your Out of Office settings.");
            vm.loading = false;
          }
        );
    }

    function setVisibility() {
      vm.loading = false;
      let personalInboxEnabled = vm.profile.outOfOfficeConfig ? vm.profile.outOfOfficeIsActive : false;
      let sharedInboxEnabled = _.some(vm.profile.groupAccounts, { outOfOfficeIsActive: true });
      vm.isEnabled = personalInboxEnabled || sharedInboxEnabled;
    }

    function showNotification() {
      return vm.isEnabled;
    }

    function showNotificationEvent(event) {
      if (event.detail.enabled) {
        setTimeout(() => {
          vm.isEnabled = true;
          $scope.$apply();
        }, 1000);
      }
    }
  },
});
