(function () {

    'use strict';

    var accountColors = [
        {hex: '#3c78d8', name: 'Blue'},
        {hex: '#cca42a', name: 'Gold'},
        {hex: '#a61c00', name: 'Red'},
        {hex: '#7f28a3', name: 'Purple'},
        {hex: '#cc197c', name: 'Pink'}
    ];

    angular.module('mongoose')
        .constant('accountColors', accountColors);
})();