angular.module('mongoose').filter('messageDate', function () {
    return function (messageDate, timeZone, isUtc) {
        if (!messageDate) { return ''; }
        
        if (!isUtc) isUtc = false;

        var tz = timeZone,
            today = moment.tz(tz),
            date = isUtc ? moment.tz(moment.utc(messageDate), tz) : moment.tz(messageDate, tz),
            pastWeek = moment(today).subtract(6, 'days');
        


        if (date.isSame(today, "day")) {
            messageDate = date.format('h:mm A');
        } else if(date.isAfter(pastWeek)) {
            messageDate = date.format('dddd, h:mm A');
        } else {
            messageDate = date.format('ddd, M/D/YY, h:mm A');
        }

        return messageDate;
    };
});