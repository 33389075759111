
angular.module('mongoose').controller('ConnectConfigurationController', function ($rootScope, $scope, $state, growl, Restangular) {

    var initialize = function () {
        $scope.configuration = {
            apiUsername: '',
            apiPassword: ''
        };

        Restangular.one("connect/settings").get().then(function (configuration) {
            if (configuration) {
                $scope.configuration = configuration;
            }
        });

    };

    $scope.save = function () {
        Restangular.all("connect/settings").post($scope.configuration).then(function() {
            growl.success("Connect integration configuration updated!");
            $rootScope.$broadcast('validCredentials');
            initialize();
        });
    };

     $scope.verifyCredentials = function () {
         $scope.showVerifySuccess = false;
         $scope.showVerifyFailure = false;
    
         if ($scope.configuration.webServiceUrl
             && $scope.configuration.webServiceClientName
             && $scope.configuration.webServiceAccessToken) {
    
             var credentials = {
                 webServiceUrl: $scope.configuration.webServiceUrl,
                 webServiceClientName: $scope.configuration.webServiceClientName,
                 webServiceAccessToken: $scope.configuration.webServiceAccessToken
             };
             Restangular.all("connect/verifyCredentials").post(credentials).then(function (response) {
                 $scope.showVerifySuccess = response;
                 $scope.showVerifyFailure = !response;
             });
         }
    };

     $scope.cancel = function () {
        initialize();
    };

    initialize();
});