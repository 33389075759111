angular.module('mongoose').controller('ImportStudentsController', function ($rootScope, $scope, $state, $uibModal, $timeout, Restangular, fieldService, profile, growl, FileSaver, Blob) {

    $scope.allFields = allFields;
    $scope.cancel = cancel;
    $scope.requiredFields = requiredFields;
    $scope.showCancelButton = $state.current.data && $state.current.data.cancel;
    $scope.submit = submit;


    activate();

    function activate() {
        $scope.isMigrating = $rootScope.config.customFields.inProgress;
        $scope.customFieldsEnabled = $rootScope.config.customFields.enabled;
        $scope.recipients = '';
        $scope.validationMessage = '';
    }

    function cancel(){
        $state.go($state.current.data.cancel.state);
    }

    function requiredFields() {
        Restangular.all("studentImports/requiredFields").getList().then(function(usage) {
            var data = new Blob([usage], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(data, 'required-fields.csv');
        });
    }

    function allFields() {
        Restangular.all("studentImports/allFields").getList().then(function(usage) {
            var data = new Blob([usage], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(data, 'all-fields.csv');
        });
    }

    function submit() {
        $scope.validationMessage = '';
        Restangular.all('studentImports').post({ recipients: $scope.recipients, allowMobileUpdate: $scope.allowMobileUpdate }).then(function () {
            var toState = $state.current.data && $state.current.data.redirectOnSubmit
                ? $state.current.data.redirectOnSubmit.state
                : 'app.admin.integration.import.history';
            growl.success("Your contact import is being processed and you will receive an email when it completes!");
            $scope.recipients = '';
            mixpanel.track("Ran Student Import", {
                "Import Method": "Copy/Paste",
                "AllowMobileUpdates": $scope.allowMobileUpdate
            });
            $state.go(toState);
        },
        function (response) {
            if (response.status === 400) {
                $scope.validationMessage = response.data;
            }
        });
    }

});