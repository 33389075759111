angular
  .module("mongoose")
  .controller("TagDetailController", function ($rootScope, $state) {
    let vm = this;

    vm.tagId = $state.params.tagId;
    vm.fromProfile = $state.params.fromProfile;

    vm.goBack = goBack;
    vm.viewTagContact = viewTagContact;

    function viewTagContact(data) {
      $state.go("app.students.contactProfile", { contactId: data.contactId });
    }

    function goBack() {
      let state = "app.tags.list";
      let params = $rootScope.previousState.name
        ? Object.assign($rootScope.previousParams, { fromDetail: true })
        : { fromDetail: true };
      $state.go(state, params);
    }
  });
