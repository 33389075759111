
angular.module('mongoose').controller('FtpConnectionSettingsController', function ($rootScope, $state, $uibModalInstance, Restangular, growl, ftpService) {

    var vm = this;

    vm.config = {};
    vm.dismiss = $uibModalInstance.dismiss;
    vm.onProtocolChange = onProtocolChange;
    vm.save = saveCredentials;


    activate();

    function activate() {
        vm.loading = true;
        ftpService.config().then(function(response){
            vm.config = response;
            vm.loading = false;
        });
    }

    function onProtocolChange(){
        vm.config.port = vm.config.protocol === 'FTPS' ? '990' : '22';
    }

    function saveCredentials(){
        vm.saving = true;
        var config = _.cloneDeep(vm.config);
        delete config.deliveryFailureConfiguration;
        delete config.importConfiguration;
        delete config.messageActivityExportConfiguration;
        delete config.optOutExportConfiguration;
        delete config.status;

        Restangular.one('ftp/configuration').post('', config).then(function(){
            growl.success("Connection Settings Updated");
            $uibModalInstance.close();
        },function(response){
            vm.saving = false;
            growl.error(response.data);
        });
    }

});