angular.module("mongoose").component("messageMetrics", {
  templateUrl: "dashboard/message-metrics.html",
  controllerAs: "vm",
  bindings: {
    profile: "<",
  },
  controller: function MessagesController(Restangular) {
    var vm = this;

    vm.getFilters = getFilters;
    vm.messageChartOptions = messageChartOptions();
    vm.replyChartOptions = replyChartOptions();
    vm.replyChartOverride = replyChartOverride();

    function getFilters(filters) {
      vm.timeSelect = filters.time;
      vm.userSelect = filters.groupId;
      vm.groupName = filters.groupName;
      update();

      // $interval(function(){
      //     update();
      // }, 5000);
    }

    function getMessageActivity(userType, id, time) {
      getMessageMetrics(userType, id, time);
      getReplyChart(userType, id, time);
    }

    function getMessageMetrics(userType, id, time) {
      let _timeInterval = "Last24Hours",
        _today = new Date(),
        _start = _today,
        _end = _today;
      if (time === "month") {
        _timeInterval = "UnspecifiedTime";
        _start = new Date(
          _today.getFullYear(),
          _today.getMonth(),
          _today.getDate() - 30
        );
      } else if (time === "week") {
        _timeInterval = "Last7Days";
        _start = new Date(
          _today.getFullYear(),
          _today.getMonth(),
          _today.getDate() - 6
        );
      } else if (time === "day") {
        _timeInterval = "Last24Hours";
        _start = _today;
        _end = _today;
      }
      let params;
      params = {
        timeInterval: _timeInterval,
        take: 0,
        skip: 0,
        start: _start.toISOString().slice(0, -14),
        end: _end.toISOString().slice(0, -14),
        departments:
          userType === "departments" ? [vm.profile.currentDepartment.id] : [],
        sharedinboxes: userType === "groupAccounts" ? [id] : [],
        staffId: userType === "staff" ? id : null,
      };
      let messages = Restangular.all("reporting/messages").post(params);
      messages.then(function (msg) {
        vm.sentMessages = _.result(
          _.find(msg.metrics, "name", "Sent"),
          "value"
        );
        vm.receivedMessages = _.result(
          _.find(msg.metrics, "name", "Received"),
          "value"
        );
        vm.replyPercentage =
          _.result(_.find(msg.metrics, "name", "RepliedPercentage"), "value") *
          100;

        vm.sentLegendColor = "#396FB0";
        vm.receivedLegendColor = "#737373";
        vm.messageChartLegend = ["Sent", "Received"];
        vm.messageChartColors = [vm.sentLegendColor, vm.receivedLegendColor];
        vm.messageChartData = [];
        vm.messageDates = Object.keys(msg.series["Incoming"]);
        vm.outgoing = _.get(msg.series, "Outgoing");
        vm.incoming = _.get(msg.series, "Incoming");

        vm.messageChartData.push(_.map(vm.outgoing));
        vm.messageChartData.push(_.map(vm.incoming));

        getMessageChartLabels(vm.messageDates, time);
      });
    }

    function getMessageChartLabels(chartDates, time) {
      let textDates = [];
      chartDates.forEach((date) => {
        let newDate = new Date(date);
        if (time == "day") {
          textDates.push(
            newDate.toLocaleString("en-us", {
              hour: "numeric",
              hour12: true,
            })
          );
        } else {
          let textString =
            newDate.toLocaleString("default", {
              month: "short",
            }) +
            " " +
            newDate.getDate();
          textDates.push(textString);
        }
      });
      return (vm.messageChartLabels = textDates);
    }

    function getReplyChart(userType, id, time) {
      vm.replyBarColor = "#396fb0";
      vm.replyChartColors = [
        vm.replyBarColor,
        vm.replyBarColor,
        vm.replyBarColor,
        vm.replyBarColor,
        vm.replyBarColor,
        vm.replyBarColor,
        vm.replyBarColor,
      ];

      Restangular.one(userType, id)
        .all("dashboard")
        .get("replyTimes", { time: time })
        .then(function (messages) {
          vm.replyChartLabels = _.map(messages, "timeRange");
          vm.replyChartData = _.map(messages, "replies");
        });
    }

    function messageChartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {},
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
            },
          ],
        },
        tooltips: {
          position: "nearest",
        },
      };
    }

    function replyChartOptions() {
      return {
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
            },
          ],
          yAxes: [
            {
              barThickness: 15,
              gridLines: {
                display: false,
              },
              ticks: {},
            },
          ],
        },
        tooltips: {
          position: "nearest",
        },
        layout: {
          padding: {
            right: 20,
          },
        },
      };
    }

    function replyChartOverride() {
      return {
        label: "Replies",
        borderWidth: 0,
      };
    }

    function update() {
      vm.heading =
        "Message Activity" + (vm.groupName ? " - " + vm.groupName : "");
      if (vm.userSelect == "admin") {
        getMessageActivity(
          "departments",
          vm.profile.currentDepartment.id,
          vm.timeSelect
        );
        vm.altHeading = "Team Message Activity";
      } else if (vm.userSelect == "mine") {
        getMessageActivity("staff", vm.profile.id, vm.timeSelect);
        vm.altHeading = "Message Activity";
      } else {
        getMessageActivity("groupAccounts", vm.userSelect, vm.timeSelect);
        vm.altHeading =
          "Message Activity - " + vm.profile.groupAccounts[0].name;
      }
    }
  },
});
