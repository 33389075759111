angular
  .module("mongoose")
  .controller(
    "InboxDetailController",
    function (profile, $rootScope, $state, $stateParams, Restangular) {
      let vm = this;
      vm.orgId = profile.currentDepartment.organizationId;
      vm.inboxType = $stateParams.inbox;
      vm.inboxContext = {
        type: vm.inboxType === "shared" ? "Shared" : "HighSpeed",
        id: parseInt($stateParams.inboxId),
        teamId: parseInt($stateParams.teamId),
        name: "",
      };

      vm.goBack = goBack;

      function goBack() {
        let state = "app.reporting.main.analytics";
        let params = $rootScope.previousState.name
          ? Object.assign($rootScope.previousParams, { fromDetail: true })
          : { fromDetail: true };
        $state.go(state, params);
      }

      Restangular.all("reporting")
        .one("inbox")
        .customPOST(
          "",
          "",
          {
            inboxType: vm.inboxType,
            inboxId: $stateParams.inboxId,
          },
          {}
        )
        .then(function (res) {
          vm.inboxContext.name = res[0].name;
          vm.inboxContext.teamName = res[0].teamName;
        });
    }
  );
