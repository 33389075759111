angular.module('mongoose').factory('contractService', function($rootScope, $q, Restangular) {

    return {
        get: get
    };

    function get() {
        var requests = [
            Restangular.all("admin/users/count").customGET(),
            Restangular.all('admin/groupAccounts').getList(),
            Restangular.all("contract").customGET('')
        ];

        return $q.all(requests).then(function (results) {
            var userCount = results[0],
                groupCount = results[1].length,
                contract = results[2],
                contractUserCount = contract ? contract.users : 'unlimited',
                accountsCount = userCount + groupCount;

            return {
                accountsCount: accountsCount,
                groupCount: groupCount,
                accountLimit: contractUserCount,
                accountLimitMet: accountsCount >= contractUserCount,
            }
        });
    }

});