angular.module('mongoose').controller('FtpDashboardController', function ($scope, $interval, $state, ftpConfig, ftpService, config) {

    var vm = this,
        interval;

    vm.open = open;


    activate();

    function activate() {
        if(!ftpConfig.status.connectionIsConfigured)
            return;

        getHistory();

        interval = $interval(function() {
            getHistory(true);
        }, 5000);

        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    }

    function getHistory(disableLoading){
        //dateFormat: 'MMM D, YYYY h:mma'
        ftpService.getDashboardData({limit: 3, timezone: config.timeZone, disableLoading: disableLoading}).then(function(response){
            vm.exports = response.exports;
            vm.imports = response.imports;
        }, function(){
            $interval.cancel(interval);
        });
    }

    function open(state){
        $state.go(state);
    }

});