(function() {
'use strict';
angular.module('mongoose').controller('WebhooksController', WebhooksController);

function WebhooksController($q, Restangular, growl, profile, departmentScope) {
    var vm = this,
        departmentId = profile.currentDepartment.id;

    initialize();

    vm.saveNotifications = function() {
        var emails  = vm.notificationEmails.replace(/[\s\,]/g, "|").split('|');
        Restangular.all("notifications").post({notificationEmails: emails, isActive: true, event: 'WebhookFailure', department: departmentScope}).then(function(response) {
            growl.success("Notification settings updated!");
            initialize();
        });
    };

    vm.saveActivityWebhook = function() {
        if (validateWebhook(vm.activityWebhook)) {
            saveWebhook(vm.activityWebhook);
        }
    };

     vm.saveDeliveryFailureWebhook = function() {
        if (validateWebhook(vm.deliveryFailureWebhook)) {
            saveWebhook(vm.deliveryFailureWebhook);
        }
    };

    vm.saveOptOutWebhook = function() {
        if (validateWebhook(vm.optOutWebhook)) {
            saveWebhook(vm.optOutWebhook);
        }
    };

    function validateWebhook(webhook) {
        vm.hasValidationErrors = {activityWebhook: {}, optOutWebhook:{}, deliveryFailureWebhook:{}};
        if (webhook.webhook === 'Interaction') {
            vm.hasValidationErrors.activityWebhook.url = !vm.activityWebhook.url;
            vm.hasValidationErrors.activityWebhook.basicAuthHeader = !(vm.activityWebhook.basicAuthHeader && isBase64(vm.activityWebhook.basicAuthHeader));
        } else if (webhook.webhook === 'OptedOutChanged') {
            vm.hasValidationErrors.optOutWebhook.url = !vm.optOutWebhook.url;
            vm.hasValidationErrors.optOutWebhook.basicAuthHeader = !(vm.optOutWebhook.basicAuthHeader && isBase64(vm.optOutWebhook.basicAuthHeader));
        } else if (webhook.webhook === 'DeliveryFailure') {
            vm.hasValidationErrors.deliveryFailureWebhook.url = !vm.deliveryFailureWebhook.url;
            vm.hasValidationErrors.deliveryFailureWebhook.basicAuthHeader = !(vm.deliveryFailureWebhook.basicAuthHeader && isBase64(vm.deliveryFailureWebhook.basicAuthHeader));
        }
        return  !(_.includes(vm.hasValidationErrors.activityWebhook,true) || _.includes(vm.hasValidationErrors.optOutWebhook,true) || _.includes(vm.hasValidationErrors.deliveryFailureWebhook,true));
    }

    function isBase64(value) {
        try { 
            atob(value); 
            return true; 
        } catch (e) {
            return false; 
        }
    }

    function saveWebhook(webhook) {
        Restangular.all("webhooks").post(webhook).then(function() {
            growl.success("Webhook updated!");
            initialize();
        });
    }

    function initialize() {
        var requests = [
            Restangular.all('webhooks').getList({department: departmentScope}),
            Restangular.one("notifications").get({type: 'WebhookFailure', department: departmentScope})
        ];

        $q.all(requests).then(function(results){
            var webhooks = results[0];
            var notification = results[1];
            vm.optOutWebhook = _.find(webhooks, {'webhook' : 'OptOutChanged'}) || {webhook : 'OptOutChanged', departmentId: departmentScope ? departmentId : null};
            vm.activityWebhook = _.find(webhooks, {'webhook' : 'Interaction'}) || {webhook : 'Interaction', departmentId: departmentScope ? departmentId : null};
            vm.deliveryFailureWebhook = _.find(webhooks, {'webhook' : 'DeliveryFailure'}) || {webhook : 'DeliveryFailure', departmentId: departmentScope ? departmentId : null};
            
            if (notification) {
                vm.notificationEmails = notification.notificationEmails.map(function(email) { return email.emailAddress; }).join('\n');
            }
        });
    }
}
})();