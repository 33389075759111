angular
  .module("mongoose")
  .controller(
    "ViewCampaignModalController",
    function ($uibModalInstance, $state, campaignId, growl, profile, Restangular) {
      if (!campaignId) {
        $uibModalInstance.dismiss();
      }

      var vm = this;

      vm.close = $uibModalInstance.dismiss;

      vm.viewReport = function () {
        $state.go("app.reports.campaign", { id: campaignId });
        vm.close();
      };

      var load = function () {
        vm.loading = true;
        Restangular.one("campaigns", campaignId)
          .get()
          .then(function (campaign) {
            vm.loading = false;
            vm.campaign = campaign;

            if (campaign.createdBy !== profile.id) {
              Restangular.all("staff")
                .get(campaign.createdBy)
                .then(function (staff) {
                  vm.campaign.createdByName = _.get(staff, "displayName");
                });
            }
          });
      };
      load();
    }
  );
