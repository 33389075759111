angular.module('mongoose')
    .config(function ($httpProvider) {

        // Disable IE Caching
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

        $httpProvider.interceptors.push(function ($rootScope, $q, $window) {
            return {
                request: function (config) {
                    config.headers = config.headers || {};
                    if ($window.localStorage.token) {
                        config.headers.Authorization = 'Bearer ' + $window.localStorage.token;
                    }
                    return config;
                },
                response: function (response) {
                    return response || $q.when(response);
                },
                responseError: function (response) {
                    if (response.status === 401 || response.status === 403) {
                        delete $window.localStorage.token;
                        $window.location.href = '/';
                        return $q.reject(response);
                    }
                    if (response.status !== 400) {
                        return $q.reject(response);
                    }
                    return $q.reject(response);
                }
            };
        });

        $httpProvider.interceptors.push(function($q) {

            var numLoadings = 0;

            if(window.eventBus) {
                window.eventBus.addEventListener('incrementLoading', incrementLoading);
                window.eventBus.addEventListener('decrementLoading', decrementLoading);
            }

            function incrementLoading(){
                numLoadings++;
                $('#loader').show();
            }

            function decrementLoading(){
                --numLoadings;
                if (numLoadings === 0) {
                    $('#loader').hide();
                }
            }

            return {
                request: function(config) {
                    if (config.headers["x-disable-loading"])
                        return config;

                    incrementLoading();

                    return config || $q.when(config);
                },
                response: function(response) {
                    if (response.config.headers["x-disable-loading"])
                        return response;

                    decrementLoading();                    

                    return response || $q.when(response);

                },
                responseError: function(response) {
                    if (response.config.headers["x-disable-loading"])
                        return $q.reject(response);

                    decrementLoading();

                    return $q.reject(response);
                }
            };
        });
    });