angular
  .module("mongoose")
  .factory("automationService", function (growl, Restangular) {
    var inbox;

    return {
      init: init,
      getKeywordGroup: getKeywordGroup,
      getSettings: getSettings,
      saveKeywordGroup: saveKeywordGroup,
      saveSettings: saveSettings,
      toggleFeature: toggleFeature,
    };

    function init(inboxObject) {
      inbox = inboxObject;
    }

    function getKeywordGroup(id) {
      return Restangular.all("keywords")
        .get(id)
        .then(function (res) {
          if (!res) return;

          var keywordGroup = res.plain();
          var keywords = _.map(keywordGroup.keywords, function (keyword) {
            return keyword.phrase;
          });

          return keywords.join(", ");
        })
        .catch(function (err) {
          growl.error("There was an error loading the keywords.");
        });
    }

    function getSettings() {
      var requests = {
        personal: function () {
          return Restangular.one("automation").get();
        },
        group: function () {
          return Restangular.all("automation/shared").get(inbox.groupId);
        },
        "high-speed": function () {
          return Restangular.all("automation/highspeed").get(inbox.highSpeedId);
        },
      };

      return requests[inbox.type]()
        .then(function (res) {
          if (!res) return null;

          return res.plain();
        })
        .catch(function (err) {
          growl.error("There was an error loading the settings.");
        });
    }

    function saveKeywordGroup(data) {
      var keywordGroup = {
        name: inbox.name + "'s Inbox Keywords",
        keywords: _.map(data.keywords, function (keyword) {
          return { phrase: keyword };
        }),
      };
      var createOrUpdate = function () {
        if (!data.id) {
          return Restangular.all("keywords").post(keywordGroup);
        } else {
          return Restangular.all("keywords").customPUT(keywordGroup, data.id);
        }
      };

      return createOrUpdate()
        .then(function (res) {
          if (!res) return null;

          var keywords = _.map(res.keywords, function (keyword) {
            return keyword.phrase;
          });
          var keywordGroup = {
            id: res.id,
            keywords: keywords.join(", "),
          };

          return keywordGroup;
        })
        .catch(function (err) {
          growl.error("There was an error saving the keywords.");
        });
    }

    function saveSettings(data) {
      var requests = {
        personal: function () {
          return Restangular.one("automation").post("", data);
        },
        group: function () {
          return Restangular.one("automation/shared", inbox.groupId).post(
            null,
            data
          );
        },
        "high-speed": function () {
          return Restangular.one(
            "automation/highspeed",
            inbox.highSpeedId
          ).post(null, data);
        },
      };

      return requests[inbox.type]()
        .then(function (res) {
          if (!res) return null;

          return res.plain();
        })
        .catch(function (err) {
          growl.error("There was an error saving the settings.");
        });
    }

    function toggleFeature(disableFeature) {
      var path = disableFeature ? "disable" : "enable";
      var requests = {
        personal: function () {
          return Restangular.one("automation").customPUT(null, path);
        },
        group: function () {
          return Restangular.one("automation/shared", inbox.groupId).customPUT(
            null,
            path
          );
        },
        "high-speed": function () {
          return Restangular.one(
            "automation/highspeed",
            inbox.highSpeedId
          ).customPUT(null, path);
        },
      };

      return requests[inbox.type]()
        .then(function (res) {
          if (!res) return null;

          return res.plain();
        })
        .catch(function (err) {
          growl.error("There was an error enabling/disabling automation.");
        });
    }
  });
