angular
  .module("mongoose")
  .controller(
    "NewStaticSegmentController",
    function (Restangular, $state, $uibModal, growl) {
      var vm = this;

      vm.importTypes = ["MobileNumber", "StudentIdentifier"];

      vm.importType = vm.importTypes[0];

      vm.create = function () {
        var segmentParams = {
          name: vm.name,
        };

        Restangular.all("staticsegments")
          .post(segmentParams)
          .then(function (segment) {
            var importParams = {
              students: vm.recipients,
              staticImportType: vm.importType,
            };

            Restangular.one("staticsegments", segment.id)
              .all("students")
              .all("manualimport")
              .post(importParams)
              .then(
                function (validationResults) {
                  mixpanel.track("Created Segment", {
                    "Segment Type": "Manual",
                  });

                  Appcues.track("Created Segment", {
                    "Segment Type": "Manual",
                  });

                  if (validationResults && validationResults.length === 0) {
                    growl.success("Your segment was created successfully!");
                    $state.go("app.segments.list");
                  } else {
                    var modalInstance = $uibModal.open({
                      controller: "ConfirmSegmentModalController",
                      controllerAs: "vm",
                      templateUrl: "segments/static/confirm-segment-modal.html",
                      ariaLabelledBy: "confirm-modal",
                      resolve: {
                        validationResults: function () {
                          return validationResults;
                        },
                        action: function () {
                          return "created";
                        },
                      },
                    });

                    modalInstance.result.then(
                      function () {
                        $state.go("app.segments.list");
                      },
                      function () {
                        $state.go("app.segments.list");
                      }
                    );
                  }
                },
                function (response) {
                  var res = response;
                  Restangular.one("staticsegments", segment.id)
                    .remove()
                    .then(function () {
                      vm.validationMessage = res.data;
                    });
                }
              );
          });
      };
    }
  );
