angular
  .module("mongoose")
  .controller(
    "ExportModalController",
    function ($uibModalInstance, Restangular, reportType, growl, profile) {
      var vm = this,
        isOwner = profile.isOwner,
        reportNames = {
          activity: "Message Activity",
          optout: "Opt-out Changes",
          undelivered: "Undelivered Messages",
          students: "Contact Data",
          highspeed: "High Speed Message Activity",
          webchat: "Chat Leads",
        };

      vm.profile = profile;
      vm.close = $uibModalInstance.dismiss;
      vm.endDate = moment().format();
      vm.export = exportReport;
      vm.reportName = reportNames[reportType];
      vm.reportType = reportType;
      vm.selectedDepartment = null;
      vm.startDate = moment().subtract(7, "days").format();
      vm.selectedBotId = parseInt(localStorage.getItem("selectedBotId")) || 0;
      activate();

      function activate() {
        if (isOwner) {
          Restangular.all("departments")
            .getList()
            .then(function (response) {
              vm.departments = _.sortBy(response.plain(), "name");
              if (reportType !== "activity") {
                vm.selectedDepartment = _.find(vm.departments, {
                  id: profile.currentDepartment.id,
                });
              }
            });
        } else {
          vm.currentDepartment = profile.currentDepartment;
        }
      }

      function exportReport() {
        vm.loading = true;

        var promise,
          params = {
            startDate: moment(vm.startDate).format("MM/DD/YYYY"),
            endDate: moment(vm.endDate).format("MM/DD/YYYY"),
          };

        if (isOwner) {
          if (vm.selectedDepartment) {
            params.departmentId = vm.selectedDepartment.id;
          } else {
            params.scope = "org";
          }

          if (vm.reportType === "activity") {
            promise = Restangular.all("ownerexports/activity").post(params);
          } else if (vm.reportType === "optout") {
            promise = Restangular.all("ownerexports/optouts").post(params);
          } else if (vm.reportType === "undelivered") {
            promise = Restangular.all("ownerexports/undelivered").post(params);
          } else if (vm.reportType === "students") {
            promise = Restangular.all("ownerexports/students").post(params);
          } else if (vm.reportType === "highspeed") {
            promise = Restangular.all("ownerexports/highspeed").post(params);
          } else if (vm.reportType === "webchat") {
            promise = Restangular.all("ownerexports/webchat").post(params);
          }
        } else {
          if (vm.reportType === "activity") {
            promise = Restangular.all("adminexports/activity").post(params);
          } else if (vm.reportType === "optout") {
            promise = Restangular.all("adminexports/optouts").post(params);
          } else if (vm.reportType === "undelivered") {
            promise = Restangular.all("adminexports/undelivered").post(params);
          } else if (vm.reportType === "students") {
            promise = Restangular.all("adminexports/students").post(params);
          } else if (vm.reportType === "highspeed") {
            promise = Restangular.all("adminexports/highspeed").post(params);
          } else if (vm.reportType === "webchat") {
            promise = Restangular.all(`${vm.selectedBotId}/hitl/export`).post(
              params
            );
          }
        }

        promise.then(function () {
          growl.success(
            "You will receive an email when the " +
              vm.reportName +
              " export is complete."
          );
          switch (vm.reportType) {
            case "activity":
              mixpanel.track("Exported Sent Message Details Report");
              break;
            case "optout":
              mixpanel.track("Exported Opt Out Changes Report");
              break;
            case "undelivered":
              mixpanel.track("Exported Undelivered Messages Report");
              break;
            case "students":
              mixpanel.track("Exported Contact Data Report");
              break;
            case "highspeed":
              mixpanel.track("Exported High Speed Message Acitivity");
              break;
            case "webchat":
              mixpanel.track("Exported Chat Activity");
          }
          vm.close();
        });
      }
    }
  );
