angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.students", {
      url: "/students",
      abstract: true,
      template: "<ui-view/>",
    })
    .state("app.students.view", {
      url: "/view",
      params: { search: null },
      templateUrl: "students/view.html",
      controller: "StudentsViewController",
      pageTitle: "Contacts",
    })
    .state("app.students.new", {
      url: "/new",
      templateUrl: "students/new-student.html",
      controller: "NewStudentController",
      pageTitle: "New Contact",
    })
    .state("app.students.contactProfile", {
      url: "/:contactId?fromV2",
      params: { fromV2: null },
      templateUrl: "students/contact-profile.html",
      controller: "ContactProfileController",
      controllerAs: "vm",
      pageTitle: "Contact Profile",
    });
});
