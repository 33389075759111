angular.module('mongoose').controller('FieldsCompleteController', function ($rootScope, $scope, $state, fieldService) {

    var vm = this;

    $rootScope.currentSetupStep = 3;

    $scope.$on('$destroy', function(){
        $rootScope.currentSetupStep = null;
    });


    activate();

    function activate() {
        $rootScope.$broadcast('startPollConfig');
    }

});