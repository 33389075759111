angular.module('mongoose').factory('salesforceService', function($q, Restangular){

    function getConfiguration(){
        var deferred = $q.defer();
        Restangular.one('salesforce/configuration').get().then(function (config){
            deferred.resolve(config.plain());
        }, function (response){
            deferred.reject(response.data);
        });
        return deferred.promise;
    }

    function saveConfiguration(config){
        var deferred = $q.defer();
        Restangular.all("salesforce/configuration").post({configuration: config}).then(function() {
            deferred.resolve(config);
        }, function(response) {
            deferred.reject(response.data);
        });
        return deferred.promise;
    }

    return {
        getConfiguration: getConfiguration,
        saveConfiguration: saveConfiguration
    };
});