angular
  .module("mongoose")
  .controller(
    "AddStaticSegmentStudentsController",
    function (
      $scope,
      $uibModal,
      $uibModalInstance,
      Restangular,
      segmentId,
      $stateParams,
      growl
    ) {
      if (!segmentId) {
        segmentId = $stateParams.id;
      }

      $scope.importTypes = ["MobileNumber", "StudentIdentifier"];

      $scope.importType = $scope.importTypes[0];

      $scope.add = function () {
        var importParams = {
          students: $scope.recipients,
          staticImportType: $scope.importType,
        };

        $scope.loading = true;

        Restangular.one("staticsegments", segmentId)
          .all("students")
          .all("manualimport")
          .post(importParams)
          .then(function (validationResults) {
            if (validationResults && validationResults.length === 0) {
              growl.success("Your segment was updated successfully!");
            } else {
              $uibModal.open({
                controller: "ConfirmSegmentModalController",
                controllerAs: "vm",
                templateUrl: "segments/static/confirm-segment-modal.html",
                ariaLabelledBy: "confirm-modal",
                resolve: {
                  validationResults: function () {
                    return validationResults;
                  },
                  action: function () {
                    return "updated";
                  },
                },
              });
            }
            $scope.close();
          });
      };

      $scope.close = function () {
        $uibModalInstance.close();
      };

      $scope.dismiss = function () {
        $uibModalInstance.dismiss("cancel");
      };
    }
  );
