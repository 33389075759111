angular
  .module("mongoose")
  .controller("DashboardController", function ($scope, profile, Restangular, securityService, $timeout, $window) {
    var vm = this;

    vm.dismissNotification = dismissNotification;
    vm.showOrgDashboard = $scope.config.features["rollout-organizational-dashboard"] && $scope.config.aiEnabled;

    activate();

    function activate() {
      if (profile.unreadNotifications) getLatestNotification();
    }

    function dismissNotification() {
      vm.notification = null;

      mixpanel.track("Dismissed Dashboard Notification");

      $timeout(function () {
        Restangular.one("systemnotifications")
          .post(undefined, undefined, undefined, { "x-disable-loading": "true" })
          .then(function () {
            profile.unreadNotifications = false;
            $window.localStorage.profile = JSON.stringify(profile);
          });
      }, 1000);
    }

    function getLatestNotification() {
      Restangular.all("inproductnotification")
        .getList()
        .then(function (results) {
          var notifications = results.plain(),
            notification;

          if (securityService.isAuthorized("owner")) {
            notification = _.findLast(notifications, { ownerVisible: true });
          } else if (securityService.isAuthorized("admin")) {
            notification = _.findLast(notifications, { adminVisible: true });
          } else {
            notification = _.findLast(notifications, { userVisible: true });
          }

          if (_.isUndefined(notification)) return;

          notification.summaryHeader = twemoji.parse(notification.summaryHeader, {
            base: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/",
          });
          notification.summarySubHeader = twemoji.parse(notification.summarySubHeader, {
            base: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/",
          });
          notification.content = twemoji.parse(notification.content, {
            base: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/",
          });
          vm.notification = notification;
        });
    }
  });
