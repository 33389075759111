angular
  .module("mongoose")
  .controller(
    "TeamUsersController",
    function (
      $rootScope,
      $scope,
      caretakerService,
      Restangular,
      $q,
      config,
      profile,
      $uibModal,
      viewportService,
      $window
    ) {
      var vm = this,
        allUsers = [],
        defaultFilter = {
          role: "none",
          team: "none",
        },
        filter = !_.isNull($window.sessionStorage.getItem("users-filters"))
          ? JSON.parse($window.sessionStorage.getItem("users-filters"))
          : defaultFilter,
        requestParams = {
          excludedfromteams: [],
          filterby: filter.role,
          includedinteams: [profile.currentDepartment.id],
          loadvirtualnumbers: false,
          orderby: "name",
          skip: 0,
          take: 50,
          searchtext: "",
          sortorder: "asc",
        };

      vm.assignCaretaker = assignCaretaker;
      vm.canBeTarget = canBeTarget;
      vm.canDeleteUser = canDeleteUser;
      vm.caretaker = caretaker;
      vm.clearSearch = clearSearch;
      vm.config = config;
      vm.deleteUser = deleteUser;
      vm.editUser = editUser;
      vm.filter = filter;
      vm.gravatar = gravatar;
      vm.gridOptions = {
        appScopeProvider: vm,
        paginationPageSize: requestParams.take,
        rowHeight: 80,
        useExternalPagination: true,
        useExternalSorting: true,
        onRegisterApi: function (gridApi) {
          gridApi.pagination.on.paginationChanged($scope, onPaginate);
          gridApi.core.on.sortChanged($scope, onSort);
          goToPage1 = function () {
            gridApi.pagination.seek(1);
          };
          scrollToTop = function () {
            gridApi.core.scrollTo(vm.gridOptions.data[0]);
          };
        },
      };
      vm.load = load;
      vm.onFilter = onFilter;
      vm.profile = profile;
      vm.removeCaretaker = removeCaretaker;
      vm.setup = setup;
      vm.search = search;
      vm.searched = false;

      $rootScope.$on("teamActivate", activate);

      activate();

      function activate() {
        return load().then(setup);
      }

      function load() {
        var requests = [caretakerService.get(), Restangular.all("admin/users/query").post(requestParams)];

        $window.sessionStorage.setItem("users-filters", JSON.stringify(vm.filter));

        return $q.all(requests);
      }

      function setup(results) {
        vm.caretakerList = results[0].plain();
        var usersResponse = results[1];

        var users = _.map(usersResponse.results, function (user) {
          user.canBeTarget = canBeTarget(user);
          user.canDeleteUser = canDeleteUser(user);
          user.caretaker = caretaker(user.id);
          user.gravatarLetter = user.firstName.slice(0, 1);
          user.gravatar = gravatar(user.emailAddress);
          user.isAdmin = isAdmin(user.departments);
          user.isChatAdmin = isChatAdmin(user.departments);
          user.hasChat = hasChat(user.departments);
          user.hasTexting = hasTexting(user.departments);
          user.isPersonalInboxEnabled = isPersonalInboxEnabled(user.departments);
          user.teamList = teamList(user.departments);

          return user;
        });

        var defaultColumns = [
          {
            order: 0,
            name: "Name",
            field: "name",
            cellTemplate: "nameTemplate.html",
            sort: {
              direction: requestParams.sortorder,
            },
            minWidth: 280,
            width: viewportService.isSize("gt-sm") ? "35%" : "*",
          },
          {
            order: 1,
            name: "User Id",
            field: "identifier",
            cellTemplate:
              '<div class="ui-grid-cell-contents d-flex align-items-center"><span ng-bind="row.entity.campusUniqueId"></span></div>',
            visible: viewportService.isSize("gt-sm"),
          },
          {
            order: 4,
            name: "Teams",
            field: "userteams",
            cellTemplate: "teamsTemplate.html",
            enableSorting: false,
            visible: viewportService.isSize("gt-sm"),
          },
          {
            order: 5,
            name: "Last Sign In",
            field: "logindate",
            cellFilter: 'date : "M/dd/yy"',
            cellTemplate: "lastSignInTemplate.html",
            visible: viewportService.isSize("gt-sm"),
          },
          {
            order: 6,
            name: "Actions",
            cellClass: "table-actions text-center",
            headerCellTemplate: '<div class="sr-only">Actions</div>',
            cellTemplate: "menuTemplate.html",
            enableSorting: false,
            width: 60,
          },
        ];

        if (vm.profile.currentDepartment.teamHasChat) {
          defaultColumns.push({
            order: 2,
            name: vm.profile.currentDepartment.teamHasSms ? "Chat Role" : "Role",
            field: "chatrole",
            cellTemplate: "chatRoleTemplate.html",
            visible: viewportService.isSize("gt-sm"),
          });
        }

        if (vm.profile.currentDepartment.teamHasSms) {
          defaultColumns.push({
            order: 3,
            name: vm.profile.currentDepartment.teamHasChat ? "Text Role" : "Role",
            field: "textrole",
            cellTemplate: "textRoleTemplate.html",
            visible: viewportService.isSize("gt-sm"),
          });
        }

        vm.gridOptions.columnDefs = _.sortBy(defaultColumns, function (c) {
          return c.order;
        });
        vm.gridOptions.data = users;
        vm.gridOptions.totalItems = usersResponse.meta.total;
        vm.loaded = true;
      }

      function assignCaretaker(user) {
        var allUsersRequestParams = JSON.parse(JSON.stringify(requestParams));
        allUsersRequestParams.filterby = "none";
        allUsersRequestParams.searchtext = "";
        allUsersRequestParams.skip = 0;
        allUsersRequestParams.take = 10000;

        return Restangular.all("admin/users/query")
          .post(allUsersRequestParams)
          .then(function (response) {
            selectCaretaker(response, user);
          });
      }

      function selectCaretaker(response, user) {
        var hasCaretakerList = _.map(vm.caretakerList, function (user) {
          return user.targetStaffId;
        });

        var allUsers = _.map(response.results, function (user) {
          return user;
        });

        _.remove(allUsers, function (selectedUser) {
          return hasCaretakerList.includes(selectedUser.id) || selectedUser.id === user.id;
        });

        var modalInstance = $uibModal.open({
          templateUrl: "admin/department/users/assign-caretaker.html",
          controller: "AssignTeamCaretakerController",
          controllerAs: "vm",
          ariaLabelledBy: "assign-caretaker",
          resolve: {
            user: function () {
              return user;
            },
            users: function () {
              return allUsers;
            },
          },
        });
        modalInstance.result.then(function () {
          activate();
        });
      }

      function canBeTarget(user) {
        return (
          !config.isEnabled("chat-only-user") &&
          !isAdmin(user.departments) &&
          !user.isNumberlessUser &&
          !caretaker(user.id) &&
          !isCaretaker(user.id)
        );
      }

      function canDeleteUser(user) {
        return (config.isEnabled("delete-users") || config.isEnabled("chat-only-user")) && user.id !== profile.id;
      }

      function caretaker(user) {
        var caretaker = _.filter(vm.caretakerList, { targetStaffId: user });

        if (caretaker.length) {
          var caretakerStaffId = caretaker[0].caretakerStaffId;

          return Restangular.one("admin/users", caretakerStaffId).get().$object;
        }
      }

      function clearSearch() {
        vm.searchText = "";
        vm.searched = false;
      }

      function deleteUser(user) {
        $uibModal
          .open({
            templateUrl: "admin/department/users/admin-delete-user-modal.html",
            controller: "AdminDeleteUserModalController",
            controllerAs: "vm",
            ariaLabelledBy: "delete-user",
            resolve: {
              user: function () {
                return user;
              },
              profile: function () {
                return profile;
              },
              config: function () {
                return config;
              },
            },
          })
          .result.then(function () {
            activate();
          });
      }

      function editUser(userId) {
        $uibModal
          .open({
            templateUrl: "admin/department/users/edit-user.html",
            controller: "EditUserController",
            controllerAs: "vm",
            ariaLabelledBy: "edit-user",
            backdrop: "static",
            resolve: {
              userId: function () {
                return userId;
              },
              existingUsers: function () {
                return {
                  campusIdentifiers: _.map(allUsers, function (u) {
                    return u.campusUniqueId;
                  }),
                  emailAddresses: _.map(allUsers, function (u) {
                    return u.emailAddress;
                  }),
                };
              },
              profile: function () {
                return profile;
              },
              config: function () {
                return config;
              },
            },
          })
          .result.then(function () {
            activate();
          });
      }

      function gravatar(email) {
        return "https://www.gravatar.com/avatar/" + md5(email) + "?r=pg&d=blank";
      }

      function isAdmin(user) {
        return _.filter(user, {
          departmentId: profile.currentDepartment.id,
        })[0].isAdministrator;
      }

      function isChatAdmin(user) {
        return _.filter(user, {
          departmentId: profile.currentDepartment.id,
        })[0].isChatAdministrator;
      }

      function hasChat(departments) {
        return _.filter(departments, {
          departmentId: profile.currentDepartment.id,
        })[0].isChatAgent;
      }

      function hasTexting(departments) {
        return _.filter(departments, {
          departmentId: profile.currentDepartment.id,
        })[0].hasTexting;
      }

      function isCaretaker(user) {
        var isCaretaker = _.filter(vm.caretakerList, {
          caretakerStaffId: user,
        });
        return !!isCaretaker.length;
      }

      function isPersonalInboxEnabled(user) {
        return _.filter(user, {
          departmentId: profile.currentDepartment.id,
        })[0].personalInboxEnabled;
      }

      function removeCaretaker(user) {
        var caretakerId = user.caretaker.id,
          targetId = user.id;

        caretakerService.remove(caretakerId, targetId).then(function () {
          activate();
        });
      }

      function search() {
        requestParams.searchtext = vm.searchText;
        if (vm.searchText) {
          vm.searched = true;
        }
        goToPage1();
        activate();
      }

      function onFilter() {
        if (vm.filter.role !== "none") {
          mixpanel.track("Filtered Team Tab User Grid by Role", {
            "Filtered by": vm.filter.role,
          });
        }
        requestParams.filterby = vm.filter.role;
        goToPage1();
        activate();
      }

      function onPaginate(newPage) {
        requestParams.skip = newPage * requestParams.take - requestParams.take;
        scrollToTop();
        activate();
      }

      function onSort(grid, sortColumns) {
        requestParams.sortorder = sortColumns.length === 0 ? "asc" : sortColumns[0].sort.direction;
        requestParams.orderby = sortColumns.length === 0 ? "name" : sortColumns[0].field;

        activate();
      }

      function teamList(team) {
        var teams = _.map(team, "departmentName").sort();
        var teamsMax = 50;
        var teamsArr = [];

        for (var i = 0; i < teams.length; i++) {
          teamsArr.push(teams[i]);
        }

        return teamsArr.join(", ");
      }
    }
  );
