(function () {
    'use strict';
    angular.module('mongoose').controller('DeleteSmartMessageModalController', DeleteSmartMessageModalController);

    function DeleteSmartMessageModalController($uibModalInstance, Restangular, growl, smartMessage) {
        var vm = this;
        vm.smartMessage = smartMessage;

        vm.dismiss = function () {
            $uibModalInstance.dismiss();
        };

        vm.delete = function () {
            Restangular.one('smartmessages', vm.smartMessage.id).remove().then(function () {
                growl.success("Your Smart Message has been deleted!");
                mixpanel.track('Deleted Smart Message', {
                    'Smart Message Name': smartMessage.name
                });
                $uibModalInstance.close();
            });
        };
    }
})();