angular
  .module("mongoose")
  .controller(
    "ComposeMessageModalController",
    function (
      $rootScope,
      $scope,
      $q,
      $uibModal,
      $uibModalInstance,
      $timeout,
      config,
      profile,
      studentService,
      growl,
      Restangular,
      segmentsService,
      securityService,
      $window,
      utilitiesService,
      presetData
    ) {
      var account = presetData.inbox;
      var campaign = presetData.campaign;
      var scheduledMessageId = presetData.scheduledMessageId;
      var messageText = presetData.messageText;
      var restrictToAssignment = presetData.restrictToAssignment;
      var schedule = presetData.schedule;
      var segments = presetData.segments;
      var sendAsStaff = presetData.sendAsStaff;
      var students = presetData.contacts;
      var smartMessage = presetData.smartMessage;
      var isScheduledMessage = presetData.isScheduledMessage;
      var modalTitle = presetData.modalTitle;
      var isFollowUpSMS = presetData.isFollowUpSMS;
      var SMSfromChat = presetData.SMSfromChat;
      const slateQuery = presetData.slateQuery;
      const reNxtList = presetData.reNxtList;

      $scope.addStudent = addStudent;
      $scope.addCampaign = addCampaign;
      $scope.backToCompose = backToCompose;
      $scope.backToSender = backToSender;
      $scope.backToReview = backToReview;
      $scope.close = close;
      $scope.getMediaType = utilitiesService.getMediaType;
      $scope.goToAddStudent = goToAddStudent;
      $scope.goToSchedule = goToSchedule;
      $scope.hasContent = hasContent;
      $scope.inboxDisabled = inboxDisabled;
      $scope.isLegacyMediaPickerEnabled = isLegacyMediaPickerEnabled;
      $scope.isMediaPickerEnabled = isMediaPickerEnabled;
      $scope.isLegacyMediaPicker = isLegacyMediaPicker;
      $scope.isValidPhone = isValidPhone;
      $scope.removeSmartMessage = removeSmartMessage;
      $scope.restrictToAssignmentChanged = restrictToAssignmentChanged;
      $scope.selectedSegmentsUpdated = selectedSegmentsUpdated;
      $scope.selectedTargetsUpdated = selectedTargetsUpdated;
      $scope.selectedFiltersUpdated = selectedFiltersUpdated;
      $scope.selectedSlateQueriesUpdated = selectedSlateQueriesUpdated;
      $scope.selectedReNxtListsUpdated = selectedReNxtListsUpdated;
      $scope.selectedCampaignUpdated = selectedCampaignUpdated;
      $scope.selectRecipientType = selectRecipientType;
      $scope.scheduleDateTimeUpdate = scheduleDateTimeUpdate;
      $scope.scheduleMessage = scheduleMessage;
      $scope.searchStudents = searchStudents;
      $scope.searchSegments = searchSegments;
      $scope.searchTargets = searchTargets;
      $scope.searchFilters = searchFilters;
      $scope.searchCampaigns = searchCampaigns;
      $scope.searchSlateQueries = searchSlateQueries;
      $scope.searchReNxtLists = searchReNxtLists;
      $scope.selectSender = selectSender;
      $scope.send = send;
      $scope.showMediaPicker = showMediaPicker;
      $scope.showSmartMessages = showSmartMessages;
      $scope.validateStudent = validateStudent;
      $scope.review = review;
      $scope.compose = compose;
      $scope.isScheduledMessage = false;
      $scope.isFollowUpSMS = false;
      $scope.SMSfromChat = false;
      $scope.modalTitle = getModalTitle();
      $scope.scheduledMessageId = scheduledMessageId;
      $scope.templatePicker = getTemplates;
      $scope.selectedTemplateId = null;
      $scope.addTemplate = addTemplate;
      $scope.templates = [];
      $scope.staff = [];
      $scope.smartMessagePicker = getSmartMessages;
      $scope.addSmartMessage = addSmartMessage;
      $scope.smartMessages = [];
      $scope.smartMessageType = smartMessageType;
      $scope.popoverState = popoverState;
      $scope.insertEmoji = insertEmoji;
      $scope.getStaffDisplayName = getStaffDisplayName;

      $scope.groupAccounts = _.filter(profile.groupAccounts, {
        departmentId: profile.currentDepartment.id,
      });

      $scope.inboxes =
        $rootScope.config.features["a2p-verification"] === true &&
        $rootScope.profile.currentDepartment.campaignStatus !== "VERIFIED"
          ? _.filter($rootScope.profile.inboxes, (i) => i.type !== "group" && i.type !== "high-speed")
          : _.filter($rootScope.profile.inboxes, (inbox) => inbox.type !== "high-speed");

      $scope.recipientType = {
        students: true,
        segments: false,
        targets: false,
        filters: false,
        slateQueries: false,
        reNxtLists: false,
      };
      $scope.isMongooseAdministrator = profile.isMongooseAdministrator;
      $scope.disableQuietHoursSafeguard = profile.currentDepartment.disableQuietHoursSafeguard;

      $rootScope.viewHistory = viewHistory;
      $rootScope.viewStudent = viewStudent;

      var allFilters = [],
        allTargets = [],
        allSlateQueries = [],
        allReNxtLists = [],
        options = $window.sessionStorage.getItem("compose-options");

      activate();

      function activate() {
        $scope.student = {};
        $scope.staticSegmentCounts = [];
        $scope.autoSegmentCounts = [];
        $scope.studentSegmentCount = 0;

        $scope.showSelectSender = profile.inboxes.length > 1;
        $scope.step = $scope.showSelectSender && !account ? "sender" : "compose";

        Restangular.all("messageTypes")
          .getList()
          .then(function (results) {
            $scope.messageTypes = results;
            if ($scope.messageTypes.length === 1) {
              $scope.message.messageType = _.head($scope.messageTypes);
            } else {
              $scope.message.messageType = _.find($scope.messageTypes, {
                isDefault: true,
              });
              if (!$scope.message.messageType) {
                //if there isnt a default for some reason
                $scope.message.messageType = _.head($scope.messageTypes);
              }
            }
          });

        $scope.fields = [];
        $scope.message = {
          campaigns: [],
          filters: [],
          mediaUris: [],
          restrictToAssignment: !_.isNull(options)
            ? JSON.parse(options).restrictToAssignment
            : profile.hasStudentsAssigned,
          segments: [],
          students: [],
          targets: [],
          slateQueries: [],
          reNxtLists: [],
          text: "",
          trackAsCampaign: false,
        };

        $scope.hasValidationError = {
          recipients: false,
          messageType: false,
          messageText: false,
          maxCampaigns: false,
          missingCampaign: false,
        };

        if (profile.inboxes.length === 1) {
          setAccount(profile.inboxes[0]);
        }
        if (!_.isNull(account) && !_.isUndefined(account)) {
          if (account.type === "personal") {
            setAccount(_.find(profile.inboxes, { userId: account.userId }));
          } else if (account.type === "group") {
            setAccount(_.find(profile.inboxes, { groupId: account.groupId }));
          }
        }
        if (!_.isNull(messageText) && !_.isUndefined(messageText)) {
          $scope.message.text = messageText;
        }
        if (!_.isNull(restrictToAssignment) && !_.isUndefined(restrictToAssignment)) {
          $scope.message.restrictToAssignment = restrictToAssignment;
        }
        if (!_.isNull(sendAsStaff) && !_.isUndefined(sendAsStaff)) {
          $scope.message.sendAsStaff = sendAsStaff;
        }
        if (!_.isNull(schedule) && !_.isUndefined(schedule)) {
          var scheduleDate = new Date(schedule.date);
          var scheduleTime = new Date();
          scheduleTime.setHours(scheduleDate.getHours());
          scheduleTime.setMinutes(scheduleDate.getMinutes());
          $scope.scheduleDate = scheduleDate;
          $scope.scheduleTime = scheduleTime;
          $scope.message.scheduleName = schedule.name;
        }
        if (!_.isNull(students) && !_.isUndefined(students)) {
          selectRecipientType("students");
        }
        if (!_.isNull(segments) && !_.isUndefined(segments)) {
          selectRecipientType("segments");
        }
        if (!_.isNull(slateQuery) && !_.isUndefined(slateQuery)) {
          selectRecipientType("slateQueries");
          $scope.message.slateQueries = [{ id: slateQuery.id, tagText: slateQuery.name }];
        }
        if (!_.isNull(reNxtList) && !_.isUndefined(reNxtList)) {
          selectRecipientType("reNxtLists");
          $scope.message.reNxtLists = [{ id: reNxtList.id, tagText: reNxtList.name }];
        }
        if (!_.isNull(smartMessage) && !_.isUndefined(smartMessage)) {
          $scope.message.smartMessage = smartMessage;
          $scope.message.text = smartMessage.message;
        }
        if (!_.isNull(campaign) && !_.isUndefined(campaign)) {
          $scope.message.trackAsCampaign = true;
          campaign.text = campaign.name;
          campaign.tagText = campaign.name;
          $scope.message.campaigns = [campaign];
        }
        if (isScheduledMessage) {
          $scope.isScheduledMessage = true;
        }
        if (isFollowUpSMS) {
          $scope.isFollowUpSMS = true;
        }

        if (SMSfromChat) {
          $scope.SMSfromChat = true;
        }

        getTemplates();
        getSmartMessages();
        getStaff();
        $rootScope.openSegmentDetailModal = function (segment) {
          $uibModal.open({
            templateUrl: "segments/segmentCriteriaModal.html",
            controller: "SegmentCriteriaModalController as vm",
            ariaLabelledBy: "segment-criteria",
            resolve: {
              segment: segment,
            },
            size: "sm criteria-preview-modal",
          });
        };
      }

      function getStaffDisplayName(staffId) {
        var staff = _.find($scope.staff, { id: staffId });

        if (!staff) {
          return "Unknown";
        }

        if (staff.fullName) {
          return staff.fullName;
        } else if (staff.firstName && staff.lastName) {
          return staff.firstName + " " + staff.lastName;
        } else {
          return staff.emailAddress;
        }
      }

      function close() {
        $uibModalInstance.close();
      }

      function inboxDisabled(inbox) {
        return (
          (inbox.type === "personal" && $scope.message.smartMessage) ||
          (inbox.type === "high-speed" && !inbox.credits) ||
          (inbox.type === "high-speed" &&
            ((inbox.credits.balance && inbox.credits.balance < 1) || !inbox.credits.loaded))
        );
      }

      function isLegacyMediaPickerEnabled() {
        return (
          (config.plan === "Legacy" && ($scope.recipientType.students || config.hasBulkMms)) ||
          (config.plan !== "Legacy" && ($scope.recipientType.students || config.bulkMmsMethod === "Bulk"))
        );
      }

      function isMediaPickerEnabled() {
        return config.bulkMmsMethod === "CreditBased";
      }

      function isLegacyMediaPicker() {
        return config.plan === "Legacy" || ["Individual", "Bulk"].includes(config.bulkMmsMethod);
      }

      function sendMessage() {
        var newMessage = {
            text: $scope.message.text,
            restrictToAssignment: $scope.message.restrictToAssignment,
            fromCompose: true,
            mediaUris: $scope.message.mediaUris,
            templateId: $scope.selectedTemplateId,
          },
          campaignPromise = $q.when(null),
          inboxType = $scope.account.type;

        newMessage = setNewMessagePropertiesFromScope(newMessage);

        if ($scope.message.messageType) newMessage.messageTypeId = $scope.message.messageType.id;

        if ($scope.message.scheduleDate) {
          newMessage.scheduleDate = $scope.message.scheduleDate;
          newMessage.scheduleName = $scope.message.scheduleName;
        }

        if ($scope.message.campaigns.length > 0 && $scope.message.trackAsCampaign) {
          if (_.isUndefined($scope.message.campaigns[0].id)) {
            var newCampaign = {
              name: $scope.message.campaigns[0].text,
              description: "",
              active: true,
            };
            campaignPromise = Restangular.all("campaigns").post(newCampaign);
          } else {
            newMessage.campaignId = $scope.message.campaigns[0].id;
          }
        }

        return campaignPromise
          .then(function (campaign) {
            if (campaign) {
              newMessage.campaignId = campaign.id;
              $rootScope.$broadcast("campaignCreated");
              if (window.eventBus) window.eventBus.dispatchEvent("campaignCreated", campaign.plain());

              mixpanel.track("Created Campaign", {
                "Campaign Status": "Active",
                "Created Source": "Compose",
              });
            }

            if (inboxType === "group") {
              return Restangular.one("groupaccounts", $scope.account.groupId).post("send", newMessage);
            }

            return $scope.message.sendAsStaff
              ? Restangular.all("messages").customPOST(newMessage, "AsStaff")
              : Restangular.all("messages").post(newMessage);
          })
          .then(
            function () {
              var eventAction, eventType, eventCount;

              $scope.close();

              if ($scope.recipientType.students) {
                eventType = "Students";
                eventCount = newMessage.recipients.length;
              } else if ($scope.recipientType.segments) {
                eventType = "Segments";
                eventCount = $scope.message.segments.length;
              } else if ($scope.recipientType.targets) {
                eventType = "Targets";
                eventCount = $scope.message.targets.length;
              } else if ($scope.recipientType.filters) {
                eventType = "Filters";
                eventCount = $scope.message.filters.length;
              } else if ($scope.message.slateQueries.length) {
                eventType = "Slate Queries";
                eventCount = $scope.message.slateQueries.length;
              } else if ($scope.message.reNxtLists.length) {
                eventType = "RE Nxt Lists";
                eventCount = $scope.message.reNxtLists.length;
              }

              if ($scope.message.scheduleDate) {
                if (scheduledMessageId) {
                  Restangular.one("messages", scheduledMessageId)
                    .post("unschedule", {})
                    .then(
                      function () {
                        growl.success("Your scheduled message has been updated!");
                        $timeout(function () {
                          window.eventBus.dispatchEvent("updateScheduledMessage");
                        });
                      },
                      function () {
                        growl.error("There was an error updating your scheduled message.");
                      }
                    );
                  eventAction = "Update Scheduled";
                } else {
                  $timeout(function () {
                    window.eventBus.dispatchEvent("updateScheduledMessage");
                  });
                  eventAction = "Scheduled";
                  growl.success("Your message has been scheduled!");
                }
                $rootScope.$broadcast("messageScheduledEvent");
              } else {
                growl.success("Your message is on its way!");
                eventAction = "Sent";
              }

              var eventData = {
                "Account Type":
                  inboxType === "personal"
                    ? "Personal"
                    : inboxType === "group"
                    ? "Group"
                    : inboxType === "high-speed"
                    ? "High Speed"
                    : "",
                "Recipient Type": eventType,
                "Send to My Students Only": !!$scope.message.restrictToAssignment,
                "Send as Staff": !!$scope.message.sendAsStaff,
                "Track as Campaign": !!newMessage.campaignId,
                MMS: $scope.message.mediaUris.length > 0,
                "Smart Message": !!$scope.message.smartMessage,
              };

              eventData[eventType + " Count"] = eventCount;

              if ($scope.SMSfromChat) {
                mixpanel.track(eventAction + " Message from Chat", eventData);
              } else {
                mixpanel.track(eventAction + " Message", eventData);
              }

              if (eventAction === "Sent") {
                Appcues.track("Sent Message", { "Recipient Type": eventType });

                if (eventCount === 1)
                  Appcues.track("Sent Message to one contact", {
                    "Recipient Type": eventType,
                  });

                if (eventType === "Segments")
                  Appcues.track("Sent Message to Segment", {
                    "Recipient Type": eventType,
                  });
              }
            },
            function (error) {
              $scope.hasServerValidationError = true;
              $scope.serverValidationErrorMessage = error && error.data ? error.data : null;
              $scope.sending = false;
            }
          );
      }

      function setNewMessagePropertiesFromScope(newMessage) {
        if ($scope.account.type === "group") newMessage.groupAccountId = $scope.account.groupId;

        if ($scope.account.type === "high-speed") newMessage.highspeedinboxid = $scope.account.highSpeedId;

        if ($scope.message.smartMessage) newMessage.smartMessageId = $scope.message.smartMessage.id;

        if ($scope.recipientType.students && $scope.message.students) {
          newMessage.recipients = $scope.message.students.map(function (s) {
            return s.id;
          });
        } else if ($scope.recipientType.segments && $scope.message.segments) {
          newMessage.autoUpdateSegments = _.filter($scope.message.segments, {
            type: "Auto-Update",
          }).map(function (s) {
            return s.id;
          });
          newMessage.staticSegments = _.filter($scope.message.segments, {
            type: "Static",
          }).map(function (s) {
            return s.id;
          });
          newMessage.automatedSegments = _.filter($scope.message.segments, { type: "Automated" }).map(function (s) {
            return s.id;
          });
        } else if ($scope.recipientType.targets && $scope.message.targets) {
          newMessage.radiusTargetId = $scope.message.targets[0].entityId;
        } else if ($scope.recipientType.filters && $scope.message.filters) {
          newMessage.connectFilterId = $scope.message.filters[0].id;
        } else if ($scope.recipientType.slateQueries && $scope.message.slateQueries) {
          newMessage.slateWebServiceQueryId = $scope.message.slateQueries[0].id;
        } else if ($scope.recipientType.reNxtLists && $scope.message.reNxtLists) {
          newMessage.raisersEdgeNxtListId = $scope.message.reNxtLists[0].id;
        }

        return newMessage;
      }

      function addStudent(student) {
        $scope.adding = true;
        $scope.validationMessage = "";
        studentService
          .add(student)
          .then(function (response) {
            response.text = response.firstName + " " + response.lastName;
            response.tagText = response.text;
            $scope.message.students.push(response);
            backToCompose();

            mixpanel.track("Added Student Manually");
          })
          .catch(function (response) {
            $scope.validationMessage = response;
          })
          .finally(function () {
            $scope.adding = false;
          });
      }

      function setAccount(account) {
        $scope.account = account;

        if (account.type !== "personal") {
          $scope.message.restrictToAssignment = false;
          $scope.message.sendAsStaff = false;
        }
      }

      function selectSender(account) {
        if (inboxDisabled(account)) return;

        setAccount(account);

        setHeight();
        $scope.slideDirection = "left";
        $timeout(function () {
          $scope.step = "compose";
        });
        $timeout(function () {
          setHeight("auto");
          $scope.slideDirection = "";
        }, 500);
      }

      function addCampaign() {
        var query = $scope.campaignQuery;
        $scope.message.campaigns.push({
          tagText: query,
          text: query,
        });
      }

      function insertEmoji(emoji) {
        const replyTextarea = document.getElementById("messageText");
        const caret = replyTextarea.selectionStart;

        if (!$scope.message.text || $scope.message.text.trim().length === 0) {
          $scope.message.text = emoji.unicode;
        } else {
          var partOne = $scope.message.text.substring(0, caret);
          if (partOne.length > 0 && partOne.charAt(partOne.length - 1) !== " ") {
            partOne += " ";
          }
          var partTwo = $scope.message.text.substring(caret);
          if (partTwo.length > 0 && partOne.charAt(0) !== " ") {
            partTwo = " " + partTwo;
          }
          $scope.message.text = partOne + emoji.unicode + partTwo;
        }

        mixpanel.track("Inserted Emoji", {
          Emoji: emoji.unicode,
        });
      }

      function backToCompose() {
        setHeight();
        $scope.slideDirection = "right";
        $scope.showAddStudentButton = false;
        $scope.student = {};
        $timeout(function () {
          $scope.step = "compose";
        });
        $timeout(function () {
          setHeight("auto");
          $scope.slideDirection = "";
        }, 500);
      }

      function backToReview() {
        setHeight();
        $scope.slideDirection = "right";
        $timeout(function () {
          $scope.step = "review";
        });
        $timeout(function () {
          setHeight("auto");
          $scope.slideDirection = "";
        }, 500);
      }

      function backToSender() {
        setHeight();
        $scope.slideDirection = "right";
        $timeout(function () {
          $scope.step = "sender";
        });
        $timeout(function () {
          setHeight("auto");
          $scope.slideDirection = "";
        }, 500);
      }

      function goToAddStudent() {
        setHeight();
        $scope.validationMessage = "";
        $scope.slideDirection = "left";
        $timeout(function () {
          $scope.step = "add";
        });
        $timeout(function () {
          setHeight("auto");
          $scope.slideDirection = "";
          document.getElementById("firstName").focus();
        }, 500);
      }

      function hasContent() {
        return ($scope.message.mediaUris && $scope.message.mediaUris.length > 0) || $scope.message.text.length > 0;
      }

      function isValidPhone(input) {
        var validChars = input.match(/[A-Za-z]/g) ? false : true,
          trimmed = input.replace(/[^0-9]/g, ""),
          validLength = trimmed.length === 10 || (trimmed.length === 11 && trimmed.startsWith("1"));

        return validChars && validLength;
      }

      function goToSchedule() {
        setHeight();
        $scope.validationMessage = "";
        $scope.slideDirection = "left";
        $timeout(function () {
          $scope.step = "schedule";
        });
        $timeout(function () {
          setHeight("auto");
          $scope.slideDirection = "";
          if (!$scope.message.scheduleName) {
            document.getElementById("messageName").focus();
          }
        }, 500);
      }

      function scheduleDateTimeUpdate(scheduleDate, scheduleTime) {
        $scope.scheduleValidationError = false;
        $scope.serverValidationErrorMessage = false;
        var scheduleDate = moment(scheduleDate),
          scheduleTime = moment(scheduleTime),
          now = moment().add(5, "minutes").tz(config.timeZone).format("YYYY-MM-DDTHH:mm"),
          combinedScheduleDateTime = moment(scheduleDate.format("L") + " " + scheduleTime.format("LT")).format(
            "YYYY-MM-DDTHH:mm"
          );
        if (
          scheduleDate.isValid() &&
          scheduleTime.isValid() &&
          moment(now).isBefore(moment(combinedScheduleDateTime))
        ) {
          $scope.scheduleDate = scheduleDate;
          $scope.scheduleTime = scheduleTime;
          $scope.combinedScheduleDateTime = combinedScheduleDateTime;
        } else {
          $scope.scheduleValidationError = true;
        }
      }

      function scheduleMessage() {
        $scope.message.scheduleDate = $scope.combinedScheduleDateTime;
        send();
      }

      function removeSmartMessage() {
        delete $scope.message.smartMessage;
        $scope.message.text = "";
      }

      function restrictToAssignmentChanged() {
        $window.sessionStorage.setItem(
          "compose-options",
          JSON.stringify({
            restrictToAssignment: $scope.message.restrictToAssignment,
          })
        );
      }

      function searchStudents(searchText) {
        var deferred = $q.defer();

        $scope.searching = true;

        Restangular.all("students")
          .customPOST({ searchText: searchText, includeOptOut: true }, "search")
          .then(
            function (response) {
              var results = response.plain().map(function (item) {
                var displayText = item.firstName ? item.firstName + " " + item.lastName : item.formattedMobile;
                item.tagText = displayText;
                if (item.campusStudentId || item.campusUniqueId) {
                  displayText = displayText + " (" + (item.campusStudentId || item.campusUniqueId) + ")";
                }
                item.text = displayText;
                item.assignedToYou = item.assignedStaffId === profile.id;
                return item;
              });

              $scope.searching = false;
              $scope.showAddStudentButton = !results.length && isValidPhone(searchText);

              if ($scope.showAddStudentButton) {
                $scope.student.mobileNumber = searchText;
              }

              deferred.resolve(results);
            },
            function () {
              $scope.searching = false;
            }
          );

        return deferred.promise;
      }

      function searchSegments(searchText) {
        $scope.searching = true;

        var staffPromise = Restangular.all("staff").getList(),
          segmentPromise = segmentsService.get(searchText);

        return $q.all([staffPromise, segmentPromise]).then(function (results) {
          $scope.searching = false;

          var staff = results[0].plain(),
            segments = results[1],
            currentUserSegments;

          segments = _.map(segments, function (item) {
            var createdBy = _.find(staff, { id: item.createdBy }),
              name = !createdBy ? "Unknown" : item.createdBy === profile.id ? "<b>You</b>" : createdBy.displayName;
            item.tagText = item.name;
            item.text = item.name;
            item.createdByName = name;
            return item;
          });

          currentUserSegments = _.remove(segments, function (s) {
            return s.createdBy === profile.id;
          });

          return currentUserSegments.concat(segments);
        });
      }

      function searchCampaigns(searchText) {
        $scope.loadingCampaigns = true;
        var query = {
          isActive: true,
          searchText: null,
        };
        query.searchText = searchText;
        $scope.campaignQuery = searchText;

        var staffPromise = Restangular.all("staff").getList(),
          campaignPromsie = Restangular.all("campaigns").getList(query);

        return $q.all([staffPromise, campaignPromsie]).then(function (results) {
          var staff = results[0].plain(),
            campaigns = results[1].plain();

          if ($scope.account.type !== "personal") {
            campaigns = _.filter(campaigns, {
              departmentId: profile.currentDepartment.id,
            });
          }

          $scope.loadingCampaigns = false;
          $scope.campaignQueryResponseCount = campaigns.length;
          return _.map(campaigns, function (item) {
            var createdBy = _.find(staff, { id: item.createdBy }),
              name = !createdBy ? "Unknown" : item.createdBy === profile.id ? "<b>You</b>" : createdBy.displayName;

            item.tagText = item.name;
            item.text = item.name;
            item.createdByName = name;
            return item;
          });
        });
      }

      $scope.showAddCampaignButton = function () {
        return (
          $scope.message.campaigns.length === 0 &&
          $scope.campaignQuery &&
          $scope.campaignQuery.length > 2 &&
          $scope.campaignQueryResponseCount < 1
        );
      };

      function searchFilters(searchText) {
        $scope.searching = true;

        var filteredFilters = _.filter(allFilters, function (filter) {
          return !searchText || filter.name.toUpperCase().includes(searchText.toUpperCase());
        });
        var sordidFilters = _.sortByOrder(filteredFilters, ["lastModified"], ["desc"]);

        $scope.searching = false;

        var deferred = $q.defer();
        deferred.resolve(sordidFilters);
        return deferred.promise;
      }

      function searchTargets(searchText) {
        $scope.searching = true;

        var filteredTargets = _.filter(allTargets, function (target) {
          return !searchText || target.name.toUpperCase().includes(searchText.toUpperCase());
        });

        $scope.searching = false;

        var deferred = $q.defer();
        deferred.resolve(filteredTargets);
        return deferred.promise;
      }

      function searchSlateQueries(searchText) {
        $scope.searching = true;

        const filteredQueries = _.filter(allSlateQueries, function (query) {
          return !searchText || query.name.toUpperCase().includes(searchText.toUpperCase());
        });

        $scope.searching = false;

        var deferred = $q.defer();
        deferred.resolve(filteredQueries);
        return deferred.promise;
      }

      function searchReNxtLists(searchText) {
        $scope.searching = true;

        const filteredLists = _.filter(allReNxtLists, function (list) {
          return !searchText || list.name.toUpperCase().includes(searchText.toUpperCase());
        });

        $scope.searching = false;

        var deferred = $q.defer();
        deferred.resolve(filteredLists);
        return deferred.promise;
      }

      function send() {
        $scope.sending = true;

        if (validate()) {
          sendMessage().then(
            function () {},
            function () {
              $scope.sending = false;
            }
          );
        } else {
          $scope.sending = false;
        }
      }

      function setHeight(height) {
        var modal = $(".modal-slide-container"),
          newHeight = height ? height : modal[0].scrollHeight,
          overflow = height ? "visible" : "hidden";
        modal.css({
          height: newHeight,
          overflow: overflow,
        });
      }

      function selectRecipientType(type) {
        $scope.recipientType = {
          students: type === "students",
          segments: type === "segments",
          targets: type === "targets",
          filters: type === "filters",
          slateQueries: type === "slateQueries",
          reNxtLists: type === "reNxtLists",
        };

        if ($scope.recipientType.students) {
          $scope.showAddStudentButton = false;
          $scope.message.segments = [];
          $scope.message.targets = [];
          $scope.message.filters = [];
          $scope.message.slateQueries = [];
          $scope.message.reNxtLists = [];

          $timeout(function () {
            if (!(_.isNull(students) || _.isUndefined(students))) {
              $scope.message.students = students;
              students = [];
            } else {
              $("#recipients input")[0].focus();
            }
          });
        } else if ($scope.recipientType.segments) {
          $scope.message.students = [];
          $scope.message.targets = [];
          $scope.message.filters = [];
          $scope.message.slateQueries = [];
          $scope.message.reNxtLists = [];

          $timeout(function () {
            if (!(_.isNull(segments) || _.isUndefined(segments))) {
              $scope.message.segments = segments;
              segments = [];
            } else {
              $("#segments input")[0].focus();
            }
          });
        } else if ($scope.recipientType.targets) {
          $scope.searching = true;
          $scope.message.students = [];
          $scope.message.segments = [];
          $scope.message.filters = [];
          $scope.message.slateQueries = [];
          $scope.message.reNxtLists = [];

          Restangular.all("radius/targets")
            .getList("")
            .then(function (response) {
              $scope.searching = false;
              allTargets = _.map(response.plain(), function (item) {
                item.tagText = item.name;
                item.text = item.name;
                return item;
              });
              $timeout(function () {
                $("#targets input")[0].focus();
              });
            });
        } else if ($scope.recipientType.filters) {
          $scope.searching = true;
          $scope.message.students = [];
          $scope.message.segments = [];
          $scope.message.targets = [];
          $scope.message.slateQueries = [];
          $scope.message.reNxtLists = [];

          Restangular.all("connect/filters")
            .getList()
            .then(function (response) {
              $scope.searching = false;
              allFilters = _.map(response.plain(), function (filter) {
                filter.text = filter.name;
                filter.tagText = filter.name;
                return filter;
              });
              $timeout(function () {
                $("#filters input")[0].focus();
              });
            });
        } else if ($scope.recipientType.slateQueries) {
          $scope.searching = true;
          $scope.message.students = [];
          $scope.message.segments = [];
          $scope.message.targets = [];
          $scope.message.filters = [];
          $scope.message.reNxtLists = [];

          Restangular.all("slatequeries")
            .getList()
            .then(function (response) {
              $scope.searching = false;
              allSlateQueries = _.map(response.plain(), function (query) {
                query.text = query.name;
                query.tagText = query.name;
                return query;
              });
              $timeout(function () {
                $("#slateQueries input")[0].focus();
              });
            });
        } else if ($scope.recipientType.reNxtLists) {
          $scope.searching = true;
          $scope.message.students = [];
          $scope.message.segments = [];
          $scope.message.targets = [];
          $scope.message.filters = [];
          $scope.message.slateQueries = [];

          Restangular.all("raisersEdgeNxt/constituent-lists")
            .getList()
            .then(function (response) {
              $scope.searching = false;
              allReNxtLists = _.map(response.plain(), function (query) {
                query.text = query.name;
                query.tagText = query.name;
                return query;
              });
              $timeout(function () {
                $("#reNxtLists input")[0].focus();
              });
            });
        }

        if (
          (isLegacyMediaPicker() && !isLegacyMediaPickerEnabled()) ||
          (!isLegacyMediaPicker() && !isMediaPickerEnabled())
        ) {
          $rootScope.$broadcast("resetMediaPicker");
        }
      }

      function selectedSegmentsUpdated() {
        $timeout(function () {
          $("#segments input").blur();
        });
      }

      function selectedFiltersUpdated($tag) {
        // Limit to 1 filter (ngTagsInput validation not working)
        if ($scope.message.filters && $scope.message.filters.length > 1) {
          $scope.message.filters.pop($tag);
          $scope.hasValidationError.maxRecipients = true;
          $timeout(function () {
            $scope.hasValidationError.maxRecipients = false;
          }, 3000);
        }
        $timeout(function () {
          $("#filters input").blur();
        });
      }

      function selectedTargetsUpdated($tag) {
        // Limit to 1 target (ngTagsInput validation not working)
        if ($scope.message.targets && $scope.message.targets.length > 1) {
          $scope.message.targets.pop($tag);
          $scope.hasValidationError.maxRecipients = true;
          $timeout(function () {
            $scope.hasValidationError.maxRecipients = false;
          }, 3000);
        }
        $timeout(function () {
          $("#targets input").blur();
        });
      }

      function selectedSlateQueriesUpdated($tag) {
        // Limit to 1 query (ngTagsInput validation not working)
        if ($scope.message.slateQueries && $scope.message.targets.slateQueries > 1) {
          $scope.message.targets.pop($tag);
          $scope.hasValidationError.maxRecipients = true;
          $timeout(function () {
            $scope.hasValidationError.maxRecipients = false;
          }, 3000);
        }
        $timeout(function () {
          $("#slateQueries input").blur();
        });
      }

      function selectedReNxtListsUpdated($tag) {
        // Limit to 1 query (ngTagsInput validation not working)
        if ($scope.message.reNxtLists && $scope.message.targets.reNxtLists > 1) {
          $scope.message.targets.pop($tag);
          $scope.hasValidationError.maxRecipients = true;
          $timeout(function () {
            $scope.hasValidationError.maxRecipients = false;
          }, 3000);
        }
        $timeout(function () {
          $("#reNxtLists input").blur();
        });
      }

      function selectedCampaignUpdated($tag) {
        // Limit to 1 campaign (ngTagsInput validation not working)
        if ($scope.message.campaigns && $scope.message.campaigns.length > 1) {
          $scope.message.campaigns.pop($tag);
          $scope.hasValidationError.maxCampaigns = true;
          $timeout(function () {
            $scope.hasValidationError.maxCampaigns = false;
          }, 3000);
        }
        $timeout(function () {
          $("#campaign input").blur();
        });
      }

      function showMediaPicker() {
        return $scope.account.type !== "high-speed";
      }

      function showSmartMessages() {
        return true;
      }

      function validate() {
        $scope.hasServerValidationError = false;

        $scope.hasValidationError.recipients =
          $scope.message.segments.length === 0 &&
          $scope.message.students.length === 0 &&
          $scope.message.targets.length === 0 &&
          $scope.message.filters.length === 0 &&
          $scope.message.slateQueries.length === 0 &&
          $scope.message.reNxtLists.length === 0;

        $scope.hasValidationError.messageType = !$scope.message.messageType;
        $scope.hasValidationError.messageText =
          !$scope.message.text && $scope.message.mediaUris && $scope.message.mediaUris.length === 0;
        $scope.hasValidationError.missingCampaign =
          $scope.message.trackAsCampaign && $scope.message.campaigns.length === 0;

        return (
          hasContent() &&
          $scope.message.text.length <= 350 &&
          !$scope.hasValidationError.recipients &&
          !$scope.hasValidationError.messageType &&
          !$scope.hasValidationError.messageText &&
          !$scope.hasValidationError.missingCampaign
        );
      }

      function validateStudent($tag) {
        if ($tag.optedIn) {
          return true;
        } else {
          growl.error("This student is currently opted-out or blocked.");
          return false;
        }
      }

      function viewHistory(studentId) {
        $uibModal.open({
          controller: "ConversationModalController",
          controllerAs: "vm",
          ariaLabelledBy: "conversation",
          resolve: {
            account: $scope.account,
            config: config,
            profile: profile,
            studentId: function () {
              return studentId;
            },
          },
          templateUrl: "messages/modals/conversation.html",
        });
      }

      function viewStudent(studentId) {
        $uibModal.open({
          templateUrl: "messages/modals/student-view.html",
          controller: "StudentViewModalController",
          ariaLabelledBy: "student-view",
          resolve: {
            studentId: function () {
              return studentId;
            },
            profile: profile,
            source: function () {
              return "Compose";
            },
          },
        });
      }

      function review() {
        if (validate()) {
          $scope.loading = true;
          return estimate().then(
            function () {
              $scope.loading = false;
              setHeight();
              $scope.slideDirection = "left";
              $timeout(function () {
                $scope.step = "review";
              });
              $timeout(function () {
                setHeight("auto");
                $scope.slideDirection = "";
              }, 500);
            },
            function (response) {
              $scope.loading = false;
              growl.error(response.data);
            }
          );
        }
      }

      function compose() {
        setHeight();
        $scope.slideDirection = "right";
        $timeout(function () {
          $scope.step = "compose";
        });
        $timeout(function () {
          setHeight("auto");
          $scope.slideDirection = "";
        }, 500);
      }

      function estimate() {
        var newMessage = {
            text: $scope.message.text,
            restrictToAssignment: $scope.message.restrictToAssignment,
            mediaUris: $scope.message.mediaUris,
            templateId: $scope.selectedTemplateId,
          },
          newCampaignName;

        newMessage = setNewMessagePropertiesFromScope(newMessage);

        if ($scope.message.campaigns.length > 0 && $scope.message.trackAsCampaign) {
          if (!_.isUndefined($scope.message.campaigns[0].id)) {
            newMessage.campaignId = $scope.message.campaigns[0].id;
          } else {
            newCampaignName = $scope.message.campaigns[0].text;
          }
        }

        var estimatePromise = $scope.message.sendAsStaff
          ? Restangular.all("messages").customPOST(newMessage, "estimateAsStaff")
          : Restangular.all("messages").customPOST(newMessage, "estimate");

        return estimatePromise.then(function (response) {
          var estimate = response.plain();
          if ($scope.message.smartMessage && $scope.message.smartMessage.messageType === 2) {
            estimate.sample = estimate.sample.split("\n")[0];
          }
          $scope.estimate = {
            message: estimate.sample,
            count: estimate.studentCount,
            details: estimate.details,
            uniqueCount: estimate.uniqueStudentCount,
            maxCount: estimate.maxStudentCount,
            warnings: estimate.warnings,
            campaignId: estimate.campaignId,
            campaignName: newCampaignName ? newCampaignName : estimate.campaignName,
            staffSend: $scope.message.sendAsStaff,
            restrictToAssignment: $scope.message.restrictToAssignment,
            exportToSegment: function (warning) {
              warning.loading = true;
              Restangular.all("messages")
                .customPOST(newMessage, "ExportToSegment")
                .then(
                  function (response) {
                    warning.handled = true;
                    warning.loading = false;
                    warning.exportedSegmentName = response.name;
                  },
                  function () {
                    warning.handled = false;
                    warning.loading = false;
                    growl.error("There was an error creating Segment.");
                  }
                );
            },
          };
          return estimate;
        });
      }

      function getModalTitle() {
        if (scheduledMessageId) {
          return "Edit Message";
        }
        if (isScheduledMessage) {
          return "Schedule Message";
        }
        if (modalTitle) {
          return modalTitle;
        }
        return "Compose Message";
      }

      function getTemplates() {
        Restangular.all("templates")
          .getList()
          .then(function (templates) {
            $scope.templates = templates;
          });
      }

      function getStaff() {
        Restangular.all("staff")
          .getList()
          .then(function (staff) {
            $scope.staff = staff;
          });
      }

      function getSmartMessages() {
        $scope.searching = true;

        let profilePromise = securityService.getProfile(),
          staffPromise = Restangular.all("staff").getList(),
          smartMessagesPromise = Restangular.all("smartmessages").getList();

        return $q.all([profilePromise, staffPromise, smartMessagesPromise]).then(function (results) {
          $scope.searching = false;

          let userId = results[0].id,
            staff = results[1].plain(),
            smartMessages = results[2].filter((smartMessage) => !smartMessage.archived);

          $scope.smartMessages = _.map(smartMessages, function (item) {
            let createdBy = _.find(staff, { id: item.createdById }),
              name = !createdBy ? "Unknown" : item.createdById === userId ? "You" : createdBy.displayName;
            item.createdByName = name;
            return item;
          });
        });
      }

      function addTemplate(item) {
        $scope.message.text = item.message;
        $scope.selectedTemplateId = item.id;
        mixpanel.track("Inserted Message Template");
      }

      function addSmartMessage(smartMessage) {
        $scope.message.text = smartMessage.message;
        $scope.message.smartMessage = smartMessage;

        mixpanel.track("Inserted Smart Message", {
          "Message Name": smartMessage.name,
          "Message Type": smartMessageType(smartMessage.messageType),
        });
      }

      function popoverState(isPopoverOpen) {}

      function smartMessageType() {
        if (!$scope.message.smartMessage) return;
        switch ($scope.message.smartMessage.messageType) {
          case 0:
            return "Yes or No";
          case 1:
            return "Numeric Scale";
          case 2:
            return "Multiple Choice";
        }
      }
    }
  );
