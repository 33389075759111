angular.module('mongoose').factory('reportService', function() {

    var report;

    function addGroup(name, data) {
        var group = {name: name, identifiers: data};
        report.groups.push(group);
    }

    function initialize(name, isFunnel) {
        report = {
            name: name,
            isFunnel: isFunnel,
            groups:[]
        };
    }
    function getReport() {
        return report;
    }
    
    return {
        addGroup: addGroup,
        initialize: initialize,
        getReport : getReport
    };
});