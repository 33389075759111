
angular.module('mongoose').controller('FtpExportsController', function ($scope, $interval, ftpService, config, Restangular, growl) {

    var vm = this,
        interval;

    vm.run = run;


    activate();

    function activate() {
        getHistory();

        interval = $interval(function() {
            getHistory(true);
        }, 5000);
        
        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    }

    function getHistory(disableLoading){
        ftpService.getExports({timezone: config.timeZone, disableLoading: disableLoading}).then(function(exports){
            vm.exports = exports;
        }, function(){
            $interval.cancel(interval);
        });
    }

    function run(exportType){
        Restangular.all('ftp/exports').post({exportType: exportType}).then(function(){
            growl.success("Your export has been initiated");
        });
    }

});