angular
  .module("mongoose")
  .controller(
    "ConversationModalController",
    function (
      $scope,
      studentId,
      account,
      $timeout,
      $q,
      $uibModalInstance,
      profile,
      config,
      Restangular,
      studentService,
      smoothScroll
    ) {
      var vm = this,
        student = {};

      vm.account = account;
      vm.close = close;
      vm.getDate = getDate;
      vm.getDisplayName = getDisplayName;
      vm.messageClass = messageClass;
      vm.senderName = senderName;
      vm.showSender = showSender;
      vm.studentDisplayFirstName = studentDisplayFirstName;
      vm.timeZone = config.timeZone;

      $scope.$on("$destroy", destroy);

      activate();

      function activate() {
        var requests = [
          Restangular.one("students", studentId).get(),
          studentService.getMessages(account, profile, studentId),
          Restangular.all("campaigns").getList(),
        ];

        $q.all(requests).then(function (results) {
          student = results[0];
          vm.conversation = results[1];
          vm.campaigns = results[2];
          $timeout(scrollToBottom, 200);
        });

        mixpanel.track("Viewed Conversation History from Compose");
      }

      function close() {
        $uibModalInstance.close();
      }

      function destroy() {}

      function getDate(message) {
        return moment.tz(message.date, config.timeZone).fromNow();
      }

      function getDisplayName(message) {
        if (!message) return;
        return studentService.getDisplayName(student);
      }

      function messageClass(message, next) {
        if (!message) return;

        var messageClass =
          message.direction === "incoming" ? "incoming" : "outgoing";

        if (next) {
          if (
            message.direction === next.direction &&
            message.staff === next.staff &&
            moment(message.date).isSame(next.date, "minute") &&
            !message.error
          ) {
            messageClass += " consecutive";
          }
        }

        if (
          message.media &&
          message.media.length &&
          message.message &&
          message.message !== "<Multimedia Message>"
        ) {
          messageClass += " media-with-text";
        }

        if (message.pending) {
          messageClass += " pending";
        }

        if (message.error) {
          messageClass += " hasError";
        }
        return messageClass;
      }

      function scrollToBottom(opts) {
        var element = document.getElementById("modal-convo-bottom"),
          options = {
            containerId: "modal-convo",
            duration: 800,
          };
        if (opts) {
          _.assign(options, opts);
        }
        smoothScroll(element, options);
      }

      function senderName(message) {
        return message.staff ? message.staff : message.sender;
      }

      function showSender(message) {
        return (
          message.direction === "outgoing" &&
          (message.staff || message.sender) &&
          (account.type !== "personal" ||
            (account.type === "personal" && message.senderId !== profile.id))
        );
      }

      function studentDisplayFirstName() {
        return studentService.getDisplayFirstName(student);
      }
    }
  );
