/**
 * @desc button for selecting a template
 * @example <field-picker></field-picker>
 */
angular.module("mongoose").directive("fieldPicker", fieldPicker);

function fieldPicker($rootScope, $timeout, Restangular) {
  return {
    link: linkFunc,
    templateUrl: "core/compose-toolbar/field-picker.html",
    restrict: "EA",
    scope: {
      message: "=",
      disabled: "=",
    },
  };

  function linkFunc(scope, el, attr) {
    var message = attr.ngModel;

    scope.fieldPicker = {
      isDisabled: false,
      isLoading: true,
      isOpen: false,
      select: select,
      fields: "",
      toggle: toggle,
    };

    scope.$watch("disabled", function (disabled) {
      scope.fieldPicker.isDisabled = disabled;
    });

    activate();

    function activate() {
      Restangular.all("customfields")
        .getList({ includeSystemFields: true })
        .then(function (fields) {
          var mergeFields = _.filter(fields, { canMailMerge: true });
          mergeFields.push({ id: -1, displayName: "User First Name" });
          mergeFields.push({ id: -2, displayName: "User Last Name" });

          scope.fieldPicker.isLoading = false;
          scope.fieldPicker.fields = mergeFields;
        });
    }

    function close() {
      $("body").unbind("click.popover");
      $timeout(function () {
        scope.fieldPicker.isOpen = false;
      });
    }

    function select(field) {
      close();

      var caret = $(el).parents(".insert-content-parent").find("textarea")[0].selectionStart,
        fieldTag = "<" + field.displayName.toUpperCase() + ">";

      if (!scope.message || scope.message.trim().length === 0) {
        scope.message = fieldTag;
      } else {
        var partOne = scope.message.substring(0, caret);
        if (partOne.length > 0 && partOne.charAt(partOne.length - 1) !== " ") {
          partOne += " ";
        }
        var partTwo = scope.message.substring(caret);
        if (partTwo.length > 0 && partOne.charAt(0) !== " ") {
          partTwo = " " + partTwo;
        }
        scope.message = partOne + fieldTag + partTwo;
      }

      $rootScope.$broadcast("fieldPicked", { field, fieldTag });
      mixpanel.track("Inserted Message Field");
    }

    function toggle() {
      if (scope.fieldPicker.isDisabled) return;

      $("body").trigger("click.popover");
      scope.fieldPicker.isOpen = !scope.fieldPicker.isOpen;
      if (scope.fieldPicker.isOpen) {
        $("body").bind("click.popover", function (e) {
          if ($(e.target).parents(".popover").length === 0 && !$(e.target).attr("uib-popover-template")) {
            close();
          }
        });
      }
    }
  }
}
