angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.marketplace", {
      abstract: true,
      url: "/marketplace",
      template: "<ui-view></ui-view>",
      pageTitle: "Marketplace",
    })
    .state("app.marketplace.list", {
      url: "",
      templateUrl: "marketplace/marketplace.html",
      controller: "MarketplaceController as vm",
      pageTitle: "Marketplace",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/marketplace");
        },
      },
    })
    .state("app.marketplace.detail", {
      url: "/:key",
      templateUrl: "marketplace/marketplace-detail.html",
      controller: "MarketplaceDetailController as vm",
      pageTitle: "Marketplace Detail",
    });
});
