
angular.module('mongoose').controller('RadiusAutomationLogController', function ($scope, config, Restangular, FileSaver, Blob) {

    var vm = this;
    vm.getErrors = getErrors;
    vm.getMessages = getMessages;

    activate();

    function activate() {
        Restangular.all("radius/automationLogs").getList().then(function(results) {

            vm.batches = results.map(function(i) {
                i.dateStarted = moment.tz(moment.utc(i.dateStarted), config.timeZone).format('M/D/YY, h:mma z');
                i.dateCompleted = !i.dateCompleted ? '' : moment.tz(moment.utc(i.dateCompleted), config.timeZone).format('M/D/YY, h:mma z');
                return i;
            }); 
        });
    };

     function getErrors(automationBatchId) {
        Restangular.one("radius/automationLogs", automationBatchId).getList("errors").then(function(errors) {
            var accumulator = 'Error,Case Entity Id, Case Subject, Send Time, Message Content, Primary Contact, Message Type Code, Template Code\n';
            errors.forEach(function(error,index){
                var dataString = [error.error, error.caseEntityId, error.caseSubject, error.sendTime, error.messageContent, error.primaryContact, error.messageTypeCode, error.templateCode].join(',');
                accumulator += index < errors.length ? dataString+ "\n" : dataString;
            })
            var data = new Blob([accumulator], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(data, 'automation-errors' + automationBatchId + '.csv');
        });
    };

    function getMessages(automationBatchId) {
        Restangular.one("radius/automationLogs", automationBatchId).getList("messages").then(function(messages) {
            var accumulator = 'Case Entity ID, Case Subject, Message Schedule Date, Student First Name, Student Last Name, Student Mobile Number, Student Id, Message Text\n';
            messages.forEach(function(message,index){
                var dataString = [message.CaseEntityId, message.CaseSubject, message.SendDate, message.FirstName, message.LastName, message.MobileNumber, message.StudentId, message.MessageText.replace(/(?:\r\n|\r|\n)/g, ' ') ].join(',');
                accumulator += index < messages.length ? dataString+ "\n" : dataString;
            })
            var data = new Blob([accumulator], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(data, 'automation-messages_' + automationBatchId + '.csv');
        });
    };
});
