angular
  .module("mongoose")
  .controller(
    "EditSmartMessagesController",
    function ($rootScope, $state, Restangular, growl, smartMessageService, $stateParams, profile, $scope) {
      var vm = this;
      messageClosingOptions = smartMessageService.closingOptions;

      vm.getMessageClosingLabel = getMessageClosingLabel;
      vm.isInvalid = isInvalid;
      vm.messageClosingOptions = messageClosingOptions;
      vm.questionType = null;
      vm.submit = submit;

      $scope.templatePicker = getTemplates;
      $scope.addTemplate = addTemplate;
      $scope.templates = [];
      $scope.popoverState = popoverState;

      activate();

      function activate() {
        Restangular.one("smartmessages", $stateParams.smartMessageId)
          .get()
          .then(function (result) {
            vm.smartMessage = result;
            if (vm.smartMessage.createdById !== profile.id) {
              $state.go("app.automation.smartmessages.list");
            }
          });
        getTemplates();
      }

      function getMessageClosingLabel() {
        if (_.isUndefined(vm.smartMessage)) return;
        return smartMessageService.getMessageClosingLabel(vm.smartMessage.messageClosing);
      }

      function isInvalid() {
        return _.isUndefined(vm.smartMessage) || _.isUndefined(vm.smartMessage.message)
          ? false
          : vm.smartMessage.message.toLowerCase().indexOf("reply") !== -1 ||
              vm.smartMessage.message.toLowerCase().indexOf("respond") !== -1;
      }

      function submit() {
        vm.smartMessage.save().then(
          function () {
            growl.success("Your Smart Message has been edited successfully!");
            window.location.replace("/#/v2/automation/smartmessages");
          },
          function (error) {
            growl.error(error && error.data ? error.data : "There was an issue editing your Smart Message.");
          }
        );
      }
      function getTemplates() {
        Restangular.all("templates")
          .getList()
          .then(function (templates) {
            $scope.templates = templates;
          });
      }

      function addTemplate(item) {
        vm.smartMessage.message = item.message;
        mixpanel.track("Inserted Message Template");
      }

      function popoverState(isPopoverOpen) {}
    }
  );
