angular.module("mongoose").controller("TagsListController", function ($state) {
  let vm = this;

  vm.fromDetail = $state.params.fromDetail;
  vm.viewTag = viewTag;

  function viewTag(data) {
    $state.go("app.tags.tagDetail", { tagId: data.tagId });
  }
});
