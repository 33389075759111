angular
  .module("mongoose")
  .controller(
    "ViewAutoSegmentController",
    function (
      $rootScope,
      Restangular,
      FileSaver,
      $state,
      $stateParams,
      $q,
      $uibModal,
      comparisons,
      profile,
      config
    ) {
      var vm = this;
      var segmentId = $stateParams.id;
      var baseFieldKeys = [
        "Contact ID",
        "First Name",
        "Last Name",
        "Mobile Number",
        "Assigned User",
        "Opted In",
      ];

      if (!segmentId) {
        $state.go("app.segments.list");
      }

      vm.archive = archive;
      vm.comparisons = comparisons;
      vm.conjunctions = ["all", "any"];
      vm.delete = deleteSegment;
      vm.export = exportSegment;
      vm.getFieldValue = getFieldValue;
      vm.sendMessage = sendMessage;
      vm.updateCriteria = updateCriteria;

      activate();

      function activate() {
        var requests = [
          Restangular.one("autoupdatesegments", segmentId).get(),
          Restangular.all("customfields").getList({
            includeSystemFields: true,
          }),
          Restangular.all("staff").getList(),
        ];

        return $q.all(requests).then(function (results) {
          var segment = results[0];
          segment.tagText = segment.name + " (Auto-Update)";
          segment.text = segment.tagText;
          segment.type = "Auto-Update";
          segment.conditions = JSON.parse(segment.conditions);

          vm.customFields = results[1].plain();
          vm.conditions = segment.conditions;
          vm.conjunction = segment.conjunction;
          vm.segment = segment;
          vm.staff = results[2].plain();

          if (vm.segment.createdBy !== profile.id) {
            var staff = _.find(vm.staff, { id: vm.segment.createdBy });
            vm.segment.createdByName = staff ? staff.displayName : "";
          }
        });
      }

      function archive(segment) {
        segment.archived = !segment.archived;
        Restangular.one("autoupdatesegments", segment.id)
          .customPUT(segment.archived, "archive")
          .then(function () {
            mixpanel.track(
              (segment.archived ? "Archived" : "Unarchived") +
                " Segment from Detail"
            );
            activate();
          });
      }

      function deleteSegment() {
        var modalInstance = $uibModal.open({
          controller: "DeleteSegmentModalController",
          controllerAs: "vm",
          templateUrl: "segments/delete-segment-modal.html",
          ariaLabelledBy: "delete-segment",
          resolve: {
            segment: function () {
              return vm.segment;
            },
          },
        });

        modalInstance.result.then(function () {
          $state.go("app.segments.list");
        });
      }

      function exportSegment() {
        Restangular.one("autoupdatesegments", vm.segment.id)
          .all("export")
          .getList()
          .then(function (ex) {
            var data = new Blob([ex], { type: "text/csv;charset=utf-8" });
            FileSaver.saveAs(data, "autosegment-" + vm.segment.id + ".csv");
          });
      }

      function formatDate(rawDate) {
        var date = moment.tz(moment.utc(rawDate), config.timeZone);
        return date.format("M/D/YYYY[,] h:mm A");
      }

      function getFieldValue(student, displayName) {
        var fieldKey = displayName;
        var customFieldObj;

        if (fieldKey === "Contact ID") {
          return student["StudentId"];
        }

        if (fieldKey === "Assigned User") {
          return student["AssignedUser"];
        }

        if (fieldKey === "Opted In") {
          return student["OptedIn"] ? "Yes" : "No";
        }

        customFieldObj = _.find(vm.customFields, { displayName: fieldKey });
        if (customFieldObj) {
          if (customFieldObj.isSystemField === true) {
            fieldKey = customFieldObj.searchField;
          }
          if (customFieldObj.isDateField === true) {
            return formatDate(student[fieldKey]);
          }
        }
        return student[fieldKey];
      }

      function preview() {
        vm.previewResults = {};

        var includeKeys = [],
          displayKeys = _.uniq(
            _.map(_.map(vm.segment.conditions, "field"), "displayName")
          ),
          includeIds = [];

        includeKeys = _.difference(displayKeys, baseFieldKeys);

        _.forEach(vm.customFields, function (n) {
          var index = _.indexOf(includeKeys, n.displayName);
          if (index !== -1) {
            includeIds.push(n.id);
          }
        });
        vm.previewResults.fields = baseFieldKeys.concat(includeKeys);

        var paramCriteria = _.map(vm.segment.conditions, function (c) {
            return {
              field: c.field.id,
              comparison: c.comparison.value,
              value: typeof c.value === "object" ? c.value.id : c.value,
            };
          }),
          proms = [],
          optInParams = {
            includeFields: includeIds,
            criteria: angular.copy(paramCriteria),
            conjunction: vm.segment.conjunction,
            optInValue: true,
          },
          optOutParams = {
            includeFields: includeIds,
            criteria: angular.copy(paramCriteria),
            conjunction: vm.segment.conjunction,
            optInValue: false,
          };

        var optInProm = Restangular.all("students")
          .customPOST(optInParams, "fieldsearch")
          .then(function (response) {
            vm.previewResults.optedInStudents = response.Students;
            vm.previewResults.optedInCount = response.Total;

            _.forEach(vm.previewResults.optedInStudents, function (student) {
              if (student.StaffId) {
                var assignedStaff = _.find(vm.staff, { id: student.StaffId });
                student.AssignedUser = !assignedStaff
                  ? ""
                  : assignedStaff.firstName + " " + assignedStaff.lastName;
              } else {
                student.AssignedUser = "";
              }
            });
          });

        var optOutProm = Restangular.all("students")
          .customPOST(optOutParams, "fieldsearch")
          .then(function (response) {
            vm.previewResults.optedOutStudents = response.Students;
            vm.previewResults.optedOutCount = response.Total;
          });

        proms.push(optInProm);
        proms.push(optOutProm);

        $q.all(proms);
      }

      function sendMessage(segments) {
        $rootScope.openComposeModal({ segments: segments });
        mixpanel.track("Clicked Message from Segment Detail");
      }

      function updateCriteria(val) {
        Object.assign(vm.segment, val);
        preview();
      }
    }
  );
