angular.module('mongoose').controller('EditCampaignModalController', function ($uibModalInstance, $state, campaignId, growl, Restangular) {

    if(!campaignId)
    {
        $uibModalInstance.dismiss();
    }

    var vm = this;

    vm.close = $uibModalInstance.dismiss;

    vm.edit = function () {
        vm.loading = true;
        vm.campaign.save().then(function () {
            growl.success('Your campaign was successfully updated.');
            $uibModalInstance.close();
        });
    };

    vm.viewReport = function(){
        $state.go('app.reports.campaign', {'id': campaignId});
        vm.close();
    };

    var load = function (){
        vm.loading = true;
        Restangular.one('campaigns', campaignId).get().then(function (campaign) {
            vm.loading = false;
            vm.campaign = campaign;
        });
    };
    load();
});