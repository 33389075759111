angular.module('mongoose').controller('EthosAddFieldController', function ($uibModalInstance, types) {

    var vm = this;

    vm.add = add;
    vm.dismiss = $uibModalInstance.dismiss;
    vm.field = {
        fieldType: 'custom',
        customField: {
            displayName: '',
            isRequired: true,
            canMailMerge: false,
            isVisible: true
        }
    };
    vm.fields = types.fields;
    vm.updateTypes = updateTypes;
    vm.optionText = "Select Ethos Field";


    function add() {
        if(vm.field.fieldType !== 'custom'){
            vm.field.type = vm.field.ethosFieldName;
        }
        $uibModalInstance.close(vm.field);
    }

    function updateTypes(){
        switch (vm.field.fieldType) {
            case 'custom':
                vm.fields = types.fields;
                vm.optionText = "Select Ethos Field"
                break;
            case 'email':
                vm.fields = types.personEmailTypes;
                vm.optionText = "Select Ethos Email Type"
                break;
            case 'phone':
                vm.fields = types.personPhoneTypes;
                vm.optionText = "Select Ethos Phone Type"
                break;
        }
    }
});