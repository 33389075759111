angular.
    module('mongoose')
    .component('phoneNumberPicker', {
        templateUrl: 'admin/department/users/phone-number-picker.html',
        controllerAs: "vm",
        bindings: {
            user: '=',
            invalid: '='
        },
        controller: function($rootScope, $scope, Restangular, $timeout) {
            var vm = this;

            vm.$onInit = activate;
            vm.$doCheck = validate;
            vm.lookupNumber = lookupNumber;
            vm.numberLookup = { areaCode: $rootScope.config.areaCode, prefix: '' };
            vm.user.phoneType = 'new';
            vm.verifyLandline = verifyLandline;

            $scope.$watch('vm.user.phoneType', togglePhoneType);


            function activate(){
                Restangular.all('twilio/reservednumbers').getList().then(function(response){
                    vm.reservedNumbers = response.plain();
                });
            }

            function validate() {
                $timeout(function(){
                    if (vm.loading)
                        return vm.invalid = true;
                    if (vm.user.phoneType === 'landline' && !vm.user.landlineOptions.phoneNumber)
                        return vm.invalid = true;
                    if (vm.user.phoneType === 'landline' && vm.user.landlineOptions.phoneNumber && !vm.landlineVerification)
                        return vm.invalid = true;
                    if (vm.user.phoneType !== 'landline' && !vm.user.virtualOptions.phoneNumber)
                        return vm.invalid = true;
                    return vm.invalid = false;
                });
            }

            function lookupNumber() {
                vm.isSearching = true;
                vm.user.virtualOptions.phoneNumber = '';

                Restangular.all('twilio').all('numberlookup').getList(vm.numberLookup, { 'x-disable-loading': 'true' }).then(function (n) {
                    vm.isSearching = false;
                    vm.availableNumbers = _.chunk(n.plain(), Math.ceil(n.length / 2));
                    vm.resultNumbers = {
                        hasNumbers: n.length > 0,
                        number: n.length,
                        areaCode: vm.numberLookup.areaCode
                    };
                });
            }

            function togglePhoneType(phoneType) {
                if (phoneType === 'landline') {
                    vm.user.virtualOptions = null;
                    vm.user.forwardingNumber = '';
                    vm.user.landlineOptions = {
                        phoneNumber: '',
                        numberSid: ''
                    };
                } else {
                    vm.user.landlineOptions = null;
                    vm.user.virtualOptions = {
                        isReserved: false,
                        numberSid: '',
                        phoneNumber: ''
                    };
                }
            }

            function verifyLandline() {
                vm.isVerifying = true;

                Restangular.all('twilio').one('verifylandline').get({ phonenumber: vm.user.landlineOptions.phoneNumber }, { 'x-disable-loading': 'true' }).then(function (result) {
                    vm.isVerifying = false;
                    vm.user.landlineOptions.numberSid = result.number.sid;
                    vm.landlineVerification = result.success;
                });
            }

        }
});
