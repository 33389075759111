angular
  .module("mongoose")
  .controller(
    "SegmentsListController",
    function (
      $rootScope,
      $scope,
      $filter,
      Restangular,
      $q,
      $timeout,
      config,
      profile,
      segmentsService,
      $uibModal,
      $state,
      FileSaver,
      $window
    ) {
      var vm = this,
        filter = $window.sessionStorage.getItem("segments-filters"),
        defaultFilter = {
          staff: profile.id,
          status: "active",
        };

      vm.filter = !_.isNull(filter) ? JSON.parse(filter) : defaultFilter;
      vm.gridOptions = {
        appScopeProvider: vm,
        paginationPageSize: 50,
        rowHeight: 60,
      };

      var load = function () {
          var archived = vm.filter.status === "archived",
            user = vm.filter.staff === "null" ? null : vm.filter.staff,
            segPromise = segmentsService.get("", archived, user),
            staffPromise = Restangular.all("staff").getList();
          return $q.all([segPromise, staffPromise]);
        },
        getStaffDisplayName = function (staffId) {
          var staff = _.find(vm.staff, { id: staffId });

          if (!staff) {
            return "Unknown";
          }

          if (staff.fullName) {
            return staff.fullName;
          } else if (staff.firstName && staff.lastName) {
            return staff.firstName + " " + staff.lastName;
          } else {
            return staff.emailAddress;
          }
        },
        search = function () {
          if (_.isUndefined(vm.filter.searchText)) return;
          $window.sessionStorage.setItem("segments-filters", JSON.stringify(vm.filter));
          vm.gridOptions.data = $filter("filter")(vm.segments, vm.filter.searchText);
        },
        setup = function (results) {
          $scope.$watch("vm.filter.searchText", search);

          vm.config = config;
          vm.profile = profile;
          vm.staff = results[1].plain();
          vm.segments = _.map(results[0], function (s) {
            s.ownedByCurrentUser = s.createdBy === profile.id;
            s.staffDisplayName = getStaffDisplayName(s.createdBy);
            return s;
          });
          vm.gridOptions.columnDefs = [
            {
              name: "Name",
              field: "name",
              cellTemplate:
                '<div class="ui-grid-cell-contents"><a href ng-click="grid.appScope.open(row.entity)" ng-bind="row.entity.name"></a></div>',
            },
            { field: "type", cellTemplate: "typeTemplate.html" },
            { name: "Owner", field: "staffDisplayName" },
            {
              name: "Created",
              field: "dateCreated",
              cellFilter: 'date : "M/dd/yy"',
            },
            { field: "lastUsed", cellFilter: 'date : "M/dd/yy"' },
            {
              name: "Actions",
              cellClass: "table-actions text-center",
              headerCellTemplate: '<div class="sr-only">Actions</div>',
              cellTemplate: "menuTemplate.html",
              width: 60,
            },
          ];
          vm.gridOptions.data = vm.segments;
          vm.loaded = true;
        },
        closePreview = function (item) {
          $("body").unbind("click.popover");
          $timeout(function () {
            item.showingPreview = false;
          });
        };

      vm.search = function () {
        $window.sessionStorage.setItem("segments-filters", JSON.stringify(vm.filter));
        load().then(setup);
      };

      vm.togglePreview = function (event, item) {
        event.stopPropagation();
        $("body").trigger("click.popover");
        item.showingPreview = !item.showingPreview;
        if (item.showingPreview) {
          $("body").bind("click.popover", function (e) {
            if ($(e.target).parents(".popover").length === 0 && !$(e.target).attr("popover-template")) {
              closePreview(item);
            }
          });
        }
      };

      vm.copy = function (segment) {
        var modalInstance = $uibModal.open({
          controller: "CopyStaticSegmentModalController",
          templateUrl: "segments/static/copy/copy-segment.static.html",
          ariaLabelledBy: "copy-segment",
          resolve: {
            template: function () {
              return segment;
            },
          },
        });

        modalInstance.result.then(function (newSegment) {
          $state.go("app.segments.view.static", { id: newSegment.id });
        });
      };

      vm.export = function (segment) {
        if (segment.type === "Static") {
          segment
            .all("students")
            .all("export")
            .getList()
            .then(function (ex) {
              var data = new Blob([ex], { type: "text/csv;charset=utf-8" });
              FileSaver.saveAs(data, "segment-" + segment.id + ".csv");
            });
        } else {
          Restangular.one("autoupdatesegments", segment.id)
            .all("export")
            .getList()
            .then(function (ex) {
              var data = new Blob([ex], { type: "text/csv;charset=utf-8" });
              FileSaver.saveAs(data, "autosegment-" + segment.id + ".csv");
            });
        }
      };

      vm.delete = function (segment) {
        var modalInstance = $uibModal.open({
          controller: "DeleteSegmentModalController",
          controllerAs: "vm",
          templateUrl: "segments/delete-segment-modal.html",
          ariaLabelledBy: "delete-segment",
          resolve: {
            segment: function () {
              return segment;
            },
          },
        });

        modalInstance.result.then(function () {
          load().then(setup);
        });
      };

      vm.open = function (segment) {
        var state = segment.type === "Static" ? "app.segments.view.static" : "app.segments.view.auto";

        $state.go(state, { id: segment.id });
      };

      vm.archive = function (segment) {
        var prom;
        segment.archived = !segment.archived;

        if (segment.type === "Auto-Update") {
          prom = Restangular.one("autoupdatesegments", segment.id).customPUT(segment.archived, "archive");
        } else {
          prom = segment.save();
        }

        prom.then(function () {
          mixpanel.track((segment.archived ? "Archived" : "Unarchived") + " Segment from List");
          load().then(setup);
        });
      };

      vm.newSegment = function () {
        if (
          (profile.currentDepartment.isAdministrator ||
            profile.isOwner ||
            profile.currentDepartment.usersCanBuildCriteriaBasedSegments) &&
          $rootScope.config.customFields.enabled
        ) {
          $state.go("app.segments.new.auto");
        } else {
          $state.go("app.segments.new.static");
        }
      };

      vm.sendMessage = function (segments) {
        $rootScope.openComposeModal({ segments: segments });
        mixpanel.track("Clicked Message from Segment List");
      };

      vm.toggleCriteria = function (e, segment) {
        e.stopPropagation();
        segment.visible = !segment.visible;
      };

      load().then(setup);
    }
  );
