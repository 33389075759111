angular.module("mongoose").component("conversationReply", {
  templateUrl: "messaging/conversation-reply.html",
  bindings: {
    account: "<",
    contactId: "<",
  },
  controllerAs: "vm",
  controller: function ($rootScope, $state, $scope, $q, growl, Restangular) {
    // Note: $scope.message is the only scoped variable not using 'vm'
    // as many child directives are still accessing it directly from `$scope`.

    var vm = this,
      account,
      contactId,
      messageTypes = [],
      isMac = navigator.appVersion.indexOf("Mac") !== -1,
      profile = $rootScope.profile,
      config = $rootScope.config;
    isReplying = _.debounce(isReplyingListener, 2000);

    vm.conversationLocked = conversationLocked;
    vm.conversationLockedByCurrentStaff = conversationLockedByCurrentStaff;
    vm.disabledReason = disabledReason;
    vm.disableReply = disableReply;
    vm.formInvalid = formInvalid;
    vm.hasContent = hasContent;
    vm.isBlocked = isBlocked;
    vm.isOptedOut = isOptedOut;
    vm.mergeInvalid = false;
    vm.invalidMergeTags = [];
    vm.validateMergeFields = validateMergeFields;
    vm.isStudentInDepartment = isStudentInDepartment;
    vm.messagePlaceholder = "";
    vm.sendMessage = sendMessage;
    vm.showReplyOverlay = showReplyOverlay;
    vm.showToolbar = vm.account.type !== "high-speed";
    vm.commandKeyLabel = isMac ? "Cmd" : "Ctrl";
    vm.lockConversation = lockConversation;
    vm.mmsDisabled = mmsDisabled;
    vm.smsAiEnabled = config.features["permanent-ai"];
    vm.toggleAiDialog = toggleAiDialog;
    vm.aiDialogOpen = false;
    vm.aiFadeTransition = false;

    $scope.templatePicker = getTemplates;
    $scope.addTemplate = addTemplate;
    $scope.templates = [];
    $scope.popoverState = popoverState;
    $scope.insertEmoji = insertEmoji;

    vm.$onChanges = watchContactId;

    $scope.message = {};
    initSendShortcut();

    if (window.eventBus) {
      window.eventBus.addEventListener("updateContact", updateContact);
      window.eventBus.addEventListener("resetUpdateContact", resetUpdateContact);
      window.eventBus.removeEventListener("legacy-close-sms-ai-dialog", handleAiDialogCancelClick);
      window.eventBus.addEventListener("legacy-close-sms-ai-dialog", handleAiDialogCancelClick);
      window.eventBus.removeEventListener("legacy-sms-ai-scroll", scrollToAiDialog);
      window.eventBus.addEventListener("legacy-sms-ai-scroll", scrollToAiDialog);
      window.eventBus.removeEventListener("legacy-sms-use-ai-reply", useAiReply);
      window.eventBus.addEventListener("legacy-sms-use-ai-reply", useAiReply);
    }
    $scope.$watch("message.text", validateMergeFields);
    $rootScope.$watch("lockedState.locked", resetAiDialog);

    activate();

    $scope.$on("fieldPicked", function (event, field) {
      let isInvalid = false;
      switch (field.field.displayName) {
        case "First Name":
          isInvalid = !vm.student.firstName;
          break;

        case "Last Name":
          isInvalid = !vm.student.lastName;
          break;

        case "Full Name":
          isInvalid = !vm.student.fullName;
          break;

        case "User First Name":
          isInvalid = !vm.profile.firstName;
          break;

        case "User Last Name":
          isInvalid = !vm.profile.lastName;
          break;

        default:
          isInvalid = !!!vm.student.additionalFields[field.field.displayName];
          break;
      }
      if (isInvalid) {
        vm.invalidMergeTags.push(field.fieldTag);
      }
    });

    function activate() {
      account = vm.account;
      contactId = vm.contactId;
      vm.messagePlaceholder = "Type a message" + (account.type !== "personal" ? " from " + account.name : "") + "...";

      if (account.type !== "personal") {
        var conversationReply = document.querySelector(".compose-input-form");
        var mediaPicker = document.querySelector(".compose-toolbar");

        conversationReply.addEventListener("click", isReplying);
        conversationReply.addEventListener("keyup", isReplying);
        mediaPicker.addEventListener("click", isReplying);
      }

      initMessage();
      getTemplates();
    }

    function conversationLocked() {
      vm.lockedForStaff = $rootScope.lockedState ? $rootScope.lockedState.lockedForStaff : "Another User";
      return (
        !!$rootScope.lockedState &&
        $rootScope.lockedState.locked &&
        $rootScope.lockedState.lockedForStaffId !== profile.id
      );
    }

    function conversationLockedByCurrentStaff() {
      if ($rootScope.lockedState && $rootScope.lockedState.locked) {
        return $rootScope.lockedState.lockedForStaffId === profile.id;
      }
      return false;
    }

    function disabledReason() {
      if (!vm.student) return false;
      if (isStudentInDepartment()) {
        if (isBlocked()) {
          return 'Contact is blocked<span class="d-none d-sm-inline"> from sending and receiving messages</span>';
        }
        if (isOptedOut()) {
          return 'Contact is opted-out<span class="d-none d-sm-inline"> of receiving messages</span>';
        }
        if (conversationLocked()) {
          return '<i class="fa fa-commenting-o"></i>&nbsp;' + vm.lockedForStaff + " is responding&hellip;";
        }
        if (vm.mergeInvalid) {
          return "Recipient is missing data for included merge field.";
        }
      } else {
        return "Contact is not in " + profile.currentDepartment.name;
      }
      return false;
    }

    function disableReply() {
      return isOptedOut() || isBlocked() || !isStudentInDepartment() || conversationLocked() || vm.mergeInvalid;
    }

    function formInvalid() {
      return disableReply() || !hasContent() || !vm.selectedMessageType;
    }

    function hasContent() {
      return ($scope.message.mediaUris && $scope.message.mediaUris.length > 0) || $scope.message.text.length > 0;
    }

    function getContact(disableLoading) {
      var deferred = $q.defer();

      Restangular.one("students", contactId)
        .get({}, { "x-disable-loading": !!disableLoading })
        .then(function (result) {
          vm.student = result;
          deferred.resolve(result);
        })
        .catch(function () {
          deferred.reject();
          return growl.error("Contact not found");
        });

      return deferred.promise;
    }

    function getMessageTypes() {
      return Restangular.all("messageTypes").getList({}, { "x-disable-loading": "true" });
    }

    function initMessage() {
      $scope.message = {
        autoArchive: false,
        text: "",
        fromCompose: false,
        mediaUris: [],
      };

      if (account.type === "group") {
        $scope.message.groupAccountId = account.id;
        $scope.message.recipients = [contactId];
      } else {
        $scope.message.id = contactId;
      }
    }

    function initMessageTypes() {
      var messageTypesPromise = messageTypes.length ? $q.when(messageTypes) : getMessageTypes();
      var student = vm.student;

      vm.messageTypes = [];

      messageTypesPromise.then(setup);

      function setup(types) {
        messageTypes = types;

        if (profile.departments.length === 1) {
          vm.messageTypes = types.map(function (t) {
            return _.omit(t, "department");
          });
        } else {
          var groupedTypes = _.groupBy(types, function (t) {
              return t.department;
            }),
            studentDepartments = student.departments.map(function (m) {
              return m.name;
            });

          groupedTypes = _.pick(groupedTypes, _.intersection(studentDepartments, _.keys(groupedTypes)));
          var departmentNames = _.keys(groupedTypes);

          if (departmentNames.length > 1) {
            var allDepartmentsHaveOneType = _.every(departmentNames, function (key) {
              return groupedTypes[key].length === 1;
            });

            if (allDepartmentsHaveOneType) {
              vm.messageTypes = _.map(departmentNames, function (department) {
                var messageType = groupedTypes[department][0];
                return {
                  id: messageType.id,
                  description: department + " related",
                };
              });
            } else {
              _.forEach(departmentNames, function (department) {
                _.forEach(groupedTypes[department], function (messageType) {
                  vm.messageTypes.push({
                    id: messageType.id,
                    department: department,
                    description: messageType.department + " - " + messageType.description,
                  });
                });
              });
            }
          } else {
            vm.messageTypes = _.map(groupedTypes[departmentNames[0]], function (messageType) {
              return {
                id: messageType.id,
                description: messageType.description,
              };
            });
          }
        }

        if (vm.messageTypes.length === 1) {
          vm.selectedMessageType = vm.messageTypes[0];
        }
      }
    }

    function initSendShortcut() {
      var replyTextarea = document.getElementById("messageText");

      replyTextarea.addEventListener("keydown", function (event) {
        var sendShortcut = isMac ? event.metaKey && event.key === "Enter" : event.ctrlKey && event.key === "Enter";

        if (formInvalid() || !sendShortcut) return;

        document.getElementById("messageText").blur();
        sendMessage(true);
      });
    }

    function isBlocked() {
      if (!vm.student || !vm.student.departments) return false;

      var studentDepartment = _.find(vm.student.departments, {
        id: profile.currentDepartment.id,
      });

      return studentDepartment ? studentDepartment.muted : false;
    }

    function isOptedOut() {
      if (!vm.student || !vm.student.departments) return false;

      var studentDepartment = _.find(vm.student.departments, {
        id: profile.currentDepartment.id,
      });

      return studentDepartment ? studentDepartment.optedOut : false;
    }

    function mmsDisabled() {
      return config.plan !== "Legacy" && config.bulkMmsMethod === "Disabled";
    }

    function isReplyingListener() {
      window.eventBus.dispatchEvent("isReplying");
    }

    function isStudentInDepartment() {
      if (!vm.student || !vm.student.departments) return false;

      return _.find(vm.student.departments, {
        id: profile.currentDepartment.id,
      });
    }

    function lockConversation() {
      window.eventBus.dispatchEvent("lockConversation");
      document.getElementById("messageText").focus();

      return;
    }

    function showReplyOverlay() {
      return vm.account.type !== "personal" && !vm.conversationLockedByCurrentStaff();
    }

    function sendMessage(usedShortcut) {
      var request;

      $scope.message.messageTypeId = vm.selectedMessageType.id;

      if (account.type === "personal") {
        request = Restangular.one("students", $state.params.id).post("messages", $scope.message);
      } else if (account.type === "group") {
        request = Restangular.one("groupaccounts", account.id).post("send", $scope.message);
      }

      request.then(
        function () {
          mixpanel.track("Replied to Student", {
            MMS: $scope.message.mediaUris.length > 0,
            "Submit Method": usedShortcut ? "Shortcut" : "Button",
          });
          initMessage();
          initMessageTypes();
          document.getElementById("messageText").focus();
          $scope.mediaPicker.reset();
          growl.success("Your message is on its way and will appear in the conversation shortly!");
        },
        function (error) {
          var errorMessage =
            "There was an issue sending your message!  You may try again - also please verify is it under 320 characters, and was not copied from Microsoft Word.";
          if (error && error.data) {
            errorMessage = error.data;
          }
          growl.error(errorMessage);
        }
      );
    }

    function resetUpdateContact() {
      window.eventBus.removeEventListener("updateContact", updateContact);
    }

    function updateContact() {
      return getContact(true);
    }

    function watchContactId(change) {
      if (change.contactId && change.contactId.currentValue) {
        var currentValue = change.contactId.currentValue;
        if (currentValue !== contactId) {
          contactId = currentValue;
          resetAiDialog();
          getContact().then(function () {
            initMessage();
            initMessageTypes();
          });
        }
      }
    }
    function getTemplates() {
      Restangular.all("templates")
        .getList()
        .then(function (templates) {
          $scope.templates = templates;
        });
    }

    function addTemplate(item) {
      $scope.message.text = item.message;
      mixpanel.track("Inserted Message Template");
    }

    function popoverState(isPopoverOpen) {
      isPopoverOpen = !isPopoverOpen;
    }

    function insertEmoji(emoji) {
      const replyTextarea = document.getElementById("messageText");
      const caret = replyTextarea.selectionStart;
      if (!$scope.message.text || $scope.message.text.trim().length === 0) {
        $scope.message.text = emoji.unicode;
      } else {
        var partOne = $scope.message.text.substring(0, caret);
        if (partOne.length > 0 && partOne.charAt(partOne.length - 1) !== " ") {
          partOne += " ";
        }
        var partTwo = $scope.message.text.substring(caret);
        if (partTwo.length > 0 && partOne.charAt(0) !== " ") {
          partTwo = " " + partTwo;
        }
        $scope.message.text = partOne + emoji.unicode + partTwo;
      }
      mixpanel.track("Inserted Emoji", {
        Emoji: emoji.unicode,
      });
    }

    function validateMergeFields() {
      vm.mergeInvalid = false;
      vm.invalidMergeTags.forEach(function (tag) {
        if ($scope.message.text.indexOf(tag) >= 0) {
          vm.mergeInvalid = true;
        }
      });
    }

    function toggleAiDialog() {
      window.eventBus.dispatchEvent("cadence-ai-toggle-sms-ai-dialog", {
        contactId,
        groupAccountId: _.get(account, "id"),
      });
      vm.aiDialogOpen = !vm.aiDialogOpen;
    }

    function handleAiDialogCancelClick() {
      // this only gets triggered by the close icon in the dialog, so it doesn't need an event
      vm.aiDialogOpen = false;
      $scope.$apply(); // ultimate sin
    }

    function scrollToAiDialog() {
      if (vm.aiDialogOpen) {
        let aiDialog = document.getElementById("cadence-ai-container");
        aiDialog.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }

    function useAiReply(event) {
      const replyText = event.detail.replyText;
      $scope.message.text = replyText;
    }

    function resetAiDialog() {
      vm.aiDialogOpen = false;
      window.eventBus.dispatchEvent("cadence-ai-close-sms-ai-dialog");
    }
  },
});
