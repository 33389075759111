angular
  .module("mongoose")
  .controller(
    "NewCampaignModalController",
    function ($uibModalInstance, Restangular, growl) {
      var vm = this;
      vm.campaign = {
        name: "",
        description: "",
        active: true,
      };

      vm.close = $uibModalInstance.dismiss;

      vm.create = function () {
        vm.loading = true;

        Restangular.all("campaigns")
          .post(vm.campaign)
          .then(
            function (campaign) {
              if (campaign) {
                growl.success("Your campaign was created successfully!");
                if (window.eventBus) {
                  window.eventBus.dispatchEvent(
                    "campaignCreated",
                    campaign.plain()
                  );
                }
                mixpanel.track("Created Campaign", {
                  "Campaign Status": vm.campaign.active ? "Active" : "Inactive",
                  "Created Source": "Campaigns",
                });
                $uibModalInstance.close();
              }
            },
            function (response) {
              vm.validationMessage = response.data;
            }
          );
      };
    }
  );
