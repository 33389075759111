angular.module("mongoose").component("campaigns", {
  templateUrl: "dashboard/campaign-metrics.html",
  controllerAs: "vm",
  bindings: {
    profile: "<",
  },
  controller: function CampaignsController($scope, Restangular, uiGridConstants, $window, $rootScope) {
    var vm = this,
      sortDefault = {
        sortBy: "reached",
        sortOrder: "desc",
      },
      selectedSort = JSON.parse($window.localStorage.getItem("dashboard-campaign-sort")),
      baseSort = !_.isNull(selectedSort) ? selectedSort : sortDefault;
    isAdmin = vm.profile.currentDepartment.isAdministrator;
    hasPersonalMessaging = $rootScope.config.isEnabled("personal-messaging");

    vm.getFilters = activate;
    vm.showGrid = showGrid;
    vm.isAdmin = isAdmin;
    vm.hasPersonalMessaging = hasPersonalMessaging;

    function activate(filters) {
      vm.timeSelect = filters.time;
      vm.userSelect = filters.groupId;
      vm.groupName = filters.groupName;
      setGridOptions();
      getCampaignData(baseSort);

      if (isAdmin) {
        vm.altHeading = "Team Top Campaigns";
      } else {
        vm.altHeading = "Top Campaigns";
      }
    }

    function setGridOptions() {
      vm.gridOptions = {
        appScopeProvider: vm,
        rowHeight: 30,
        useExternalSorting: true,
        columnDefs: [
          {
            name: "Campaign",
            field: "name",
            cellTemplate:
              '<div class="ui-grid-cell-contents"><a href="/#/v2/reports/campaigns/{{row.entity.id}}" ng-bind="row.entity.name"></a></div>',
            enableSorting: false,
          },
          {
            name: "Reached",
            field: "reached",
            headerCellClass: "text-right",
            cellClass: "text-right",
            sortDirectionCycle: [uiGridConstants.DESC, uiGridConstants.ASC],
            sort: {
              direction: baseSort.sortBy === "reached" ? baseSort.sortOrder : false,
            },
          },
          {
            name: "Replied",
            field: "replied",
            headerCellClass: "text-right",
            cellClass: "text-right",
            sortDirectionCycle: [uiGridConstants.DESC, uiGridConstants.ASC],
            sort: {
              direction: baseSort.sortBy === "replied" ? baseSort.sortOrder : false,
            },
          },
          {
            name: "Replies",
            field: "replies",
            headerCellClass: "text-right",
            cellClass: "text-right",
            sortDirectionCycle: [uiGridConstants.DESC, uiGridConstants.ASC],
            sort: {
              direction: baseSort.sortBy === "replies" ? baseSort.sortOrder : false,
            },
          },
          {
            name: "Reply Rate",
            field: "replyRate",
            headerCellClass: "text-right",
            cellClass: "text-right",
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD * 100 | number: 0}}%</div>',
            sortDirectionCycle: [uiGridConstants.DESC, uiGridConstants.ASC],
            sort: {
              direction: baseSort.sortBy === "replyRate" ? baseSort.sortOrder : false,
            },
          },
        ],
        onRegisterApi: function (gridApi) {
          gridApi.core.on.sortChanged($scope, function (grid, column) {
            sortChanged(column);
          });
        },
      };
    }

    function getCampaignData(sort) {
      vm.loaded = false;

      var userType = vm.userSelect == "admin" ? "departments" : "staff",
        userId = vm.userSelect == "admin" ? vm.profile.currentDepartment.id : vm.profile.id,
        time = vm.timeSelect;

      var params = {
        sortBy: sort.sortBy,
        time: time,
        sortOrder: sort.sortOrder,
      };

      return Restangular.one(userType, userId)
        .all("dashboard")
        .get("campaigns", params)
        .then(function (response) {
          vm.gridOptions.data = response.plain();
          vm.loaded = true;
        });
    }

    function showGrid() {
      return vm.gridOptions && vm.gridOptions.data && vm.gridOptions.data.length > 0;
    }

    function sortChanged(column) {
      var newSort = {
        sortBy: column[0].colDef.field,
        sortOrder: column[0].sort.direction,
      };
      $window.localStorage.setItem("dashboard-campaign-sort", JSON.stringify(newSort));
      getCampaignData(newSort);
    }
  },
});
