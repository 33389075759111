
angular.module('mongoose').controller('NotificationsController', function ($scope, $state, $q, growl, Restangular) {

    var initialize = function () {

        $scope.optOutNotification = {
            event: 'optOut',
            isActive: false,
            isValid: true,
            notificationEmails : []
        };

        $scope.unknownStudentNotification = {
            event: 'unknownStudentMessage',
            isActive: false,
            isValid: true,
            notificationEmails : []
        };

        $scope.newStudentNotification = {
            event: 'newStudentAdded',
            isActive: false,
            isValid: true,
            notificationEmails : []
        };

        var requests = [
            Restangular.one("notifications").get({type: 'OptOut'}),
            Restangular.one("notifications").get({type: 'UnknownStudentMessage'}),
            Restangular.one("notifications").get({type: 'NewStudentAdded'})
        ];

        $q.all(requests).then(function(results){
            var optOutNotification = results[0];
            var unknownStudentNotification = results[1];
            var newStudentNotification = results[2];

            if (optOutNotification) {
                $scope.optOutNotification.recipients = optOutNotification.notificationEmails.map(function(email) { return email.emailAddress; }).join('\n');
                $scope.optOutNotification.isActive = optOutNotification.isActive;
            }

            if (unknownStudentNotification) {
                $scope.unknownStudentNotification.recipients = unknownStudentNotification.notificationEmails.map(function(email) { return email.emailAddress; }).join('\n');
                $scope.unknownStudentNotification.isActive = unknownStudentNotification.isActive;
            }

            if (newStudentNotification) {
                $scope.newStudentNotification.recipients = newStudentNotification.notificationEmails.map(function(email) { return email.emailAddress; }).join('\n');
                $scope.newStudentNotification.isActive = newStudentNotification.isActive;
            }
        });
    },
    updateNotification = function(notification) {
        if (notification.isActive && (!notification.recipients || notification.recipients === '')) {
            notification.isValid = false;
            return $q.reject();
        }
        else {
            notification.isValid = true;
            if (notification.recipients)
                notification.notificationEmails = notification.recipients.replace(/[\s\,]/g, "|").split('|');
            return Restangular.all("notifications").post(notification).then();
        }
    };

    $scope.cancel = function() {
        initialize();
    };

    $scope.update = function () {
        var requests = [
            updateNotification($scope.optOutNotification),
            updateNotification($scope.unknownStudentNotification),
            updateNotification($scope.newStudentNotification)
        ];

        $q.all(requests).then(function(){
           growl.success('Your notifications have been updated!');
           initialize();
        });

    };

    initialize();
});