angular.module('mongoose').factory('slateService', function($q, Restangular) {
    
    function getConfiguration() {
        var deferred = $q.defer();
        Restangular.one("slate/configuration").get().then(function(config) {
            deferred.resolve(config.plain());
        }, function(response) {
            deferred.reject(response.data);
        });
        return deferred.promise;
    }

    function saveConfiguration(config) {
        var deferred = $q.defer();
         Restangular.all("slate/configuration").post({slateConfiguration: config}).then(function() {
            deferred.resolve(config);
        }, function(response) {
            deferred.reject(response.data);
        });
        return deferred.promise;
    }

    function timeToLocalDate(utcTime, config) {
        if (!utcTime) return null;
        return moment.tz(moment.utc(utcTime, 'HH:mm'), config.timeZone).toISOString();
    }

    function localDateToUtcTimespan(localTime) {
        if (!localTime) return null;
        return moment.utc(localTime).format('HH:mm');
    }

    return {
        getConfiguration : getConfiguration,
        saveConfiguration : saveConfiguration,
        timeToLocalDate: timeToLocalDate,
        localDateToUtcTimespan: localDateToUtcTimespan
    };
});
