

angular.module('mongoose').controller('SentHighSpeedController', function ($state) {

        var vm = this;

        vm.selectedBatch = selectedBatch;

        function selectedBatch(batchId) {
            $state.go('app.highspeed.sent-detail', {id: batchId});
        }
});