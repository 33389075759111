angular.module('mongoose').controller('ConfirmSegmentModalController', function (Restangular, $uibModalInstance, validationResults, action, FileSaver, Blob) {

    var vm = this;

    vm.validationResults = validationResults;
    vm.action = action;

    vm.close = function() {
        $uibModalInstance.close();
    };

    vm.export = function() {
       var accumulator = 'Identifier, Error\n';
        vm.validationResults.forEach(function(error,index){
            dataString = [error.identifier, error.error].join(',');
            accumulator += index < vm.validationResults.length ? dataString + "\n" : dataString;
        });

        var data = new Blob([accumulator], { type: 'text/csv;charset=utf-8' });
        FileSaver.saveAs(data, 'segment-errors.csv');
    }
 
});