angular
  .module("mongoose")
  .controller(
    "ViewUserController",
    function ($rootScope, $uibModalInstance, $q, Restangular, userId, config, profile, $uibModal, departmentUsers) {
      var vm = this;

      vm.close = $uibModalInstance.dismiss;
      vm.newUser = newUser;
      vm.profile = profile;

      activate();

      function activate() {
        vm.loading = true;

        var requests = [Restangular.one("admin/users", userId).get()];

        $q.all(requests).then(function (results) {
          vm.user = results[0].plain();

          var userIds = _.map(departmentUsers, "id");
          vm.userExistsInDepartment = _.includes(userIds, vm.user.id);

          vm.loading = false;
        });
      }

      function newUser(user) {
        $uibModalInstance.close();

        $uibModal
          .open({
            templateUrl: "admin/department/users/invite-user.html",
            controller: "InviteUserController",
            controllerAs: "vm",
            backdrop: "static",
            resolve: {
              allUsers: function () {
                return Restangular.all("admin/users").getList();
              },
              departmentUsers: function () {
                return Restangular.all("admin/users").getList({
                  currentDepartmentOnly: true,
                });
              },
              profile: function () {
                return profile;
              },
              config: function () {
                return config;
              },
              user: function () {
                return user;
              },
            },
          })
          .result.then(function () {
            $rootScope.$broadcast("organizationActivate");
          });
      }
    }
  );
