angular
  .module("mongoose")
  .factory("studentService", function ($q, Restangular, firebaseService) {
    function add(student) {
      var deferred = $q.defer();
      Restangular.one("students/create")
        .customPOST(student, "")
        .then(
          function (response) {
            deferred.resolve(response);
          },
          function (response) {
            deferred.reject(response.data);
          }
        );

      return deferred.promise;
    }

    function get(id) {
      return Restangular.one("students", id).get();
    }

    function getDisplayName(student) {
      if (!student) return;
      return student.firstName
        ? student.firstName + " " + student.lastName
        : student.mobileNumberFormatted;
    }

    function getDisplayFirstName(student) {
      if (!student) return;
      return student.firstName
        ? student.firstName
        : student.mobileNumberFormatted;
    }

    function getMessages(account, profile, contactId) {
      var deferred = $q.defer(),
        collectionRef =
          firebaseService.getInboxRef(account, profile) + "/conversations",
        conversationId,
        departmentId = profile.currentDepartment.id,
        firestore = firebase.firestore(),
        query = firestore.collection(collectionRef);
      query = query.where("contactId", "==", contactId).limit(1);

      if (account.type === "personal") {
        query = query.where("departmentId", "==", departmentId);
      }

      query.get().then(function (conversationSnapshot) {
        conversationSnapshot.forEach(function (doc) {
          conversationId = doc.id;
        });

        firestore
          .collection(collectionRef + "/" + conversationId + "/messages")
          .orderBy("date")
          .get()
          .then(function (querySnapshot) {
            var collection = [];
            querySnapshot.forEach(function (doc) {
              var item = doc.data();
              collection.push(item);
            });
            deferred.resolve(collection);
          });
      });

      return deferred.promise;
    }

    return {
      add: add,
      get: get,
      getDisplayName: getDisplayName,
      getDisplayFirstName: getDisplayFirstName,
      getMessages: getMessages,
    };
  });
