angular
  .module("mongoose")
  .controller(
    "ProfileNotificationsController",
    function ($q, $rootScope, $scope, $window, $state, $filter, profile, Restangular, growl, config) {
      var initialize = function () {
        $scope.profile = _.cloneDeep(profile);
        // eslint-disable-next-line no-prototype-builtins
        const notificationsSupported = window.hasOwnProperty("Notification");
        const notificationsPermission = notificationsSupported ? Notification.permission : "unsupported";
        const notificationsEnabled = notificationsPermission === "granted";

        $scope.notifications = {
          supported: notificationsSupported,
          permission: notificationsPermission,
          enabled: notificationsEnabled,
          receiveFollowUpsEmail: profile.chatNotifications ? profile.chatNotifications.receiveFollowUpsEmail : false,
        };

        $scope.showTextNotificationSection = profile.currentDepartment.userHasTexting && profile.inboxes.length > 0;
      };

      $scope.enableNotifications = function () {
        const scope = $scope;

        Notification.requestPermission().then(function (permission) {
          scope.notifications.permission = permission;
          scope.notifications.enabled = permission === "granted";
          scope.$apply();
        });
      };

      $scope.update = function () {
        var groupNotificationSettings = {
            groupAccounts: _.map($scope.profile.groupAccounts, function (group) {
              return {
                groupAccountId: group.id,
                receiveEmail: group.receiveEmail,
                receivePushNotification: group.receivePushNotification,
              };
            }),
          },
          profilePromise = Restangular.all("profile").customPOST($scope.profile, "update"),
          groupPromise = Restangular.all("profile").customPOST(groupNotificationSettings, "notifications");

        const chatNotificationSettings = {
          ReceiveFollowUpsEmail: $scope.notifications.receiveFollowUpsEmail,
        };

        const chatNotificationPromise = Restangular.all("profile").customPOST(
          chatNotificationSettings,
          "chat-notifications"
        );

        const promises = [profilePromise, groupPromise, chatNotificationPromise];

        $q.all(promises).then(
          function () {
            $rootScope.$broadcast("profileChanged");
          },
          function (response) {
            if (response.status === 400) {
              $scope.validationMessage = response.data;
            }
          }
        );
      };

      initialize();
    }
  );
