angular.module('mongoose').controller('ForgotPasswordController', function ($scope, $http, $window, $rootScope, Restangular) {
    var initialize = function () {
            $scope.credentials = { emailAddress: ''};
            $scope.validationMessage = '';
        },

    validate = function () {
        $scope.validationMessage = '';
        if (!($scope.credentials.emailAddress)) {
            $scope.validationMessage = 'E-mail address is required.';
        }
        return !$scope.validationMessage;
    };

    $scope.submit = function() {
        if (validate()) {
            $scope.loading = true;
            Restangular.all('auth').customPOST({ emailAddress: $scope.credentials.emailAddress},'forgotpassword')
            .then(function () {
                $scope.loading = false;
                $window.location.href = '#/forgot-password-complete';
            },function () {
                initialize();
                $scope.validationMessage = 'Please verify e-email address is correct.';
                $scope.loading = false;
            });
        }
    };

    initialize();
});

angular.module('mongoose').controller('ForgotPasswordCompleteController', function ($rootScope) {
});