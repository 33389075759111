/**
 * @desc button for selecting a template
 * @example <campaign-dom></campaign-dom>
 */
angular
    .module('mongoose')
    .directive('campaignDom', campaignDom);

function campaignDom($timeout) {

    return {
        link: linkFunc,
        restrict: 'A'
    };

    function linkFunc(scope, element) {
        $timeout(function(){
            var input = $('#campaign').find('input'),
                loader = $('#campaign').find('activity-dots');
                addButton = $('#addCampaignButton');
            $timeout(function() {
                loader.insertAfter(input);
                addButton.insertAfter(input);
            });
        });
    }
}