angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.integrations.ethos", {
      url: "/ethos",
      abstract: true,
      template: "<ui-view></ui-view>",
      controller: "EthosController as ethosScope",
      resolve: {
        ethosConfig: [
          "ethosService",
          function (ethosService) {
            return ethosService.config();
          },
        ],
      },
      pageTitle: "Ethos Integration",
    })
    .state("app.integrations.ethos.main", {
      abstract: true,
      templateUrl: "integrations/ethos/ethos.html",
      pageTitle: "Ethos Integration",
    })
    .state("app.integrations.ethos.main.dashboard", {
      url: "/dashboard",
      templateUrl: "integrations/ethos/ethos-dashboard.html",
      controller: "EthosDashboardController as vm",
      pageTitle: "Ethos Integration Dashboard",
    })

    .state("app.integrations.ethos.main.imports", {
      url: "/imports",
      templateUrl: "integrations/ethos/ethos-import.html",
      controller: "EthosImportController as vm",
      pageTitle: "Ethos Import",
    })
    .state("app.integrations.ethos.importSettings", {
      url: "/import-settings",
      templateUrl: "integrations/ethos/ethos-import-settings.html",
      controller: "EthosImportSettingsController as vm",
      pageTitle: "Ethos Import Settings",
    })
    .state("app.integrations.ethos.manual", {
      abstract: true,
      templateUrl: "integrations/ethos/ethos-manual-template.html",
      pageTitle: "Ethos Manual Template",
    });
});
