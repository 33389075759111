angular
  .module("mongoose")
  .controller(
    "OrganizationUsersController",
    function ($rootScope, $scope, Restangular, $q, config, profile, $uibModal, viewportService, $window) {
      var vm = this,
        defaultFilter = {
          role: "none",
          team: "none",
        },
        filter = !_.isNull($window.sessionStorage.getItem("users-filters"))
          ? JSON.parse($window.sessionStorage.getItem("users-filters"))
          : defaultFilter,
        requestParams = {
          excludedfromteams: [],
          filterby: "none",
          includedinteams: filteredTeam(),
          loadvirtualnumbers: true,
          orderby: "name",
          skip: 0,
          take: 50,
          searchtext: "",
          sortorder: "asc",
        };

      vm.clearSearch = clearSearch;
      vm.config = config;
      vm.filter = filter;
      vm.filteredTeam = filteredTeam;
      vm.gravatar = gravatar;
      vm.gridOptions = {
        appScopeProvider: vm,
        paginationPageSize: requestParams.take,
        rowHeight: 80,
        useExternalPagination: true,
        useExternalSorting: true,
        onRegisterApi: function (gridApi) {
          gridApi.pagination.on.paginationChanged($scope, onPaginate);
          gridApi.core.on.sortChanged($scope, onSort);
          goToPage1 = function () {
            gridApi.pagination.seek(1);
          };
          scrollToTop = function () {
            gridApi.core.scrollTo(vm.gridOptions.data[0]);
          };
        },
      };
      vm.inTeam = inTeam;
      vm.load = load;
      vm.newUser = newUser;
      vm.onFilter = onFilter;
      vm.profile = profile;
      vm.setup = setup;
      vm.search = search;
      vm.searched = false;
      vm.teamList = teamList;
      vm.viewUser = viewUser;

      $rootScope.$on("organizationActivate", activate);

      activate();

      function activate() {
        return load().then(setup);
      }

      function load() {
        var requests = [
          Restangular.all("admin/users/query").post(requestParams),
          Restangular.all("departments/all").getList(),
        ];

        $window.sessionStorage.setItem("users-filters", JSON.stringify(vm.filter));

        return $q.all(requests);
      }

      function setup(results) {
        var usersResponse = results[0];

        vm.teams = results[1].plain();
        var users = _.map(usersResponse.results, function (user) {
          user.gravatarLetter = user.firstName.slice(0, 1);
          user.gravatar = gravatar(user.emailAddress);
          user.inTeam = inTeam(user.departments);
          user.teamList = teamList(user.departments);

          return user;
        });

        vm.gridOptions.columnDefs = [
          {
            name: "Name",
            field: "name",
            cellTemplate: "nameTemplate.html",
            sort: {
              direction: requestParams.sortorder,
            },
            minWidth: 235,
          },
          {
            name: "Teams",
            field: "userteams",
            cellTemplate: "teamsTemplate.html",
            enableSorting: false,
            visible: viewportService.isSize("gt-sm"),
          },
          {
            name: "Actions",
            cellClass: "table-actions text-center",
            headerCellTemplate: '<div class="sr-only">Actions</div>',
            cellTemplate: "menuTemplate.html",
            enableSorting: false,
            width: 105,
          },
        ];

        vm.gridOptions.data = users;
        vm.gridOptions.totalItems = usersResponse.meta.total;
        vm.loaded = true;
      }

      function clearSearch() {
        vm.searchText = "";
        vm.searched = false;
      }

      function filteredTeam() {
        return filter.team === "none" ? [] : [filter.team];
      }

      function gravatar(email) {
        return "https://www.gravatar.com/avatar/" + md5(email) + "?r=pg&d=blank";
      }

      function inTeam(team) {
        var teams = _.map(team, "departmentName").sort();

        if (teams.includes(profile.currentDepartment.name)) {
          return true;
        }
      }

      function newUser(user) {
        $uibModal
          .open(
            {
              templateUrl: "admin/department/users/invite-user.html",
              controller: "InviteUserController",
              controllerAs: "vm",
              backdrop: "static",
              resolve: {
                profile: function () {
                  return profile;
                },
                config: function () {
                  return config;
                },
                user: function () {
                  return user;
                },
              },
            },
            mixpanel.track("Clicked Invite from Org Tab User Grid")
          )
          .result.then(function () {
            activate();
          });
      }

      function search() {
        requestParams.searchtext = vm.searchText;
        if (vm.searchText) {
          vm.searched = true;
        }
        goToPage1();
        activate();
      }

      function onFilter() {
        if (vm.filter.team !== "none") {
          mixpanel.track("Filtered Org Tab User Grid by Team");
        }
        requestParams.includedinteams = vm.filter.team === "none" ? [] : [vm.filter.team];
        goToPage1();
        activate();
      }

      function onPaginate(newPage) {
        requestParams.skip = newPage * requestParams.take - requestParams.take;
        scrollToTop();
        activate();
      }

      function onSort(grid, sortColumns) {
        requestParams.sortorder = sortColumns.length === 0 ? "asc" : sortColumns[0].sort.direction;
        requestParams.orderby = sortColumns.length === 0 ? "name" : sortColumns[0].field;

        activate();
      }

      function teamList(team) {
        var teams = _.map(team, "departmentName").sort();
        var teamsMax = 50;
        var teamsArr = [];

        for (var i = 0; i < teams.length; i++) {
          if (teamsMax > 0 && teams[i].length <= teamsMax) {
            teamsArr.push(teams[i]);
            teamsMax -= teams[i].length;
          } else {
            var teamsLeft = teams.length - teamsArr.length;
            var teamsLeftMessage = " & " + teamsLeft + " more...";
          }
        }

        return teamsLeft ? teamsArr.join(", ") + teamsLeftMessage : teamsArr.join(", ");
      }

      function viewUser(userId) {
        $uibModal
          .open({
            templateUrl: "admin/department/users/view-user.html",
            controller: "ViewUserController",
            controllerAs: "vm",
            resolve: {
              userId: function () {
                return userId;
              },
              config: function () {
                return config;
              },
              departmentUsers: function () {
                return Restangular.all("admin/users").getList({
                  currentDepartmentOnly: true,
                });
              },
              profile: function () {
                return profile;
              },
            },
          })
          .result.then(function () {
            activate();
          });
      }
    }
  );
