(function() {
    'use strict';

    angular.module('mongoose').controller('DeleteStudentsModalController', DeleteStudentsModalController);

    function DeleteStudentsModalController($uibModalInstance, Restangular, growl) {
        var vm = this;

        vm.dismiss = $uibModalInstance.dismiss;

        vm.submit = function() {
            $uibModalInstance.close();
        };
    }
})();