angular.module('mongoose').config(function ($stateProvider) {

    $stateProvider
        .state('app.reports', {
            url: '/reports',
            abstract: true,
            template: '<ui-view/>',
            pageTitle: "Reports"
        })
        .state('app.reports.view', {
            url: '',
            templateUrl: 'reports/reports.html',
            controller: 'ReportsController as vm',
            pageTitle: "Reports"
        })

        .state('app.reports.messages', {
            url: '/messages',
            templateUrl: 'reports/messages/message-report.html',
            controller: 'MessageReportController as vm',
            pageTitle: "Message Reports"
        })

        .state('app.reports.campaigns', {
            url: '/campaigns',
            templateUrl: 'reports/campaigns/campaign-report-list.html',
            controller: 'CampaignReportListController as vm',
            pageTitle: "Campaign Reports"
        })
        .state('app.reports.campaign', {
            url: '/campaigns/:id',
            templateUrl: 'reports/campaigns/campaign-report.html',
            controller: 'CampaignReportController as vm',
            pageTitle: "Campaign Report"
        });
});