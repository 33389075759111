angular
  .module("mongoose")
  .controller(
    "CampaignReportController",
    function (
      $q,
      $rootScope,
      $state,
      $stateParams,
      $uibModal,
      Restangular,
      config,
      securityService,
      profile,
      FileSaver
    ) {
      var vm = this,
        campaignId = $stateParams.id;

      activate();

      function activate() {
        if (!campaignId) $state.go("app.campaigns.list");

        mixpanel.track("Viewed Campaign Report");

        var campaign = Restangular.one("campaigns", campaignId),
          requests = [
            campaign.get(),
            campaign.one("usage", "meta").get(),
            campaign.one("usage", "activity").get(),
            campaign.one("usage", "replytimes").get(),
            campaign.one("usage", "replynumbers").get(),
            campaign.one("usage", "messageactivity").get(),
          ];

        $q.all(requests).then(function (results) {
          vm.campaign = results[0];
          vm.campaign.createdOn = moment.tz(moment.utc(vm.campaign.createdOn), config.timeZone).format("M/D/YY, h:mma");
          vm.campaign.meta = results[1].plain();
          vm.campaign.outgoingActivity = formatOutgoingActivity(results[2].plain());

          if (vm.campaign.createdBy !== profile.id) {
            Restangular.all("staff")
              .get(vm.campaign.createdBy)
              .then(function (staff) {
                vm.campaign.createdByName = _.get(staff, "displayName");
              });
          }

          initReplyTimeChart(results[3].plain());
          initReplyNumbersChart(results[4].plain());
          initMessagesChart(results[5].plain());
        });
      }

      function activitySpanInDays(messages) {
        var sorted = _.chain(messages.outgoingActivity)
          .union(messages.incomingActivity)
          .sort(function (left, right) {
            return moment.utc(left.activityDate).diff(moment.utc(right.activityDate));
          })
          .value();

        return sorted.length
          ? Math.abs(
              moment.utc(sorted[0].activityDate).diff(moment.utc(sorted[sorted.length - 1].activityDate), "days")
            )
          : [];
      }

      function createCategories(min, max, interval) {
        var category = min,
          categories = [];
        while (category <= max) {
          categories.push(moment.tz(category, config.timeZone).format());
          category = category.clone().add(1, interval);
        }
        return categories;
      }

      function formatOutgoingActivity(activity) {
        return activity.map(function (i) {
          var segmentCount = _.size(i.autoUpdateSegments) + _.size(i.staticSegments);
          segmentCount = segmentCount + _.size(i.automatedSegments);
          var event =
            i.firstName +
            " " +
            i.lastName +
            " sent a message to " +
            (segmentCount > 0 ? segmentCount + " Segment" : i.recipientCount + " recipient") +
            (segmentCount > 1 ? "s" : i.recipientCount > 1 ? "s" : "") +
            (segmentCount > 0 ? " (" + i.recipientCount + " recipient" + (i.recipientCount > 1 ? "s" : "") + ")" : "");
          i.dateCreated = moment.tz(moment.utc(i.dateCreated), config.timeZone).format("M/D/YY,[&nbsp;]h:mma");
          i.event = event;
          return i;
        });
      }

      function groupByInterval(activity, interval) {
        if (activity.length === 0) return {};

        return _.chain(activity)
          .map(function (i) {
            i.interval = moment.tz(moment.utc(i.activityDate), config.timeZone).startOf(interval).format();
            return i;
          })
          .groupBy("interval")
          .transform(function (result, n, key) {
            result[key] = n.length;
          })
          .value();
      }

      function mapSeries(categories, data) {
        if (_.isEmpty(data)) return {};

        return _.map(categories, function (i) {
          return [moment.utc(i).valueOf(), !!data[i] ? data[i] : 0];
        });
      }

      function initMessagesChart(data) {
        var intervalOptions = ["hour", "day"],
          selectedInterval = activitySpanInDays(data) <= 2 ? intervalOptions[0] : intervalOptions[1],
          outgoing = groupByInterval(data.outgoingActivity, selectedInterval),
          incoming = groupByInterval(data.incomingActivity, selectedInterval),
          sortedIntervals = _.union(_.keys(outgoing), _.keys(incoming)).sort(function (left, right) {
            return moment.utc(left).diff(moment.utc(right));
          }),
          minCategory = moment(sortedIntervals[0]),
          maxCategory = moment(sortedIntervals[sortedIntervals.length - 1]),
          categories = createCategories(minCategory, maxCategory, selectedInterval),
          outgoingData = mapSeries(categories, outgoing),
          incomingData = mapSeries(categories, incoming);

        vm.messageActivityConfig.series[0].data = outgoingData;
        vm.messageActivityConfig.series[1].data = incomingData;
        vm.messageActivityConfig.xAxis.labels.format =
          selectedInterval === "hour" ? "{value:%m/%d %l%P}" : "{value:%b %d}";
        vm.messageActivityConfig.tooltip.xDateFormat = selectedInterval === "hour" ? "%A, %b %d, %l:%M%P" : "%A, %B %d";
      }

      function initReplyTimeChart(data) {
        vm.replyTimesConfig.series[0].data = [
          data.underFiveMinutes,
          data.fiveToThirtyMinutes,
          data.thirtyToSixtyMinutes,
          data.oneToTwoHours,
          data.twoToSixHours,
          data.sixToTwelveHours,
          data.twelvePlusHours,
        ];
      }

      function initReplyNumbersChart(data) {
        vm.replyNumbersConfig.series[0].data = [
          data.zeroSet,
          data.oneSet,
          data.twoSet,
          data.threeSet,
          data.fourSet,
          data.fivePlusSet,
        ];
      }

      Highcharts.setOptions({
        chart: {
          style: {
            fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontWeight: 300,
          },
        },
        credits: {
          enabled: false,
        },
        global: {
          timezoneOffset: Math.abs(moment().tz(config.timeZone).utcOffset()),
        },
        lang: {
          thousandsSep: ",",
        },
      });

      vm.goBack = function () {
        var state = $rootScope.previousState.name ? $rootScope.previousState.name : "app.reports.campaigns",
          params = $rootScope.previousState.name ? $rootScope.previousParams : {};
        $state.go(state, params);
      };

      vm.messageActivityConfig = {
        chart: {
          type: "line",
        },
        xAxis: {
          type: "datetime",
          labels: {},
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: null,
          },
        },
        legend: {
          enabled: true,
        },
        series: [
          {
            name: "Outgoing",
            markers: {},
            data: [],
          },
          {
            name: "Incoming",
            data: [],
          },
        ],
        title: {
          text: null,
        },
        tooltip: {},
      };

      vm.replyNumbersConfig = {
        chart: {
          type: "bar",
          height: 160,
        },
        xAxis: {
          categories: ["0", "1", "2", "3", "4", "5+"],
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: null,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            //colors: ['#396fb0'],
            dataLabels: {
              enabled: true,
              formatter: function () {
                var percent = (this.y / _.sum(this.series.yData)) * 100,
                  formatted = eval(Highcharts.numberFormat(percent))
                    .toPrecision(10)
                    .replace(/0+$/, "")
                    .replace(/\.$/, "");
                return '<span class="text-muted">' + formatted + "%</span>";
              },
              style: {
                //color: '#999'
              },
            },
          },
          series: {
            //colorByPoint: true
          },
        },
        series: [
          {
            data: [],
            groupPadding: 0.1,
            name: "Replies",
          },
        ],
        title: {
          text: null,
        },
      };

      vm.replyTimesConfig = {
        chart: {
          type: "bar",
          height: 160,
        },
        xAxis: {
          categories: ["&lt;5 min", "5-30 min", "30-60 min", "1-2 hrs", "2-6 hrs", "6-12 hrs", "12+ hrs"],
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: null,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                var percent = (this.y / _.sum(this.series.yData)) * 100,
                  formatted = eval(Highcharts.numberFormat(percent))
                    .toPrecision(10)
                    .replace(/0+$/, "")
                    .replace(/\.$/, "");
                return '<span class="text-muted">' + formatted + "%</span>";
              },
              style: {
                //color: '#999'
              },
            },
          },
        },
        series: [
          {
            data: [],
            groupPadding: 0.1,
            name: "Replies",
          },
        ],
        title: {
          text: null,
        },
      };

      vm.isAuthorized = function (role) {
        return securityService.isAuthorized(role);
      };

      vm.exportActivity = function () {
        Restangular.one("campaigns", campaignId)
          .all("usage")
          .all("export")
          .getList()
          .then(function (ex) {
            var data = new Blob([ex], { type: "text/csv;charset=utf-8" });
            FileSaver.saveAs(data, "campaign-activity-" + campaignId + ".csv");
            mixpanel.track("Exported Campaign Report");
          });
      };

      vm.delete = function () {
        var modalInstance = $uibModal.open({
          controller: "DeleteCampaignModalController as vm",
          templateUrl: "campaigns/delete-campaign-modal.html",
          ariaLabelledBy: "delete-campaign",
          resolve: {
            campaign: function () {
              return vm.campaign;
            },
          },
        });

        modalInstance.result.then(function () {
          $state.go("app.campaigns.list");
        });

        mixpanel.track("Clicked Delete from Campaign Detail");
      };

      vm.edit = function () {
        var modalInstance = $uibModal.open({
          controller: "EditCampaignModalController as vm",
          templateUrl: "campaigns/edit-campaign-modal.html",
          ariaLabelledBy: "edit-campaign",
          resolve: {
            campaignId: function () {
              return campaignId;
            },
          },
        });

        modalInstance.result.then(function () {
          activate();
        });

        mixpanel.track("Clicked Edit from Campaign Detail");
      };

      vm.sendMessage = function () {
        var campaign = vm.campaign;
        $rootScope.openComposeModal({ campaign: campaign });
        mixpanel.track("Clicked Message from Campaign Detail");
      };

      vm.share = function () {
        var modalInstance = $uibModal.open({
          controller: "ShareCampaignModalController as vm",
          templateUrl: "campaigns/share-campaign-modal.html",
          ariaLabelledBy: "share-campaign",
          resolve: {
            campaign: function () {
              return vm.campaign;
            },
            profile: function () {
              return profile;
            },
          },
        });

        modalInstance.result.then(function () {
          activate();
        });

        mixpanel.track("Clicked Share from Campaign Detail");
      };
    }
  );
