(function() {
    'use strict';

    angular
        .module('mongoose')
        .directive('imageOnload', imageOnload);

    function imageOnload($rootScope, $timeout) {

        function linkFunc(scope, el, attr, ctrl) {
            el.bind('load', function() {
                $timeout(function(){
                    $rootScope.$broadcast('conversationResized');
                });
            });
        }

        return {
            restrict: 'A',
            link: linkFunc
        };

    }
})();
