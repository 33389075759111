angular
  .module("mongoose")
  .controller(
    "EditAutoSegmentController",
    function (
      Restangular,
      $state,
      $timeout,
      $q,
      $stateParams,
      comparisons,
      profile,
      config
    ) {
      var vm = this;
      var segmentId = $stateParams.id;
      var baseFieldKeys = [
        "Contact ID",
        "First Name",
        "Last Name",
        "Mobile Number",
        "Assigned User",
        "Opted In",
      ];

      if (!segmentId) {
        $state.go("app.segments.list");
      }
      if (
        !(
          profile.currentDepartment.isAdministrator ||
          profile.isOwner ||
          profile.currentDepartment.usersCanBuildCriteriaBasedSegments
        )
      ) {
        $state.go("app.segments.list");
      }

      vm.comparisons = comparisons;
      vm.conjunctions = ["all", "any"];
      vm.getFieldValue = getFieldValue;
      vm.preview = preview;
      vm.saveSegment = saveSegment;
      vm.step = "criteria";
      vm.updateCriteria = updateCriteria;

      activate();

      function activate() {
        var requests = [
          Restangular.one("autoupdatesegments", segmentId).get(),
          Restangular.all("customfields").getList({
            includeSystemFields: true,
          }),
          Restangular.all("staff").getList(),
        ];

        return $q.all(requests).then(function (results) {
          var segment = results[0];
          segment.autoUpdate = true;
          segment.conditions = JSON.parse(segment.conditions);

          vm.customFields = results[1].plain();
          vm.conditions = segment.conditions;
          vm.conjunction = segment.conjunction;
          vm.segment = segment;
          vm.staff = results[2].plain();
        });
      }

      function formatDate(rawDate) {
        var date = moment.tz(moment.utc(rawDate), config.timeZone);
        return date.format("M/D/YYYY[,] h:mm A");
      }

      function getFieldValue(student, displayName) {
        var fieldKey = displayName;
        var customFieldObj;

        if (fieldKey === "Contact ID") {
          return student["StudentId"];
        }

        if (fieldKey === "Assigned User") {
          return student["AssignedUser"];
        }

        if (fieldKey === "Opted In") {
          return student["OptedIn"] ? "Yes" : "No";
        }

        customFieldObj = _.find(vm.customFields, { displayName: fieldKey });
        if (customFieldObj) {
          if (customFieldObj.isSystemField === true) {
            fieldKey = customFieldObj.searchField;
          }
          if (customFieldObj.isDateField === true) {
            return formatDate(student[fieldKey]);
          }
        }
        return student[fieldKey];
      }

      function preview() {
        vm.previewResults = {};

        var includeKeys = [],
          displayKeys = _.map(
            _.map(vm.segment.conditions, "field"),
            "displayName"
          ),
          includeIds = [];

        includeKeys = _.difference(displayKeys, baseFieldKeys);

        _.forEach(vm.customFields, function (n) {
          var index = _.indexOf(includeKeys, n.displayName);
          if (index !== -1) {
            includeIds.push(n.id);
          }
        });
        vm.previewResults.fields = baseFieldKeys.concat(includeKeys);

        var paramCriteria = _.map(vm.segment.conditions, function (c) {
            return {
              field: c.field.id,
              comparison: c.comparison.value,
              value: typeof c.value === "object" ? c.value.id : c.value,
            };
          }),
          optInParams = {
            includeFields: includeIds,
            criteria: angular.copy(paramCriteria),
            conjunction: vm.segment.conjunction,
            optInValue: true,
          },
          optOutParams = {
            includeFields: includeIds,
            criteria: angular.copy(paramCriteria),
            conjunction: vm.segment.conjunction,
            optInValue: false,
          };

        var optInProm = Restangular.all("students")
          .customPOST(optInParams, "fieldsearch")
          .then(function (response) {
            vm.previewResults.optedInStudents = response.Students;
            vm.previewResults.optedInCount = response.Total;

            _.forEach(vm.previewResults.optedInStudents, function (student) {
              if (student.StaffId) {
                var assignedStaff = _.find(vm.staff, { id: student.StaffId });
                student.AssignedUser = !assignedStaff
                  ? ""
                  : assignedStaff.firstName + " " + assignedStaff.lastName;
              } else {
                student.AssignedUser = "";
              }
            });
          });

        var optOutProm = Restangular.all("students")
          .customPOST(optOutParams, "fieldsearch")
          .then(function (response) {
            vm.previewResults.optedOutStudents = response.Students;
            vm.previewResults.optedOutCount = response.Total;
          });

        $q.all([optInProm, optOutProm])
          .then(function () {
            vm.step = "preview";
            vm.error = "";
          })
          .catch(function (err) {
            if (err && err.data) {
              vm.error = err.data;
            } else {
              vm.error =
                "There was an error generating the preview. Contact Support if this problem persists.";
            }
          });
      }

      function saveSegment() {
        var paramCriteria = _.map(vm.segment.conditions, function (c) {
          return {
            field: c.field.id,
            comparison: c.comparison.value,
            value: typeof c.value === "object" ? c.value.id : c.value,
          };
        });

        vm.segment.conditions = JSON.stringify(paramCriteria);

        vm.segment.save().then(function () {
          $state.go("app.segments.list");
        });
      }

      function updateCriteria(val) {
        vm.conditions = val.conditions;
        vm.conjunction = val.conjunction;
        Object.assign(vm.segment, val);
      }
    }
  );
