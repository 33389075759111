angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.cadenceweb", {
      url: "/v2",
      abstract: true,
      template: "<ui-view/>",
    })
    .state("app.cadenceweb.view", {
      url: "/",
      templateUrl: "cadenceweb/cadence-web.html",
      controller: "CadenceWebController",
      controllerAs: "vm",
      pageTitle: "",
    })
    .state("app.cadenceweb.view2", {
      url: "/*path",
      templateUrl: "cadenceweb/cadence-web.html",
      controller: "CadenceWebController",
      controllerAs: "vm",
      pageTitle: "",
    });
});
