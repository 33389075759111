
angular.module('mongoose').controller('EthosConnectionSettingsController', function ($rootScope, $state, $uibModalInstance, Restangular, growl, ethosService) {

    var vm = this;

    vm.config = {};
    vm.dismiss = $uibModalInstance.dismiss;
    vm.save = saveCredentials;


    activate();

    function activate() {
        vm.loading = true;
        ethosService.config().then(function(response){
            vm.config = response;
            vm.loading = false;
        });
    }

    function saveCredentials(){
        vm.saving = true;
        vm.validationMessage = '';

        Restangular.one('ethos/configuration').post('', vm.config).then(function(){
            growl.success("Connection Settings Updated");
            $uibModalInstance.close();
        },function(response){
            vm.saving = false;
            vm.validationMessage = response.data ? response.data.message : 'Error saving Ethos API Key';
        });
    }

});