angular.module('mongoose').controller('SlateImportConfigurationController', function ($scope, $timeout, $state, $q, growl, Restangular, config, slateService) {

    var vm = this;

    vm.addCustomField = addCustomField;
    vm.customField = {};
    vm.cancel = cancel;
    vm.removeCustomField = removeCustomField;
    vm.removeField = removeField;
    vm.save = save;

    activate();

    function activate() {

        vm.loaded=false;
        
        var requests = [
            slateService.getConfiguration(),
            Restangular.all("customFields").getList()
        ];

         $q.all(requests).then(function (results) {
            var configuration = results[0];
            vm.allCustomFields = _.sortBy(results[1].plain(), function(f) {return f.displayName;});

            if (configuration) {
                vm.config = configuration;
                vm.config.importTime = slateService.timeToLocalDate(vm.config.importTime, config);
            } else {
                vm.config = { };
            }

            if (!vm.config.importConfiguration) {
                vm.config.importConfiguration = {
                    delimiter: 'Tab',
                    optOut: {},
                    customFields:[]
                }
            }

            filterCustomFields();
        });
    }

    function filterCustomFields() {
        var usedFields = _.pluck(vm.config.importConfiguration.customFields, 'mongooseFieldName');
        vm.customFields = _.filter(vm.allCustomFields, function(f) {return !(_.includes(usedFields,f.displayName));});
    }

    function addCustomField($event, field){
        $event.preventDefault();
        if(!field || !field.mongooseFieldName || !field.slateFieldName)
            return;
        if (!vm.config.importConfiguration.customFields)
            vm.config.importConfiguration.customFields = [];
        vm.config.importConfiguration.customFields.push(_.clone(field));
        field.mongooseFieldName = '';
        field.slateFieldName = [];
       filterCustomFields();
    }

    function cancel($event) {
        $event.preventDefault();
        activate();
    }

    function removeCustomField($event, field){
        $event.preventDefault();
        _.pull(vm.config.importConfiguration.customFields, field);
       filterCustomFields();
    }

    function removeField($event, fields, field){
        $event.preventDefault();
        _.pull(fields, field);
    }

    function save() {

        if (!vm.config.importScheduled) vm.config.importTime = null;
        vm.config.importTime = slateService.localDateToUtcTimespan(vm.config.importTime);

        slateService.saveConfiguration(vm.config).then(function(response) {
            growl.success("Slate configuration updated!");
            activate();
        });
    }
});