angular.module('mongoose').controller('LoginLegacyController', function ($scope, $http, $window, $stateParams, securityService, passwordService, tokenUrl, $location) {

    var redirectOnAuthState = 'app.personal.account.inbox';

    $scope.login = login;


    activate();

    function activate() {
        $scope.credentials = { email: '', password: '' };
        $scope.validationMessage = '';

        if($window.localStorage.token){
            securityService.processToken($window.localStorage.token, redirectOnAuthState, true);
        }
    }

    function login() {
        if (validate()) {
            $scope.loading = true;
            $http({
                method: 'POST',
                url: tokenUrl,
                data: $.param({ username: $scope.credentials.email, password: $scope.credentials.password, grant_type: 'password', passwordIsStrong: (passwordService.validatePassword($scope.credentials.password).score === 100) }),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
            )
            .success(function (data) {
                securityService.processToken(data.access_token, redirectOnAuthState, true);
                mixpanel.track('Signed In');
            })
            .error(function (data) {
                activate();
                delete $window.localStorage.token;
                $scope.validationMessage = data.invalid_grant;
                $scope.loading = false;
            });
        }
    }

    function validate() {
        $scope.validationMessage = '';
        if (!($scope.credentials.email && $scope.credentials.password)) {
            $scope.validationMessage = 'E-mail address and password are required.';
        }
        return !$scope.validationMessage;
    }
});
