(function() {
    'use strict';

    angular.module('mongoose').controller('InvalidateCredentialsModalController', InvalidateCredentialsModalController);

    function InvalidateCredentialsModalController($uibModalInstance, Restangular, growl, credentialId) {
        var vm = this;

        vm.dismiss = $uibModalInstance.dismiss;

        vm.invalidate = function() {
             Restangular.all('apiCredentials/' + credentialId).remove().then(function () {
                growl.success("Your API credential has been revoked.");
                $uibModalInstance.close();
            });
        };
    }

})();