angular.module("mongoose").factory("workdayService", function ($rootScope, $q, Restangular) {
  function config() {
    var deferred = $q.defer(),
      workdayPromise = Restangular.one("workday/configuration").get(),
      config = {};

    $q.all([workdayPromise]).then(
      function (results) {
        workdayConfig = results[0].plain();

        deferred.resolve(workdayConfig);
      },
      function () {
        config = {
          exportConfiguration: {
            admissionsIncomingTopic: "",
            admissionsMessageTypeId: null,
            admissionsOutgoingTopic: "",
            campusEngagementEndpoint: "",
            recruitmentIncomingTopic: "",
             recruitmentOutgoingTopic: "",
            shareWithStudent: false,
          },
          importConfiguration: {
            customFields: [],
            firstNameField: null,
            lastModifiedDate: null,
            lastNameField: null,
            mobileNumberField: null,
            notificationEmail: null,
            optOutField: null,
            reportPassword: null,
            reportUrl: null,
            reportUsername: null,
            schedules: [],
            staffIdField: null,
            studentIdField: null,
          },
        };
        deferred.resolve(config);
      }
    );

    return deferred.promise;
  }

  function getDashboardData(options) {
    var exportRequest = getExports(options),
      importRequest = getImports(options);

    return $q.all([exportRequest, importRequest]).then(function (results) {
      return {
        exports: results[0],
        imports: results[1],
      };
    });
  }

  function getExports(options) {
    var params = {};
    if (!_.isUndefined(options.limit)) params.limit = options.limit;

    return Restangular.one("workday/errors")
      .get(params, { "x-disable-loading": "true" })
      .then(
        function (response) {
          return _.map(response.plain(), function (item) {
            item.date = item.date ? moment.tz(moment.utc(item.date), options.timeZone).format("M/D/YY, h:mma") : "";
            return item;
          });
        },
        function (error) {
          $interval.cancel(interval);
          growl.error("An error has occurred loading the export error log. Please reload the page.");
        }
      );
  }

  function getImports(options) {}

  return {
    config: config,
    getDashboardData: getDashboardData,
  };
});
