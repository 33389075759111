angular
  .module("mongoose")
  .controller(
    "WorkdayExportSettingsController",
    function ($q, Restangular, growl, fieldService, workdayConfig) {
      var vm = this;

      vm.config = { workdayExportConfiguration: {} };
      vm.save = save;

      activate();

      function activate() {
        var types = Restangular.all("admin/messageTypes").getList(),
          fields = fieldService.get();

        vm.config.workdayExportConfiguration =
          workdayConfig.exportConfiguration;

        $q.all([types, fields, workdayConfig]).then(function (results) {
          vm.messageTypes = _.sortBy(results[0].plain(), "description");
          vm.customFields = _.sortBy(results[1].plain(), "displayName");
        });
      }

      function save() {
        vm.loading = true;
        Restangular.one("workday/exportConfiguration")
          .post("", vm.config.workdayExportConfiguration)
          .then(function () {
            growl.success("Export Settings Updated");
          })
          .catch(function (response) {
            growl.error(response);
          })
          .finally(function () {
            vm.loading = false;
          });
      }
    }
  );
