angular
  .module("mongoose")
  .controller(
    "InviteUserController",
    function ($q, $scope, $rootScope, $uibModalInstance, Restangular, growl, config, $timeout, profile, $filter, user) {
      var vm = this;

      vm.close = $uibModalInstance.dismiss;
      vm.validateNewUser = validateNewUser;
      vm.finish = finish;
      vm.goToStep = goToStep;
      vm.lookupNumber = lookupNumber;
      vm.needsNumber = needsNumber;
      vm.nextStep = nextStep;
      vm.numberFormInvalid = numberFormInvalid;
      vm.profile = profile;
      vm.resetUserSearch = resetUserSearch;
      vm.searchExistingUsers = searchExistingUsers;
      vm.searchNumbers = searchNumbers;
      vm.selectUserAccount = selectUserAccount;
      vm.selectedAuthMethodName = selectedAuthMethodName;
      vm.selectedItemChange = selectedItemChange;
      vm.validateEmail = validateEmail;
      vm.validateUser = validateUser;
      vm.verifyLandline = verifyLandline;
      vm.isDisabled = isDisabled;
      vm.updatePermissions = updatePermissions;
      vm.updateAuthorizedFeatures = updateAuthorizedFeatures;
      vm.skipInboxSteps = skipInboxSteps;
      vm.showRolePicker = showRolePicker;
      vm.isA2pVerified =
        $rootScope.config.features["a2p-verification"] === true
          ? $rootScope.profile.currentDepartment.campaignStatus === "VERIFIED"
          : true;

      const authorizedFeatures = resetAuthorizedFeatures();

      vm.hasGroupInbox = false;
      vm.selectedGroupAccountName = [];
      vm.selectedHighSpeedAccountName = [];
      vm.user = {
        campusUniqueId: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        selectedDepartments: [
          {
            id: profile.currentDepartment.id,
            role: !config.isEnabled("text-only-team") || config.isEnabled("chat-only-team") ? "none" : "user",
            chatRole: !config.isEnabled("chat-only-team") ? "none" : "user",
            authorizedFeatures: authorizedFeatures,
            selectedGroupAccounts: [],
            selectedHighSpeedAccounts: [],
            personalInboxEnabled: false,
          },
        ],
        forwardingNumber: "",
        sendWelcomeEmail: true,
        virtualOptions: null,
        landlineOptions: null,
        samlConfigurationId: null,
      };
      vm.role = !config.isEnabled("text-only-team") || config.isEnabled("chat-only-team") ? "none" : "user";
      vm.chatRole = !config.isEnabled("chat-only-team") ? "none" : "user";

      vm.newUser = false;
      vm.queryChange = queryChange;

      vm.checkAccountSelection = checkAccountSelection;
      vm.selectAccount = selectAccount;
      vm.hasHighSpeedInbox = false;

      activate();

      function activate() {
        if (user) {
          vm.selectedItem = user;
          selectedItemChange(user);
        }

        Restangular.all("samlconfiguration")
          .getList()
          .then(function (results) {
            vm.authMethods = results;
          });

        if (!config.isEnabled("chat-only-team") && vm.profile.currentDepartment.isAdministrator) {
          Restangular.all("twilio/reservednumbers")
            .getList()
            .then(function (results) {
              vm.reservedNumbers = results.plain();
            });
          Restangular.all("admin/groupAccounts")
            .getList({ currentDepartmentOnly: true })
            .then(function (results) {
              vm.groupAccounts = results.plain();
              vm.disableSharedInbox =
                $rootScope.config.features["a2p-verification"] === true
                  ? !vm.isA2pVerified || !vm.groupAccounts.length
                  : !vm.groupAccounts.length;
            });
        }

        vm.hasValidationErrors = {};
        vm.inboxEnabled = inboxEnabled;
        vm.numberLookup = {
          areaCode: config.areaCode,
          prefix: "",
        };
        vm.phoneType = "new";
        vm.step = 1;

        $scope.$watch("vm.phoneType", togglePhoneType);
      }

      function checkAccountSelection(account, selectedAccount) {
        return selectedAccount.indexOf(account.id) > -1;
      }

      function finish() {
        vm.serverValidationError = false;
        var postBody = _.cloneDeep(vm.user);

        if (vm.phoneType === "reserved") {
          postBody.virtualOptions.isReserved = true;
          postBody.virtualOptions.numberSid = postBody.virtualOptions.phoneNumber.sid;
          postBody.virtualOptions.phoneNumber = "";
        }

        if (postBody.virtualOptions && postBody.virtualOptions.phoneNumber && vm.phoneType !== "reserved") {
          postBody.virtualOptions.isReserved = false;
          postBody.virtualOptions.numberSid = postBody.virtualOptions.phoneNumber.sid;
          postBody.virtualOptions.phoneNumber = postBody.virtualOptions.phoneNumber.phoneNumber;
        }

        if (!postBody.selectedDepartments[0].personalInboxEnabled) postBody.virtualOptions = null;

        if (postBody.samlConfigurationId == "null") postBody.samlConfigurationId = null;

        vm.busy = true;

        var mixpanelObject = {
          "User Type": postBody.selectedDepartments[0].role,
          "Chat User Type": postBody.selectedDepartments[0].chatRole,
          "Group Accounts Count": postBody.selectedDepartments[0].selectedGroupAccounts.length,
          "High Speed Accounts Count": postBody.selectedDepartments[0].selectedHighSpeedAccounts.length,
          Numberless: !postBody.landlineOptions && !postBody.virtualOptions,
          "Contact Creation": vm.manualContactAddAuthorized ? "Enabled" : "Disabled",
        };

        if (postBody.selectedDepartments[0].role === "user") {
          mixpanelObject["Segment Permission"] = postBody.selectedDepartments[0].authorizedFeatures.includes(
            "Segments"
          );
        }

        if (vm.selectedItem && !vm.userDoesNotExist) {
          postBody = _.assign({ id: vm.user.id }, postBody);

          if (!vm.user.isNumberlessUser) {
            postBody = _.omit(postBody, "virtualOptions", "landlineOptions");
          }

          Restangular.all("admin/users/update")
            .post(postBody)
            .then(
              function () {
                growl.success("Your user has been invited!");
                mixpanelObject["Existing User"] = true;
                mixpanel.track("Created New User", mixpanelObject);
                $uibModalInstance.close();
              },
              function (errResponse) {
                vm.serverValidationError = errResponse.data;
                vm.busy = false;
              }
            );
        } else {
          Restangular.all("admin/users")
            .post(postBody)
            .then(
              function () {
                growl.success("Your user has been invited!");
                mixpanelObject["Existing User"] = false;
                mixpanel.track("Created New User", mixpanelObject);
                $uibModalInstance.close();
              },
              function (errResponse) {
                vm.serverValidationError = errResponse.data;
                vm.busy = false;
              }
            );
        }
      }

      function needsNumber() {
        var inboxEnabled = vm.user.selectedDepartments[0].personalInboxEnabled,
          newUser = vm.newUser,
          existingNumberlessUser = vm.selectedItem && !vm.newUser && vm.user.isNumberlessUser;

        vm.existingNumberedUser = vm.selectedItem && !vm.newUser && vm.user.virtualNumbers;

        return inboxEnabled && (newUser || existingNumberlessUser);
      }

      function goToStep(step) {
        setHeight();
        vm.slideDirection = step < vm.step ? "right" : "left";
        $timeout(function () {
          vm.step = step;
        });
        $timeout(function () {
          setHeight("auto");
          vm.slideDirection = "";
        }, 500);

        if (step == "3") {
          if (vm.user.selectedDepartments[0].role == "user") {
            requireUserAccount();
          }
        }

        if (vm.step === 5 && vm.slideDirection === "right") {
          vm.serverValidationError = false;
        }
      }

      function inboxEnabled() {
        return vm.user.selectedDepartments[0].personalInboxEnabled;
      }

      function lookupNumber() {
        vm.isSearching = true;
        vm.user.virtualOptions.phoneNumber = "";

        Restangular.all("twilio")
          .all("numberlookup")
          .getList(vm.numberLookup, { "x-disable-loading": "true" })
          .then(function (n) {
            vm.isSearching = false;
            vm.availableNumbers = _.chunk(n.plain(), Math.ceil(n.length / 2));
            vm.resultNumbers = {
              hasNumbers: n.length > 0,
              number: n.length,
              areaCode: vm.numberLookup.areaCode,
            };
          });
      }

      function numberFormInvalid() {
        if (vm.loading) return true;
        if (
          vm.phoneType === "landline" &&
          (!vm.user.landlineOptions || !vm.user.landlineOptions.phoneNumber || !vm.landlineVerification)
        )
          return true;
        if (vm.phoneType !== "landline" && vm.user.virtualOptions && !vm.user.virtualOptions.phoneNumber) return true;
        return false;
      }

      function resetUserRole() {
        vm.hasGroupInbox = false;
        vm.hasHighSpeedInbox = false;
        vm.selectedGroupAccountName = [];
        vm.selectedHighSpeedAccountName = [];

        vm.user.selectedDepartments[0].selectedGroupAccounts = [];
        vm.user.selectedDepartments[0].selectedHighSpeedAccounts = [];
        vm.user.selectedDepartments[0].personalInboxEnabled = false;
        vm.user.forwardingNumber = "";
        vm.user.sendWelcomeEmail = true;

        togglePhoneType("new");
      }

      function requireUserAccount() {
        if (
          (vm.hasGroupInbox && vm.selectedGroupAccountName.length) ||
          (vm.hasHighSpeedInbox && vm.selectedHighSpeedAccountName.length) ||
          vm.user.selectedDepartments[0].personalInboxEnabled
        ) {
          return;
        }
      }

      function resetAuthorizedFeatures() {
        vm.segmentsAuthorized = true;
        vm.chatAgentAuthorized = false;
        vm.manualContactAddAuthorized = true;
        const authorizedFeatures = ["Segments", "ManualContactAdd"];
        return authorizedFeatures;
      }

      function resetUserSearch() {
        vm.selectedItem = null;
        vm.query = "";
        vm.hasValidationErrors = {};
        vm.userDoesNotExist = false;
        vm.newUser = false;
        vm.showInvite = false;
        vm.userExistsInDepartment = false;
        vm.hasGroupInbox = false;
        vm.hasHighSpeedInbox = false;
        vm.selectedGroupAccountName = [];
        vm.selectedHighSpeedAccountName = [];

        const authorizedFeatures = resetAuthorizedFeatures();

        vm.user = {
          campusUniqueId: "",
          firstName: "",
          lastName: "",
          emailAddress: "",
          selectedDepartments: [
            {
              id: profile.currentDepartment.id,
              role: !config.isEnabled("text-only-team") || config.isEnabled("chat-only-team") ? "none" : "user",
              chatRole: !config.isEnabled("chat-only-team") ? "none" : "user",
              authorizedFeatures: authorizedFeatures,
              selectedGroupAccounts: [],
              selectedHighSpeedAccounts: [],
              personalInboxEnabled: false,
            },
          ],
          forwardingNumber: "",
          sendWelcomeEmail: true,
          virtualOptions: null,
          landlineOptions: null,
          samlConfigurationId: null,
        };

        togglePhoneType("new");
      }

      function searchExistingUsers(searchText) {
        var itemList = angular.element(document.getElementsByClassName("md-virtual-repeat-container"));

        itemList.addClass("create-user");
        vm.userExistsInDepartment = false;
        vm.userDoesNotExist = false;

        return Restangular.all("admin/users/query")
          .post({
            excludedfromteams: [profile.currentDepartment.id],
            filterby: "none",
            loadvirtualnumbers: false,
            orderby: "name",
            take: 50,
            searchtext: searchText,
          })
          .then(function (response) {
            let queryText = { queryText: searchText };
            response = Object.assign(response, queryText);
            if (validateEmail()) {
              if (response.results.length === 0) {
                return isUserInTeam(searchText).then(function (isInTeam) {
                  if (isInTeam) {
                    vm.userExistsInDepartment = true;
                  } else {
                    response.results = [{ emailAddress: searchText, newUser: true }];
                    vm.userDoesNotExist = true;
                  }
                  return response.results;
                });
              } else {
                if (response.results[0].emailAddress !== searchText) {
                  response.results.push({
                    emailAddress: searchText,
                    newUser: true,
                  });
                }
              }
            }
            return response.results;
          });
      }

      function isUserInTeam(email) {
        return Restangular.all("admin/users/query")
          .post({
            includedinteams: [profile.currentDepartment.id],
            loadvirtualnumbers: false,
            take: 5,
            searchtext: email,
          })
          .then(function (response) {
            let userEmails = _.map(response.results, "emailAddress");
            return _.includes(userEmails, email);
          });
      }

      function searchNumbers(areaCode) {
        Restangular.all("twilio")
          .customPOST({ areaCode: areaCode, numberToReserve: 1 }, "reservenumbers")
          .then(function () {
            vm.searchStarted = true;
          });
      }

      function selectAccount(account, selectedAccount, type) {
        var selectedAccountName = type === "group" ? vm.selectedGroupAccountName : vm.selectedHighSpeedAccountName;

        var idx = selectedAccount.indexOf(account.id);
        if (idx > -1) {
          selectedAccount.splice(idx, 1);
          selectedAccountName.splice(idx, 1);
        } else {
          selectedAccount.push(account.id);
          selectedAccountName.push(account.name);
        }

        if (type === "group") {
          vm.hasGroupInbox = true;
        }
      }

      function selectUserAccount(account) {
        if (account === "group" && !vm.hasGroupInbox) {
          vm.user.selectedDepartments[0].selectedGroupAccounts = [];
          vm.selectedGroupAccountName = [];

          if (vm.user.selectedDepartments[0].personalInboxEnabled) {
            return;
          }

          if (vm.user.selectedDepartments[0].role === "user") {
            if (!vm.highSpeedAccounts.length) {
              vm.user.selectedDepartments[0].personalInboxEnabled = true;
            }
          }
        }

        if (account === "high-speed" && !vm.hasHighSpeedInbox) {
          vm.user.selectedDepartments[0].selectedHighSpeedAccounts = [];
          vm.selectedHighSpeedAccountName = [];

          if (vm.user.selectedDepartments[0].personalInboxEnabled) {
            return;
          }

          if (vm.user.selectedDepartments[0].role === "user") {
            if (!vm.groupAccounts.length) {
              vm.user.selectedDepartments[0].personalInboxEnabled = true;
            }
          }
        }

        if (account === "personal" && !vm.user.selectedDepartments[0].personalInboxEnabled) {
          vm.user.forwardingNumber = "";
          togglePhoneType("new");
        }
      }

      function selectedAuthMethodName() {
        if (!vm.user.samlConfigurationId || vm.user.samlConfigurationId == "null") return "Cadence";

        if (vm.authMethods.length > 0) {
          var method = _.find(vm.authMethods, {
            id: vm.user.samlConfigurationId,
          });
          return method ? method.friendlyName : "";
        }
      }

      function selectedItemChange(item) {
        if (_.isUndefined(item)) return;

        if (vm.userDoesNotExist || item.newUser) {
          vm.newUser = true;
          vm.user.emailAddress = item.emailAddress;
          $timeout(function () {
            document.getElementById("firstName").focus();
          });
          return;
        }

        resetNewUser();

        var requests = [Restangular.one("admin/users", item.id).get()];

        if (!config.isEnabled("chat-only-team") && vm.profile.currentDepartment.isAdministrator) {
          requests.push(Restangular.one("admin/users", item.id).getList("numbers"));
        }

        $q.all(requests).then(function (results) {
          vm.user.id = results[0].id;
          vm.user.emailAddress = results[0].emailAddress;
          vm.user.firstName = results[0].firstName;
          vm.user.lastName = results[0].lastName;
          vm.user.campusUniqueId = results[0].campusUniqueId;
          vm.user.forwardingNumber = $filter("phone")(results[0].forwardingNumber);
          vm.user.isNumberlessUser = results[0].isNumberlessUser;
          vm.user.samlConfigurationId = results[0].samlConfigurationId;

          if (
            !config.isEnabled("chat-only-team") &&
            vm.profile.currentDepartment.isAdministrator &&
            !vm.user.isNumberlessUser
          )
            vm.user.phoneNumber = results[1][0].number;
        });
      }

      function setHeight(height) {
        var modal = $(".modal-slide-container"),
          newHeight = height ? height : modal[0].scrollHeight;
        modal.height(newHeight);
      }

      function togglePhoneType(phoneType) {
        if (phoneType === "landline") {
          vm.user.virtualOptions = null;
          vm.user.forwardingNumber = "";
          vm.user.landlineOptions = {
            phoneNumber: "",
            numberSid: "",
          };
        } else {
          vm.user.landlineOptions = null;
          vm.user.virtualOptions = {
            isReserved: false,
            numberSid: "",
            phoneNumber: "",
          };
        }
      }

      function nextStep() {
        var label = "Next";

        if (
          vm.user.selectedDepartments[0].role === "administrator" &&
          !vm.user.selectedDepartments[0].selectedGroupAccounts.length &&
          !vm.user.selectedDepartments[0].selectedHighSpeedAccounts.length &&
          !vm.user.selectedDepartments[0].personalInboxEnabled
        ) {
          label = "Skip";
        }
        return label;
      }

      function validateEmail() {
        var regex = RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/);
        return regex.test(vm.query);
      }

      function validateUser() {
        vm.userExistsInDepartment = false;
        goToStep(2);
      }

      function verifyLandline() {
        vm.isVerifying = true;

        Restangular.all("twilio")
          .one("verifylandline")
          .get({ phonenumber: vm.user.landlineOptions.phoneNumber }, { "x-disable-loading": "true" })
          .then(function (result) {
            vm.isVerifying = false;
            vm.user.landlineOptions.numberSid = result.number.sid;
            vm.landlineVerification = result.success;
          });
      }

      function validateNewUser() {
        return user ? true : validateEmail();
      }

      function queryChange() {
        if (!vm.query) {
          resetUserSearch();
        }
      }

      function resetNewUser() {
        vm.userDoesNotExist = false;
        vm.newUser = false;
      }

      function isDisabled() {
        // Checks that the Shared and/or High Speed inboxes AND children are selected and/or Personal inbox is selected
        let sharedInboxesSelected = vm.user.selectedDepartments[0].selectedGroupAccounts.length;
        let highspeedInboxesSelected = vm.user.selectedDepartments[0].selectedHighSpeedAccounts.length;
        let personalInboxSelected = vm.user.selectedDepartments[0].personalInboxEnabled;

        // Checks if parent checkbox is selected, but child checkbox is not
        let sharedInboxesInvalid = sharedInboxesSelected === 0 && vm.hasGroupInbox;
        let highSpeedInboxInvalid = highspeedInboxesSelected === 0 && vm.hasHighSpeedInbox;

        // Ensures button is disabled when child checkbox is not checked, but other checkboxes are checked
        if (sharedInboxesInvalid || highSpeedInboxInvalid) {
          return true;
        }

        // Ensures admin can 'skip'
        if (vm.user.selectedDepartments[0].role === "administrator") {
          return false;
        }

        // Check that everything is valid
        if (!personalInboxSelected && highspeedInboxesSelected === 0 && sharedInboxesSelected === 0) {
          return true;
        } else {
          return false;
        }
      }

      function updatePermissions(permissions) {
        if (permissions.text.access) {
          vm.role = permissions.text.role;
          vm.user.selectedDepartments[0].role = permissions.text.role;
        } else {
          delete vm.user.selectedDepartments[0].role;
          vm.user.selectedDepartments[0].selectedGroupAccounts = [];
          vm.user.selectedDepartments[0].selectedHighSpeedAccounts = [];
          vm.role = "none";
        }
        if (permissions.chat.access) {
          vm.chatRole = permissions.chat.role;
          vm.user.selectedDepartments[0].chatRole = permissions.chat.role;
        } else {
          delete vm.user.selectedDepartments[0].chatRole;
          vm.chatRole = "none";
        }
        vm.chatAgentAuthorized = permissions.chat.access;
        vm.manualContactAddAuthorized = permissions.text.contactCreation;
        vm.updatedPermissions = permissions;
      }

      function updateAuthorizedFeatures(authorizedFeatures) {
        if (authorizedFeatures) {
          vm.user.selectedDepartments[0].authorizedFeatures = authorizedFeatures;
        }
      }

      function skipInboxSteps() {
        if (vm.role === "none") {
          vm.goToStep(5);
        } else {
          vm.goToStep(3);
        }
      }

      function showRolePicker() {
        if (
          config.isEnabled("text-and-chat-team") &&
          vm.profile.currentDepartment.isAdministrator &&
          vm.profile.currentDepartment.isChatAdministrator
        ) {
          return "text-and-chat";
        }

        if (
          config.isEnabled("text-only-team") ||
          (vm.profile.currentDepartment.isAdministrator && !vm.profile.currentDepartment.isChatAdministrator)
        ) {
          return "text-only";
        }

        if (
          config.isEnabled("chat-only-team") ||
          (!vm.profile.currentDepartment.isAdministrator && vm.profile.currentDepartment.isChatAdministrator)
        ) {
          return "chat-only";
        }
      }
    }
  );
