(function() {
    'use strict';
    angular.module('mongoose').controller('GenerateCredentialsModalController', GenerateCredentialsModalController);

    function GenerateCredentialsModalController($uibModalInstance, Restangular, departmentId) {
        var vm = this;

        vm.close = $uibModalInstance.dismiss;
        vm.create = create;
        vm.finish = $uibModalInstance.close;
        vm.protoCredential = { name: null };


        activate();

        function activate(){
            if(departmentId)
                vm.protoCredential.departmentId = departmentId;
        }

        function create() {
            vm.loading = true;
            Restangular.all('apiCredentials').post(vm.protoCredential).then(function (cred){
               vm.credential = cred;
            });
        }
    }
})();