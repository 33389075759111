angular
  .module("mongoose")
  .controller(
    "WorkdayImportsController",
    function ($interval, $scope, growl, Restangular, config) {
      var vm = this;

      activate();

      function activate() {}
    }
  );
