angular.module('mongoose').controller('StudentDeletionController', function ($scope, $state, growl, Restangular) {

    var vm = this;

    vm.submit = submit;

    function submit() {
        vm.validationMessage = '';
        Restangular.all("deletestudents").post({ numbers: vm.numbers }).then(function () {
                growl.success("You will receive a report via email once the student deletion is completed.");
                vm.numbers = '';
            },
            function (response) {
                if (response.status === 400) {
                    vm.validationMessage = response.data;
                }
            });
    }

});