angular.module('mongoose').controller('SalesforceImportHistoryController', function($scope, $interval, Restangular, config, growl, FileSaver){

    var vm = this,
        interval;

    function loadImports(disableLoading){
        return Restangular.all("salesforce/imports").getList({},{'x-disable-loading': !!disableLoading}).then(function(results) {
            vm.imports = results.map(function(i) {
                i.dateStarted = moment.tz(i.dateStarted, config.timeZone).format('M/D/YY, h:mma z');
                i.dateCompleted = i.dateCompleted ? moment.tz(i.dateCompleted, config.timeZone).format('M/D/YY, h:mma z') : '';
                return i;
            });
        }, function(error) {
            $interval.cancel(interval);
            growl.error("An error has occurred updating import status.  Please reload the page.");
        });
    }

    var load = function () {
            return loadImports();
        },
        setup = function () {
            interval = $interval(function (){
                loadImports(true);
            }, 10000);

            $scope.$on('$destroy', function () {$interval.cancel(interval)});

            vm.initiateImport = function (differential) {
                Restangular.all("salesforce/initiatestudentimport").post({differential: differential}).then(function () {
                    growl.success("Your import has been initiated!");
                });
            };

            vm.initiateExport = function () {
                Restangular.all('salesforce/initiatestudentexport').post().then(function () {
                   growl.success("Your export has been initiated");
                });
            };
        };

    load().then(setup);
});