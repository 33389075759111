angular.module('mongoose').factory('smartMessageService', function() {

    var closingOptions = [{
        value: 0,
        label: '(Please reply Yes or No)'
    }, {
        value: 1,
        label: 'Reply yes/no'
    }, {
        value: 2,
        label: 'Reply Y/N'
    }];

    function getMessageClosingLabel(selected) {
        return _.find(closingOptions, { value: selected }).label;
    }

    function getMessageWithClosing(smartMessage){
        var closing = getMessageClosingLabel(smartMessage.messageClosing);
        return smartMessage.message + ' ' + closing;
    }

    return {
        closingOptions: closingOptions,
        getMessageClosingLabel: getMessageClosingLabel,
        getMessageWithClosing: getMessageWithClosing
    };
});
