angular.module('mongoose').controller('ProfileGeneralController', function ($rootScope, $scope, $window, $state, $filter, profile, Restangular, growl) {

    var initialize = function () {
        $scope.profile = _.cloneDeep(profile);
        $scope.profile.forwardingNumber = $filter('phone')($scope.profile.forwardingNumber);
    },
    validateNumber = function () {
        $scope.forwardingValidationMessage = '';
        var forwardingNumberRegEx = $scope.profile.forwardingNumber && $scope.profile.forwardingNumber.match(/\d/g);
        if ($scope.profile.forwardingNumber && !(forwardingNumberRegEx && forwardingNumberRegEx.length===10)) {
            $scope.forwardingValidationMessage = "Number must be a valid US phone number with area code";
        }
        return !$scope.forwardingValidationMessage;
    };

    $scope.update = function() {
        if (validateNumber()) {
            Restangular.all("profile").customPOST($scope.profile, "update")
            .then(function() {
                $rootScope.$broadcast('profileChanged');
            },
            function (response) {
                if (response.status === 400) {
                    $scope.forwardingValidationMessage = response.data;
                }
            });
        }
    };

    initialize();
});