(function() {
'use strict';
angular.module('mongoose').controller('NewDepartmentController', NewDepartmentController);

function NewDepartmentController($uibModalInstance, Restangular, growl, categories, departments) {
    var vm = this;

    vm.categories = categories;
    vm.close = $uibModalInstance.dismiss;
    vm.create = create;
    vm.updateCode = updateCode;


    activate();

    function activate() {
        vm.department = {
            name: '',
            code: '',
            usersCanBuildCriteriaBasedSegments: false
        };
        vm.hasValidationErrors = {};
    }

    function create() {
        if (isValid()) {
            vm.busy = true;
            Restangular.all('departments').post(vm.department).then(function () {
                    growl.success("Your team has been created!");
                    $uibModalInstance.close();
                },
                function(errResponse) {
                    vm.busy = false;
                    vm.serverValidationError = errResponse.data;
                });
        }
    }

    function isValid() {
        vm.hasValidationErrors = {};
        vm.hasValidationErrors.name = !vm.department.name || _.some(departments,function(d) {return d.name.toLowerCase() === vm.department.name.toLowerCase();});
        vm.hasValidationErrors.code = !vm.department.code || _.some(departments,function(d) {return d.code.toLowerCase() === vm.department.code.toLowerCase();});
        return !_.includes(vm.hasValidationErrors,true)
    }

    function updateCode() {
        if (!vm.department.code && vm.department.name) {
            var matches = vm.department.name.match(/\b(\w)/g);
            vm.department.code = matches.join('');   
        }
    }
}
})();