angular.module('mongoose').controller('DeliveryErrorModalController', function ($scope, $uibModalInstance, Restangular, message) {

    $scope.close = function () {
        $uibModalInstance.close();
    };

    if(message.hadError){
        Restangular.one("messages", message.messageId).one("deliveryError").get().then(function(message) {
            $scope.message = message;
        });
    } else if (message.isCancelled){
        $scope.message = {
            errorMessage: 'Message was cancelled prior to delivery',
            messageId: message.messageId
        };
    }
});