(function () {
  angular.module("mongoose").config(config);

  function config($stateProvider) {
    $stateProvider
      .state("app.admin.integration", {
        url: "/integration",
        abstract: true,
        templateUrl: "admin/department/integration/integration.html",
      })

      // New imports pages
      .state("app.admin.integration.imports", {
        url: "/imports",
        controller: "ImportsController",
        controllerAs: "vm",
        templateUrl: "admin/department/integration/import/imports.html",
        pageTitle: "Imports",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/imports");
            }, 500);
          },
        },
      })
      .state("app.admin.newImport", {
        abstract: true,
        url: "/integration/imports",
        controller: "NewImportController as importScope",
        templateUrl: "admin/department/integration/newImport/new-import.html",
        pageTitle: "New Import",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/newImport");
            }, 500);
          },
        },
      })
      .state("app.admin.newImport.select", {
        url: "/new",
        templateUrl: "admin/department/integration/newImport/select-import-type.html",
        pageTitle: "New Import",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/imports/new");
            }, 500);
          },
        },
      })
      .state("app.admin.newImport.file", {
        url: "/file",
        templateUrl: "admin/department/integration/newImport/import-file.html",
        controller: "ImportFileController",
        controllerAs: "vm",
        pageTitle: "New Import",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/imports/file");
            }, 500);
          },
        },
      })
      .state("app.admin.newImport.text", {
        url: "/text",
        templateUrl: "admin/department/integration/newImport/import-text.html",
        controller: "ImportStudentsController",
        data: {
          cancel: {
            state: "app.admin.integration.imports",
          },
          redirectOnSubmit: {
            state: "app.admin.integration.imports",
          },
        },
        pageTitle: "New Import",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/imports/text");
            }, 500);
          },
        },
      })
      // New imports pages

      .state("app.admin.integration.import", {
        abstract: true,
        url: "/import",
        templateUrl: "admin/department/integration/import/view.html",
      })
      .state("app.admin.integration.import.students", {
        url: "/",
        templateUrl: "admin/department/integration/import/import-students.html",
        controller: "ImportStudentsController",
        pageTitle: "Imports",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/import/import-students");
            }, 500);
          },
        },
      })
      .state("app.admin.integration.import.define", {
        url: "/define",
        templateUrl: "admin/department/integration/import/setup/define-fields.html",
        controller: "DefineFieldsController",
        controllerAs: "vm",
        pageTitle: "Imports",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/import/setup/define-fields");
            }, 500);
          },
        },
      })
      .state("app.admin.integration.import.manage", {
        url: "/manage",
        templateUrl: "admin/department/integration/import/setup/manage-fields.html",
        controller: "ManageFieldsController",
        controllerAs: "vm",
        pageTitle: "Imports",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/import/manage");
            }, 500);
          },
        },
      })
      .state("app.admin.integration.import.complete", {
        url: "/complete",
        templateUrl: "admin/department/integration/import/setup/fields-complete.html",
        controller: "FieldsCompleteController",
        controllerAs: "vm",
        pageTitle: "Imports",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/import/complete");
            }, 500);
          },
        },
      })
      .state("app.admin.integration.import.history", {
        url: "/history",
        templateUrl: "admin/department/integration/import/history.html",
        controller: "ImportHistoryController",
        controllerAs: "vm",
        pageTitle: "Import History",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/import/history");
            }, 500);
          },
        },
      })
      .state("app.admin.integration.fields", {
        url: "/fields",
        templateUrl: "admin/department/integration/fields/view.html",
        controller: "FieldsController",
        controllerAs: "vm",
        pageTitle: "Import Fields",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/fields");
            }, 500);
          },
        },
      })
      .state("app.admin.integration.hasIntegration", {
        url: "/contacts",
        templateUrl: "admin/department/integration/import/has-integration.html",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/import/has-integration");
            }, 500);
          },
        },
      })
      .state("app.admin.integration.numberValidation", {
        url: "/number-validation",
        templateUrl: "admin/department/integration/numberValidation/number-validation.html",
        controller: "NumberValidationController",
        controllerAs: "vm",
        pageTitle: "Number Validation",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/number-validation");
            }, 500);
          },
        },
      })
      .state("app.admin.integration.messageTypes", {
        url: "/message-types",
        templateUrl: "admin/department/integration/messagetypes/list.html",
        controller: "MessageTypesListController",
        controllerAs: "vm",
        pageTitle: "Message Types",
        resolve: {
          redirect: function ($rootScope) {
            // Wait for rootscope to become available.
            setTimeout(function () {
              if ($rootScope.config)
                window.location.replace("/#/v2/admin/integration/message-types");
            }, 500);
          },
        },
      });

    /*$stateProvider.state('app.admin.integration.studentDeletion', {
            url: '/studentDeletion',
            templateUrl: 'admin/department/integration/studentdeletion/upload.html',
            controller: 'AdminStudentDeletionController',
            controllerAs: 'vm'
        });*/
  }
})();
