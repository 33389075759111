
angular.module('mongoose').controller('FtpImportsController', function ($scope, $interval, ftpService, config, Restangular, growl) {

    var vm = this,
        interval;

    vm.run = run;


    activate();

    function activate() {
        getHistory();

        interval = $interval(function() {
            getHistory(true);
        }, 5000);
        
        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    }

    function getHistory(disableLoading){
        ftpService.getImports({timezone: config.timeZone, disableLoading: disableLoading}).then(function(imports){
            vm.imports = imports;
        }, function(){
            $interval.cancel(interval);
        });
    }

    function run(){
         Restangular.all('ftp/imports').post().then(function(){
             growl.success("Your import has been initiated");
         });
    }
});