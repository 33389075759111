
angular.module('mongoose').controller('RadiusConfigurationController', function ($rootScope, $scope, $state, growl, Restangular) {

    var initialize = function () {

        $scope.configuration = {
            apiUsername: '',
            apiPassword: '',
            studentImportConfiguration: ''
        };

        Restangular.one("radius/settings").get().then(function (configuration) {
            if (configuration) {
                $scope.configuration = configuration;
            } else {
                $scope.configuration = {
                    apiUrl: 'https://api.hobsonsradius.com/'
                }
            }
        });
    };

    $scope.save = function () {
        Restangular.all("radius/settings").post($scope.configuration).then(function(response) {
            growl.success("Radius integration configuration updated");
            $rootScope.$broadcast('validCredentials');
            initialize();
        });
    };

    $scope.verifyCredentials = function() {
        $scope.showVerifySuccess = false;
        $scope.showVerifyFailure = false;

        if ($scope.configuration.apiUrl
            && $scope.configuration.apiUsername
            && $scope.configuration.apiPassword) {

            Restangular.all("radius/verifyCredentials").post({
                apiUrl: $scope.configuration.apiUrl,
                apiUsername: $scope.configuration.apiUsername,
                apiPassword: $scope.configuration.apiPassword
            }).then(function (response) {
                $scope.showVerifySuccess = response;
                $scope.showVerifyFailure = !response;
            });
        }
    };

    $scope.cancel = function () {
        initialize();
    };

    initialize();
});