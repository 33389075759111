angular.module('mongoose').controller('ImportHistoryController', function ($scope, $state, $interval, growl, config, Restangular) {
    
    var vm = this;

    function loadImports() {
        Restangular.all("studentimports").getList({},{'x-disable-loading': 'true'}).then(function(results) {
            vm.imports = results.map(function(i) {
                i.dateStarted = moment.tz(i.dateStarted, config.timeZone).format('M/D/YY, h:mma z');
                i.dateCompleted = i.dateCompleted ? moment.tz(i.dateCompleted, config.timeZone).format('M/D/YY, h:mma z') : '';
                i.importFile = i.recordsUpload ? i.recordsUpload.cdnMediaUri : null;
                return i;
            });
        }, function() {
            $interval.cancel(interval); 
            growl.error("An error has occurred updating import status.  Please reload the page.");
        });
    }
    
    function initialize() {
        loadImports();
        
        interval = $interval(function() {
            loadImports();
        }, 10000);
        
        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    };
    
    initialize();
});