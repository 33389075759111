angular
  .module("mongoose")
  .controller(
    "WorkdayController",
    function ($rootScope, $state, workdayConfig, workdayService) {
      var workdayScope = this;

      workdayScope.config = workdayConfig;
      workdayScope.setTitle = setTitle;

      activate();

      function activate() {
        setTitle($state.current);

        $rootScope.$on("$stateChangeSuccess", function (event, toState) {
          setTitle(toState);
        });
        if (workdayConfig.exportConfiguration === null) {
          workdayScope.exportConfigured = false;
        } else {
          workdayScope.exportConfigured = true;
        }
        if (workdayConfig.importConfiguration === null) {
          workdayScope.importConfigured = false;
        } else {
          workdayScope.importConfigured = false;
        }
      }

      function setTitle(state, title) {
        if (title) {
          workdayScope.pageTitle = title;
        } else if (state.data && state.data.title) {
          workdayScope.pageTitle = state.data.title;
        }
      }
    }
  );
