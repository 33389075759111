angular
  .module("mongoose")
  .controller(
    "ContactModalController",
    function (
      $rootScope,
      $filter,
      $uibModalInstance,
      studentService,
      Restangular,
      config,
      profile,
      growl,
      contactId,
      actionsEnabled,
      showEditViewOnly
    ) {
      var vm = this;
      var currentDepartmentId = profile.currentDepartment.id;
      var hasUpdate = false;

      vm.actionsEnabled = actionsEnabled;
      vm.canEdit = canEdit;
      vm.cancel = cancel;
      vm.close = close;
      vm.displayName = displayName;
      vm.edit = edit;
      vm.isEditView = false;
      vm.getFieldLinkOrValue = getFieldLinkOrValue;
      vm.loading = true;
      vm.profile = profile;
      vm.sendMessage = sendMessage;
      vm.update = update;
      $rootScope.$on("reloadStudent", activate);

      activate();

      function activate() {
        studentService.get(contactId).then(function (student) {
          setStudent(student);
          if (showEditViewOnly) {
            edit(true);
          }
          vm.loading = false;
        });
      }

      function cancel() {
        if (showEditViewOnly) {
          close();
        } else {
          vm.edit(false);
        }
      }

      function canEdit() {
        if (!vm.student) {
          return false;
        }
        return (
          profile.isAdministrator ||
          vm.student.source === "Staff" ||
          vm.student.source === "ColdContact" ||
          vm.student.source === "WhatsAppColdContact"
        );
      }

      function close() {
        if (hasUpdate) {
          $uibModalInstance.close();
        } else {
          $uibModalInstance.dismiss();
        }
      }

      function displayName(student) {
        return studentService.getDisplayName(student);
      }

      function edit(showEditView) {
        if (showEditView) {
          var contact = angular.copy(vm.student);
          contact.mobileNumber = $filter("phone")(contact.mobileNumber);
          vm.mutableContact = contact;
        }
        vm.isEditView = showEditView;
      }

      function formatDate(rawDate) {
        var date = moment.tz(moment.utc(rawDate), config.timeZone);
        return date.format("M/D/YYYY[,] h:mm A");
      }

      function getFieldLinkOrValue(value) {
        var urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var telRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

        if (urlRegex.exec(value)) {
          if (value.toLowerCase().indexOf("http") > -1) {
            value = '<a href="' + value + '" target="_blank">' + value + "</a>";
          } else {
            value = '<a href="//' + value + '" target="_blank">' + value + "</a>";
          }
        } else if (emailRegex.exec(value)) {
          value = '<a href="mailto:' + value + '">' + value + "</a>";
        } else if (telRegex.exec(value)) {
          value = '<a href="tel:' + value + '">' + value + "</a>";
        }
        return value;
      }

      function sendMessage(contact) {
        var contacts = [],
          displayText = contact.firstName ? contact.firstName + " " + contact.lastName : contact.mobileNumberFormatted;

        contact.tagText = displayText;
        contacts.push(contact);
        $rootScope.openComposeModal({ contacts: contacts });

        mixpanel.track("Clicked Message from Student Modal");
      }

      function setStudent(student) {
        var fields = _.omit(student.additionalFields, _.isEmpty),
          currentDepartment = _.find(student.departments, function (department) {
            return department.id === currentDepartmentId;
          }),
          staff = currentDepartment.staff;

        student.additionalFields = _.isEmpty(fields) ? null : fields;
        student.optedOut = currentDepartment.optedOut;
        student.staff = !_.isEmpty(staff) ? staff.firstName + " " + staff.lastName : "None";
        student.dateCreated = formatDate(student.dateCreated);
        student.lastUpdated = formatDate(student.lastUpdated);
        student.lastIncomingMessage = student.lastIncomingMessage ? formatDate(student.lastIncomingMessage) : "-";
        student.lastOutgoingMessage = student.lastOutgoingMessage ? formatDate(student.lastOutgoingMessage) : "-";
        vm.student = student;
      }

      function update() {
        vm.saving = true;
        hasUpdate = true;

        if (
          vm.mutableContact.source === "Staff" ||
          vm.mutableContact.source === "ColdContact" ||
          vm.mutableContact.source === "WhatsAppColdContact"
        ) {
          Restangular.all("students/updateAdHoc").post(vm.mutableContact).then(updateSuccess, updateError);
        } else {
          Restangular.all("students").post(vm.mutableContact).then(updateSuccess, updateError);
        }
      }

      function updateError(response) {
        vm.validationMessage = response.data;
        vm.saving = false;
      }

      function updateSuccess() {
        growl.success("Contact has been updated successfully!");
        vm.saving = false;
        mixpanel.track("Updated Student Manually", {
          "Contact Type": vm.student.source,
          "Contact Id": vm.student.id,
        });
        if (showEditViewOnly) {
          close();
        } else {
          edit(false);
          activate();
        }
      }
    }
  );
