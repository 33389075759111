angular.module("mongoose").config(function ($stateProvider) {
  // Personal Inbox
  $stateProvider
    .state("app.messages", {
      abstract: true,
      data: {
        isNewInbox: true,
      },
      resolve: {
        inbox: [
          "profile",
          function (profile) {
            return _.find(profile.inboxes, { userId: profile.id });
          },
        ],
      },
      template: "<ui-view />",
      controller: "MessagingBaseController as vm",
    })
    .state("app.messages.main", {
      abstract: true,
      templateUrl: "messaging/index.html",
      controller: "MessagingIndexController as vm",
    })
    .state("app.messages.main.inbox", {
      url: "/inbox",
      abstract: true,
      params: {
        isInboxView: true,
        isArchivedView: false,
      },
      templateUrl: "messaging/inbox.html",
      controller: "MessagingInboxController as inboxVm",
      pageTitle: "Inbox",
    })
    .state("app.messages.main.inbox.list", {
      url: "",
      controller: "MessagingConversationController as vm",
      pageTitle: "Inbox",
    })
    .state("app.messages.main.inbox.conversation", {
      url: "/{id}",
      controller: "MessagingConversationController as vm",
      pageTitle: "Conversation",
    })
    .state("app.messages.main.sent", {
      url: "/sent",
      templateUrl: "accounts/account-sent.html",
      pageTitle: "Sent",
    })
    .state("app.messages.sent-detail", {
      url: "/sent/{id}",
      templateUrl: "accounts/account-sent-detail.html",
      controller: "AccountSentDetailController as vm",
      pageTitle: "Sent Details",
    })
    .state("app.messages.main.archived", {
      url: "/archived",
      abstract: true,
      params: {
        isInboxView: false,
        isArchivedView: true,
      },
      templateUrl: "messaging/inbox.html",
      controller: "MessagingInboxController as inboxVm",
      pageTitle: "Archived",
    })
    .state("app.messages.main.archived.list", {
      url: "",
      controller: "MessagingConversationController as vm",
      pageTitle: "Archived",
    })
    .state("app.messages.main.archived.conversation", {
      url: "/{id}",
      controller: "MessagingConversationController as vm",
      pageTitle: "Conversation",
    })
    .state("app.messages.main.automation", {
      url: "/automation",
      templateUrl: "messaging/automation.html",
      pageTitle: "Automation Settings",
    })
    .state("app.messages.main.outofoffice", {
      url: "/outofoffice",
      templateUrl: "messaging/out-of-office-personal.html",
      pageTitle: "Out Of Office Settings",
    });

  // Shared Inbox
  $stateProvider
    .state("app.shared", {
      url: "/shared/{accountId:int}",
      abstract: true,
      data: {
        isNewInbox: true,
      },
      resolve: {
        inbox: [
          "$stateParams",
          "profile",
          function ($stateParams, profile) {
            return _.find(profile.inboxes, { groupId: $stateParams.accountId });
          },
        ],
      },
      onEnter: function ($rootScope, $stateParams, $timeout) {
        $timeout(function () {
          $rootScope.$broadcast("groupAccountTitle", $stateParams.accountId);
        });
      },
      onExit: function ($rootScope, $timeout) {
        $timeout(function () {
          $rootScope.$broadcast("groupAccountTitle", "");
        });
      },
      template: "<ui-view />",
      controller: "MessagingBaseController as vm",
    })
    .state("app.shared.main", {
      abstract: true,
      templateUrl: "messaging/index.html",
      controller: "MessagingIndexController as vm",
    })
    .state("app.shared.main.inbox", {
      url: "/inbox",
      params: {
        isInboxView: true,
        isArchivedView: false,
      },
      templateUrl: "messaging/inbox.html",
      controller: "MessagingInboxController as inboxVm",
      pageTitle: "Inbox",
    })
    .state("app.shared.main.inbox.list", {
      url: "",
      controller: "MessagingConversationController as vm",
      pageTitle: "Inbox",
    })
    .state("app.shared.main.inbox.conversation", {
      url: "/{id:int}",
      controller: "MessagingConversationController as vm",
      pageTitle: "Conversation",
    })
    .state("app.shared.main.sent", {
      url: "/sent",
      templateUrl: "accounts/account-sent.html",
      pageTitle: "Sent",
    })
    .state("app.shared.sent-detail", {
      url: "/sent/{id}",
      templateUrl: "accounts/account-sent-detail.html",
      controller: "AccountSentDetailController as vm",
      pageTitle: "Sent Details",
    })
    .state("app.shared.main.archived", {
      url: "/archived",
      abstract: true,
      params: {
        isInboxView: false,
        isArchivedView: true,
      },
      templateUrl: "messaging/inbox.html",
      controller: "MessagingInboxController as inboxVm",
      pageTitle: "Archived",
    })
    .state("app.shared.main.archived.list", {
      url: "",
      controller: "MessagingConversationController as vm",
      pageTitle: "Archived",
    })
    .state("app.shared.main.archived.conversation", {
      url: "/{id:int}",
      controller: "MessagingConversationController as vm",
      pageTitle: "Conversation",
    })
    .state("app.shared.main.automation", {
      url: "/automation",
      templateUrl: "messaging/automation.html",
      pageTitle: "Automation Settings",
    })
    .state("app.shared.main.outofoffice", {
      url: "/outofoffice",
      templateUrl: "messaging/out-of-office-shared.html",
      controller: "OutOfOfficeSharedController",
      controllerAs: "vm",
      pageTitle: "Out Of Office Settings",
    });
});
