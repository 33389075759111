(function() {
    'use strict';

    angular
        .module('mongoose')
        .directive('setHeight', setHeight);

    function setHeight($timeout) {

        function linkFunc() {
            $timeout(function(){
            }, 1000);
        }

        return {
            restrict: 'A',
            link: linkFunc
        };

    }
})();