angular
  .module("mongoose")
  .controller(
    "WorkdayDashboardController",
    function ($scope, $interval, $state, workdayConfig, workdayService, config) {
      var vm = this,
        interval;

      vm.open = open;

      activate();

      function activate() {
        getHistory();
        interval = $interval(function () {
          getHistory(true);
        }, 5000);

        $scope.$on("$destroy", function () {
          $interval.cancel(interval);
        });

        if (workdayConfig.exportConfiguration === null) {
          vm.exportConfigured = false;
        } else {
          vm.exportConfigured = true;
        }
        if (workdayConfig.importConfiguration === null) {
          vm.importConfigured = false;
        } else {
          vm.importConfigured = false;
        }
      }

      function getHistory(disableLoading) {
        //dateFormat: 'MMM D, YYYY h:mma'
        workdayService
          .getDashboardData({
            limit: 3,
            timeZone: config.timeZone,
            disableLoading: disableLoading,
          })
          .then(
            function (response) {
              vm.exports = response.exports;
              // vm.imports = response.imports;
            },
            function () {
              $interval.cancel(interval);
            }
          );
      }

      function open(state) {
        $state.go(state);
      }
    }
  );
