angular.module('mongoose').controller('UpdateReplyCategoryModalController', function ($uibModalInstance, $scope, $state, growl, Restangular, autoReplies, batchId, groupAccountId, contacts, newValue, replyCategoryOptions, oldValue, inbox) {

    var vm = this;

    vm.autoReplies = autoReplies;
    vm.changeCategory = changeCategory;
    vm.close = $uibModalInstance.dismiss;
    vm.disableSubmit = disableSubmit;
    vm.replyCategory = newValue;
    vm.replyCategoryOptions = replyCategoryOptions;
    vm.sendReply = vm.replyCategory && vm.replyCategory !== 'Other';
    vm.submit = submit;
    vm.inbox = inbox;


    function changeCategory(){
        vm.sendReply = vm.replyCategory !== 'Other';
    }

    function disableSubmit(){
        return !vm.replyCategory || vm.replyCategory == oldValue || vm.loading;
    }

    function submit() {
        vm.loading = true;
        if (vm.inbox.type !== 'high-speed') {
            Restangular.one('outgoingmessagebatch', batchId).post('recipients', {
                contacts: contacts,
                replyCategory: vm.replyCategory,
                sendReply: vm.sendReply,
                groupAccountId: groupAccountId,
            }).then(function () {
                mixpanel.track('Updated Reply Category', {
                    'Send Reply': vm.sendReply
                });
                $uibModalInstance.close();
            });
        }
    }
});