angular
  .module("mongoose")
  .controller("RemoveFieldModalController", function ($uibModalInstance, Restangular, fieldService, field) {
    var vm = this;

    vm.dismiss = $uibModalInstance.dismiss;
    vm.removeField = removeField;

    vm.field = field;
    vm.loading = true;

    activate();

    function activate() {
      Restangular.one("customFields/isinuse", field.customField.id)
        .get()
        .then(function (segments) {
          if (segments.inUse) {
            vm.hasSegments = true;
            vm.segments = segments.segmentNames;
          } else {
            vm.hasSegments = false;
          }
          vm.loading = false;
        });
    }

    function removeField() {
      vm.serverValidationError = "";
      $uibModalInstance.close(field);
    }
  });
