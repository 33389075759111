angular
  .module("mongoose")
  .controller("DeleteFieldController", function ($uibModalInstance, Restangular, fieldService, field) {
    var vm = this;

    vm.dismiss = $uibModalInstance.dismiss;
    vm.deleteField = deleteField;
    vm.field = field;
    vm.loading = true;

    function initialize() {
      Restangular.one("customFields/isinuse", field.id)
        .get()
        .then(function (segments) {
          if (segments.inUse) {
            vm.hasSegments = true;
            vm.segments = segments.segmentNames;
          } else {
            vm.hasSegments = false;
          }
          vm.loading = false;
        });
    }

    function deleteField() {
      vm.serverValidationError = "";

      field.displayName = vm.fieldName;

      fieldService.deleteField(field).then(
        function (response) {
          $uibModalInstance.close(response);
        },
        function (response) {}
      );
    }

    initialize();
  });
