angular
  .module("mongoose")
  .controller(
    "EthosImportSettingsController",
    function ($q, $uibModal, $state, Restangular, growl, ethosConfig) {
      var vm = this;

      vm.addField = addField;
      vm.config = mapFields(ethosConfig.personImportConfiguration);
      vm.removeField = removeField;
      vm.save = save;
      vm.scheduleCategories = scheduleCategories();
      vm.scheduleCategoryChange = scheduleCategoryChange;
      vm.testImport = testImport;
      vm.testImportLimit = 10;

      activate();

      function activate() {
        vm.loading = true;
        vm.loadingFilters = true;
        vm.loadingDataTypes = true;

        Restangular.one("ethos/personFilters")
          .get("", { "x-disable-loading": "true" })
          .then(function (response) {
            vm.loadingFilters = false;
            vm.personFilters = response.plain();
          });
        Restangular.one("ethos/dataTypes")
          .get()
          .then(function (response) {
            vm.loading = false;
            vm.loadingDataTypes = false;
            vm.types = response.plain();
          });
      }

      function addField() {
        var modalInstance = $uibModal.open({
          templateUrl: "integrations/ethos/ethos-add-field.html",
          controller: "EthosAddFieldController",
          controllerAs: "vm",
          ariaLabelledBy: "ethos-add",
          resolve: {
            types: function () {
              return vm.types;
            },
          },
        });

        modalInstance.result.then(
          function (response) {
            vm.config.fields.push(response);
          },
          function () {}
        );
      }

      function mapFields(config) {
        var fields = [];

        if (_.isUndefined(config) || _.isNull(config)) {
          config = {
            customFields: [],
            emailMappings: [],
            phoneMappings: [],
            nameMapping: { useFirstByType: true },
            mobilePhoneMapping: { useFirstByType: true },
          };
        }

        _.each(config.customFields, function (field) {
          field.fieldType = "custom";
          fields.push(field);
        });

        _.each(config.emailMappings, function (field) {
          field.fieldType = "email";
          fields.push(field);
        });

        _.each(config.phoneMappings, function (field) {
          field.fieldType = "phone";
          fields.push(field);
        });

        config.fields = fields;
        return config;
      }

      function removeField(field) {
        if (_.isUndefined(field.customField.id))
          return _.pull(vm.config.fields, field);

        var modalInstance = $uibModal.open({
          templateUrl: "admin/department/integration/fields/delete-field.html",
          controller: "DeleteFieldController",
          controllerAs: "vm",
          ariaLabelledBy: "delete-field",
          resolve: {
            field: field.customField,
          },
        });

        modalInstance.result.then(
          function () {
            _.pull(vm.config.fields, field);
          },
          function () {}
        );
      }

      function save() {
        vm.saving = true;

        var config = _.cloneDeep(vm.config);
        config.isConfigured = true;
        config.customFields = _.filter(config.fields, { fieldType: "custom" });
        config.emailMappings = _.filter(config.fields, { fieldType: "email" });
        config.phoneMappings = _.filter(config.fields, { fieldType: "phone" });
        delete config.fields;

        ethosConfig.personImportConfiguration = config;

        Restangular.one("ethos/configuration")
          .post("", ethosConfig)
          .then(
            function () {
              vm.saving = false;
              growl.success("Import Settings Updated");
            },
            function (response) {
              vm.saving = false;
              growl.error(response.data);
            }
          );
      }

      function scheduleCategories() {
        return _.map(
          [
            { category: "Overnight", label: "3am-5am" },
            { category: "Morning", label: "10am-12pm" },
            { category: "Afternoon", label: "2pm-4pm" },
            { category: "Evening", label: "6pm-8pm" },
          ],
          function (item) {
            item.selected =
              _.findIndex(vm.config.schedules, { category: item.category }) >
              -1;
            return item;
          }
        );
      }

      function scheduleCategoryChange(item) {
        vm.config.schedules = vm.config.schedules || [];

        if (item.selected) {
          vm.config.schedules.push(item);
        } else {
          _.remove(vm.config.schedules, { category: item.category });
        }
      }

      function testImport() {
        vm.testImportRunning = true;
        Restangular.one("ethos/importPreview")
          .get("", { "x-disable-loading": "true" })
          .then(
            function (response) {
              vm.testImportResults = response.plain();
              vm.testImportComplete = true;
              vm.testImportRunning = false;
            },
            function (response) {
              vm.testImportComplete = false;
              vm.testImportRunning = false;
              growl.error(response.data);
            }
          );
      }
    }
  );
