angular.module('mongoose').controller('SalesforceImportErrorsController', function (Restangular, config){

    var vm = this;

    var load = function () {
        return Restangular.all('salesforce/errors').getList().then(function(results){
            vm.errors = results.map(function(i){
                i.errorDate = moment.tz(moment.utc(i.errorDate), config.timeZone).format('M/D/YY, h:mma z');
                return i;
            });
        });
    };

    load();
});