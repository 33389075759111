angular.module('mongoose').config(function ($stateProvider) {
        $stateProvider.state('app.campusmanagement', {
            url: '/campusmanagement',
            abstract: true,
            template: '<ui-view/>',
            pageTitle: "Campus Management"
        })

       .state('app.campusmanagement.radius', {
            abstract: true,
            url: '/radius',
            templateUrl: 'campusmanagement/radius/radius.html',
            controller: 'RadiusController as vm',
            pageTitle: "Radius"

        })
        .state('app.campusmanagement.radius.configuration', {
            url: '/configuration',
            templateUrl: 'campusmanagement/radius/configuration.html',
            controller: 'RadiusConfigurationController',
            pageTitle: "Radius Configuration"

        })
        .state('app.campusmanagement.radius.import-configuration', {
            url: '/import-configuration',
            templateUrl: 'campusmanagement/radius/import-configuration.html',
            controller: 'RadiusImportConfigurationController as vm',
            pageTitle: "Radius Import Configuration"
        })
        .state('app.campusmanagement.radius.fields', {
            url: '/fields',
            templateUrl: 'admin/department/integration/fields/view.html',
            controller: 'FieldsController as vm',
            pageTitle: "Fields"
        })
        .state('app.campusmanagement.radius.message-configuration', {
            url: '/message-configuration',
            templateUrl: 'campusmanagement/radius/message-configuration.html',
            controller: 'RadiusMessageConfigurationController as vm',
            pageTitle: "Radius Message Configuration"
        })
        .state('app.campusmanagement.radius.automation', {
            url: '/automation',
            templateUrl: 'campusmanagement/radius/automation-configuration.html',
            controller: 'RadiusAutomationConfigurationController as vm',
            pageTitle: "Radius Automation Configuration"

        })
        .state('app.campusmanagement.radius.logs', {
            abstract: true,
            url: '/logs',
            templateUrl: 'campusmanagement/radius/logs.html',
            pageTitle: "Radius Logs"
        })
            .state('app.campusmanagement.radius.logs.import', {
                url: '/import',
                templateUrl: 'campusmanagement/radius/student-import.html',
                controller: 'RadiusStudentImportController',
                pageTitle: "Radius Student Import"
            })
            .state('app.campusmanagement.radius.logs.messages', {
                url: '/messages',
                templateUrl: 'campusmanagement/radius/error-log.html',
                controller: 'RadiusErrorLogController',
                pageTitle: "Radius Error Log"
            })
            .state('app.campusmanagement.radius.logs.automation', {
                url: '/automation',
                templateUrl: 'campusmanagement/radius/automation-log.html',
                controller: 'RadiusAutomationLogController',
                controllerAs: 'vm',
                pageTitle: "Radius Automation Log"
            })

        .state('app.campusmanagement.connect', {
            abstract: true,
            url: '/connect',
            templateUrl: 'campusmanagement/connect/connect.html',
            controller: 'ConnectController as vm',
            pageTitle: "Connect"
        })
        .state('app.campusmanagement.connect.fields', {
            url: '/fields',
            templateUrl: 'admin/department/integration/fields/view.html',
            controller: 'FieldsController as vm',
            pageTitle: "Connect Fields"
        })
        .state('app.campusmanagement.connect.import', {
            url: '/import',
            templateUrl: 'campusmanagement/connect/student-import.html',
            controller: 'ConnectStudentImportController',
            pageTitle: "Connect Student Import"
        })
        .state('app.campusmanagement.connect.configuration', {
            url: '/configuration',
            templateUrl: 'campusmanagement/connect/configuration.html',
            controller: 'ConnectConfigurationController',
            pageTitle: "Connect Configuration"
        })
        .state('app.campusmanagement.connect.import-configuration', {
            url: '/import-configuration',
            templateUrl: 'campusmanagement/connect/import-configuration.html',
            controller: 'ConnectImportConfigurationController as vm',
            pageTitle: "Connect Import Configuration"
        })
        .state('app.campusmanagement.connect.message-configuration', {
            url: '/message-configuration',
            templateUrl: 'campusmanagement/connect/message-configuration.html',
            controller: 'ConnectMessageConfigurationController as vm'
        })
        .state('app.campusmanagement.connect.errors', {
            url: '/errors',
            templateUrl: 'campusmanagement/connect/error-log.html',
            controller: 'ConnectErrorLogController',
            pageTitle: "Connect Error Log"
        });

});
