angular
  .module("mongoose")
  .controller(
    "ReportingPageController",
    function ($rootScope, $scope, $state, profile) {
      let vm = this;

      vm.hasChat = profile.currentDepartment.teamHasChat;
      vm.hasSms = profile.currentDepartment.teamHasSms;
      vm.orgId = profile.currentDepartment.organizationId;
      vm.updateTitle = updateTitle;
      vm.userIsChatAgent = profile.currentDepartment.userIsChatAgent;
      vm.userHasTexting = profile.currentDepartment.userHasTexting;
      vm.userIsChatOnlyAgent =
        profile.currentDepartment.userIsChatAgent &&
        !profile.currentDepartment.userHasTexting;
      $scope.trackChatAnalyticsTabClicks = trackChatAnalyticsTabClicks;

      function updateTitle(analyticsType) {
        if (vm.hasChat && vm.hasSms && vm.userIsChatAgent) {
          $state.current.pageTitle = `${analyticsType} Analytics`;
        } else {
          $state.current.pageTitle = `Analytics`;
        }
        $rootScope.$broadcast("titleUpdate");
      }

      function trackChatAnalyticsTabClicks() {
        mixpanel.track("Clicked on chat analytics tab");
      }
    }
  );
