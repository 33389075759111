(function () {
  "use strict";

  angular.module("mongoose").directive("sideMenuToggle", sideMenuToggle);

  function sideMenuToggle($rootScope, $timeout) {
    function linkFunc(scope, el, attr, ctrl) {
      $(el).on("click", function () {
        toggle(this);
      });

      function toggle(item) {
        var element = $(item).closest("li"),
          siblings = element.parent("ul").children("li.hasChildren"),
          open = element.hasClass("open");

        if (!open) {
          $(siblings).removeClass("open").find(".sub-menu").height(0);
          var menuHeight = element.find("ul").height();
          element.find(".sub-menu").height(menuHeight);
          element.addClass("open");
        } else {
          element.find(".sub-menu").height(0);
          element.removeClass("open");
        }
      }
    }

    return {
      restrict: "A",
      link: linkFunc,
    };
  }
})();
