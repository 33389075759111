
(function() {
    angular.module('mongoose').config(config);

    function config($stateProvider) {

        $stateProvider.state('app.owner.api', {
            url: '/api',
            abstract: true,
            templateUrl: 'owner/api/api.html'            
        });

        $stateProvider.state('app.owner.api.credentials', {
            url: '/credentials',
            templateUrl: 'owner/api/credentials/api-settings.html',
            controller: 'ApiSettingsController',
            controllerAs: 'vm',
            pageTitle: "API"
        });

        $stateProvider.state('app.owner.api.webhooks', {
            url: '/webhooks',
            templateUrl: 'owner/api/webhooks/webhooks.html',
            controller: 'WebhooksController',
            controllerAs: 'vm',
            pageTitle: "Webhooks",
            resolve: {
                departmentScope: function(){
                    return false;
                }
            }
        });
    }
})();

