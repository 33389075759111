angular.module("mongoose").factory("marketplaceService", marketplaceService);

function marketplaceService($q, Restangular) {
  var config = {},
    profile = {},
    items = {
      chat: {
        name: "Cadence Chat",
        list_name: "Cadence Chat",
        list_description: "Make Every Message Count™",
        boldDescription: true,
        logo: {
          src: "/images/marketplace/icon_chat.png",
          height: 64,
        },
        enabled: true,
        category: 6,
        actions: { request: true },
        description_intro: "Did you know that 50% of higher ed executives plan to add chatbots/webchat in 2022/2023?",
        description_items: [
          "Instant live chat support to improve your website visitor experience",
          "Chatbot playbooks designed to increase enrollment and boost retention",
          "Follow-up functionality via SMS text to ensure high student satisfaction",
          "ROI, data analysis, and usage metrics to prove your efforts are working",
          "Time to focus on high-value tasks with significant staff hours saved per year",
        ],
        proposition:
          "Cadence engagement solutions empower 750+ institutions to advance and inspire students' success by increasing enrollment, engagement, retention, and alumni gifts. " +
          "Mongoose offers the industry's first and only solution built by higher ed professionals for higher ed leaders. " +
          "Cadence Chat provides 1-1 support to website visitors, assisting with urgent questions and adding a personal touch when it's needed the most. " +
          "Built into the Cadence platform with an easy-to-use interface, dedicated support, and chat-to-text follow-up functionality.",
        resources: [
          {
            text: "Cadence Chat Overview PDF",
            path:
              "https://4076260.fs1.hubspotusercontent-na1.net/hubfs/4076260/Cadence%20Chat%20Sales%20Kit/OP_Cadence+Chat+Overview_0922F.pdf?__hstc=227075522.68f9582e7cbd78b6e9de24347fbdc1dd.1661285672337.1663695474289.1663764662830.9&__hssc=227075522.4.1663764662830&__hsfp=&hsCtaTracking=f390d724-edcd-42e4-b1cd-4e48f4c69a5a%7C528ed75a-83b9-4e1c-a8c5-f775eb771aa4",
          },
          {
            text: "Cadence Chat Overview Web Page",
            path: "https://www.mongooseresearch.com/cadence-chat",
          },
          {
            text: "Enrollment Playbooks PDF",
            path:
              "https://4076260.fs1.hubspotusercontent-na1.net/hubfs/4076260/Cadence%20Chat%20Sales%20Kit/OP_Enrollment+Playbook-0922F.pdf?__hstc=227075522.68f9582e7cbd78b6e9de24347fbdc1dd.1661285672337.1663695474289.1663764662830.9&__hssc=227075522.4.1663764662830&__hsfp=&hsCtaTracking=50cbc58c-26f0-4ba0-9315-dddcd8a30757%7C35f8dd08-47fc-425b-af74-3e69ee1ce18c",
          },
          {
            text: "Student Success Playbooks PDF",
            path:
              "https://4076260.fs1.hubspotusercontent-na1.net/hubfs/4076260/Cadence%20Chat%20Sales%20Kit/OP_Success+Playbook-0922F.pdf?__hstc=227075522.68f9582e7cbd78b6e9de24347fbdc1dd.1661285672337.1663695474289.1663764662830.9&__hssc=227075522.4.1663764662830&__hsfp=&hsCtaTracking=ee6a81e0-3bff-47bc-af07-e1bb4ac0a5b4%7Cb795a6ca-48ac-4c39-90c6-68b5503af470",
          },
        ],
        trackResourceClicks: true,
        showNewBadge: false,
        hubSpotEventName: "pe4076260_marketplace_visit",
      },
      "bulk-mms": {
        name: "Bulk Multimedia Messaging",
        list_name: "Bulk MMS",
        list_description: "Enable sending MMS messages to Segments",
        logo: {
          src: "/images/marketplace/icon_bulk-mms.png",
          height: 50,
        },
        enabled: false,
        category: 2,
        actions: {
          nonOwnerMessage: {
            text: "Contact your Organization Owner for more information about enabling Bulk Multimedia Messaging.",
          },
        },
        description_intro:
          "Bulk MMS allows users to send multimedia messages (images, GIFs, etc.) to segments of multiple contacts at once rather than one contact at a time. For more information, please review the Resources area on this page and contact your Mongoose Client Success Lead.",
        description_items: [],
        resources: [
          {
            text: "MMS Best Practices",
            path: "https://help.mongooseresearch.com/article/277-mms-best-practices",
          },
        ],
      },
      "mongoose-api": {
        name: "Cadence API",
        list_name: "Cadence API",
        list_description: "Automate contact and message activity with 3rd party systems",
        logo: {
          src: "/images/marketplace/icon_mongoose-api.png",
          height: 64,
        },
        enabled: true,
        category: 1,
        actions: {
          configure: {
            state: "app.owner.api.credentials",
          },
        },
        description_intro:
          "Cadence provides a simple JSON-based web API for managing contact data and messaging. This includes:",
        description_items: [
          "Creating & updating contacts, both individually and in batch format",
          "Managing contact custom field data",
          "Sending & scheduling messages - allowing for campus-triggered automations",
          "Webhooks for capturing message activity, contact opt-out changes and delivery failures in realtime",
        ],
        proposition:
          "<p>A well designed and crafted API integration offers a level of efficiency that is hard to replicate with manual data import and export processes.</p>" +
          "<p>Contact data can be loaded into Cadence instantly as changes happen in your authoritative system, or changes can be loaded in batch nightly - either way, users in Cadence will have access to the latest data when they are communicating with contacts.</p>" +
          "<p>Utilizing our webhooks, message activity can be synced back to your CRM or system of record in real time, ensuring that text conversations live along side the rest of your contact communications.  Similarly, bringing opt-out data back will keep contact preferences consistent between systems.</p>" +
          "<p>With the messaging capabilities, the API can be utilized to send/schedule messages under any scenario, or data-trigger on campus.  For example, when contacts are assigned an admission's counselor or advisor, they can be automatically be sent an introductory message from that users's Cadence number.</p>" +
          "<p>Once the IT investment is made to develop an API integration, the repetitive manual work of importing/exporting data can be significantly reduced.</p>",
        resources: [
          {
            text: "Support Documentation",
            path: "https://help.mongooseresearch.com/category/253-api-integration",
          },
          {
            text: "API Documentation",
            path: "http://api.mongooseresearch.com/docs/",
          },
        ],
      },
      "number-validation": {
        name: "Phone Number Validation",
        list_name: "Number Validation",
        list_description: "Automatically detect landlines and invalid phone numbers",
        logo: {
          src: "/images/marketplace/icon_number-validation-service.png",
          height: 64,
        },
        enabled: false,
        category: 2,
        actions: {
          nonOwnerMessage: {
            text: "Contact your Organization Owner for more information about enabling Phone Number Validation.",
          },
        },
        description_items: [
          "Validate on import or ad-hoc",
          "Reduce undelivered messages",
          "Protect from carrier spam blacklists",
          "Improve staff efficiency",
        ],
        resources: [
          {
            text: "Documentation",
            path: "https://help.mongooseresearch.com/article/175-number-validation",
          },
        ],
        proposition:
          '<p>In order to successfully text with contacts, each contact must have a valid mobile phone number stored as the mobile number on his/her Cadence record. While contacts may provide your school with a phone number as the "mobile number", sometimes they enter a landline number or a number that is invalid altogether.</p>' +
          "<p>Sending text messages to landline phone numbers or invalid phone numbers will increase your  undelivered rate in Cadence. Additionally, the more texts that you attempt to send to these phone numbers, the higher the chance that the carrier starts to view your texting phone number as spam which can eventually lead to carrier violations.</p>" +
          "<p>The validation allows you to check the phone numbers on the contact records you store in your information system and import into the platform, telling you whether or not each number is a true, valid mobile number.</p>",
      },

      sso: {
        name: "Single Sign-On",
        list_name: "Single Sign-On",
        list_description: "Secure Authentication via SAML",
        logo: {
          src: "/images/marketplace/icon_sso.svg",
          height: 64,
        },
        enabled: false,
        billing_frequency: "one-time",
        category: 5,
        description_items: [
          "Leverage campus-issued login credentials",
          "Campus IT can maintain oversight and control of user credentials, including strength and expiration policies",
          "Cadence SSO can support SAML 2.0-based systems such as Azure AD, Shibboleth, and ADFS]",
        ],
        proposition:
          "<p>Single sign on (SSO) is a secure method of authentication that allows individuals to utilize one set of credentials to access several programs and applications. Applying this to Cadence, staff can utilize their existing campus credentials that are already familiar to them for logging into the web and mobile applications.</p>",
        actions: {
          external: {
            text:
              "For more information, please review the Resources area on this page and contact your Mongoose Client Success Lead.",
          },
        },
        resources: [
          {
            text: "Documentation",
            path: "https://help.mongooseresearch.com/article/393-single-sign-on",
          },
        ],
      },
      capture: {
        name: "Capture Behavioral Engagement (CBE)",
        list_name: "Capture CBE",
        list_description: "Automate text interactions",
        logo: {
          src: "/images/marketplace/icon_capture.png",
          height: 32,
        },
        enabled: false,
        category: 3,
        actions: {
          external: {
            text:
              "If you are a prospective or current Capture CBE client, contact Capture to learn more and to enable the Mongoose Cadence integration.",
          },
        },
        description_intro: "The integration between Mongoose Cadence and Capture CBE allows institutions to:",
        description_items: [
          "Initiate behavioral-based text interactions",
          "Convert automated interactions into real-human conversations",
          "Automatically record message activity in CBE",
        ],
        resources: [],
      },

      rnl: {
        name: "RNL Engage",
        list_name: "RNL Engage",
        list_description:
          "Secure Authentication via SAMLPersonalized Engagement. Increased Integration. Greater Giving.",
        logo: {
          src: "/images/marketplace/icon_rnlengage.png",
          height: 28,
        },
        enabled: false,
        billing_frequency: "one-time",
        category: 5,
        actions: {
          nonOwnerMessage: {
            text: "Contact your Organization Owner for more information about enabling RNL Engage.",
          },
          request: true,
          external: {
            text: "If you are interested in RNL Engage, please contact your RNL rep to learn more.",
          },
        },
        description_intro: "The RNL Engage integration allows users within the RNL Engage platform to:",
        description_items: [
          "Instantly trigger text messages that are then sent through Cadence",
          "Send messages immediately after a call while the task is top of mind",
          "Use pre-written text templates to send a follow-up message seamlessly",
          "Utilize follow-up texts to encourage and complete donations",
        ],
        proposition:
          "<p>RNL Engage™ is the most advanced fundraising software platform available for higher education today, one that makes it easy for you to reach donors through their mobile devices and create experiences that generate gifts.</p>" +
          "<p>Manage your engagement ambassadors from campus or remotely, while they can also conduct their outreach from call centers or remote locations. It allows you to minimize disruptions and maintain donor engagement.</p>",
      },

      connect: {
        name: "Connect CRM",
        list_name: "Connect CRM",
        list_description: "2-way native integration",
        logo: {
          src: "/images/marketplace/icon_connect.png",
          height: 36,
        },
        enabled: false,
        category: 4,
        actions: {
          configure: {
            state: "app.campusmanagement.connect.configuration",
          },
          external: {
            text: "To enable native Connect integration, send a support request to Mongoose.",
          },
        },
        description_items: [
          "Automates the import of contact data to Cadence (based on Filter)",
          "Automatically records text messages in the Comm Log",
          "Requires no additional development time",
        ],
        resources: [
          {
            text: "Documentation",
            path: "https://help.mongooseresearch.com/category/106-connect-integration",
          },
        ],
        proposition:
          "<p>Without any additional effort, you can easily get all your contact data loaded from Connect into Cadence and begin texting with your contacts. Your staff working in Cadence will have all the context they need.</p>" +
          "<p>Freshman Admissions will be able to see a prospective contact's current stage, their GPA, and their hometown while sending them messages.</p>" +
          "<p>The grad school will be able to see their current employer and their most recently attended school.</p>" +
          "<p>Your SMS conversations will come back to Connect so they are integrated with the rest of the communication history.</p>" +
          "<p>And with bi-directional synchronization of opt-out preferences, you won't need to worry about unintentionally messaging a contact against their wishes.</p>",
      },
      customviewbook: {
        name: "CustomViewbook",
        list_name: "CustomViewbook",
        list_description:
          "Engage Generation Z with personalized CustomViewbooks and real-time, two-way communication via Mongoose Cadence.",
        logo: {
          src: "/images/marketplace/icon_custom-viewbook.png",
          height: 15,
        },
        enabled: false,
        category: 3,
        actions: {
          external: {
            text:
              "If you are a current CustomViewbook client or interested in the CustomViewbook platform please contact sales@customviewbook.com or learn more at customviewbook.com.",
          },
        },
        description_items: [],
        resources: [],
        proposition:
          "<p>CustomViewbook has teamed up with Mongoose Cadence to help recruiters focus on what matters: Contact-driven, real-time communication that meets contact needs and gains their trust.</p>" +
          "<p>Mongoose Cadence clients can directly integrate with the CustomViewbook platform for personalized SMS communications each time a prospective contact generates a personalized digital viewbook. Contacts can opt-in to instantly receive their viewbooks via text – and can begin real-time communication with Admissions' recruiters. This proven funnel helps qualify your prospects.</p>",
      },
      raisersEdgeNxt: {
        name: "Blackbaud Raiser's Edge NXT",
        list_name: "Raiser's Edge NXT",
        list_description: "Automated imports and exports",
        logo: {
          src: "/images/marketplace/icon_renxt.png",
          height: 32,
        },
        enabled: false,
        category: 4,
        actions: {
          configure: {
            state: "app.integrations.renxt.view",
          },
          external: {
            text: "To enable the Raiser's Edge NXT integration, send a support request to Mongoose.",
          },
        },
        description_intro:
          "Mongoose offers a bi-directional, fully configurable, and automated integration with Cadence + Raiser&rsquo;s Edge NXT.",

        description_items: [
          "User-facing configuration area in Cadence",
          "Secure, verifiable connection between Cadence + Raiser&rsquo;s Edge NXT",
          "Ability to configure data imports from Raiser&rsquo;s Edge NXT to Cadence, including any custom fields prioritized by your team.",
          "Multiple automatic import times are available per day, as well as the option for on-demand imports.",
          "Incoming/Outgoing text messages that occur in Cadence send to Raiser&rsquo;s Edge NXT as they happen in real-time.",
          "Changes to contact opt-out preferences in Cadence automatically update the corresponding record in Raiser&rsquo;s Edge NXT.",
          "Logging and monitoring tools are provided so integration activity can be monitored.",
        ],
        resources: [
          {
            text: "Documentation",
            path: "//help.mongooseresearch.com/category/581-raisers-edge-nxt-integration",
          },
        ],
        proposition:
          "<li>TIME SAVINGS</li>" +
          "<p>See Cadence messages and opt-out preferences in Raiser&rsquo;s Edge NXT in real-time.</p>" +
          "<li>MINIMAL IMPLEMENTATION</li>" +
          "<p>No custom development via manual setup, FTP,  or API is required.</p>" +
          "<li>DATA MONITORING DASHBOARD</li>" +
          "<p>Access the latest processing data, logs, and success/error notifications to ensure all data is correct.</p>" +
          "<li>IMPROVED TEXTING CAMPAIGNS</li>" +
          "<p>Having the most up to date and universally synced contact information is key to successful fundraising and alumni engagement.</p>",
      },
      ethos: {
        name: "Ellucian Ethos",
        list_name: "Ellucian Ethos",
        list_description: "Automated imports from Colleague and Banner",
        logo: {
          src: "/images/marketplace/icon_ellucian.png",
          height: 30,
        },
        enabled: false,
        category: 4,
        actions: {
          configure: {
            state: "app.integrations.ethos.main.dashboard",
          },
          external: {
            text: "To enable Ethos integration, send a support request to Mongoose.",
          },
        },
        description_intro:
          "Mongoose Cadence offers a fully configurable and automated integration with the Ellucian Ethos platform, including:",
        description_items: [
          "Scheduled and on-demand contact data imports from both Colleague and Banner",
          "Additional capabilities are added as the Ethos Data Model and Integration APIs are enhanced",
          "Planned future support for bi-directional integration",
          "Planned future support for the Recruit and Advance CRM products",
        ],
        resources: [],
      },
      recruit: {
        name: "Ellucian Recruit",
        list_name: "Ellucian Recruit",
        list_description: "Automated imports and exports from Recruit",
        logo: {
          src: "/images/marketplace/icon_ellucian_recruit.png",
          height: 100,
        },
        enabled: false,
        category: 4,
        actions: {
          configure: {
            state: "app.integrations.recruit.view",
          },
          external: {
            text: "To enable Recruit integration, send a support request to Mongoose.",
          },
        },
        description_intro:
          "Mongoose offers a bi-directional, fully configurable and automated integration with Ellucian's Recruit CRM. Key features of the integration:",

        description_items: [
          "User-facing configuration area in Cadence",
          "Secure, verifiable connection between Cadence and Recruit",
          "Ability to configure data imports from Recruit to Cadence, including any custom fields prioritized by your team",
          "Multiple automatic import times available per day as well as the option for on-demand imports",
          "Incoming/Outgoing text messages that occur in Cadence send to Recruit as they happen (real-time updates) and appear on the corresponding record as Activities",
          "Changes to contact opt-out preferences that occur in Cadence automatically update the corresponding record in Recruit",
          "Logging and monitoring tools provided so integration activity can be monitored",
        ],
        resources: [
          {
            text: "Documentation",
            path: "https://help.mongooseresearch.com/category/576-ellucian-recruit-integration",
          },
        ],
        proposition:
          "<p>Key benefits of the integration:</p>" +
          "<li>Eliminates the need to set up and utilize manual, FTP, or API options to update Cadence and Recruit data</li>" +
          "<li>Little-to-no IT involvement required; no code needs to be written</li>" +
          "<li>Integration allows for both import and export functionality, meaning it's easier to move names, contact info, and conversation history back and forth from your texting platform to your CRM</li>" +
          "<li>Ensures contact communication preferences stay updated and accurate</li>",
      },
      ftp: {
        name: "FTP Integration",
        list_name: "FTP Integration",
        list_description: "Automate imports and exports using secure file transfers",
        logo: {
          src: "/images/marketplace/icon_ftp.svg",
          height: 64,
        },
        enabled: false,
        category: 1,
        actions: {
          configure: {
            state: "app.integrations.ftp.main.dashboard",
          },
          external: {
            text: "To enable FTP Integration, send a support request to Mongoose.",
          },
        },
        description_intro:
          "A Cadence FTP-based integration allows contact data to be imported and exported from the platform automatically. Both SFTP and FTPS are supported.",
        description_items: [
          "Import contact data files, including standard & custom data fields",
          "Export messaging history",
          "Export changes in opt-out preferences",
          "Export message delivery failures",
        ],
        proposition:
          "<p>In many cases, an FTP integration is the easiest and most secure method for exchanging data between systems. Most systems are capable of producing and consuming standardized file formats, and many either have built-in FTP capabilities or an FTP server is already available within the campus IT infrastructure.</p>" +
          "<p>From a technical perspective, our FTP integration supports both <b>FTPS</b> (FTP over SSL) and <b>SFTP</b> (SSH File Transfer Protocol) and provides standard options, including both credential and SSH Key authentication.</p>" +
          "<p>File formats, delimiters, column headers, date formats, remote paths and file names can be fully configured for both imports and exports.</p>" +
          "<p>Imports and exports can each be scheduled independently to run up to four times per day, and run on demand at any time.</p>" +
          "<p>A full set of management tools for configuration, scheduling, notifications, and monitoring are provided within Cadence for administrators.</p>",
        resources: [
          {
            text: "FTP Documentation",
            path: "https://help.mongooseresearch.com/article/308-ftp-integration-guide",
          },
        ],
      },
      radius: {
        name: "Radius CRM",
        list_name: "Radius CRM",
        list_description: "2-way native integration and automated messaging",
        logo: {
          src: "/images/marketplace/icon_radius.png",
          height: 46,
        },
        enabled: false,
        category: 4,
        actions: {
          configure: {
            state: "app.campusmanagement.radius.configuration",
          },
          external: {
            text: "To enable native Radius integration, send a support request to Mongoose.",
          },
        },
        description_intro: "Mongoose Cadence enables two-way texting that integrates with Radius by Campus Management",
        description_items: [
          "Uses existing web services provided in Radius",
          "Is set up and monitored within Cadence",
          "Requires no custom development by the institution",
          "You configure the contact records and fields to import",
          "Cadence adds new or modified records every night",
          "Automate text messages based on campaigns in Radius",
          "Cases and case messages update on the fly",
          "SMS conversations flow back into Radius",
        ],
        resources: [
          {
            text: "Documentation",
            path: "https://help.mongooseresearch.com/article/295-radius-integration-guide",
          },
          {
            text: "Radius SMS",
            path: "https://www.campusmanagement.com/products/text-messaging",
          },
        ],
        proposition:
          "<p>With no additional effort, you can easily have all your contact data loaded from Radius into Cadence and begin text communications with your contacts. As your staff work in Cadence, they will have all the context they need to address contact questions and encourage their interest.</p>" +
          "<p>In practical terms, this means that your undergraduate admissions team will be able to see prospective freshmen GPAs, their current lifecycle stage, and their hometown while sending them messages. For the graduate school, admissions staff can see contacts' current employers and the college they most recently attended.</p>" +
          "<p>Your SMS conversations will come back to Radius by Campus Management and integrate with the rest of the communication history for each contact. And, with bidirectional synchronization of opt-out preferences, you can be assured that you are only sending messages to contacts who want to receive text communications.</p>",
      },
      salesforce: {
        name: "Salesforce",
        list_name: "Salesforce",
        list_description: "2-way native integration and automated messaging",
        logo: {
          src: "/images/marketplace/icon_salesforce.png",
          height: 64,
        },
        enabled: false,
        category: 4,
        actions: {
          configure: {
            state: "app.salesforce.configuration",
          },
          external: {
            text: "To enable native Salesforce integration, send a support request to Mongoose.",
          },
        },
        description_intro: "The Salesforce integration includes:",
        description_items: [
          "One-time setup for authentication between Cadence and Salesforce",
          "Setting up any additional fields that will be imported",
          "Importing of contact Contact records from Salesforce to Cadence",
          "Exporting Cadence interactions back into Salesforce",
        ],
        resources: [
          {
            text: "Documentation",
            path: "https://help.mongooseresearch.com/category/213-salesforce-integration",
          },
        ],
      },
      slate: {
        name: "Slate",
        list_name: "Slate",
        list_description: "2-way native integration",
        logo: {
          src: "/images/marketplace/icon_slate.png",
          height: 64,
        },
        enabled: false,
        category: 4,
        actions: {
          configure: {
            state: "app.slate.configuration",
          },
          external: {
            text: "To enable native Slate integration, send a support request to Mongoose.",
          },
        },
        description_intro:
          "Mongoose Cadence offers a fully-automated integration with Technolutions Slate CRM. We provide the following points of integration:",
        description_items: [
          "Contact data from Slate loaded into Cadence",
          "All message activity from Cadence loaded as interactions within Slate",
          "Any contact opt out preference changes in Cadence reflected in Slate",
          "Any message delivery errors reflected in Slate",
        ],
        resources: [
          {
            text: "Documentation",
            path: "https://help.mongooseresearch.com/category/214-slate-integration",
          },
        ],
      },
      workday: {
        name: "Workday",
        list_name: "Workday",
        list_description: "2-way native integration",
        logo: {
          src: "/images/marketplace/icon_workday.svg",
          height: 64,
        },
        enabled: false,
        category: 4,
        actions: {
          configure: {
            state: "app.integrations.workday.main.dashboard",
          },
          external: {
            text: "To enable native Workday integration, send a support request to Mongoose.",
          },
        },
        description_intro:
          "Cadence offers an automated two-way native integration with Workday. The following points are included in the integration:",
        description_items: [
          "Contact data automatically loaded from Workday into Cadence",
          "Standard fields (such as name and mobile number), custom fields, user assignment, and opt out preferences can be included",
          "All message activity from Cadence automatically exported to Workday",
        ],
        resources: [
          {
            text: "Documentation",
            path: "https://help.mongooseresearch.com/category/430-workday-integration",
          },
        ],
      },
    };

  function getAll() {
    return items;
  }

  function getByKey(key) {
    return items[key];
  }

  function getByKeyWithData(key) {
    var item = getByKey(key);
    return getAdditionalData(key).then(function (response) {
      if (!_.isUndefined(response)) item.data = response;
      return item;
    });
  }

  function getAdditionalData(key) {
    var deferred = $q.defer();
    switch (key) {
      case "messaging":
        deferred.resolve(getMessagingData());
        break;
      case "number-validation":
        deferred.resolve(getNumberValidationData());
        break;
      case "sso":
        deferred.resolve(getSsoData());
      default:
        deferred.resolve(null);
    }
    return deferred.promise;
  }

  function getMessagingData() {
    if (!profile.isOwner) return $q.when(null);

    return Restangular.all("contract")
      .customGET("")
      .then(function (response) {
        var contract = response.plain();

        return Restangular.all("contract")
          .customGET("usage")
          .then(function (response) {
            contract.twiliousage = response.plain().twilioUsage;
            contract.mongooseUsage = response.plain().mongooseUsage;
            contract.hasUnlimitedMessaging = contract.twiliousage.allottedSegments >= 1000000;

            if (contract.hasUnlimitedMessaging) {
              contract.twiliousage.mmsCost = 0;
              contract.twiliousage.voiceCost = 0;
              contract.twiliousage.segmentCost = 0;
              contract.twiliousage.messagingCost = 0;
            } else {
              contract.twiliousage.messagingCost =
                +contract.twiliousage.mmsCost.toFixed(2) +
                +contract.twiliousage.voiceCost.toFixed(2) +
                +contract.twiliousage.segmentCost.toFixed(2);
            }

            return contract;
          });
      });
  }

  function getNumberValidationData() {
    return Restangular.all("contract")
      .customGET("investment", { investmentType: "NumberValidation" })
      .then(function (response) {
        return response.plain();
      });
  }

  function getBulkMmsData() {
    return Restangular.all("contract")
      .customGET("investment", { investmentType: "BulkMms" })
      .then(function (response) {
        return response.plain();
      });
  }

  function getSsoData() {
    return Restangular.all("contract")
      .customGET("investment", { investmentType: "Sso" })
      .then(function (response) {
        return response.plain();
      });
  }

  function setStatus(configuration, userProfile) {
    config = configuration;
    profile = userProfile;
    getByKey("number-validation").enabled = config.hasNumberValidation;
    getByKey("bulk-mms").enabled = config.hasBulkMms;
    getByKey("connect").enabled = config.integrations.connect;
    getByKey("ftp").enabled = config.integrations.ftp;
    getByKey("radius").enabled = config.integrations.radius;
    getByKey("salesforce").enabled = config.integrations.salesforce;
    getByKey("slate").enabled = config.integrations.slate;
    getByKey("sso").enabled = config.hasSso;
    getByKey("workday").enabled = config.integrations.workday;
    getByKey("recruit").enabled = config.integrations.recruit;
    getByKey("ethos").enabled = config.integrations.ethos;
    getByKey("chat").enabled = profile.currentDepartment.teamHasChat;
    getByKey("chat").showNewBadge = !profile.currentDepartment.teamHasChat;
    getByKey("raisersEdgeNxt").enabled = config.integrations.raisersEdgeNxt;
  }

  return {
    setStatus: setStatus,
    getAll: getAll,
    get: getByKeyWithData,
  };
}
