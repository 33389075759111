
angular.module('mongoose').controller('RadiusAutomationConfigurationController', function ($scope, $timeout, $state, $q, growl, Restangular, config) {

    var vm = this;
    vm.cancel = cancel;
    vm.formInvalid = formInvalid;
    vm.save = save;
    vm.runAutomation = runAutomation;
    vm.getCloseCaseStatusOptions = getCloseCaseStatusOptions;

    activate();

    function activate() {
        vm.timezone =  moment.tz(new Date(),config.timeZone).zoneAbbr();
        vm.loaded=false;
        var requests = [
            Restangular.one("radius/automationConfiguration").get(),
            Restangular.one("radius/settings").get(),
            Restangular.all("admin/users").getList({currentDepartmentOnly: true}),
            Restangular.all("radius/caseFields").getList(),
            Restangular.one("radius/automationCaseFields").get(),
        ];

        $q.all(requests).then(function (results) {
            var messageConfig = results[0];
            vm.settings = results[1];
            vm.departmentUsers = config.plan === 'Legacy' ? results[2] : _.filter(results[2], function(i) {return i.departments[0].personalInboxEnabled});
            vm.caseFields = results[3];
            vm.caseStatusOptions = _.filter(results[3], function(i) { return i.fieldLabel === "Status";})[0].values;
            vm.radiusSetupValid = results[4].valid;
            if (messageConfig) {
                vm.config = messageConfig.plain();
                var unspecifiedMessageTime = moment(vm.config.unspecifiedMessageTime).local();
                vm.config.unspecifiedMessageTime = moment().hour(unspecifiedMessageTime.hour()).minute(unspecifiedMessageTime.minute());
                
                var caseSearchTime = moment(vm.config.caseSearchTime).local();
                vm.config.caseSearchTime = moment().hour(caseSearchTime.hour()).minute(caseSearchTime.minute());
            }
            else {
                vm.config = {};
            }
            vm.showScreen = vm.settings.apiPassword && vm.settings.apiUsername && vm.settings.apiUrl;
            vm.loaded=true;
        });
    }

    function formInvalid(){
        return vm.config.enabled && (!vm.config.openCaseStatus || !vm.config.closeCaseStatus || !vm.config.sendFromUserId);
    }

    function getCloseCaseStatusOptions() {
        return _.filter(vm.caseStatusOptions, function(item) { return item != vm.config.openCaseStatus;});
    }

    function cancel($event) {
        $event.preventDefault();
        activate();
    }

    function save() {
        var config = {
            automationConfiguration: angular.toJson(vm.config)
        };
        Restangular.all("radius/automationConfiguration").post(config).then(function() {
            growl.success("Automation configuration updated!");
            activate();
        });
    }

    function runAutomation() {
        if (confirm("Are you sure?  This will send messages!")) {
            Restangular.all("radius/runautomation").post().then(function () {
                growl.success("Radius message automation initialized!");
            });
        }
    }

});