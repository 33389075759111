angular.module("mongoose").controller("MarketplaceDetailController", MarketplaceDetailController);

function MarketplaceDetailController(
  $state,
  $stateParams,
  $uibModal,
  config,
  profile,
  marketplaceService,
  Restangular
) {
  var vm = this,
    key = $stateParams.key;

  vm.enableService = enableService;
  vm.goToConfigure = goToConfigure;
  vm.requestCredits = requestCredits;
  vm.trackResourceClick = trackResourceClick;
  vm.requestChat = requestChat;

  activate();

  function activate() {
    marketplaceService.setStatus(config, profile);
    marketplaceService.get(key).then(function (response) {
      vm.item = response;
      vm.item.key = key;
      vm.showEnable =
        vm.item.data &&
        vm.item.data.contractCost &&
        !vm.item.enabled &&
        vm.item.key !== "sso" &&
        (profile.isOwner || (profile.isAdministrator && config.plan !== "Legacy"));
      vm.showConfigure =
        vm.item.actions &&
        vm.item.actions.configure &&
        ((vm.item.enabled && profile.isAdministrator) || (vm.item.key === "mongoose-api" && profile.isOwner));
      vm.showExternal = !vm.item.enabled && vm.item.actions && vm.item.actions.external;
      vm.showNonHighSpeedOwnerMessage = !(profile.isOwner || (profile.isAdministrator && config.plan !== "Legacy"));
      vm.userCanRequestChat = profile.isOwner || (profile.isAdministrator && config.plan !== "Legacy");
      vm.showNonOwnerMessage =
        !(profile.isOwner || (profile.isAdministrator && config.plan !== "Legacy")) &&
        vm.item.actions &&
        vm.item.actions.nonOwnerMessage;
      vm.showProposition =
        vm.item.proposition || vm.showEnable || vm.showConfigure || vm.showExternal || vm.showNonOwnerMessage;

      if (vm.item.key === "highspeed") {
        vm.item.data = {};
        vm.additionalCredits;
        vm.creditPricing = [
          { amount: "100,000", price: "$1,650", value: 100000 },
          { amount: "250,000", price: "$3,850", value: 250000 },
          { amount: "500,000", price: "$6,600", value: 500000 },
          { amount: "1,000,000", price: "$11,000", value: 1000000 },
        ];
        Restangular.all("/contract/investment?investmentType=HighSpeed")
          .customGET()
          .then(function (response) {
            vm.item.data.contractCost = response.contractCost;
          });
      }

      if (vm.item.hubSpotEventName && !vm.item.enabled) {
        trackUserInHubspot(vm.item.hubSpotEventName);
      }

      mixpanel.track("Viewed Marketplace Feature", {
        Feature: vm.item.list_name,
      });
    });
  }

  function enableService() {
    var service = vm.item,
      investmentType =
        key === "number-validation"
          ? "enableValidation"
          : key === "highspeed"
          ? "requestHighSpeed"
          : key === "sso"
          ? "enableSso"
          : "";
    service.requestCredits = false;

    $uibModal
      .open({
        templateUrl: "marketplace/enable-service-modal.html",
        controller: "EnableServiceController",
        controllerAs: "vm",
        size: "sm",
        resolve: {
          service: function () {
            return service;
          },
        },
      })
      .result.then(
        function () {
          var body = {};
          if (service.key === "highspeed") {
            body = { quotedInvestment: service.data.contractCost };
          } else {
            body = { investment: service.data.contractCost };
          }
          Restangular.all("configuration")
            .customPUT(body, investmentType)
            .then(function () {
              mixpanel.track("Enabled Marketplace Feature", {
                Feature: service.list_name,
              });
              if (service.key === "highspeed") {
                vm.requestSent = true;
              } else {
                $state.reload();
              }
            });
        },
        function () {}
      );
  }

  function requestCredits() {
    var service = vm.item;
    service.requestCredits = true;
    _.find(vm.creditPricing, function (c) {
      if (c.value === vm.additionalCredits) {
        service.additionalCreditsPrice = c.price;
      }
    });
    $uibModal
      .open({
        templateUrl: "marketplace/enable-service-modal.html",
        controller: "EnableServiceController",
        controllerAs: "vm",
        size: "sm",
        resolve: {
          service: function () {
            return service;
          },
        },
      })
      .result.then(
        function () {
          Restangular.all("/configuration/requestHighSpeedCredits")
            .customPUT({
              quotedInvestment: vm.item.data.contractCost,
              creditsRequested: vm.additionalCredits,
            })
            .then(function () {
              vm.requestSent = true;
              mixpanel.track("Request High Speed Credits", {
                Feature: service.list_name,
              });
            });
        },
        function () {}
      );
  }

  function requestChat() {
    mixpanel.track("Click Request Chat button");
    Restangular.all("/marketplace/hubspot/task")
      .customPOST({
        properties: {
          hs_timestamp: moment().local().add(2, "days").toISOString(),
          hs_task_body:
            "Hot lead - CSL to follow up with Chat interest using Cadence Chat In Product Promotion - HOT LEAD email template.",
          hs_task_subject: "Client RFI for Chat (in-product)",
          hs_task_status: "NOT_STARTED",
          hs_task_priority: "MEDIUM",
          hs_task_type: "TODO",
        },
      })
      .then(function () {
        vm.requestSent = true;
      });
  }

  function trackUserInHubspot(eventName) {
    Restangular.all("/marketplace/hubspot/trackevent").customPOST({
      email: profile.emailAddress,
      eventName,
    });
  }

  function goToConfigure() {
    $state.go(vm.item.actions.configure.state);
  }

  function trackResourceClick(trackClicks, resource) {
    if (trackClicks) {
      mixpanel.track("Clicked Marketplace resource", {
        "Resource Name": resource.text,
      });
    }
  }
}
