(function () {
  "use strict";

  angular.module("mongoose").directive("sideMenu", sideMenu);

  function sideMenu($rootScope, $timeout) {
    function linkFunc(scope, el, attr, ctrl) {
      $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState) {
        if (toState.name === "app.cadenceweb.view2") {
          var a = $(`[href='/#/v2/${toParams.path}']`);
        } else {
          var a = $("[sidenav-ui-sref='" + toState.name + "']");
        }
        if (a.length > 0) {
          $(el).find("li.hasChildren").not(a.closest("li.hasChildren")).removeClass("open").find(".sub-menu").height(0);

          var submenu = $(a).closest(".sub-menu"),
            li = $(submenu).closest("li.hasChildren");

          if (!li.hasClass("open")) {
            var menuHeight = li.find("ul").height();
            li.find(".sub-menu").height(menuHeight);
            li.addClass("open");
          }
        }

        // Watch for integration settings pages
        var active = $(el).find(".active").closest("li.hasChildren");
        const terms = ["integrations", "settings"];
        if (toParams.path && terms && terms.every((term) => toParams.path.includes(term))) {
          var menuHeight = active.find("ul").height();
          active.find(".sub-menu").height(menuHeight);
          active.addClass("open");
        }
      });

      function openActive() {
        var active = $(el).find(".active").closest("li.hasChildren");
        if (active) {
          var menuHeight = active.find("ul").height();
          active.find(".sub-menu").height(menuHeight);
          active.addClass("open");
        }
      }

      $timeout(openActive);
    }

    return {
      restrict: "A",
      link: linkFunc,
    };
  }
})();
