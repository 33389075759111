(function () {
  "use strict";
  angular.module("mongoose").controller("ViewSmartMessageModalController", ViewSmartMessageModalController);

  function ViewSmartMessageModalController(
    $uibModalInstance,
    $window,
    smartMessage,
    $rootScope,
    smartMessageService,
    $state
  ) {
    var vm = this;
    vm.smartMessage = smartMessage;
    vm.smartMessage.messagePreview = smartMessageService.getMessageWithClosing(smartMessage);

    vm.dismiss = function () {
      $uibModalInstance.dismiss();
    };

    vm.sendMessage = function () {
      $uibModalInstance.dismiss();
      $rootScope.openComposeModal({ smartMessage: smartMessage });
      mixpanel.track("Clicked Message from View Smart Message Modal");
    };

    vm.editMessage = function () {
      $uibModalInstance.dismiss();
      let messageType =
        smartMessage.messageType === 0 ? "basic" : smartMessage.messageType === 1 ? "scale" : "multipe";
      $window.location.href = "#/v2/automation/smartmessages/" + smartMessage.id + "/edit/" + messageType;
    };
  }
})();
