angular
  .module("mongoose")
  .controller(
    "UsersListController",
    function (
      $scope,
      $state,
      $q,
      $uibModal,
      growl,
      profile,
      caretakerService,
      Restangular,
      config
    ) {
      $scope.assignCaretaker = assignCaretaker;
      $scope.canBeTarget = canBeTarget;
      $scope.deleteUser = deleteUser;
      $scope.newUser = newUser;
      $scope.profile = profile;
      $scope.removeCaretaker = removeCaretaker;
      $scope.updateUser = updateUser;

      activate();

      function activate() {
        var requests = [
          caretakerService.get(),
          Restangular.all("owner/users/getAll").getList(),
          Restangular.all("admin/users/count").customGET(""),
          Restangular.all("contract").customGET(""),
        ];

        $q.all(requests).then(function (results) {
          var relationships = results[0].plain();
          var users = results[1].plain();
          $scope.users = relationships.length
            ? groupUsersByCaretaker(relationships, users)
            : users;
          $scope.userCount = results[2];
          $scope.contract = results[3].plain();
          $scope.showAddExisting = true;
        });
      }

      function assignCaretaker(user) {
        var modalInstance = $uibModal.open({
          templateUrl: "owner/organization/users/assign-caretaker.html",
          controller: "AssignCaretakerController",
          controllerAs: "vm",
          ariaLabelledBy: "assign-caretaker",
          resolve: {
            user: function () {
              return user;
            },
            users: function () {
              return $scope.users;
            },
          },
        });

        modalInstance.result.then(activate);
      }

      function canBeTarget(user) {
        return (
          !user.isAdministrator &&
          !user.isOwner &&
          !user.isCaretaker &&
          !user.caretakerId
        );
      }

      function deleteUser(id) {
        var modalInstance = $uibModal.open({
          templateUrl: "owner/organization/users/delete-user.html",
          controller: "DeleteUserController",
          ariaLabelledBy: "delete-user",
          resolve: {
            userId: function () {
              return id;
            },
          },
        });

        modalInstance.result.then(activate, angular.noop);
      }

      function newUser() {
        var modalInstance = $uibModal.open({
          templateUrl: "owner/organization/users/new-user.html",
          controller: "NewUserController",
          controllerAs: "vm",
          ariaLabelledBy: "new-user",
          backdrop: "static",
          resolve: {
            existingUsers: function () {
              return {
                campusIdentifiers: _.map($scope.users, function (u) {
                  return u.campusUniqueId;
                }),
                emailAddresses: _.map($scope.users, function (u) {
                  return u.emailAddress;
                }),
              };
            },
            config: function () {
              return config;
            },
          },
        });

        modalInstance.result.then(activate, angular.noop);
      }

      function groupUsersByCaretaker(relationships, users) {
        var groupedRelationships = _.groupBy(relationships, function (n) {
          return n.caretakerStaffId;
        });

        var targets = [];
        _.each(relationships, function (item) {
          targets.push(_.remove(users, { id: item.targetStaffId })[0]);
        });

        _.each(groupedRelationships, function (group, key) {
          var caretakerId = parseInt(key),
            caretakerIndex = _.findIndex(users, { id: caretakerId }),
            insertIndex = caretakerIndex + 1;

          if (caretakerIndex === -1) return;

          users[caretakerIndex].isCaretaker = true;

          _.each(group, function (n) {
            var target = _.remove(targets, { id: n.targetStaffId })[0];
            if (target) {
              target.caretakerId = caretakerId;
              users.splice(insertIndex, 0, target);
              insertIndex++;
            }
          });
        });
        return users;
      }

      function removeCaretaker(user) {
        var caretakerId = user.caretakerId,
          targetId = user.id;

        caretakerService.remove(caretakerId, targetId).then(function () {
          activate();
        });
      }

      function updateUser(userId) {
        var modalInstance = $uibModal.open({
          templateUrl: "owner/organization/users/edit-user.html",
          controller: "EditUserController",
          controllerAs: "vm",
          ariaLabelledBy: "edit-user",
          resolve: {
            userId: function () {
              return userId;
            },
            existingUsers: function () {
              return $scope.users;
            },
            profile: function () {
              return profile;
            },
          },
        });

        modalInstance.result.then(activate, angular.noop);
      }
    }
  );
