angular.module('mongoose').controller('ImportFileController', function (growl, $state, $timeout, Restangular, smoothScroll, Upload) {

    var vm = this,
        importFile;

    vm.acceptedTypes = '.csv,.txt,.xlsx';
    vm.allowMobileUpdate = false;
    vm.maxFiles = 1;
    vm.maxSize = '5MB';
    vm.status = 'ready'; // ready, uploading, processing
    vm.step = 1;

    vm.import = runImport;
    vm.importReady = importReady;
    vm.resetUpload = resetUpload;
    vm.upload = upload;


    activate();

    function activate() {
    }

    function getFilePreview(){
        vm.status = 'processing';

        Restangular.all('studentimports/sample/file').post(importFile, {}, {'x-disable-loading': true}).then(function(response){
            var preview = response.plain(),
                sampleData = {};

            scrollToTop();

            vm.step = 2;

            preview.hasErrors = preview.errors || preview.extraFields.length || preview.missingRequiredFields.length;

            _.forEach(preview.firstSample, function(n, key){
               var values = [n];

               if(!_.isNull(preview.lastSample))
                   values.push(preview.lastSample[key]);

                sampleData[key] = {
                    data: values
                };
            });

            _.forEach(preview.extraFields, function(key){
                sampleData[key].hasError = true;
                sampleData[key].isExtra = true;
            });

            _.forEach(preview.missingRequiredFields, function(key){
                sampleData[key] = {
                    hasError: true,
                    isRequired: true
                };
            });

            preview.sample = sampleData;

            vm.preview = preview;
        }, function(response){
            if(response && response.data) {
                vm.serverError = response.data[0];
            }
        });
    }

    function importReady(){
        if(!vm.preview) return;

        return vm.preview.recordCount > 0 && !vm.preview.hasErrors;
    }

    function resetUpload(){
        vm.file = {};
        vm.fileName = null;
        vm.preview = {};
        vm.status = 'ready';
        vm.step = 1;
        scrollToTop();
    }

    function runImport(){
        var data = {
            importFile: importFile,
            allowMobileUpdate: vm.allowMobileUpdate
        };

        vm.loading = true;

        Restangular.all('studentimports/file').post(data, {}, {'x-disable-loading': true}).then(function(){
            $timeout(function(){
                growl.success("Your contact import is being processed and you will receive an email when it completes!");
                mixpanel.track("Ran Student Import", {
                    "Import Method": "File",
                    "AllowMobileUpdates": vm.allowMobileUpdate
                });

                $state.go('app.admin.integration.imports');
            }, 1000);
        });
    }

    function scrollToTop(){
        document.getElementById('pane').scrollTop = 0;
    }

    function upload(file){
        if(!file)
            return;

        vm.fileName = file.name;
        vm.fileError = {};
        vm.serverError = '';
        vm.status = 'uploading';

        Upload.upload({
            url: 'https://sms-api-dev.mongooseresearch.com/api/file/upload',
            data: { file: file },
            headers: { 'x-disable-loading': true }
        }).then(function (response) {
            importFile = response.data;
            getFilePreview();
        }, function (response) {
            if(response && response.data) {
                vm.serverError = response.data[0];
                resetUpload();
            }
        }, function (event) {
            var progress = parseInt(100.0 * event.loaded / event.total);
            vm.progressPercentage = progress;
        });
    }

});

