angular
  .module("mongoose", [
    "mongoose.templates",
    "mongoose.ngVueComponents",
    "ngAnimate",
    "ngCookies",
    "ngSanitize",
    "ngAria",
    "ngMaterial",
    "ui.router",
    "restangular",
    "ui.bootstrap",
    "ngTagsInput",
    "duScroll",
    "angular-growl",
    "uiSwitch",
    "ngFileSaver",
    "smoothScroll",
    "ui.mask",
    "angular-page-visibility",
    "highcharts-ng",
    "ui.grid",
    "ui.grid.pagination",
    "ngFileUpload",
    "chart.js",
  ])
  .config(function (
    $mdThemingProvider,
    uibDatepickerConfig,
    $uibTooltipProvider,
    growlProvider,
    RestangularProvider,
    $compileProvider,
    ChartJsProvider
  ) {
    $compileProvider.debugInfoEnabled(false);

    RestangularProvider.setBaseUrl("https://sms-api-dev.mongooseresearch.com/api");

    uibDatepickerConfig.showWeeks = false;
    uibDatepickerConfig.closeText = "Done";
    $uibTooltipProvider.options({ appendToBody: true });

    growlProvider.onlyUniqueMessages(false);
    growlProvider.globalTimeToLive({
      success: 3000,
      error: 5000,
      warning: 5000,
      info: 3000,
    });
    growlProvider.globalDisableCountDown(true);
    growlProvider.globalDisableIcons(true);

    $mdThemingProvider.definePalette("primaryPalette", {
      50: "E8EAF6",
      100: "eeeeee", // Light Grey
      200: "9FA8DA",
      300: "7986CB",
      400: "4e86d4",
      500: "006ba8", // Link Blue
      600: "3949AB",
      700: "303F9F",
      800: "283593",
      900: "1A237E",
      A100: "8C9EFF",
      A200: "536DFE",
      A400: "3D5AFE",
      A700: "304FFE",
      contrastDefaultColor: "light", // whether, by default, text (contrast)
      // on this palette should be dark or light

      contrastDarkColors: [
        "50",
        "100", //hues which contrast should be 'dark' by default
        "200",
        "300",
        "400",
        "A100",
      ],
    });

    $mdThemingProvider.definePalette("accentPallete", {
      50: "ffebee",
      100: "ffcdd2",
      200: "ef9a9a",
      300: "e57373",
      400: "ef5350",
      500: "f44336",
      600: "e53935",
      700: "d32f2f",
      800: "c62828",
      900: "b71c1c",
      A100: "ff8a80",
      A200: "33bb95", // Goose Green
      A400: "ff1744",
      A700: "d50000",
      contrastDefaultColor: "light", // whether, by default, text (contrast)
      // on this palette should be dark or light
      contrastDarkColors: [
        "50",
        "100", //hues which contrast should be 'dark' by default
        "200",
        "300",
        "400",
        "A100",
      ],
    });

    $mdThemingProvider.theme("default").primaryPalette("primaryPalette").accentPalette("accentPallete");

    ChartJsProvider.setOptions({
      global: {
        defaultFontColor: "#737373",
        defaultFontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
      },
    });
  })
  .constant("firebaseUrl", "https://mongoose-sms-dev.firebaseio.com/")
  .constant("apiBaseUrl", "https://sms-api-dev.mongooseresearch.com/api")
  .constant("authReturnUrl", "https://sms-dev.mongooseresearch.com")
  .constant("samlBaseUrl", "https://sms-api-dev.mongooseresearch.com/saml/")
  .constant("partnerUrl", "https://den-dev.mongooseresearch.com")
  .constant("tokenUrl", "https://sms-api-dev.mongooseresearch.com/oauth/token")
  .constant("appVersion", "26")
  .config(function ($provide) {
    $provide.decorator("$exceptionHandler", function ($delegate) {
      return function (exception, cause) {
        $delegate(exception, cause);

        if (1 != "1") return;

        try {
          appInsights.trackException({
            exception: exception,
          });
        } catch (ex) {
          console.warn("Angular Application Insights Error [trackException]: ", ex);
        }
      };
    });
  })
  .config(function ($urlRouterProvider, $stateProvider) {
    $urlRouterProvider.otherwise(function ($injector, $location) {
      // If we're in the old app, reroute to the login page on an invalid URL
      // We'll need to figure this out in the new app
      let path = $location.path();
      if (!path.startsWith("/v2")) {
        return "login";
      }
    });

    $stateProvider.state("app", {
      abstract: true,
      templateUrl: "main/main.html",
      controller: "MainController",
      resolve: {
        config: [
          "configurationService",
          function (configurationService) {
            return configurationService.get();
          },
        ],
        profile: [
          "securityService",
          function (securityService) {
            return securityService.loadProfile();
          },
        ],
      },
    });
  })
  .config(function ($provide) {
    $provide.decorator("GridOptions", function ($delegate) {
      var gridOptions;
      gridOptions = angular.copy($delegate);
      gridOptions.initialize = function (options) {
        var initOptions;
        initOptions = $delegate.initialize(options);
        initOptions.enableColumnMenus = false;
        // Template src: https://github.com/angular-ui/ui-grid/blob/v4.0.11/src/features/pagination/templates/pagination.html
        initOptions.paginationTemplate =
          '<div class="ui-grid-pager-panel" ui-grid-pager ng-show="grid.options.enablePaginationControls">' +
          '<div role="navigation" class="ui-grid-pager-container">' +
          '<div class="ui-grid-pager-control">' +
          '<button type="button" class="ui-grid-pager-first" ui-grid-one-bind-title="aria.pageToFirst" ui-grid-one-bind-aria-label="aria.pageToFirst" ng-click="pageFirstPageClick()" ng-disabled="cantPageBackward()">' +
          "<div ng-class=\"grid.isRTL() ? 'last-triangle' : 'first-triangle'\">" +
          "<div ng-class=\"grid.isRTL() ? 'last-bar-rtl' : 'first-bar'\"></div>" +
          "</div>" +
          "</button>" +
          '<button type="button" class="ui-grid-pager-previous" ui-grid-one-bind-title="aria.pageBack" ui-grid-one-bind-aria-label="aria.pageBack" ng-click="pagePreviousPageClick()" ng-disabled="cantPageBackward()">' +
          "<div ng-class=\"grid.isRTL() ? 'last-triangle prev-triangle' : 'ui-grid-icon-left-dir'\"></div>" +
          "</button>" +
          '<button type="button" class="ui-grid-pager-next" ui-grid-one-bind-title="aria.pageForward" ui-grid-one-bind-aria-label="aria.pageForward" ng-click="pageNextPageClick()" ng-disabled="cantPageForward()">' +
          "<div ng-class=\"grid.isRTL() ? 'first-triangle next-triangle' : 'ui-grid-icon-right-dir'\"></div>" +
          "</button>" +
          '<button type="button" class="ui-grid-pager-last" ui-grid-one-bind-title="aria.pageToLast" ui-grid-one-bind-aria-label="aria.pageToLast" ng-click="pageLastPageClick()" ng-disabled="cantPageToLast()">' +
          "<div ng-class=\"grid.isRTL() ? 'first-triangle' : 'last-triangle'\">" +
          "<div ng-class=\"grid.isRTL() ? 'first-bar-rtl' : 'last-bar'\"></div>" +
          "</div>" +
          "</button>" +
          '<span class="ui-grid-pager-text">' +
          " {{ grid.options.paginationCurrentPage }}" +
          '<span ng-show="paginationApi.getTotalPages() > 0">' +
          " of {{ paginationApi.getTotalPages() }} " +
          "</span>" +
          "</span>" +
          "</div>" +
          '<div class="ui-grid-pager-row-count-picker" ng-if="grid.options.paginationPageSizes.length > 1 && !grid.options.useCustomPagination"></div>' +
          '<span ng-if="grid.options.paginationPageSizes.length <= 1" class="ui-grid-pager-row-count-label">{{grid.options.paginationPageSize}}&nbsp;{{sizesLabel}}</span>' +
          "</div>" +
          '<div class="ui-grid-pager-count-container">' +
          '<div class="ui-grid-pager-count">' +
          '<span ng-show="grid.options.totalItems > 0">' +
          "{{ 1 + paginationApi.getFirstRowIndex() }}" +
          '<span ui-grid-one-bind-title="paginationThrough">-</span>' +
          "{{ 1 + paginationApi.getLastRowIndex() }}" +
          " of {{grid.options.totalItems}}</span>" +
          "</div>" +
          "</div>" +
          "</div>";
        return initOptions;
      };
      return gridOptions;
    });
  })
  .value("$anchorScroll", angular.noop)
  .run(function ($rootScope, $state, $timeout, $window, securityService) {
    $rootScope.appLoaded = true;

    $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
      securityService.authorizeRoute(toState.name).then(function (authorized) {
        if (!authorized) {
          $state.go("login");
          return event.preventDefault();
        }

        $rootScope.previousParams = fromParams;
        $rootScope.previousState = fromState;
      });
    });

    $rootScope.$on("$stateChangeSuccess", function (event, toState) {
      if (toState.name.indexOf(".logs.") !== -1) {
        // make md-nav-bar work with children
        var pos = toState.name.indexOf(".logs.");
        $rootScope.currentPage = toState.name.substring(0, pos) + ".logs";
      } else {
        $rootScope.currentPage = toState.name;
      }
      if (document.getElementById("pane")) {
        document.getElementById("pane").scrollTop = 0;
      }
      $timeout(function () {
        $rootScope.$broadcast("titleUpdate");
      });
    });

    $rootScope.$on("$locationChangeSuccess", function () {
      if ($window.Appcues) {
        $window.Appcues.page();
      }
    });
  });
