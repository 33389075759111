angular.module('mongoose').controller('FtpExportSettingsController', function (Restangular, ftpConfig, configKey, growl) {

    var vm = this;

    vm.addField = addField;
    vm.config = ftpConfig;
    vm.removeField = removeField;
    vm.saveMessageActivity = saveMessageActivity;
    vm.saveOptOuts = saveOptOuts;
    vm.saveDeliveryErrors = saveDeliveryErrors;
    vm.scheduleCategories = scheduleCategories();
    vm.scheduleCategoryChange = scheduleCategoryChange;


    activate();

    function activate() {
        Restangular.one('/ftp/exportFields').get().then(function(result){
            var fields = result.plain();
            vm.messageActivityFields = _.reject(_.reject(fields, {'type': 'OptOut'}), {'type': 'DeliveryFailure'});
            vm.optOutFields = _.reject(_.reject(fields, {'type': 'Activity'}), {'type': 'DeliveryFailure'});
            vm.deliveryFailureFields = _.reject(_.reject(fields, {'type': 'Activity'}), {'type': 'OptOut'});
        });
    }

    function addField(fields, field){
        fields.push(_.clone(field));
        delete field.mongooseExportField;
        delete field.fileFieldName;
        return fields;
    }

    function removeField(fields, field){
        return _.pull(fields, field);
    }

    function saveMessageActivity(){
        save({
            "ExportType": "MessageActivity",
            "ExportConfiguration": vm.config.messageActivityExportConfiguration
        });
    }

    function saveOptOuts(){
        save({
            "ExportType": "OptOuts",
            "ExportConfiguration": vm.config.optOutExportConfiguration
        });
    }

    function saveDeliveryErrors(){
        save({
            "ExportType": "DeliveryErrors",
            "ExportConfiguration": vm.config.deliveryFailureConfiguration
        });
    }

    function save(data){
        vm.loading = true;
        return Restangular.one('ftp/exportConfiguration').post('', data).then(function(response){
            growl.success("Export Settings Updated");
            vm.config[configKey] = response.plain();
            vm.loading = false;
        }, function(response){
            vm.loading = false;
            growl.error(response.data);
        });
    }

    function scheduleCategories(){
        return _.map([{category: 'Overnight', label: '3am-5am'}, {category: 'Morning', label: '10am-12pm'}, {category: 'Afternoon', label: '2pm-4pm'}, {category: 'Evening', label: '6pm-8pm'}], function(item){
            item.selected = _.findIndex(vm.config[configKey].schedules, {'category': item.category}) > -1;
            return item;
        });
    }

    function scheduleCategoryChange(item){
        if(_.isNull(vm.config[configKey].schedules))
            vm.config[configKey].schedules = [];

        if(item.selected){
            vm.config[configKey].schedules.push(item);
        } else {
            _.remove(vm.config[configKey].schedules, {'category': item.category});
        }
    }


});