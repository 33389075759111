angular.module("mongoose").directive("blockField", blockField);

function blockField() {
  return {
    restrict: "EA",
    controller: BlockFieldController,
    scope: {
      student: "=",
    },
    templateUrl: "core/blockField.html",
  };
}

function BlockFieldController(
  $rootScope,
  $scope,
  $uibModal,
  Restangular,
  securityService
) {
  var profile;

  $scope.update = update;

  activate();

  function activate() {
    securityService.getProfile().then(function (response) {
      profile = response;
      $scope.studentIsInDepartment = studentIsInDepartment;
    });
  }

  function studentIsInDepartment() {
    return _.find($scope.student.departments, {
      id: profile.currentDepartment.id,
    });
  }

  function update(department) {
    var update = function () {
      return Restangular.one("students", $scope.student.id).post("mute", {
        departmentId: department.id,
        muted: department.muted,
      });
    };

    if (department.muted) {
      var modalInstance = $uibModal.open({
        templateUrl: "core/blockFieldConfirmModal.html",
        controller: "BlockFieldConfirmModalController as vm",
        ariaLabelledBy: "block-field-confirm",
        resolve: {
          isBulk: false,
        },
      });
      modalInstance.result.then(
        function () {
          update().then(function () {
            $rootScope.$broadcast("reloadStudent");
          });
        },
        function () {
          department.muted = false;
        }
      );
    } else {
      update();
    }
  }
}
