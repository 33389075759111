 angular
    .module('mongoose')
    .directive('activityDots', activityDots);

function activityDots() {

    return {
        restrict: 'EA',
        template: '<span class="activity-dots"><span>&bull;</span><span>&bull;</span><span>&bull;</span></span>'
    };
}