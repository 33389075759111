angular.module('mongoose').controller('ConnectStudentImportController', function ($scope, $state,$interval, growl, config, Restangular, FileSaver, Blob) {

    var interval;

    function loadImports() {
        Restangular.all("connect/imports").getList({},{'x-disable-loading': 'true'}).then(function(results) {
            $scope.imports = results.map(function(i) {
                i.dateStarted = moment.tz(i.dateStarted, config.timeZone).format('M/D/YY, h:mma z');
                i.dateCompleted = i.dateCompleted ? moment.tz(i.dateCompleted, config.timeZone).format('M/D/YY, h:mma z') : '';
                return i;
            });
        }, function(error) {
            $interval.cancel(interval); 
            growl.error("An error has occurred updating import status.  Please reload the page.");
        });
    }

    function initiateImport(differential) {
        Restangular.all("connect/initiatestudentimport").post({differential: differential}).then(function () {
            growl.success("Your import has been initiated!");
        });
    };

    function initialize() {
        loadImports();

        interval = $interval(function() {
            loadImports();
        }, 10000);

        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    };

    $scope.initiateImport = function () {
        initiateImport(false);
    };

    $scope.initiateDifferentialImport = function () {
        initiateImport(true);
    };

    initialize();
});