angular.module('mongoose').controller('SlateImportLogController', function ($scope, $state, $interval, growl, config, Restangular, FileSaver, Blob) {
    
    var vm = this,
        initialLoad = true,
        interval;

    function loadImports() {
        Restangular.all("slate/imports").getList({},{'x-disable-loading': !initialLoad}).then(function(results) {
            initialLoad = false;
            vm.imports = results.map(function(i) {
                i.dateStarted = moment.tz(i.dateStarted, config.timeZone).format('M/D/YY, h:mma z');
                i.dateCompleted = i.dateCompleted ? moment.tz(i.dateCompleted, config.timeZone).format('M/D/YY, h:mma z') : '';
                return i;
            });
        }, function(error) {
            $interval.cancel(interval); 
            growl.error("An error has occurred updating import status.  Please reload the page.");
        });
    }
    
    function initialize() {
        loadImports();
        
        interval = $interval(function() {
            loadImports();
        }, 10000);
        
        $scope.$on('$destroy', function () { $interval.cancel(interval); });
    }
    
    vm.initiateImport = function () {
         Restangular.all("slate/initiateImport").post().then(function () {
            growl.success("Your import has been initiated!");
        });
    };

    initialize();
});