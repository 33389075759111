angular.module("mongoose").factory("configurationService", function (Restangular) {
  var config;

  function get() {
    return Restangular.one("configuration")
      .get("", { "x-disable-loading": "true" })
      .then(
        function (response) {
          return transformResponse(response);
        },
        function (err) {
          return {};
        }
      );
  }

  function getReleaseFlagString(features) {
    var enabled = [];
    _.forIn(features, function (value, key) {
      if (value) enabled.push(key);
    });
    return "'" + enabled.join("','") + "'";
  }

  function featureIsEnabled(key) {
    return config.features[key] || false;
  }

  // A2P Suppress Non-verified Inboxes
  function setA2pRestrictions(profile) {
    if (config.features["a2p-verification"] && profile.currentDepartment.campaignStatus !== "VERIFIED") {
      profile.inboxes = _.filter(profile.inboxes, function (inbox) {
        return inbox.type !== "group";
      });
    }
    return profile;
  }

  function setPermissionFeatureFlags(profile) {
    profile = setA2pRestrictions(profile);

    var permissionFeatureFlags = {
      analytics: config.plan !== "Legacy",
      "chat-only-user": profile.currentDepartment.userIsChatAgent && !profile.currentDepartment.userHasTexting,
      "chat-only-team": profile.currentDepartment.teamHasChat && !profile.currentDepartment.teamHasSms,
      "text-only-team": !profile.currentDepartment.teamHasChat && profile.currentDepartment.teamHasSms,
      "text-and-chat-team": profile.currentDepartment.teamHasChat && profile.currentDepartment.teamHasSms,
      compose: profile.inboxes.length > 0,
      "delete-users": profile.isOwner || (profile.isAdministrator && config.plan !== "Legacy"),
      "department-api": config.plan !== "Legacy",
      "department-users": config.plan !== "Legacy",
      exports: profile.isAdministrator || profile.isChatAdministrator,
      "group-limit": config.plan !== "Legacy",
      "manual-number-validation": config.plan === "Legacy",
      "personal-messaging":
        (config.plan === "Legacy" && !profile.isNumberlessUser) ||
        (config.plan !== "Legacy" && profile.currentDepartment.personalInboxEnabled),
      segments: profile.isOwner || profile.currentDepartment.isSegmentAuthorized,
      //profile.currentDepartment.authorizedFeatures.includes("Segments") || //TODO - figure this out
      manualContactAdd:
        profile.isOwner ||
        profile.currentDepartment.isAdministrator ||
        profile.currentDepartment.isManualContactAuthorized,
      "team-has-chat": profile.currentDepartment.teamHasChat,
      "user-is-chat-agent": profile.currentDepartment.userIsChatAgent,
      "smart-message-sending": _.some(profile.inboxes, function (inbox) {
        return inbox.type !== "personal";
      }),
      "unlimited-users": config.plan !== "Legacy",
      "teams-user-management": config.plan !== "Legacy",
    };

    _.assign(config.features, permissionFeatureFlags);

    return config;
  }

  function transformResponse(response) {
    config = response.plain();
    config.customFields = {
      notAvailable: config.department.customFieldStatus === "Off",
      setupRequired: config.department.customFieldStatus === "Ready",
      inProgress: config.department.customFieldStatus === "InProgress",
      enabled: config.department.customFieldStatus === "Enabled",
    };

    config.hasIntegration = _.includes(_.values(config.integrations), true);

    config.releaseFlagString = getReleaseFlagString(config.features);
    config.isEnabled = featureIsEnabled;
    return config;
  }

  return {
    get: get,
    setPermissionFeatureFlags: setPermissionFeatureFlags,
  };
});
