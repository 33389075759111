(function () {
    'use strict';

    angular.module('mongoose').config(function ($stateProvider) {
        $stateProvider.state('app.admin', {
            url: '/admin',
            abstract: true,
            template: '<ui-view/>',
            pageTitle: "Admin"
        })
    });
})();