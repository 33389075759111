angular.module('mongoose').factory('utilitiesService', function() {

    return {
        getMediaType: getMediaType,
        groupNumbersByType: groupNumbersByType
    };

    function getMediaType(fileTypeString) {
        return new RegExp("^image\/*").test(fileTypeString) ? 'image' :
            new RegExp("^audio\/*").test(fileTypeString) ? 'audio' :
            new RegExp("^video\/*").test(fileTypeString) ? 'video' : 'other';
    }

    function groupNumbersByType(numbers) {
        var groupedNumbers = {landlines: [], virtualNumbers: []};
        _.forEach(numbers, function (n) {
            var num = {};
            if (n.landline) {
                num = {
                    numberSid: n.sid,
                    phoneNumber: n.friendlyName
                };
                groupedNumbers.landlines.push(num);
            } else {
                if (n.sid) {
                    num = {
                        isReserved: true,
                        numberSid: n.sid
                    };
                }
                num.phoneNumber = n.friendlyName;
                groupedNumbers.virtualNumbers.push(num);
            }
        });
        return groupedNumbers;
    }

});