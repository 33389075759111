angular
  .module("mongoose")
  .controller(
    "FtpImportSettingsController",
    function ($q, $uibModal, $state, Restangular, growl, ftpConfig) {
      var vm = this;

      vm.addField = addField;
      vm.addIsDisabled = addIsDisabled;
      vm.config = ftpConfig.importConfiguration;
      vm.removeField = removeField;
      vm.save = save;
      vm.scheduleCategories = scheduleCategories();
      vm.scheduleCategoryChange = scheduleCategoryChange;

      activate();

      function activate() {}

      function addField() {
        var field = {
          fileColumnName: "",
          customField: {
            displayName: "",
            isRequired: true,
            canMailMerge: false,
            isVisible: true,
          },
        };

        vm.config.fileConfiguration.customFields.push(field);
      }

      function addIsDisabled() {
        var fields = vm.config.fileConfiguration.customFields,
          fileNameCollection = _.map(fields, "fileColumnName"),
          displayNameCollection = _.map(fields, "customField.displayName");

        if (fields.length === 0) return false;

        return (
          fields.length !== _.compact(fileNameCollection).length ||
          fields.length !== _.compact(displayNameCollection).length
        );
      }

      function removeField(field) {
        if (_.isUndefined(field.customField.id))
          return _.pull(vm.config.fileConfiguration.customFields, field);

        var modalInstance = $uibModal.open({
          templateUrl: "integrations/remove-field-modal.html",
          controller: "RemoveFieldModalController",
          controllerAs: "vm",
          ariaLabelledBy: "remove-field",
          resolve: {
            field: field,
          },
        });

        modalInstance.result.then(
          function (field) {
            vm.config.fileConfiguration.customFields = _.reject(
              vm.config.fileConfiguration.customFields,
              function (n) {
                return n.customField.id === field.customField.id;
              }
            );
          },
          function () {}
        );
      }

      function save() {
        vm.loading = true;

        Restangular.one("ftp/importConfiguration")
          .post("", { importConfiguration: vm.config })
          .then(
            function (response) {
              growl.success("Import Settings Updated");
              vm.config = response.plain();
              vm.loading = false;
            },
            function (response) {
              growl.error(response.data);
              vm.loading = false;
            }
          );
      }

      function scheduleCategories() {
        return _.map(
          [
            { category: "Overnight", label: "3am-5am" },
            { category: "Morning", label: "10am-12pm" },
            { category: "Afternoon", label: "2pm-4pm" },
            { category: "Evening", label: "6pm-8pm" },
          ],
          function (item) {
            item.selected =
              _.findIndex(vm.config.schedules, { category: item.category }) >
              -1;
            return item;
          }
        );
      }

      function scheduleCategoryChange(item) {
        if (item.selected) {
          vm.config.schedules.push(item);
        } else {
          _.remove(vm.config.schedules, { category: item.category });
        }
      }
    }
  );
