angular.module('mongoose').controller('MessageReportController', function ($scope, $state, $filter, profile, Restangular, FileSaver, Blob) {

    var vm = this,
        isOwner = profile.isOwner;

    vm.startDate = moment().subtract(1, 'month').format();
    vm.endDate = moment().format();
    vm.dateChanged = dateChanged;
    vm.resetView = activate;
    vm.toggleGroupUsers = toggleGroupUsers;


    activate();

    function activate() {
        if(isOwner){
            Restangular.all('departments').getList().then(function (departments) {
                vm.departments = departments;

                getUsage().then(function(){
                    vm.loaded = true;
                });
            });

            $scope.$watch('vm.selectedDepartment', changeDepartment);
        } else {
            getUsage().then(function(){
                vm.loaded = true;
            });
        }
    }

    function changeDepartment(newValue, oldValue){
        if(!newValue && !oldValue)
            return false;

        getUsage().then(angular.noop, handleUsageQueryError);
    }

    function dateChanged() {
        if(!vm.startDate || !vm.endDate)
            return false;

        getUsage().then(angular.noop, handleUsageQueryError);
    }

    function handleUsageQueryError() {
        vm.failedQuery = true;
    }

    function getUsage(){
        vm.failedQuery = false;

        var params = {startDate: moment(vm.startDate).format('MM/DD/YYYY'), endDate: moment(vm.endDate).format('MM/DD/YYYY')},
            isOwner = profile.isOwner,
            isDepartment = _.isObject(vm.selectedDepartment),
            promise;

        if(isOwner && !isDepartment) {
            promise = Restangular.one('outgoingmessage/usage/organization').get(params).then(function(response){
                var results = response.plain(),
                    mappedUserResults = mapUsers(results.userAccountBreakdown),
                    mappedGroupAccountResults = mapGroups(results.groupAccountBreakdown);

                vm.organizationUsage = results;
                vm.organizationUsage.breakdown = $filter('orderBy')(mappedUserResults.concat(mappedGroupAccountResults), '-messageCount');
            });
        } else {
            var departmentId = isOwner ? vm.selectedDepartment.id : profile.currentDepartment.id;
            _.assign(params, {departmentId: departmentId});

            promise = Restangular.one('outgoingmessage/usage/department').get(params).then(function (response) {

                var results = response.plain(),
                    mappedUserResults = mapUsers(results.userAccountBreakdown),
                    mappedGroupAccountResults = mapGroups(results.groupAccountBreakdown);

                vm.organizationUsage = results;
                vm.organizationUsage.breakdown = $filter('orderBy')(mappedUserResults.concat(mappedGroupAccountResults), '-messageCount');
            });
        }

        return promise;
    }

    function mapUsers(users){
        return _.map(users, function (u) {
            var user = {
                staffId: u.staffId,
                name: u.firstName + ' ' + u.lastName,
                messageCount: u.messageCount,
                unreadCount: u.unreadCount,
                deliverablePercentage: u.deliverablePercentage,
                replyPercentage: u.replyPercentage
            };

            if(u.groupAccountId)
                user.parentId = u.groupAccountId;

            return user;
        });
    }

    function mapGroups(groups){
        return _.map(groups, function (g) {
            return {
                groupAccountId: g.groupAccountId,
                name: g.name,
                messageCount: g.messageCount,
                unreadCount: g.unreadCount,
                deliverablePercentage: g.deliverablePercentage,
                replyPercentage: g.replyPercentage,
                color: g.color,
                group: true,
                breakdown: $filter('orderBy')(mapUsers(g.breakdown), '-messageCount')
            };
        });
    }

    function toggleGroupUsers(account){
        if(!account.group) return;

        var list = vm.organizationUsage.breakdown,
            index = _.findIndex(list, 'groupAccountId', account.groupAccountId);

        if(account.active){
            vm.organizationUsage.breakdown = _.reject(list, 'parentId', account.groupAccountId);
        } else {
            _.forEach(account.breakdown, function(user, i){
                list.splice(index + 1 + i, 0, user);
            });
        }
        account.active = !account.active;
    }

    vm.getUnreadMessages = function(user){
        var params = {startDate: vm.startDate, endDate: vm.endDate, departmentId: _.isObject(vm.selectedDepartment) ? vm.selectedDepartment.id : null, staffId: user ? user.staffId : null, groupAccountId: user ? user.groupAccountId : null};

        Restangular.all("incomingmessages/unread").getList(params).then(function(unreadMessages) {
            var accumulator = '';
            unreadMessages.forEach(function(unread,index){
                accumulator += index < unreadMessages.length ? unread + "\r\n" : unread;
            });
            var data = new Blob([accumulator], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(data, 'unread_messages.csv');
        });
    }

});