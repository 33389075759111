/**
 * @desc shared toolbar for emojis, templates, fields, etc
 * @example <div compose-toolbar></div>
 */
angular
    .module('mongoose')
    .directive('composeToolbar', composeToolbar);

function composeToolbar($timeout, Restangular) {

    return {
        link: linkFunc,
        templateUrl: 'core/compose-toolbar/compose-toolbar.html',
        restrict: 'EA'
    };

    function linkFunc(scope, el) {
        el.addClass('compose-toolbar');
    }
}