(function () {
  "use strict";

  angular
    .module("mongoose")
    .controller("DepartmentsListController", DepartmentsListController);

  function DepartmentsListController($state, $uibModal, profile, Restangular) {
    var vm = this;

    vm.createDepartment = createDepartment;
    vm.deleteDepartment = deleteDepartment;
    vm.editDepartment = editDepartment;

    activate();

    function activate() {
      Restangular.all("departments")
        .customGET("getWithCounts")
        .then(function (results) {
          vm.departments = results.map(function (dept) {
            dept.allowDelete = !(
              dept.id === profile.currentDepartment.id || results.length === 1
            );
            return dept;
          });
        });
    }

    function createDepartment() {
      $uibModal
        .open({
          templateUrl: "owner/organization/departments/new-department.html",
          controller: "NewDepartmentController",
          controllerAs: "vm",
          ariaLabelledBy: "new-dept",
          resolve: {
            categories: getDepartmentCategories,
            departments: function () {
              return vm.departments;
            },
          },
        })
        .result.then(
          function () {
            $state.reload();
          },
          function () {}
        );
    }

    function deleteDepartment(department) {
      $uibModal
        .open({
          templateUrl: "owner/organization/departments/delete-department.html",
          controller: "DeleteDepartmentController",
          controllerAs: "vm",
          ariaLabelledBy: "delete-dept",
          resolve: {
            department: function () {
              return department;
            },
            departments: function () {
              return vm.departments;
            },
          },
        })
        .result.then(
          function () {
            $state.reload();
          },
          function () {}
        );
    }

    function editDepartment(department) {
      $uibModal
        .open({
          templateUrl: "owner/organization/departments/edit-department.html",
          controller: "EditDepartmentController",
          controllerAs: "vm",
          ariaLabelledBy: "edit-dept",
          resolve: {
            categories: getDepartmentCategories,
            department: function () {
              return department;
            },
            departments: function () {
              return vm.departments;
            },
          },
        })
        .result.then(
          function () {
            $state.reload();
          },
          function () {}
        );
    }

    function getDepartmentCategories() {
      return Restangular.all("departments").get("categories");
    }
  }
})();
