angular.module('mongoose').factory('ftpService', function($q, Restangular, growl) {

    return {
        config: config,
        getConnectionStatus: getConnectionStatus,
        getDashboardData: getDashboardData,
        getExports: getExports,
        getImports: getImports
    };

    function config(){

        var deferred = $q.defer(),
            config;

        Restangular.one('ftp/configuration').get().then(function(result){
            config = result.plain();
            config.status = {
                connectionIsConfigured: !!(config.server && config.username),
                exportIsConfigured: !config.deliveryFailureConfiguration.isSample || !config.messageActivityExportConfiguration.isSample || !config.optOutExportConfiguration.isSample,
                importIsConfigured: !config.importConfiguration.isSample
            };
            deferred.resolve(config);
        },
        function(){
            config = {status: {
                    isConnected: false,
                    exportIsConfigured: false,
                    importIsConfigured: false
            }};
            deferred.resolve(config);
        });

        return deferred.promise;
    }

    function getConnectionStatus(){
        return Restangular.one('ftp/authentication').get({}, {'x-disable-loading': true});
    }

    function getDashboardData(options){
        var exportRequest = getExports(options),
            importRequest = getImports(options);

        return $q.all([exportRequest, importRequest]).then(function(results){
            return {
                exports: results[0],
                imports: results[1]
            };
        });
    }

    function getExports(options){
        var params = {};
        if(!_.isUndefined(options.limit))
            params.limit = options.limit;

        return Restangular.one('ftp/exports').get(params, {'x-disable-loading': options.disableLoading}).then(function(response){
            return _.map(response.plain(), function(item){
                item.dateStarted = formatDateWithTimezone(item.dateStarted, options);
                item.dateCompleted = formatDateWithTimezone(item.dateCompleted, options);
                item.exportType = item.exportType === 'DeliveryErrors' ? 'Delivery Errors'
                    : item.exportType === 'OptOuts' ? 'Opt-Outs'
                        : item.exportType === 'MessageActivity' ? 'Messages' : ''
                item.isErrorStatus = item.status === 'FTP Error' || item.status === 'Failed Processing';
                item.isDone = item.isErrorStatus || item.status === 'Skipping export - no records' || item.status === 'Complete';
                return item;
            });
        }, function() {
            growl.error("An error has occurred updating export history.  Please reload the page.");
        });
    }

    function getImports(options){
        var params = {};
        if(!_.isUndefined(options.limit))
            params.limit = options.limit;

        return Restangular.one('ftp/imports').get(params, {'x-disable-loading': options.disableLoading}).then(function(response){
            return _.map(response.plain(), function(item){
                item.dateStarted = formatDateWithTimezone(item.dateStarted, options);
                item.dateCompleted = formatDateWithTimezone(item.dateCompleted, options);
                item.isErrorStatus = item.status === 'FTP Error' || item.status === 'Error Processing File' || item.status === 'Post Processing Error';
                item.isDone = item.isErrorStatus || item.status === 'Complete';
                item.showErrorCount = item.status === 'Processing' || item.status === 'Complete' || item.status === 'Post Processing Error';
                return item;
            });
        }, function() {
            growl.error("An error has occurred updating import status.  Please reload the page.");
        });
    }

    function formatDateWithTimezone(d, options){
        return d ? moment.tz(moment.utc(d), options.timezone).format('M/D/YY,\u00a0h:mma') : '';
    }

});
