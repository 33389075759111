(function() {
    'use strict';

    angular.module('mongoose').config(function ($stateProvider) {

        $stateProvider.state('app.owner.organization', {
            url: '/organization',
            abstract: true,
            template: '<ui-view />',
            pageTitle: "Organization"
        });

        $stateProvider.state('app.owner.organization.reporting', {
            url: '/reporting',
            abstract: true,
            templateUrl: 'owner/organization/reporting/reporting.html',
            pageTitle: "Reporting"
        });

        $stateProvider.state('app.owner.organization.reporting.usage', {
            url: '/usage',
            templateUrl: 'owner/organization/reporting/usage/usage.html',
            controller: 'OrganizationUsageController',
            pageTitle: "Usage"
        });

        $stateProvider.state('app.owner.organization.reporting.numberValidation', {
            url: '/number-validation',
            templateUrl: 'owner/organization/reporting/numberValidation/number-validation.html',
            controller: 'OrganizationValidationUsageController',
            controllerAs: 'vm',
            pageTitle: "Number Validation"
        });

        $stateProvider.state('app.owner.organization.departments', {
            url: '/departments',
            templateUrl: 'owner/organization/departments/list.html',
            controller: 'DepartmentsListController',
            controllerAs: "vm",
            pageTitle: "Departments"

        });

        $stateProvider.state('app.owner.organization.sso', {
            url: '/sso',
            templateUrl: 'owner/organization/sso/list.html',
            controller: 'SSOListController',
            controllerAs: "vm",
            pageTitle: "SSO"

        });
    });
})();

