angular.module('mongoose').controller('TextingReportGeneralController', function ($rootScope, $scope, $state, reportService) {

    var vm = this;
    vm.submit = submit;

    activate();

    function activate() {
        vm.isFunnel = false;
        vm.name = '';
        $scope.$emit('texting-report-step-change',1);
    }
    
    function submit() {
        reportService.initialize(vm.name, vm.isFunnel);
        $state.go('app.owner.report.data');
    }

});