angular.module('mongoose').controller('DeleteStudentModalController', function ($scope, $uibModalInstance, Restangular, studentService, studentId) {

    var initialize = function() {
        Restangular.one('students', studentId).get().then(function(student) {
            student.displayName = studentService.getDisplayName(student);
            $scope.student = student;
            $scope.departmentNames = _.map(student.departments,function(d) {return d.name}).join(', ');
        });
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    }

    $scope.delete = function () {
        $scope.validationMessage = '';
        Restangular.one('students', studentId).remove().then(function () {
            mixpanel.track("Deleted Student");
            $uibModalInstance.close();
        }, function(response){
            $scope.validationMessage = response.data;
        });
    };

    initialize();
});