 angular
    .module('mongoose')
    .directive('onDrag', onDrag);

function onDrag() {

    return {
        restrict: 'A',
        link: linkFunc
    };

    function linkFunc(scope, el, attrs) {
        el.on('dragenter', function(){
            scope.$broadcast('dragevent-dragenter');
        });
    }
}