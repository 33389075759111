angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.campaigns", {
      abstract: true,
      template: "<ui-view></ui-view>",
      pageTitle: "Campaigns",
    })
    .state("app.campaigns.list", {
      url: "/campaigns",
      templateUrl: "campaigns/list.html",
      controller: "CampaignsListController as vm",
      pageTitle: "Campaigns",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/campaigns");
        },
      },
    });
});
