(function() {
    'use strict';

    angular
        .module('mongoose')
        .directive('appNotifications', appNotifications);

    function appNotifications($rootScope, $sce, notificationService) {

        return {
            restrict: 'E',
            link: linkFunc,
            controller: controllerFunc,
            controllerAs: 'vm',
            scope: {},
            templateUrl: 'core/appNotifications.html'
        };


        function linkFunc(scope, el, attr, ctrl) {
        }

        function controllerFunc(){

            var vm = this;

            $rootScope.$on('notificationUpdate', getNotifications);

            activate();

            function activate(){
                getNotifications();
            }

            function getNotifications(){
                var notifications = notificationService.get();

                vm.notifications = _.map(notifications, function(n){
                    if(!_.isUndefined(n.message) && _.isString(n.message)){
                        n.message = $sce.trustAsHtml(n.message);
                    }
                    return n;
                });
            }
        }

    }
})();