angular.module('mongoose').controller('AdminDeleteUserModalController', function (user, $uibModalInstance, Restangular, profile, config) {

    var vm = this;
    
    activate();

    function activate() {
        Restangular.one('admin/users', user.id).get().then(function(user){
            vm.multiDepartmentUser = user.departments.length > 1;
        });
    }

    vm.canDelete = function() {
        return vm.userConfirm && vm.userConfirm.toLowerCase() === vm.user.displayName.toLowerCase();
    };

    vm.close = $uibModalInstance.dismiss;

    vm.delete = function () {
        vm.loading = true;
        if (vm.multiDepartmentUser) {
            Restangular.all('admin/users/remove').post({id: user.id, departmentId: profile.currentDepartment.id}).then(function () {
                mixpanel.track('Admin Removed Multi-Department User', {
                    'User Name': user.displayName
                });
                $uibModalInstance.close();
            }, function(){
                vm.loading = false;
            });
        } else {
            Restangular.one('admin/users', user.id).remove().then(function () {
                mixpanel.track('Admin Deleted Single-Deparmtent User', {
                    'User Name': user.displayName
                });
                $uibModalInstance.close();
            }, function(){
                vm.loading = false;
            });
        }
    };

    vm.user = user;

});