(function () {
  "use strict";

  angular.module("mongoose").controller("MessageTypesListController", MessageTypesListController);

  function MessageTypesListController($state, $uibModal, Restangular, growl) {
    var vm = this;

    initialize();

    vm.new = function () {
      var modalInstance = $uibModal.open({
        templateUrl: "admin/department/integration/messagetypes/new.html",
        controller: "NewMessageTypeController",
        controllerAs: "vm",
        ariaLabelledBy: "new-template",
        resolve: {
          messageTypes: function () {
            return vm.messageTypes;
          },
        },
      });
      modalInstance.result.then(
        function () {
          $state.reload();
        },
        function () {}
      );
    };

    vm.makeDefault = function (messageType) {
      Restangular.all("admin/messageTypes")
        .customPUT(messageType, "makeDefault")
        .then(function () {
          growl.success("Your message type has been updated!");
          $state.reload();
        });
    };

    vm.enable = function (messageType) {
      messageType.isEnabled = true;
      Restangular.all("admin/messageTypes")
        .customPUT(messageType)
        .then(function () {
          growl.success("Message type has been enabled!");
          $state.reload();
        });
    };

    vm.disable = function (messageType) {
      messageType.isEnabled = false;
      Restangular.all("admin/messageTypes")
        .customPUT(messageType)
        .then(function () {
          growl.success("Message type has been disabled!");
          $state.reload();
        });
    };

    vm.edit = function (messageType) {
      var modalInstance = $uibModal.open({
        templateUrl: "admin/department/integration/messagetypes/edit.html",
        controller: "EditMessageTypeController",
        controllerAs: "vm",
        ariaLabelledBy: "edit",
        resolve: {
          messageType: function () {
            return messageType;
          },
          messageTypes: function () {
            return vm.messageTypes;
          },
        },
      });
      modalInstance.result.then(
        function () {
          $state.reload();
        },
        function () {}
      );
    };

    function initialize() {
      Restangular.all("admin/messageTypes")
        .getList()
        .then(function (results) {
          vm.messageTypes = results.plain();
        });
    }
  }
})();
