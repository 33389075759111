angular.module('mongoose').controller('DeleteCampaignModalController', function (campaign, $uibModalInstance, Restangular) {

    var vm = this;

    vm.delete = function () {
        campaign.remove().then(function () {
            mixpanel.track('Deleted Campaign');
            $uibModalInstance.close();
        });
    };

    vm.close = $uibModalInstance.dismiss;

    var load = function () {

        vm.campaign = campaign;

        Restangular.one("messages").getList("scheduled").then(function(response){
            var lists = response.plain(),
                campaigns = [],
                scheduledCount = 0;

            //todo: add hooks to tie campaigns to messages so we can display a warning
            // _.forEach(lists, function(list){
            //
            //     if(_.indexOf(campaigns, campaign.id) > -1){
            //         scheduledCount++;
            //     }
            // });

            vm.scheduledCount = scheduledCount;
        });
    };
    load();
});