angular.module('mongoose').controller('TextingReportCompleteController', function ($rootScope, $scope, reportService, Restangular) {

    var vm = this;
    vm.submit = submit;

    activate();

    function activate() {
        $scope.$emit('texting-report-step-change',3);
    }
    
    function submit() {    
        
        Restangular.all('textingreport').post(reportService.getReport()).then(function () {
            growl.success("Report Processing...");
            $uibModalInstance.close();
        },
        function(errResponse) {
            vm.serverValidationError = errResponse.data;
        });
        $state.go('app.owner.report.complete');
    }

});