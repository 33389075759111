angular.module('mongoose').controller('NewTemplateController', function ($scope, $stateParams, $compile, $sce, $state, $uibModal, $uibModalInstance, growl, Restangular, template) {

    var validate = function() {
            $scope.hasValidationErrors.name = !$scope.template.name;
            $scope.hasValidationErrors.message = !$scope.message.text;
            return !_.includes($scope.hasValidationErrors,true) && $scope.message.text.length <=306;
    },
    intialize = function() {
        $scope.template = { message: '&nbsp;', isEnabled: true  };
        $scope.message = { text:'' };
        $scope.devSection = true;
        $scope.hasValidationErrors = {
            name: false,
            message: false
        };

        if (template) {
            $scope.template = template;
            $scope.message.text = $scope.template.message;
        }
    };

    $scope.insertEmoji = function (emoji) {
        const replyTextarea = document.getElementById("messageText");
        const caret = replyTextarea.selectionStart;

        if (!$scope.message.text || $scope.message.text.trim().length === 0) {
          $scope.message.text = emoji.unicode;
        } else {
          var partOne = $scope.message.text.substring(0, caret);
          if (partOne.length > 0 && partOne.charAt(partOne.length - 1) !== " ") {
            partOne += " ";
          }
          var partTwo = $scope.message.text.substring(caret);
          if (partTwo.length > 0 && partOne.charAt(0) !== " ") {
            partTwo = " " + partTwo;
          }
          $scope.message.text = partOne + emoji.unicode + partTwo;
        }

        mixpanel.track("Inserted Emoji", {
          Emoji: emoji.unicode,
        });
      }

    $scope.updateCode = function() {
        if (!$scope.template.code) {
            var matches = $scope.template.name.match(/\b(\w)/g);
            $scope.template.code = matches.join('');
        }
    };

    $scope.save = function () {
        if (validate()) {
            $scope.template.message = $scope.message.text;
            $scope.loading = true;
            Restangular.all("templates").post($scope.template).then(function () {
                growl.success("Your template has been saved successfully!");
                if ($scope.template.id) {
                    mixpanel.track('Updated Template');
                } else {
                    mixpanel.track('Created Template');
                }
                $uibModalInstance.close();
            },
            function(errResponse) {
                $scope.loading = false;
                $scope.serverValidationError = errResponse.data;
            });
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };

    intialize();
});
