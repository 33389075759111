/**
 * @desc hijack tag-input and jack in a button
 * @example <add-student-button></add-student-button>
 */
angular
    .module('mongoose')
    .directive('addStudentButton', addStudentButton);

function addStudentButton() {

    return {
        link: linkFunc,
        template: '<button id="addStudentButton" type="button" class="btn btn-sm btn-primary ng-hide autocomplete-add-button" ng-click="goToAddStudent()" ng-show="showAddStudentButton">Add Contact</button>',
        restrict: 'E'
    };

    function linkFunc(scope, el) {
        var input = $('#recipients').find('input'),
            addButton = $('#addStudentButton');
        input.focus();
        addButton.insertAfter(input);
    }
}