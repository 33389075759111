/**
 * @desc button for selecting a file to upload
 * @example <media-picker></media-picker>
 */
angular.module("mongoose").directive("mediaPicker", mediaPicker);

function mediaPicker($rootScope, $timeout, Upload, utilitiesService) {
  return {
    link: linkFunc,
    template:
      '<button ng-if="mediaPicker.isEnabled" type="button" class="btn btn-xs btn-default btn-icon btn-media" ng-class="{\'active\': mediaPicker.isActive, \'disabled-ui\': mediaPicker.isDisabled}" ng-click="mediaPicker.enable()"><i class="fa fa-image"></i><span class="sr-only">Insert Media</span></button>',
    restrict: "E",
  };

  function linkFunc(scope, el, attr) {
    var errorTimeout;

    scope.mediaPicker = {
      disable: disable,
      enable: enable,
      isActive: false,
      isDisabled: false,
      isEnabled: false,
      isUploading: false,
      acceptedTypes: ".gif,.png,.jpg,.jpeg",
      maxFiles: 1,
      maxSize: "5MB",
      getMediaType: utilitiesService.getMediaType,
      progressPercentage: 0,
      reset: reset,
      upload: upload,
      uploadComplete: false,
    };

    loadExistingFile();

    scope.$on("dragevent-dragenter", function () {
      $timeout(enable);
    });

    $rootScope.$on("resetMediaPicker", reset);

    scope.$watch(attr.enabled, function (enabled) {
      scope.mediaPicker.isEnabled = enabled;
    });

    scope.$watch(attr.disabled, function (disabled) {
      scope.mediaPicker.isDisabled = disabled;
    });

    function disable() {
      scope.mediaPicker.isActive = false;
      scope.mediaPicker.fileError = {};
    }

    function enable() {
      if (scope.mediaPicker.isDisabled) return;

      scope.mediaPicker.isActive = true;
    }

    function loadExistingFile() {
      if (!scope.message.mediaUris || scope.message.mediaUris.length === 0)
        return;

      var existingFile = scope.message.mediaUris[0],
        path = existingFile.cdnMediaUri,
        parts = path.split("/"),
        filename = parts[parts.length - 1];

      scope.mediaPicker.file = {
        name: filename,
        path: existingFile.cdnMediaUri,
        type: existingFile.mimeType,
      };
      scope.mediaPicker.uploadComplete = true;
    }

    function reset() {
      scope.mediaPicker.disable();
      scope.mediaPicker.uploadComplete = false;
      scope.message.mediaUris = [];
      scope.mediaPicker.file = "";
    }

    function upload(file) {
      if (!_.isUndefined(errorTimeout)) $timeout.cancel(errorTimeout);

      scope.mediaPicker.fileError = {};
      scope.mediaPicker.serverError = "";
      scope.mediaPicker.isUploading = true;
      scope.mediaPicker.disable();

      Upload.upload({
        url: "https://sms-api-dev.mongooseresearch.com/api/media/upload",
        data: { file: file },
        headers: { "x-disable-loading": true },
      }).then(
        function (response) {
          var uploadedFile = response.data;

          scope.mediaPicker.file = {
            name: file.name,
            path: uploadedFile.cdnMediaUri,
            type: uploadedFile.mimeType,
          };
          scope.message.mediaUris = [response.data];
          scope.mediaPicker.uploadComplete = true;
        },
        function (response) {
          scope.mediaPicker.enable();
          if (response && response.data) {
            scope.mediaPicker.serverError = response.data[0];
            scope.mediaPicker.file = "";
            scope.message.mediaUris = [];
          }
          errorTimeout = $timeout(function () {
            scope.mediaPicker.fileError = {};
            scope.mediaPicker.serverError = "";
          }, 4000);
        },
        function (event) {
          var progress = parseInt((100.0 * event.loaded) / event.total);
          scope.mediaPicker.progressPercentage = progress;

          if (progress == 100) {
            $timeout(function () {
              scope.mediaPicker.isUploading = false;
            }, 1000);
          }
        }
      );
    }
  }
}
