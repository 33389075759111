angular
  .module("mongoose")
  .controller(
    "WorkdayImportSettingsController",
    function (
      $q,
      $uibModal,
      $state,
      Restangular,
      growl,
      fieldService,
      workdayConfig
    ) {
      var vm = this;

      vm.config = { workdayImportConfiguration: {} };

      vm.shareOptions = [
        { value: false, text: "No" },
        { value: true, text: "Yes" },
      ];

      vm.scheduleCategories = scheduleCategories();
      vm.scheduleCategoryChange = scheduleCategoryChange;
      vm.addField = addField;
      vm.addIsDisabled = addIsDisabled;
      vm.removeField = removeField;

      vm.save = save;

      activate();

      function activate() {
        var types = Restangular.all("admin/messageTypes").getList(),
          fields = fieldService.get();

        vm.config.workdayImportConfiguration =
          workdayConfig.importConfiguration;

        $q.all([types, fields, workdayConfig]).then(function (results) {
          vm.messageTypes = _.sortBy(results[0].plain(), "description");
          vm.customFields = _.sortBy(results[1].plain(), "displayName");
        });
      }

      function save() {
        vm.loading = true;
        Restangular.one("workday/importConfiguration")
          .post("", vm.config)
          .then(function () {
            growl.success("Import Settings Updated");
            $state.go("app.integrations.workday.main.dashboard");
          })
          .catch(function (response) {
            growl.error(response);
          })
          .finally(function () {
            vm.loading = false;
          });
      }

      function addField() {
        var field = {
          fileColumnName: "",
          customField: {
            displayName: "",
            isRequired: true,
            canMailMerge: false,
            isVisible: true,
          },
        };

        vm.config.fileConfiguration.customFields.push(field);
      }

      function addIsDisabled() {
        var fields = vm.config.fileConfiguration.customFields,
          fileNameCollection = _.map(fields, "fileColumnName"),
          displayNameCollection = _.map(fields, "customField.displayName");

        if (fields.length === 0) return false;

        return (
          fields.length !== _.compact(fileNameCollection).length ||
          fields.length !== _.compact(displayNameCollection).length
        );
      }

      function removeField(field) {
        if (_.isUndefined(field.customField.id))
          return _.pull(vm.config.fileConfiguration.customFields, field);

        var modalInstance = $uibModal.open({
          templateUrl: "integrations/remove-field-modal.html",
          controller: "RemoveFieldModalController",
          controllerAs: "vm",
          ariaLabelledBy: "remove-field",
          resolve: {
            field: field,
          },
        });

        modalInstance.result.then(
          function (field) {
            vm.config.fileConfiguration.customFields = _.reject(
              vm.config.fileConfiguration.customFields,
              function (n) {
                return n.customField.id === field.customField.id;
              }
            );
          },
          function () {}
        );
      }

      function scheduleCategories() {
        return _.map(
          [
            { category: "Overnight", label: "3am-5am" },
            { category: "Morning", label: "10am-12pm" },
            { category: "Afternoon", label: "2pm-4pm" },
            { category: "Evening", label: "6pm-8pm" },
          ],
          function (item) {
            item.selected =
              _.findIndex(vm.config.workdayImportConfiguration.schedules, {
                category: item.category,
              }) > -1;
            return item;
          }
        );
      }

      function scheduleCategoryChange(item) {
        if (item.selected) {
          vm.config.workdayImportConfiguration.schedules.push(item);
        } else {
          _.remove(vm.config.workdayImportConfiguration.schedules, {
            category: item.category,
          });
        }
      }
    }
  );
