angular
  .module("mongoose")
  .controller(
    "DepartmentUsersController",
    function ($scope, $state, $q, Restangular, $uibModal, caretakerService, growl, profile, config, contractService) {
      var vm = this,
        allUsers = [];

      vm.canDeleteUser = canDeleteUser;
      vm.showAll = false;
      vm.search = search;
      vm.profile = profile;
      vm.config = config;
      vm.includeDepartment = vm.showAll;

      load().then(setup);

      function load() {
        var usersRequestParams = config.isEnabled("department-users") ? { currentDepartmentOnly: true } : {},
          requests = [
            caretakerService.get(),
            Restangular.all("admin/users").getList(usersRequestParams),
            contractService.get(),
          ];

        return $q.all(requests);
      }

      function setup(results) {
        var relationships = results[0].plain(),
          users = results[1].plain().map(function (user) {
            var currentDepartment = _.find(user.departments, {
              departmentId: profile.currentDepartment.id,
            });
            user.inCurrentDepartment = user.isOwner || !_.isUndefined(currentDepartment);
            return user;
          });

        vm.assignCaretaker = assignCaretaker;
        vm.canBeTarget = canBeTarget;
        vm.editUser = editUser;
        vm.removeCaretaker = removeCaretaker;
        vm.newUser = newLegacyUser;
        vm.users = relationships.length ? groupUsersByCaretaker(relationships, users) : users;
        vm.userCount = users.length;
        vm.contract = results[2];
        vm.deleteUser = deleteUser;
        vm.convertToGroup = convertToGroup;
      }

      function newLegacyUser() {
        $uibModal
          .open({
            templateUrl: "admin/department/users/legacy/new-user.html",
            controller: "LegacyNewUserController",
            controllerAs: "vm",
            backdrop: "static",
            resolve: {
              existingUsers: function () {
                return {
                  campusIdentifiers: _.map(allUsers, function (u) {
                    return u.campusUniqueId;
                  }),
                  emailAddresses: _.map(allUsers, function (u) {
                    return u.emailAddress;
                  }),
                };
              },
              profile: function () {
                return profile;
              },
              config: function () {
                return config;
              },
            },
          })
          .result.then(function () {
            load().then(setup);
          });
      }

      function canDeleteUser(user) {
        return config.isEnabled("delete-users") && user.id !== profile.id;
      }

      function assignCaretaker(user) {
        var modalInstance = $uibModal.open({
          templateUrl: "admin/department/users/assign-caretaker.html",
          controller: "AssignDepartmentCaretakerController",
          controllerAs: "vm",
          ariaLabelledBy: "assign-caretaker",
          resolve: {
            user: function () {
              return user;
            },
            users: function () {
              return vm.users;
            },
          },
        });
        modalInstance.result.then(function () {
          load().then(setup);
        });
      }

      function canBeTarget(user) {
        var hasUserRole = !!_.find(user.departments, {
          isAdministrator: false,
        });
        return hasUserRole && !user.isNumberlessUser && !user.isCaretaker && !user.caretakerId;
      }

      function editUser(userId) {
        return config.isEnabled("department-users") ? editDepartmentUser(userId) : editLegacyUser(userId);
      }

      function editDepartmentUser(userId) {
        $uibModal
          .open({
            templateUrl: "admin/department/users/edit-user.html",
            controller: "EditUserController",
            controllerAs: "vm",
            ariaLabelledBy: "edit-user",
            resolve: {
              userId: function () {
                return userId;
              },
              existingUsers: function () {
                return {
                  campusIdentifiers: _.map(allUsers, function (u) {
                    return u.campusUniqueId;
                  }),
                  emailAddresses: _.map(allUsers, function (u) {
                    return u.emailAddress;
                  }),
                };
              },
              profile: function () {
                return profile;
              },
              config: function () {
                return config;
              },
            },
          })
          .result.then(function () {
            load().then(setup);
          });
      }

      function editLegacyUser(userId) {
        $uibModal
          .open({
            templateUrl: "admin/department/users/legacy/edit-user.html",
            controller: "LegacyEditUserController",
            controllerAs: "vm",
            ariaLabelledBy: "edit-user",
            resolve: {
              userId: function () {
                return userId;
              },
              existingUsers: function () {
                return {
                  campusIdentifiers: _.map(allUsers, function (u) {
                    return u.campusUniqueId;
                  }),
                  emailAddresses: _.map(allUsers, function (u) {
                    return u.emailAddress;
                  }),
                };
              },
              profile: function () {
                return profile;
              },
            },
          })
          .result.then(function () {
            load().then(setup);
          });
      }

      function groupUsersByCaretaker(relationships, users) {
        var groupedByCaretaker = _.groupBy(relationships, function (n) {
            return n.caretakerStaffId;
          }),
          targets = removeTargetsFromList(relationships, users);

        _.forEach(groupedByCaretaker, function (group, key) {
          var caretakerId = parseInt(key),
            caretakerIndex = _.findIndex(users, { id: caretakerId }),
            insertIndex = caretakerIndex !== -1 ? caretakerIndex + 1 : users.length;

          if (caretakerIndex !== -1) {
            users[caretakerIndex].isCaretaker = true;
          }

          _.forEach(group, function (n) {
            var target = _.remove(targets, { id: n.targetStaffId })[0];
            if (target) {
              target.caretakerId = caretakerId;
              users.splice(insertIndex, 0, target);
              insertIndex++;
            }
          });
        });

        return users;
      }

      function removeTargetsFromList(relationships, users) {
        var targets = [];
        _.each(relationships, function (item) {
          targets.push(_.remove(users, { id: item.targetStaffId })[0]);
        });
        return targets;
      }

      function deleteUser(user) {
        $uibModal
          .open({
            templateUrl: profile.isOwner
              ? "admin/department/users/delete-user-modal.html"
              : "admin/department/users/admin-delete-user-modal.html",
            controller: profile.isOwner ? "DeleteUserModalController" : "AdminDeleteUserModalController",
            controllerAs: "vm",
            ariaLabelledBy: "delete-user",
            resolve: {
              user: function () {
                return user;
              },
              profile: function () {
                return profile;
              },
              config: function () {
                return config;
              },
            },
          })
          .result.then(function () {
            load().then(setup);
          });
      }

      function removeCaretaker(user) {
        var caretakerId = user.caretakerId,
          targetId = user.id;

        caretakerService.remove(caretakerId, targetId).then(function () {
          load().then(setup);
        });
      }

      function search(item) {
        if (
          (item.inCurrentDepartment || (vm.showAll && !item.inCurrentDepartment)) &&
          (!vm.searchText ||
            item.displayName.toLowerCase().indexOf(vm.searchText.toLowerCase()) != -1 ||
            item.emailAddress.toLowerCase().indexOf(vm.searchText.toLowerCase()) != -1)
        ) {
          return true;
        }
        return false;
      }
    }
  );
