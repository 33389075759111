
angular.module('mongoose').controller('ConnectController', function ($rootScope, $timeout,$state, Restangular, config, fieldService) {

    var vm = this;

    $rootScope.$on('validCredentials', activate);

    activate();

    function activate() {
        Restangular.one("connect/settings").get().then(function (configuration) {
            if (configuration
                && configuration.webServiceUrl
                && configuration.webServiceClientName
                && configuration.webServiceAccessToken) {
                var credentials = {
                    webServiceUrl: configuration.webServiceUrl,
                    webServiceClientName: configuration.webServiceClientName,
                    webServiceAccessToken: configuration.webServiceAccessToken
                };
                Restangular.all("connect/verifyCredentials").post(credentials).then(function(response) {
                        vm.authIsValid = response;
                        if(vm.authIsValid && config.customFields.setupRequired){
                            fieldService.initialize([]).then(function(response){
                                if(response == "Enabled"){
                                    $state.reload();
                                } else if(response == "InProgress"){
                                    $rootScope.$broadcast('startPollConfig');
                                }
                            });
                        }
                        selectTab();
                    }
                );
            } else {
                vm.authIsValid = false;
                selectTab();
            }
        });
    }

    function selectTab(){
        var tab = $rootScope.currentPage;
        $rootScope.currentPage = null;
        $timeout(function(){
            $rootScope.currentPage = tab;
        });
    }
});