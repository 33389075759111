
angular.module('mongoose').factory('passwordService', function() {

    function validatePassword(password) {
       var result  = zxcvbn(password);
       var score, type, feedback;
        switch (result.score) {
            case 0:
                score = 1;
                type = 'danger';
                break;
            case 1:
                score = 30;
                type = 'danger';
                break;
            case 2:
                score = 60;
                type = 'warning';
                break;
            case 3:
            case 4:
                score = 100;
                type = 'success';
                type = 'success';
                break;
        }
        if (score === 100) {
           feedback = 'Great Password!';
        }
        else {
            feedback = result.feedback.suggestions.length>0 ? result.feedback.suggestions[0] : '';
            if (result.feedback.warning) {
                feedback = result.feedback.warning + '; ' + feedback;
            }
            feedback = feedback || 'The password is still a bit too weak...';
        }

        return {
            score: score,
            type: type,
            feedback: feedback
        };
    };

    return {
        validatePassword : validatePassword
    };
});
