/**
 * @desc Used in Smart Message Create and Edit, Conversation reply and compose modal
 * @todo Refactor and combine with messageProgress.directive.js
 * @example <message-progress-bar />
 * @attributes message[required] show-text[optional]
 */

(function () {
  "use strict";

  angular.module("mongoose").directive("messageProgressBar", messageProgressBar);

  function messageProgressBar() {
    return {
      restrict: "E",
      link: linkFunc,
      scope: {
        includeInCount: "=",
        message: "=",
        showText: "=",
        feature: "=",
        maxLength: "=",
      },
      template:
        '<div class="message-progress ng-hide fade-in-out" ng-show="showProgress" ng-class="{\'max-reached\': maxReached}">' +
        '<div class="message-length-warning" ng-show="warningText" ng-bind="warningText"></div>' +
        '<md-progress-linear md-mode="determinate" class="md-alt" value="{{percent}}"></md-progress-linear>' +
        "</div>",
    };

    function linkFunc(scope, el, attrs) {
      var maxLength = scope.maxLength ? parseInt(scope.maxLength) : 306,
        showLength = 160,
        warnings = [
          {
            length: 160,
            message: "You’ve reached 160 characters.",
          },
          {
            length: maxLength * 0.75,
            message: "Heads up, this text is getting closer to the character limit.",
          },
          {
            length: maxLength - 6,
            message: "You've reached the character limit!",
          },
        ];

      scope.$watchGroup(["message", "includeInCount"], checkMessageLength, linkFunc);

      function checkMessageLength() {
        var messageCharCount = _.isUndefined(scope.message) || _.isNull(scope.message) ? 0 : scope.message.length,
          additionalCharCount = _.isUndefined(scope.includeInCount) ? 0 : scope.includeInCount.length + 1,
          length = messageCharCount + additionalCharCount,
          warning = _.findLast(warnings, function (warning) {
            return length >= warning.length;
          });

        if (length > maxLength) scope.message = scope.message.substring(0, maxLength - additionalCharCount);

        scope.maxReached = length === maxLength;
        scope.percent = length > 0 ? (length / maxLength) * 100 : 0;
        scope.showProgress = length >= showLength;
        scope.warningText = scope.showText ? (warning ? warning.message : "") : "";
      }
    }
  }
})();
