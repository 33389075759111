angular.module('mongoose').controller('TextingReportDataController', function ($rootScope, $scope, $state, reportService) {

    var vm = this;
    vm.submit = submit;
    vm.addGroup = addGroup;

    activate();

    function activate() {
        reset();
        vm.groups = [];
        $scope.$emit('texting-report-step-change',2);
    }

    function reset() {
        vm.name = '';
        vm.data = '';
    }
    
    function submit() {
        _.each(vm.groups, function(group){
            reportService.addGroup(group.name, group.data);
        });
        $state.go('app.owner.report.complete');
    }

    function addGroup() {
        var data = vm.data.split(/\r|\n|,|\t|\s/);
        vm.groups.push({name:vm.name,data:data});
        vm.name = '';
        vm.data = '';
    }

});