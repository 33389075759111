angular
  .module("mongoose")
  .controller("ManageFieldsController", function (
    $rootScope,
    $scope,
    $state,
    config,
    fieldService,
    growl
  ) {
    var vm = this;

    vm.baseFields = fieldService.getBaseFields(config);
    vm.complete = complete;
    $rootScope.currentSetupStep = 2;

    $scope.$on("$destroy", function () {
      $rootScope.currentSetupStep = null;
    });

    activate();

    function activate() {
      vm.fields = fieldService.getTempFields();
    }

    function complete() {
      fieldService.initialize(vm.fields).then(
        function (response) {
          if (response == "Enabled") {
            $state.go("app.admin.newImport.select", {}, { reload: true });
          } else {
            $state.go("app.admin.integration.import.complete");
          }
        },
        function (response) {
          growl.error(response.data);
        }
      );
    }
  });
