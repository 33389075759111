angular.module('mongoose').controller('DefineFieldsController', function ($rootScope, $scope, $state, $timeout, Restangular, smoothScroll, config, fieldService) {

    var vm = this,
        baseFields = fieldService.getBaseFields(config),
        baseFieldFilters = _.map(baseFields, function(n){
            return strToLowerStripWhiteSpace(n.displayName);
        });

    vm.baseFields = baseFields;
    vm.modifyInput = modifyInput;
    vm.submit = submit;
    $rootScope.currentSetupStep = 1;

    $scope.$on('$destroy', function(){
        $rootScope.currentSetupStep = null;
    });

    $scope.$watch('vm.fieldInput', parseFields);

    activate();

    function activate() {
    }

    function filterBaseFields(keys){
        return _.filter(keys, function(n){
            return _.indexOf(baseFieldFilters, strToLowerStripWhiteSpace(n)) == -1;
        });
    }

    function strToLowerStripWhiteSpace(str){
        return str.replace(/\s/g, '').toLowerCase();
    }

    function modifyInput(){
        vm.fields = [];
    }

    function parseFields(input) {
        var keys = [];
        vm.keys = [];

        if(!input)
            return;

        //first line only, comma or tab, trim, unique, compact (remove empty values from extra tabs/commas)
        keys = _.compact(
            _.uniq(
                _.map(input.split(/\n/)[0].split(/,|\t/), _.trim)
            )
        );
        vm.keys = filterBaseFields(keys);
    }

    function submit() {
        var keys = vm.noCustomFields ? [] : vm.keys,
            fields = [],
            fieldModel = {
                displayName: '',
                isRequired: true,
                canMailMerge: false,
                isVisible: true
            };

        if (!vm.noCustomFields) {
            mixpanel.track('Added Custom Fields');
        }

        vm.fields = [];

        _.forEach(keys, function(key){
            fields.push(_.assign(_.clone(fieldModel), { displayName: key }));
        });

        fieldService.setTempFields(fields);

        var top = angular.element(document.getElementById('top')),
            container = angular.element(document.getElementById('pane'));
        container.scrollTo(top,0,200);

        $state.go('app.admin.integration.import.manage');
    }

});