/**
 * @desc button for selecting a template
 * @example <emoji-picker-old></emoji-picker-old>
 */
angular.module("mongoose").directive("emojiPickerOld", emojiPicker);

function emojiPicker($timeout, emojis) {
  return {
    link: linkFunc,
    templateUrl: "core/compose-toolbar/emoji-picker.html",
    restrict: "EA",
    scope: {
      message: "=",
      disabled: "=",
    },
  };

  function linkFunc(scope, el, attr) {
    scope.emojiPicker = {
      isDisabled: false,
      isLoading: true,
      isOpen: false,
      select: select,
      fields: "",
      toggle: toggle,
    };

    scope.$watch("disabled", function (disabled) {
      scope.emojiPicker.isDisabled = disabled;
    });

    activate();

    function activate() {
      scope.emojiPicker.emojis = emojis;
    }

    function close() {
      $("body").unbind("click.popover");
      $timeout(function () {
        scope.emojiPicker.isOpen = false;
      });
    }

    function select(emoji) {
      close();

      var caret = $(el).parents(".insert-content-parent").find("textarea")[0].selectionStart;

      if (!scope.message || scope.message.trim().length === 0) {
        scope.message = emoji;
      } else {
        var partOne = scope.message.substring(0, caret);
        if (partOne.length > 0 && partOne.charAt(partOne.length - 1) !== " ") {
          partOne += " ";
        }
        var partTwo = scope.message.substring(caret);
        if (partTwo.length > 0 && partOne.charAt(0) !== " ") {
          partTwo = " " + partTwo;
        }
        scope.message = partOne + emoji + partTwo;
      }

      mixpanel.track("Inserted Emoji", {
        Emoji: emoji,
      });
    }

    function toggle() {
      if (scope.emojiPicker.isDisabled) return;

      $("body").trigger("click.popover");
      scope.emojiPicker.isOpen = !scope.emojiPicker.isOpen;
      if (scope.emojiPicker.isOpen) {
        $("body").bind("click.popover", function (e) {
          if (
            $(e.target).parents(".popover").length === 0 &&
            !($(e.target).attr("uib-popover-template") || $(e.target).parent().attr("uib-popover-template"))
          ) {
            close();
          }
        });
      }
    }
  }
}
