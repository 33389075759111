angular.module('mongoose').controller('RenameStaticSegmentModalController', function ($scope, $uibModalInstance, Restangular, segment) {

    if(!segment){
        segment = $stateParams.segment;
    }

    $scope.segmentName = angular.copy(segment.name);

    $scope.close = $uibModalInstance.dismiss;

    $scope.rename = function () {
        if(_.isUndefined($scope.segmentName) || _.isEmpty($scope.segmentName)) return;

        segment.name = $scope.segmentName;

        segment.save().then(function () {
            mixpanel.track('Renamed Segment');
            $uibModalInstance.close(segment.name);
        });
    }
});