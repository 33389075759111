angular.module('mongoose').config(function ($urlRouterProvider, $stateProvider) {

    $stateProvider.state('account', {
        url: '',
        abstract: true,
        templateUrl: 'account/public-layout.html',
        controller: 'PublicController as vm',
        pageTitle: "Account"
    })
    .state('account.login', {
        url: '/login?error&returnUrl',
        templateUrl: 'account/login.html',
        controller: 'LoginController as vm',
        pageTitle: "Login"
    })
    .state('account.login-legacy', {
        url: '/login-legacy?error&returnUrl',
        templateUrl: 'account/login-legacy.html',
        controller: 'LoginLegacyController',
        pageTitle: "Login"
    })
    .state('account.forgot-password', {
            url: '/forgot-password',
            templateUrl: 'account/forgot-password.html',
            controller: 'ForgotPasswordController',
            pageTitle: "Forgot Password"
    })
    .state('account.forgot-password-complete', {
            url: '/forgot-password-complete',
            templateUrl: 'account/forgot-password-complete.html',
            controller: 'ForgotPasswordCompleteController',
            pageTitle: "Forgot Password"
    })
    .state('account.reset-password', {
            url: '/reset-password?k',
            templateUrl: 'account/reset-password.html',
            controller: 'ResetPasswordController',
            pageTitle: "Reset Password"
    })
    .state('update-password', {
            url: '/update-password',
            templateUrl: 'account/update-password.html',
            controller: 'UpdatePasswordController',
            pageTitle: "Update Password"
    })
    .state('account.reset-password-complete', {
            url: '/reset-password-complete',
            templateUrl: 'account/reset-password-complete.html',
            controller: 'ResetPasswordCompleteController',
            pageTitle: "Reset Password"
    })
    .state('account.reset-password-error', {
            url: '/reset-password-error',
            templateUrl: 'account/reset-password-error.html',
            controller: 'ResetPasswordErrorController',
            pageTitle: "Reset Password Error"
    })
    .state('sign-out', {
            url: '/sign-out',
            controller: 'SignOutController',
            pageTitle: "Sign Out"
    })
    .state('partner-authentication', {
            url: '/partner/auth?t',
            controller: 'PartnerAuthenticationController',
            pageTitle: "Partner Authentication"
    })
    .state('auth-callback', {
        url: '/auth-callback?access_token&token_type&expires_in&invalid_grant&returnUrl',
        templateUrl: 'account/auth-callback.html',
        controller: 'AuthCallbackController'
    });
});
