angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.integrations.ftp", {
      url: "/ftp",
      abstract: true,
      template: "<ui-view></ui-view>",
      controller: "FtpController as ftpScope",
      resolve: {
        ftpConfig: [
          "ftpService",
          function (ftpService) {
            return ftpService.config();
          },
        ],
      },
      pageTitle: "FTP Integration",
    })
    .state("app.integrations.ftp.importSettings", {
      url: "/import-settings",
      templateUrl: "integrations/ftp/ftp-import-settings.html",
      controller: "FtpImportSettingsController as vm",
      resolve: {
        ftpConfig: [
          "ftpService",
          function (ftpService) {
            return ftpService.config();
          },
        ],
      },
      pageTitle: "FTP Import Settings",
    })
    .state("app.integrations.ftp.exportSettings", {
      abstract: true,
      url: "/export-settings",
      templateUrl: "integrations/ftp/ftp-export-settings.html",
      resolve: {
        ftpConfig: [
          "ftpService",
          function (ftpService) {
            return ftpService.config();
          },
        ],
      },
      pageTitle: "FTP Export Settings",
    })
    .state("app.integrations.ftp.exportSettings.messages", {
      url: "/messages",
      templateUrl: "integrations/ftp/ftp-export-settings-messages.html",
      controller: "FtpExportSettingsController as vm",
      resolve: {
        configKey: function () {
          return "messageActivityExportConfiguration";
        },
      },
      pageTitle: "FTP Export Settings",
    })
    .state("app.integrations.ftp.exportSettings.optOuts", {
      url: "/messages",
      templateUrl: "integrations/ftp/ftp-export-settings-optOuts.html",
      controller: "FtpExportSettingsController as vm",
      resolve: {
        configKey: function () {
          return "optOutExportConfiguration";
        },
      },
      pageTitle: "FTP Export Settings",
    })
    .state("app.integrations.ftp.exportSettings.deliveryErrors", {
      url: "/messages",
      templateUrl: "integrations/ftp/ftp-export-settings-deliveryErrors.html",
      controller: "FtpExportSettingsController as vm",
      resolve: {
        configKey: function () {
          return "deliveryFailureConfiguration";
        },
      },
      pageTitle: "FTP Export Settings",
    })
    .state("app.integrations.ftp.main", {
      abstract: true,
      templateUrl: "integrations/ftp/ftp.html",
      pageTitle: "FTP Integration",
    })
    .state("app.integrations.ftp.main.dashboard", {
      url: "/dashboard",
      templateUrl: "integrations/ftp/ftp-dashboard.html",
      controller: "FtpDashboardController as vm",
      pageTitle: "FTP Integration Dashboard",
    })
    .state("app.integrations.ftp.main.imports", {
      url: "/imports",
      templateUrl: "integrations/ftp/ftp-imports.html",
      controller: "FtpImportsController as vm",
      pageTitle: "FTP Imports",
    })
    .state("app.integrations.ftp.main.exports", {
      url: "/exports",
      templateUrl: "integrations/ftp/ftp-exports.html",
      controller: "FtpExportsController as vm",
      pageTitle: "FTP Exports",
    })
    .state("app.integrations.ftp.manual", {
      abstract: true,
      templateUrl: "integrations/ftp/ftp-manual-template.html",
      pageTitle: "FTP Manual Template",
    });
});
