angular
  .module("mongoose")
  .controller(
    "AdminStudentDeletionController",
    function ($scope, $state, $uibModal, growl, Restangular) {
      var vm = this;

      vm.confirm = confirm;

      vm.confirm = function () {
        var modalInstance = $uibModal.open({
          templateUrl:
            "admin/department/integration/studentdeletion/confirm-modal.html",
          controller: "DeleteStudentsModalController",
          controllerAs: "vm",
          ariaLabelledBy: "confirm",
          resolve: {},
        });

        modalInstance.result.then(
          function () {
            submit();
          },
          function () {}
        );
      };

      function submit() {
        vm.validationMessage = "";
        Restangular.all("deletestudents")
          .post({ numbers: vm.numbers })
          .then(
            function () {
              growl.success(
                "You will receive a report via email once the student deletion is completed."
              );
              vm.numbers = "";
            },
            function (response) {
              if (response.status === 400) {
                vm.validationMessage = response.data;
              }
            }
          );
      }
    }
  );
