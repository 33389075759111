/**
 * @desc Directive used like ng-include but needed to replace node for flex box to work
 * @example <sidenav />
 */
angular.module("mongoose").directive("sidenav", sidenav);

function sidenav($rootScope, $state, $timeout, securityService, firebaseService) {
  return {
    link: linkFunc,
    replace: true,
    restrict: "E",
    templateUrl: "main/sidenav.html",
  };

  function linkFunc(scope) {
    var config = scope.config,
      profile,
      departmentId,
      documentId,
      unsubscribeCount;

    scope.getInboxPath = getInboxPath;
    scope.getV2InboxPath = getV2InboxPath;
    scope.isActive = isActive;
    scope.updateTitle = updateTitle;
    scope.linkIsActive = linkIsActive;
    scope.$on("$destroy", destroy);
    scope.showSegmentsNav = false;
    scope.showAdminNav = false;
    scope.showChatNav = false;
    scope.allInboxes = [...$rootScope.profile.inboxes];

    scope.inboxes =
      $rootScope.config.features["a2p-verification"] === true &&
      $rootScope.profile.currentDepartment.campaignStatus !== "VERIFIED"
        ? _.filter(scope.allInboxes, (i) => i.type !== "group")
        : scope.allInboxes;
    scope.v2SharedInboxes = $rootScope.config.features["rollout-migrate-shared-inboxes-page-to-vue-3"];
    scope.v2Recruit = $rootScope.config.features["rollout-migrate-recruit-integration-pages-to-vue-3"];
    scope.v2SSO = $rootScope.config.hasSso;
    scope.showLockedChatLink = !$rootScope.profile.currentDepartment.teamHasChat || !$rootScope.profile.isChatAgent;
    scope.showWhatsAppIntegration = $rootScope.profile.currentDepartment.teamHasWhatsApp;
    scope.v2Inbox = $rootScope.config.features["rollout-migrate-sms-inbox-to-vue-3"];
    scope.showSalesforceIntegration =
      $rootScope.config.features["rollout-migrate-salesforce-integration-pages-to-vue-3"];
    scope.showWhatsAppTemplates =
      $rootScope.config.features["rollout-whats-app-phase-2"] &&
      $rootScope.profile.currentDepartment.teamHasWhatsApp &&
      _.some(scope.allInboxes, { type: "whatsapp" });
    scope.showArchivedWhatApp =
      $rootScope.config.features["rollout-show-archive-and-unarchive-functionality-in-whats-app"] &&
      $rootScope.profile.currentDepartment.teamHasWhatsApp;
    scope.showOrgDashboard = $rootScope.config.features["rollout-organizational-dashboard"];

    securityService.getProfile().then(function (prof) {
      profile = prof;
      departmentId = profile.currentDepartment.id;
      documentId = "user-" + profile.id;
      scope.showSegmentsNav = config.isEnabled("segments");
      scope.showAdminNav = scope.isAuthorized("admin");
      scope.showChatNav = config.isEnabled("chat-only-user");
      firebaseService.isAuth().then(getCounts);
    });

    if (window.eventBus) {
      window.eventBus.addEventListener("cw-update-whatsapp-unread-count", updateWhatsAppUnreadCount);
    }

    function destroy() {
      if (_.isFunction(unsubscribeCount)) unsubscribeCount();
    }

    function getCounts() {
      var firestore = firebase.firestore();

      unsubscribeCount = firestore
        .collection(firebaseService.getOrgRef(profile))
        .doc(documentId)
        .onSnapshot(function (doc) {
          var unreadCounts = doc.exists ? doc.data().unread : null;

          if (!unreadCounts) return;

          $timeout(function () {
            var personalAccount = _.find($rootScope.profile.inboxes, {
                type: "personal",
              }),
              groupAccounts = _.filter($rootScope.profile.inboxes, {
                type: "group",
              }),
              personalUnread = _.find(unreadCounts, {
                deptId: departmentId,
                groupAccountId: null,
              });

            if (personalAccount) {
              personalAccount.unreadCount = personalUnread ? personalUnread.count : 0;
            }

            _.forEach(groupAccounts, function (group) {
              var groupUnread = _.find(unreadCounts, {
                deptId: departmentId,
                groupAccountId: group.id,
              });
              group.unreadCount = groupUnread ? groupUnread.count : 0;
            });

            _.forEach($rootScope.profile.departments, function (department) {
              var deptUnreads = _.filter(unreadCounts, {
                deptId: department.id,
              });
              department.unreadCount = deptUnreads ? _.sum(deptUnreads, "count") : 0;
            });

            $rootScope.departmentUnreadCountTotal = _.sum($rootScope.profile.departments, "unreadCount");
            $rootScope.$broadcast("accountUnreadChanged");
          });
        });
    }

    function updateWhatsAppUnreadCount(event) {
      $timeout(function () {
        _.forEach(scope.inboxes, function (inbox) {
          if (inbox.type === "whatsapp" && inbox.id === Number(event.detail.inboxId)) {
            inbox.unreadCount = event.detail.unreadCount;
          }
        });
      });
    }

    function getInboxPath(inbox, isNewInbox) {
      if (isNewInbox) {
        if (inbox.type === "personal") return "app.messages.main.inbox.list";

        if (inbox.type === "group") return "app.shared.main.inbox({accountId: " + inbox.groupId + "})";
      }

      if (inbox.type === "personal") return "app.personal.account.inbox";

      if (inbox.type === "group") return "app.groups.account.inbox({accountId: " + inbox.groupId + "})";

      if (window.location.href.indexOf("v2") > -1) {
        console.log(window.location.href);
      }
    }

    function getV2InboxPath(inbox) {
      if (inbox.type === "personal") return `#/v2/personal/inbox`;

      if (inbox.type === "group") return `#/v2/shared/${inbox.groupId}/inbox`;
    }

    function isActive(inbox, isNewInbox) {
      if (window.location.href.indexOf("v2/personal") > -1 && inbox.type === "personal") {
        return true;
      }

      if (window.location.href.indexOf("v2/shared") > -1 && inbox.type === "group") {
        let groupAccountId = parseInt(window.location.href.split("v2")[1].match(/\d+/)[0]); // split the url at the point where the vue3 router takes over, match the group account id from the url
        if (groupAccountId === inbox.groupId) return true;
      }
      if (isNewInbox) {
        if (inbox.type === "personal") return $state.includes("app.messages.**");

        if (inbox.type === "group")
          return $state.includes("app.shared.**") && $state.params.accountId === inbox.groupId;
      }

      if (inbox.type === "personal") return $state.includes("app.personal.**");

      if (inbox.type === "group") return $state.includes("app.groups.**") && $state.params.accountId === inbox.groupId;

      if (
        window.location.href.indexOf("v2/whatsapp") > -1 &&
        inbox.type === "whatsapp" &&
        !window.location.href.includes("templates")
      ) {
        let groupAccountId = parseInt(window.location.href.split("v2")[1].match(/\d+/)[0]); // split the url at the point where the vue3 router takes over, match the group account id from the url
        if (groupAccountId === inbox.groupId) return true;
      }
    }

    function updateTitle() {
      $rootScope.$broadcast("accountUnreadChanged");
    }

    function linkIsActive(mainPath, subPath) {
      let mainPathExists = window.location.hash.indexOf(mainPath) > -1;
      if (!subPath) {
        return mainPathExists;
      } else {
        let subPaths = window.location.hash.split("/");
        return mainPathExists && _.includes(subPaths, subPath);
      }
    }
  }
}
