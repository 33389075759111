angular.module('mongoose').controller('NumberValidationController', function ($scope, $state, growl, Restangular) {

    var vm = this;

    vm.submit = submit;


    function submit() {
        vm.validationMessage = '';
        Restangular.all("numbervalidation").post({ numbers: vm.numbers }).then(function () {
                growl.success("You will receive a report via email when number validation completes.");
                vm.numbers = '';

                mixpanel.track("Ran Ad-Hoc Phone Number Validation");
            },
            function (response) {
                if (response.status === 400) {
                    vm.validationMessage = response.data;
                }
            });
    }

});