angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.tags", {
      url: "/tags",
      abstract: true,
      template: "<ui-view/>",
    })
    .state("app.tags.list", {
      url: "",
      params: { fromDetail: null },
      templateUrl: "tags/list.html",
      controller: "TagsListController",
      controllerAs: "vm",
      pageTitle: "Tags",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/tags");
        },
      },
    })
    .state("app.tags.tagDetail", {
      url: "/:tagId",
      params: { tagId: null, fromProfile: null },
      templateUrl: "tags/tag-detail.html",
      controller: "TagDetailController",
      controllerAs: "vm",
      pageTitle: "Tag Detail",
    });
});
