angular.module('mongoose').controller('DeleteUserController', function ($scope, $uibModalInstance, Restangular, growl, userId) {

    var initialize = function () {
        $scope.userConfirm = '';
        Restangular.one('admin/users', userId).get().then(function (user) {
            $scope.user = user;
        });
    };

    $scope.delete = function() {
      Restangular.one('admin/users', userId).remove().then(function (user) {
            growl.success("User has been deleted successfully!");
            $uibModalInstance.close();
        }, function(response) {
            $scope.validationMessage = response;
        });
    };
    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };

    $scope.canDelete = function () {
        return $scope.userConfirm && $scope.userConfirm.toLowerCase() === $scope.user.firstName.toLowerCase() + ' ' + $scope.user.lastName.toLowerCase();
    };

    initialize();
});