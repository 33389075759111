angular.module('mongoose').controller('AddFieldController', function ($uibModalInstance, fieldService) {

    var vm = this;

    vm.add = add;
    vm.dismiss = $uibModalInstance.dismiss;
    vm.field = {
        isRequired: true,
        canMailMerge: false,
        isVisible: true
    };

    function add() {
        vm.serverValidationError = '';
        fieldService.add(vm.field).then(function(response){
            $uibModalInstance.close(response);
            mixpanel.track('Added Field Manually');
        }, function(response){
            vm.serverValidationError = response.data;
        });
    }
});