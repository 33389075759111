angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.webchat", {
      url: "/chat",
      abstract: true,
      template: "<ui-view/>",
    })
    .state("app.webchat.list", {
      url: "",
      templateUrl: "webchat/list.html",
      controller: "CadenceChatListController",
      controllerAs: "vm",
      pageTitle: "Cadence Chat",
    })
    .state("app.webchat.list.conversation", {
      url: "/:selectedConversationId",
      params: { selectedConversationId: null },
      templateUrl: "webchat/list.html",
      controller: "CadenceChatListController",
      controllerAs: "vm",
      pageTitle: "Cadence Chat",
    });
});
