angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.segments", {
      abstract: true,
      template: "<ui-view></ui-view>",
    })
    .state("app.segments.list", {
      url: "/segments",
      templateUrl: "segments/list.html",
      controller: "SegmentsListController",
      controllerAs: "vm",
      pageTitle: "Segments",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/segments");
        },
      },
    })
    .state("app.segments.new", {
      templateUrl: "segments/new-segment.html",
      abstract: true,
      pageTitle: "New Segment",
    })
    .state("app.segments.new.auto", {
      url: "/segments/new",
      templateUrl: "segments/auto/new/new-segment.auto.html",
      controller: "NewAutoSegmentController",
      controllerAs: "vm",
      params: { templateSegment: null },
      pageTitle: "New Auto Segment",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/segments");
        },
      },
    })
    .state("app.segments.new.static", {
      url: "/segments/static/new",
      templateUrl: "segments/static/new/new-segment.static.html",
      controller: "NewStaticSegmentController",
      pageTitle: "New Static Segment",
      controllerAs: "vm",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/segments/static/new");
        },
      },
    })
    .state("app.segments.view", { abstract: true, template: "<ui-view></ui-view>" })
    .state("app.segments.view.static", {
      url: "/segments/static/:id",
      templateUrl: "segments/static/view/view-segment.static.html",
      controller: "ViewStaticSegmentController",
      controllerAs: "vm",
      pageTitle: "View Static Segment",
    })
    .state("app.segments.view.auto", {
      url: "/segments/:id",
      templateUrl: "segments/auto/view/view-segment.auto.html",
      controller: "ViewAutoSegmentController",
      pageTitle: "View Auto Segment",
      controllerAs: "vm",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/segments");
        },
      },
    })

    .state("app.segments.edit", { abstract: true, template: "<ui-view></ui-view>" })
    .state("app.segments.edit.auto", {
      url: "/segments/:id/edit",
      templateUrl: "segments/auto/edit/edit-segment.auto.html",
      controller: "EditAutoSegmentController",
      pageTitle: "Edit Auto Segment",
      controllerAs: "vm",
      resolve: {
        redirect: function () {
          window.location.replace("/#/v2/segments");
        },
      },
    });
});
