angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.integrations.workday", {
      url: "/workday",
      abstract: true,
      template: "<ui-view></ui-view>",
      controller: "WorkdayController as workdayScope",
      resolve: {
        workdayConfig: [
          "workdayService",
          function (workdayService) {
            return workdayService.config();
          },
        ],
      },
      pageTitle: "Workday Integration",
    })
    .state("app.integrations.workday.exportSettings", {
      url: "/export-settings",
      templateUrl: "integrations/workday/workday-export-settings.html",
      controller: "WorkdayExportSettingsController as vm",
      resolve: {
        workdayConfig: [
          "workdayService",
          function (workdayService) {
            return workdayService.config();
          },
        ],
        redirect: function ($rootScope) {
          setTimeout(function () {
            window.location.replace("/#/v2/admin/integrations/workday/export-settings");
          }, 500);
        },
      },
      pageTitle: "Workday Export Settings",
    })
    .state("app.integrations.workday.importSettings", {
      url: "/import-settings",
      templateUrl: "integrations/workday/workday-import-settings.html",
      controller: "WorkdayImportSettingsController as vm",
      resolve: {
        workdayConfig: [
          "workdayService",
          function (workdayService) {
            return workdayService.config();
          },
        ],
      },
      pageTitle: "Workday Import Settings",
    })
    .state("app.integrations.workday.main", {
      abstract: true,
      templateUrl: "integrations/workday/workday.html",
      pageTitle: "Workday Integration",
    })
    .state("app.integrations.workday.main.dashboard", {
      url: "/dashboard",
      templateUrl: "integrations/workday/workday-dashboard.html",
      controller: "WorkdayDashboardController as vm",
      pageTitle: "Workday Integration Dashboard",
      resolve: {
        redirect: function () {
          setTimeout(function () {
            window.location.replace("/#/v2/admin/integrations/workday/dashboard");
          }, 500);
        },
      },
    })
    .state("app.integrations.workday.main.exports", {
      url: "/exports",
      templateUrl: "integrations/workday/workday-exports.html",
      controller: "WorkdayExportsController as vm",
      pageTitle: "Workday Exports",
      resolve: {
        redirect: function () {
          setTimeout(function () {
            window.location.replace("/#/v2/admin/integrations/workday/exports");
          }, 500);
        },
      },
    })
    .state("app.integrations.workday.main.imports", {
      url: "/imports",
      templateUrl: "integrations/workday/workday-imports.html",
      controller: "WorkdayImportsController as vm",
      pageTitle: "Workday Imports",
    });
});
