angular
  .module("mongoose")
  .controller(
    "UsersController",
    function (Restangular, config, profile, $uibModal, $rootScope, $state) {
      var vm = this;
      vm.newUser = newUser;

      function newUser() {
        $uibModal
          .open(
            {
              templateUrl: "admin/department/users/invite-user.html",
              controller: "InviteUserController",
              controllerAs: "vm",
              ariaLabelledBy: "invite-user",
              backdrop: "static",
              resolve: {
                profile: function () {
                  return profile;
                },
                config: function () {
                  return config;
                },
                user: function () {
                  return (user = undefined);
                },
              },
            },
            mixpanel.track("Clicked Invite User from Page Header")
          )
          .result.then(function () {
            return $state.includes("app.admin.users.team")
              ? $rootScope.$broadcast("teamActivate")
              : $rootScope.$broadcast("organizationActivate");
          });
      }
    }
  );
