angular.module('mongoose').factory('ethosService', function($rootScope, $q, Restangular) {

    return {
        config: config,
        verifyConnection: verifyConnection,
        getDashboardData: getDashboardData,
        getImports: getImports
    };

    function config(){

        var deferred = $q.defer(),
            configurationPromise = Restangular.one('ethos/configuration').get(),
            config;

        $q.all([configurationPromise]).then(function(results){
            config = results[0].plain();
            config.status = {
                connectionIsConfigured: !!config.apiKey,
                importIsConfigured: config.personImportConfiguration.isConfigured,
                isConnected: false
            };
            deferred.resolve(config);
        },
        function(){
            config = {status: {
                isConnected: false,
                importIsConfigured: false
            }};
            deferred.resolve(config);
        });

        return deferred.promise;
    }

    function formatDateWithTimezone(d, options){
        return d ? moment.tz(moment.utc(d), options.timezone).format('M/D/YY,\u00a0h:mma') : '';
    }

    function verifyConnection(){
        return Restangular.one('ethos/verifyConfiguration').get({}, {'x-disable-loading': true});
    }

    function getDashboardData(options){
        //var exportRequest = getExports(options),
        var importRequest = getImports(options);

        return $q.all([importRequest]).then(function(results){
            return {
                imports: results[0]
            };
        });
    }

    function getImports(options){
        var params = {};
        if(!_.isUndefined(options.limit))
            params.limit = options.limit;

        return Restangular.one('ethos/imports').get(params, {'x-disable-loading': options.disableLoading}).then(function(response){
            return _.map(response.plain(), function(item){
                item.dateStarted = formatDateWithTimezone(item.dateStarted, options);
                item.dateCompleted = formatDateWithTimezone(item.dateCompleted, options);
                item.isErrorStatus = item.status === 'Failed';
                item.isDone = item.isErrorStatus || item.status === 'Complete';
                item.showErrorCount = item.status === 'Loading data via Ethos' || item.status === 'Failed' || item.status === 'Complete';
                return item;
            });
        }, function() {
            growl.error("An error has occurred updating import status.  Please reload the page.");
        });
    }

});
