angular
  .module("mongoose")
  .controller("RenxtController", function ($state, $location) {
    let vm = this;
    vm.authResult = $location.search().authResult;

    vm.currentTab = $state.params.currentTab;
    vm.handleTabChange = handleTabChange;

    function handleTabChange(data) {
      $state.go("app.integrations.renxt.view.tab", {
        currentTab: data.currentTab,
      });
    }
  });
