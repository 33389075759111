angular
  .module("mongoose")
  .controller("MarketplaceController", MarketplaceController);

function MarketplaceController($state, marketplaceService, config, profile) {
  var vm = this;

  vm.goToDetail = goToDetail;
  vm.featureEnabled = featureEnabled;

  activate();

  function activate() {
    marketplaceService.setStatus(config, profile);
    vm.items = marketplaceService.getAll();
    mixpanel.track("Viewed Marketplace");
  }

  function goToDetail(key, service) {
    if (service.preview) {
      return;
    } else {
      mixpanel.track("Clicked Marketplace tile", { product: key });
      $state.go("app.marketplace.detail", { key: key });
    }
  }

  function featureEnabled(item) {
    return !_.get(item, "featureFlag") || config.isEnabled(item.featureFlag);
  }
}
