(function() {
    'use strict';

    angular
        .module('mongoose')
        .directive('isSticky', isSticky);

    function isSticky($window) {

        return {
            restrict: 'A',
            link: linkFunc,
            scope: {}
        };

        function linkFunc(scope, el, attr, ctrl) {
            scope.$watch(function(){
                scope.open = $(el).is(':visible');
            });

            scope.$watch('open', function (newValue, oldValue) {
                if(newValue != oldValue) {
                    setHeight();
                }
            });

            function setHeight(){
                var list = el[0],
                    listHeight = list.offsetHeight + list.getBoundingClientRect().top,
                    winH = $window.innerHeight - 10;

                if (listHeight > winH && listHeight > 0) {
                    angular.element(list).addClass('dropdown-scrollable');
                    list.style.maxHeight = (winH - list.getBoundingClientRect().top) + 'px';
                } else {
                    angular.element(list).removeClass('dropdown-scrollable');
                    list.style.maxHeight = 'none';
                }
            }
        }

    }
})();