
angular.module('mongoose').controller('RadiusMessageConfigurationController', function ($scope, $timeout, $state, $q, growl, Restangular) {

    var vm = this;

    vm.caseSubjectType = '';
    vm.allowCaseMessage = true;
    vm.addCaseField = addCaseField;
    vm.addCaseMessageField = addCaseMessageField;
    vm.cancel = cancel;
    vm.getCaseFieldLabel = getCaseFieldLabel;
    vm.getValue = getValue;
    vm.getCaseMessageFieldLabel = getCaseMessageFieldLabel;
    vm.removeCaseField = removeCaseField;
    vm.removeCaseMessageField = removeCaseMessageField;
    vm.save = save;
    vm.toggleStyle = toggleStyle;
    vm.getCaseSubject = getCaseSubject;

    vm.caseSubjectOptions = [
        {name: '"Mongoose SMS History"', value: 'Title'},
        {name:  'Full Message Text', value: 'Text'}
    ];

    vm.fieldTypes = [
        {name: '<Message Text>', value: '#MessageText#'},
        {name: '<Message Date>', value: '#MessageDate#'},
        {name: '<Message Date Time>', value: '#MessageDateTime#'},
        {name: '<Message Type Code>', value: '#MessageType#'},
        {name: 'Custom', value:'Custom'}
    ];

    vm.caseMessageTypes = [
        'All',
        'Incoming',
        'Outgoing'
    ];

    activate();

    function activate() {
        
        vm.loaded=false;
        var requests = [
            Restangular.one("radius/messageConfiguration").get(),
            Restangular.all("radius/caseFields").getList(),
            Restangular.all("radius/caseMessageFields").getList(),
            Restangular.all("radius/contactFields").getList(),
            Restangular.one("radius/settings").get()
        ];

         $q.all(requests).then(function (results) {
            var config = results[0];
            vm.caseFields = results[1];
            vm.caseMessageFields = results[2];
            vm.contactFields = results[3];
            vm.settings = results[4];
            vm.caseSubjectType = vm.caseSubjectOptions[0];
            vm.newCaseMessageField = {messageType: vm.caseMessageTypes[0]};
            vm.newCaseField = {};
            
            if (config) {
                vm.config = config.plain();
            }
            else {
                vm.config = {};
            }
            vm.caseStyle = vm.config.caseSubject === 'Title' ? 'single' : 'multiple';
            
            
            vm.showScreen = vm.settings.apiPassword && vm.settings.apiUsername && vm.settings.apiUrl;
            vm.loaded=true;
        });
    }

    function getCaseSubject(){
        return  _.find(vm.caseSubjectOptions, { 'value': vm.config.caseSubject }).name;
    }

    function toggleStyle() {
        if (vm.caseStyle === 'single') {
            vm.config.caseSubject = "Title";
            vm.config.createCaseMessages = true;
        } else {
            vm.config.caseSubject = "Text";
            vm.config.createCaseMessages = false;
            vm.allowCaseMessage = false;
        }
    }

    function cancel($event) {
        $event.preventDefault();
        activate();
    }

    function addCaseField($event, field){
        $event.preventDefault();
        if(!field || !field.fieldName || !field.fieldType || (field.fieldType.value==='Custom' && !field.customFieldValue))
            return;
      
        if (!vm.config.caseFields) 
            vm.config.caseFields = [];

        field.value = field.fieldType.value==='Custom' ? field.customFieldValue : field.fieldType.value;
        vm.config.caseFields.push(_.clone(vm.newCaseField));
        field.fieldName = '';
        field.customFieldValue = '';
        field.fieldType = {};
    }

    function removeCaseField($event,field){
        $event.preventDefault();
        _.pull(vm.config.caseFields, field);
    }

    function getValue(value){
        var label = _.find(vm.fieldTypes, { 'value': value });
        return label ? label.name : value;
    }

    function addCaseMessageField($event,field){
        $event.preventDefault();
        if(!field || !field.fieldName  || !field.messageType || (field.fieldType.value==='Custom' && !field.customFieldValue))
            return;
      
        field.value = field.fieldType.value==='Custom' ? field.customFieldValue : field.fieldType.value;

        if (!vm.config.caseMessageFields) 
            vm.config.caseMessageFields = [];

        vm.config.caseMessageFields.push(_.clone(field));

        field.fieldName = '';
        field.fieldType = {};
        field.value = '';
        field.customFieldValue = '';
        field.messageType = vm.caseMessageTypes[0];
    }

    function getCaseFieldLabel(id){
        var label = _.find(vm.caseFields, { 'fieldId': id });
        return label ? label.fieldLabel : id;
    }

    function getCaseMessageFieldLabel(id){
        var label = _.find(vm.caseMessageFields, { 'fieldId': id });
        return label ? label.fieldLabel : id;
    }

    function removeCaseMessageField($event,field){
        $event.preventDefault();
        _.pull(vm.config.caseMessageFields, field);
    }

    function save() {
        var config = {
            messageConfiguration: angular.toJson(vm.config)
        };
        Restangular.all("radius/messageConfiguration").post(config).then(function() {
            growl.success("Messaging configuration updated!");
            activate();
        });
    }
});