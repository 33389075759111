angular.module("mongoose").component("scheduleMessage", {
  templateUrl: "messages/schedule-message.html",
  bindings: {
    estimate: "<",
    date: "<",
    time: "<",
    onUpdate: "&",
  },
  controllerAs: "vm",
  controller: function ($rootScope, $scope) {
    var vm = this,
      config = $rootScope.config,
      department = $rootScope.profile.currentDepartment,
      scheduleDateOptions = {
        minDate: moment(),
        maxDate: moment().add(1, "year"),
        opened: false,
      };

    $scope.$watchGroup(["vm.scheduleDate", "vm.scheduleTime"], updated);

    vm.disableQuietHoursSafeguard = department.disableQuietHoursSafeguard;
    vm.openScheduleDate = openScheduleDate;
    vm.scheduleDate = new Date(
      moment.tz(vm.date || moment(), config.timeZone).format("YYYY-MM-DDTHH:mm")
    );
    vm.scheduleDateOptions = scheduleDateOptions;
    vm.scheduleTime = new Date(
      moment
        .tz(vm.time || moment().add(1, "h"), config.timeZone)
        .format("YYYY-MM-DDTHH:mm")
    );
    vm.validationError = false;

    vm.$onInit = activate;

    function activate() {
      var minStartTime = _.min(
          _.map(vm.estimate.details, function (d) {
            return new Date(d.estimatedStartTime);
          })
        ),
        maxEndTime = _.max(
          _.map(vm.estimate.details, function (d) {
            return new Date(d.estimatedEndTime);
          })
        ),
        duration = moment.duration(
          moment(maxEndTime, "DD/MM/YYYY HH:mm:ss").diff(
            moment(minStartTime, "DD/MM/YYYY HH:mm:ss")
          )
        ),
        doNotDisturbStartTime = moment().startOf("day").set("hour", 22), // 10pm
        doNotDisturbEndTime = moment().startOf("day").set("hour", 6), // 6am
        timepickerMinTime = department.disableQuietHoursSafeguard
          ? undefined
          : doNotDisturbEndTime,
        timepickerMaxTime = department.disableQuietHoursSafeguard
          ? undefined
          : doNotDisturbStartTime.subtract(duration);

      vm.scheduleDateOptions.minTime = timepickerMinTime;
      vm.scheduleDateOptions.maxTime = timepickerMaxTime;

      if (timepickerMaxTime && timepickerMinTime) {
        vm.formattedMinTime = timepickerMinTime.format("h:mm a");
        vm.formattedMaxTime = moment(_.clone(timepickerMaxTime))
          .subtract(1, "minute")
          .format("h:mm a");
      }
    }

    function updated() {
      vm.onUpdate({
        scheduleDate: vm.scheduleDate,
        scheduleTime: vm.scheduleTime,
      });
    }

    function openScheduleDate($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vm.scheduleDateOptions.opened = true;
    }
  },
});
