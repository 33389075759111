(function() {
    'use strict';

    angular.module('mongoose').config(function ($stateProvider) {
        
        $stateProvider.state('app.owner.report', {
            abstract: true,
            url: '/report',
            controller: 'TextingReportController',
            templateUrl: 'owner/report/create.html',
            controllerAs: 'vm',
            pageTitle: "Texting Report"
        });

        $stateProvider.state('app.owner.report.general', {
            url: '/general',
            templateUrl: 'owner/report/general.html',
            controller: 'TextingReportGeneralController',
            controllerAs: 'vm',
            pageTitle: "Texting Report"
        });

        $stateProvider.state('app.owner.report.data', {
            url: '/data',
            templateUrl: 'owner/report/data.html',
            controller: 'TextingReportDataController',
            controllerAs: 'vm',
            pageTitle: "Texting Report"
        });

        $stateProvider.state('app.owner.report.complete', {
            url: '/complete',
            templateUrl: 'owner/report/complete.html',
            controller: 'TextingReportCompleteController',
            controllerAs: 'vm',
            pageTitle: "Texting Report"
        });
    })
})();