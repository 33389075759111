/**
 * @desc button for selecting a template
 * @example <campaign-picker></campaign-picker>
 */
angular.module("mongoose").directive("campaignPicker", campaignPicker);

function campaignPicker($rootScope, $timeout, Restangular) {
  return {
    link: linkFunc,
    templateUrl: "campaigns/campaign-picker.html",
    restrict: "EA",
  };

  function linkFunc(scope) {
    scope.campaignPicker = {
      isOpen: false,
      select: select,
      campaigns: "",
      toggle: toggle,
    };

    function close() {
      $("body").unbind("click.popover");
      $timeout(function () {
        scope.campaignPicker.isOpen = false;
      });
    }

    function select(campaign, message) {
      close();

      var prom,
        id = message.messageId;

      $rootScope.$broadcast("campaignPickerActivity");

      if (
        message.direction === "Incoming" ||
        message.direction === "incoming"
      ) {
        prom = Restangular.one("incomingmessages", id).patch({
          campaignId: campaign.id,
        });
      } else {
        prom = Restangular.one("outgoingmessage", id).patch({
          campaignId: campaign.id,
        });
      }

      prom.then(function () {
        message.campaignId = campaign.id;
      });

      mixpanel.track("Added Campaign to Message");
    }

    function toggle() {
      $rootScope.$broadcast("campaignPickerActivity");

      $("body").trigger("click.popover");
      scope.campaignPicker.isOpen = !scope.campaignPicker.isOpen;
      if (scope.campaignPicker.isOpen) {
        $("body").bind("click.popover", function (e) {
          if (
            $(e.target).parents(".popover").length === 0 &&
            !$(e.target).attr("uib-popover-template")
          ) {
            close();
          }
        });
      }
    }
  }
}
