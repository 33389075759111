angular
  .module("mongoose.ngVueComponents")
  .value("TextAnalyticsPage", cadence.default["TextAnalyticsPage"])
  .directive("textAnalyticsPage", function (createVueComponent) {
    return createVueComponent("TextAnalyticsPage");
  })
  .value("AnalyticsInboxDetail", cadence.default["AnalyticsInboxDetail"])
  .directive("analyticsInboxDetail", function (createVueComponent) {
    return createVueComponent("AnalyticsInboxDetail");
  })
  .value("AutomationSettings", cadence.default["AutomationSettings"])
  .directive("automationSettings", function (createVueComponent) {
    return createVueComponent("AutomationSettings");
  })
  .value("AutomationStatus", cadence.default["AutomationStatus"])
  .directive("automationStatus", function (createVueComponent) {
    return createVueComponent("AutomationStatus");
  })
  .value("ChatAnalyticsPage", cadence.default["ChatAnalyticsPage"])
  .directive("chatAnalyticsPage", function (createVueComponent) {
    return createVueComponent("ChatAnalyticsPage");
  })
  .value("ContactCard", cadence.default["ContactCard"])
  .directive("contactCard", function (createVueComponent) {
    return createVueComponent("ContactCard");
  })
  .value("ContactDetails", cadence.default["ContactDetails"])
  .directive("contactDetails", function (createVueComponent) {
    return createVueComponent("ContactDetails");
  })
  .value("ContactProfile", cadence.default["ContactProfile"])
  .directive("contactProfile", function (createVueComponent) {
    return createVueComponent("ContactProfile");
  })
  .value("ContactsGrid", cadence.default["ContactsGrid"])
  .directive("contactsGrid", function (createVueComponent) {
    return createVueComponent("ContactsGrid");
  })
  .value("ConversationList", cadence.default["ConversationList"])
  .directive("conversationList", function (createVueComponent) {
    return createVueComponent("ConversationList");
  })
  .value("ConversationItem", cadence.default["ConversationItem"])
  .directive("conversationItem", function (createVueComponent) {
    return createVueComponent("ConversationItem");
  })
  .value("CreditBalance", cadence.default["CreditBalance"])
  .directive("creditBalance", function (createVueComponent) {
    return createVueComponent("CreditBalance");
  })
  .value("ExportPage", cadence.default["ExportPage"])
  .directive("exportPage", function (createVueComponent) {
    return createVueComponent("ExportPage");
  })
  .value("OutOfOfficeDialogV2", cadence.default["OutOfOfficeDialogV2"])
  .directive("outOfOfficeDialogV2", function (createVueComponent) {
    return createVueComponent("OutOfOfficeDialogV2");
  })
  .value(
    "OutOfOfficeStatusIndicator",
    cadence.default["OutOfOfficeStatusIndicator"]
  )
  .directive("outOfOfficeStatusIndicator", function (createVueComponent) {
    return createVueComponent("OutOfOfficeStatusIndicator");
  })
  .value("OutOfOfficeTab", cadence.default["OutOfOfficeTab"])
  .directive("outOfOfficeTab", function (createVueComponent) {
    return createVueComponent("OutOfOfficeTab");
  })
  .value("PopoverSearch", cadence.default["PopoverSearch"])
  .directive("popoverSearch", function (createVueComponent) {
    return createVueComponent("PopoverSearch");
  })
  .value("ReminderModal", cadence.default["ReminderModal"])
  .directive("reminderModal", function (createVueComponent) {
    return createVueComponent("ReminderModal");
  })
  .value("SegmentCriteria", cadence.default["SegmentCriteria"])
  .directive("segmentCriteria", function (createVueComponent) {
    return createVueComponent("SegmentCriteria");
  })
  .value("ScheduledMessages", cadence.default["ScheduledMessages"])
  .directive("scheduledMessages", function (createVueComponent) {
    return createVueComponent("ScheduledMessages");
  })
  .value("InboxSentTab", cadence.default["InboxSentTab"])
  .directive("inboxSentTab", function (createVueComponent) {
    return createVueComponent("InboxSentTab");
  })
  .value("SharedInboxPage", cadence.default["SharedInboxPage"])
  .directive("sharedInboxPage", function (createVueComponent) {
    return createVueComponent("SharedInboxPage");
  })
  .value("Store", cadence.default["Store"])
  .directive("store", function (createVueComponent) {
    return createVueComponent("Store");
  })
  .value("StoreInbox", cadence.default["StoreInbox"])
  .directive("storeInbox", function (createVueComponent) {
    return createVueComponent("StoreInbox");
  })
  .value("SubpageHeader", cadence.default["SubpageHeader"])
  .directive("subpageHeader", function (createVueComponent) {
    return createVueComponent("SubpageHeader");
  })
  .value("TagListPage", cadence.default["TagListPage"])
  .directive("tagListPage", function (createVueComponent) {
    return createVueComponent("TagListPage");
  })
  .value("TagDetailPage", cadence.default["TagDetailPage"])
  .directive("tagDetailPage", function (createVueComponent) {
    return createVueComponent("TagDetailPage");
  })
  .value("UserAccessChat", cadence.default["UserAccessChat"])
  .directive("userAccessChat", function (createVueComponent) {
    return createVueComponent("UserAccessChat");
  })
  .value("UserAccessChatOnly", cadence.default["UserAccessChatOnly"])
  .directive("userAccessChatOnly", function (createVueComponent) {
    return createVueComponent("UserAccessChatOnly");
  })
  .value("UserAccessText", cadence.default["UserAccessText"])
  .directive("userAccessText", function (createVueComponent) {
    return createVueComponent("UserAccessText");
  })
  .value("UserAccessTextOnly", cadence.default["UserAccessTextOnly"])
  .directive("userAccessTextOnly", function (createVueComponent) {
    return createVueComponent("UserAccessTextOnly");
  })
  .value("UserRolePicker", cadence.default["UserRolePicker"])
  .directive("userRolePicker", function (createVueComponent) {
    return createVueComponent("UserRolePicker");
  })
  .value(
    "LegacyEmojiPickerComposeMenu",
    cadence.default["LegacyEmojiPickerComposeMenu"]
  )
  .directive("legacyEmojiPickerComposeMenu", function (createVueComponent) {
    return createVueComponent("LegacyEmojiPickerComposeMenu");
  })
  .value("WebChatPage", cadence.default["WebChatPage"])
  .directive("webChatPage", function (createVueComponent) {
    return createVueComponent("WebChatPage");
  })
  .value(
    "WebChatAvailabilityDropdown",
    cadence.default["WebChatAvailabilityDropdown"]
  )
  .directive("webChatAvailabilityDropdown", function (createVueComponent) {
    return createVueComponent("WebChatAvailabilityDropdown");
  })
  .value(
    "WebChatConversationCount",
    cadence.default["WebChatConversationCount"]
  )
  .directive("webChatConversationCount", function (createVueComponent) {
    return createVueComponent("WebChatConversationCount");
  })
  .value("RecruitPage", cadence.default["RecruitPage"])
  .directive("recruitPage", function (createVueComponent) {
    return createVueComponent("RecruitPage");
  })
  .value(
    "RecruitExportSettingsPage",
    cadence.default["RecruitExportSettingsPage"]
  )
  .directive("recruitExportSettingsPage", function (createVueComponent) {
    return createVueComponent("RecruitExportSettingsPage");
  })
  .value(
    "RecruitImportSettingsPage",
    cadence.default["RecruitImportSettingsPage"]
  )
  .directive("recruitImportSettingsPage", function (createVueComponent) {
    return createVueComponent("RecruitImportSettingsPage");
  })
  .value("RenxtPage", cadence.default["RenxtPage"])
  .directive("renxtPage", function (createVueComponent) {
    return createVueComponent("RenxtPage");
  })
  .value("RenxtImportSettingsPage", cadence.default["RenxtImportSettingsPage"])
  .directive("renxtImportSettingsPage", function (createVueComponent) {
    return createVueComponent("RenxtImportSettingsPage");
  })
  .value("RenxtExportSettingsPage", cadence.default["RenxtExportSettingsPage"])
  .directive("renxtExportSettingsPage", function (createVueComponent) {
    return createVueComponent("RenxtExportSettingsPage");
  })
  .value("LegacyTemplatePicker", cadence.default["LegacyTemplatePicker"])
  .directive("legacyTemplatePicker", function (createVueComponent) {
    return createVueComponent("LegacyTemplatePicker");
  })
  .value("WhatsAppUnreadCounts", cadence.default["WhatsAppUnreadCounts"])
  .directive("whatsAppUnreadCounts", function (createVueComponent) {
    return createVueComponent("WhatsAppUnreadCounts");
  });
