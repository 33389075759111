/**
 * @desc button for selecting a file to upload
 * @example <media-picker></media-picker>
 */
angular
    .module('mongoose')
    .directive('mediaPickerDisabled', mediaPickerDisabled);

function mediaPickerDisabled($timeout, Upload) {

    return {
        link: linkFunc,
        templateUrl: 'core/compose-toolbar/mediaPickerDisabled.html',
        restrict: 'E'
    };

    function linkFunc(scope, el, attrs) {

    }
}