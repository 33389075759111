
angular.module('mongoose').controller('ConnectImportConfigurationController', function ($scope, $timeout, $state, $q, growl, Restangular, config) {

    var vm = this;
    vm.setMongooseField = setMongooseField;
    vm.getConnectFieldLabel = getConnectFieldLabel;
    vm.removeAdditionalField = removeAdditionalField;
    vm.addAdditionalField = addAdditionalField;
    vm.showMongooseFieldChooser = config.customFields.enabled;
    vm.addCriteria = addCriteria;
    vm.removeFilter = removeFilter;
    vm.save = save;
    vm.optOutOptions = [
        "Dropdown",
        "Checkbox",
    ];

    var fields = [];

    activate();

    function activate() {
      var requests = [
            Restangular.one("connect/importConfiguration").get(),
            Restangular.all("connect/fields").getList(),
            Restangular.one("connect/settings").get(),
            Restangular.all("connect/sourceCodes").getList(),
            Restangular.all("customFields").getList()
        ];

         $q.all(requests).then(function (results) {
            vm.config = results[0].plain();
            vm.fields = results[1].plain();
            vm.settings = results[2].plain();
            vm.sourceCodes = results[3].plain();
            
            vm.allCustomFields = _.sortBy(results[4].plain(), function(f) {return f.displayName;});
            filterCustomFields();
        });
    }

    function filterCustomFields() {
        if (vm.showMongooseFieldChooser) {
            var usedFields = _.pluck(vm.config.additionalFields, 'mongooseField');
            vm.customFields = _.filter(vm.allCustomFields, function(f) {return !_.includes(usedFields,f.displayName);});
        }
    }

    function setMongooseField(item, model, label) {
        vm.additionalField.mongooseField = item.displayLabel;
    }

    function getConnectFieldLabel(name) {
        var label = _.find(vm.fields, { 'name': name });
        return label ? label.displayLabel : name;
    }

    function addCriteria($event){
        $event.preventDefault();
        if (!vm.newFilter || !isInt(vm.newFilter))
            return;
        if (!vm.config.contactFilters)
            vm.config.contactFilters = [];
        vm.config.contactFilters.push(parseInt(vm.newFilter));
        vm.config.contactFilters = _.uniq(vm.config.contactFilters);
        vm.newFilter= '';
    }

    function addAdditionalField($event, field){
        $event.preventDefault();
        if(!field || !field.mongooseField || !field.connectField)
            return;
        if (!vm.config.additionalFields)
            vm.config.additionalFields = [];
        vm.config.additionalFields.push(_.clone(field));
        field.mongooseField = '';
        field.connectField = '';
        filterCustomFields();
    }

    function removeAdditionalField($event, field){
        $event.preventDefault();
        _.pull(vm.config.additionalFields, field);
    }
    
    function removeAdditionalField($event, field){
        $event.preventDefault();
        _.pull(vm.config.additionalFields, field);
        filterCustomFields();
    }
    
    function removeFilter($event, filter){
        $event.preventDefault();
        _.pull(vm.config.contactFilters, filter);
    }


    function save() {
    
        var config = {
            studentImportConfiguration: angular.toJson(vm.config)
        };
        Restangular.all("connect/importConfiguration").post(config).then(function(response) {
            Restangular.all("connect/settings").post(vm.settings).then(function(response) {
                growl.success("Connect integration configuration updated!");
                activate();
            });
        });
    }

    function staffFieldIsCustom(){
        return vm.selectedStaffField === vm.staffFieldOptions[1];
    }

    function isInt(value) {
        return !isNaN(value) && 
                parseInt(Number(value)) == value && 
                !isNaN(parseInt(value, 10));
    }
});