angular.module('mongoose').controller('ResetPasswordController', function ($scope, $http, $window, $stateParams, $rootScope, apiBaseUrl, passwordService) {
    var initialize = function () {
        resetPasswordFields();

         $http.get(apiBaseUrl + '/auth/validateresetpassword?k=' + $stateParams.k)
            .success(function (data) {
            })
            .error(function () {
                $window.location.href = '#/reset-password-error';
            });
        },
        
    validate = function () {
        $scope.validationMessage = '';
        if (!($scope.credentials.passwordConfirmation && $scope.credentials.password)) {
            $scope.validationMessage = 'E-mail address and password are required.';
        }
        if ($scope.passwordStrengthScore!==100) {
            $scope.validationMessage = 'Password does not meet complexity requirements.';
        }
        return !$scope.validationMessage;
    },
    resetPasswordFields = function() {
        $scope.credentials = {
            passwordConfirmation :'',
            password : ''
        };
        $scope.passwordStrengthMessage = '';
        $scope.passwordStrengthScore = 0;
        $scope.passwordStrengthType = '';
    };

    $scope.checkPassword = function() {
       var result = passwordService.validatePassword($scope.credentials.password);
        $scope.passwordStrengthMessage = result.feedback;
        $scope.passwordStrengthScore = result.score;
        $scope.passwordStrengthType = result.type;
    }

    $scope.submit = function() {
        if (validate()) {
            $scope.loading = true;
            $http.post(apiBaseUrl + '/auth/resetpassword', { password: $scope.credentials.password, passwordConfirmation: $scope.credentials.passwordConfirmation, passwordResetKey: $stateParams.k})
            .success(function (data) {
                $scope.loading = false;
                $window.location.href = '#/reset-password-complete';
            })
            .error(function () {
                $scope.loading = false;
                $scope.validationMessage = 'Please verify that password and password confirmation match.';
            });
        } else {
            resetPasswordFields();
        }
    };

    initialize();
});

angular.module('mongoose').controller('ResetPasswordCompleteController', function ($rootScope) {
});

angular.module('mongoose').controller('ResetPasswordErrorController', function ($rootScope) {
});