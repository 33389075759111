angular
  .module("mongoose")
  .controller(
    "ApiDepartmentController",
    function (Restangular, $uibModal, profile) {
      var vm = this;

      vm.generate = generate;
      vm.revoke = revoke;

      activate();

      function activate() {
        Restangular.all("apiCredentials")
          .getList({ department: true })
          .then(function (response) {
            vm.credentials = response;
          });
      }

      function generate() {
        $uibModal
          .open({
            templateUrl:
              "owner/api/credentials/generate-credentials-modal.html",
            controller: "GenerateCredentialsModalController",
            controllerAs: "vm",
            ariaLabelledBy: "name",
            resolve: {
              departmentId: function () {
                return profile.currentDepartment.id;
              },
            },
          })
          .result.then(function () {
            activate();
          });
      }

      function revoke(credentialId) {
        $uibModal
          .open({
            templateUrl:
              "owner/api/credentials/invalidate-credentials-modal.html",
            controller: "InvalidateCredentialsModalController",
            controllerAs: "vm",
            ariaLabelledBy: "name",
            resolve: {
              credentialId: function () {
                return credentialId;
              },
            },
          })
          .result.then(function () {
            activate();
          });
      }
    }
  );
