angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider
    .state("app.integrations.recruit", {
      url: "/recruit",
      abstract: true,
      template: "<ui-view/>",
    })
    .state("app.integrations.recruit.view", {
      url: "",
      params: { currentTab: "dashboard" },
      templateUrl: "integrations/recruit/recruit.html",
      controller: "RecruitController",
      controllerAs: "vm",
      pageTitle: "Recruit Integration",
    })
    .state("app.integrations.recruit.view.tab", {
      url: "/:currentTab/",
      params: { currentTab: null },
      templateUrl: "integrations/recruit/recruit.html",
      controller: "RecruitController",
      controllerAs: "vm",
      pageTitle: "Recruit Integration",
    })
    .state("app.integrations.recruit.exportsettings", {
      url: "/exports/settings",
      templateUrl: "integrations/recruit/recruit-export-settings.html",
      controller: "RecruitExportSettingsController",
      controllerAs: "vm",
      pageTitle: "Recruit Export Settings",
    })
    .state("app.integrations.recruit.importsettings", {
      url: "/imports/settings",
      templateUrl: "integrations/recruit/recruit-import-settings.html",
      controller: "RecruitImportSettingsController",
      controllerAs: "vm",
      pageTitle: "Recruit Import Settings",
    });
});
