
angular.module('mongoose').factory('growlService', function(growl) {

    function error(err){
        var output = err.data ? err.data : JSON.stringify(err),
            message = twemoji.parse(
                '<div class="growl-emoticon">\uD83D\uDE33</div>' +
                '<div class="growl-error-message">Eep! That didn\'t work. ' +
                'Please try again &amp; contact Support if the problem persists.</div>'
                , {base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/'});

        growl.error(message);

        if(console) {
            console.error('\uD83D\uDE33', output);
        }
    }

    return {
        error: error
    };
});
