angular
  .module("mongoose")
  .controller(
    "LegacyEditUserController",
    function (
      $uibModalInstance,
      $q,
      $filter,
      Restangular,
      growl,
      userId,
      profile
    ) {
      var vm = this;

      vm.profile = profile;

      activate();

      function activate() {
        var requests = [
          Restangular.one("admin/users", userId).get(),
          Restangular.one("admin/users", userId).getList("numbers"),
          Restangular.all("departments").getList(),
          Restangular.all("samlconfiguration").getList(),
        ];

        $q.all(requests).then(function (results) {
          vm.user = results[0];
          vm.user.forwardingNumber = $filter("phone")(vm.user.forwardingNumber);
          vm.user.number = results[1][0];
          vm.authMethods = results[3];

          vm.departments = _.map(results[2], function (dept) {
            var userDept = _.find(vm.user.departments, { id: dept.id });
            console.log(userDept);
            if (!_.isUndefined(userDept)) {
              dept.enabled = true;
              dept.role = userDept.isAdministrator ? "administrator" : "user";

              dept.authorizedFeatures = userDept.isSegmentAuthorized
                ? ["Segments"]
                : [];
              if (dept.isManualContactAuthorized) {
                dept.authorizedFeatures.push("ManualContactAdd");
              }

              dept.segmentsAuthorized = false;
              dept.manualContactAddAuthorized = false;

              if (_.includes(userDept.allowedFeatures, "Segments")) {
                dept.segmentsAuthorized = true;
              }
              if (_.includes(userDept.allowedFeatures, "ManualContactAdd")) {
                dept.manualContactAddAuthorized = true;
              }
            } else {
              dept.enabled = false;
              dept.role = "user";
            }
            return dept;
          });
        });

        vm.hasValidationErrors = {};
      }

      function isValid() {
        vm.hasValidationErrors = {};
        vm.hasValidationErrors.firstName = !vm.user.firstName;
        vm.hasValidationErrors.lastName = !vm.user.lastName;
        vm.hasValidationErrors.campusUniqueId = !vm.user.campusUniqueId;
        vm.hasValidationErrors.emailAddress = !vm.user.emailAddress;

        return !_.includes(vm.hasValidationErrors, true);
      }

      vm.accessValid = function () {
        if (vm.loading) return false;

        if (vm.user && vm.user.isOwner) return true;

        return _.some(vm.departments, { enabled: true });
      };

      vm.close = $uibModalInstance.dismiss;

      vm.sendWelcomeEmail = function () {
        vm.sendingEmail = true;
        Restangular.all("admin/users/sendEmail")
          .post(vm.user)
          .then(
            function () {
              growl.success("Welcome email has been sent!");
              $uibModalInstance.close();
            },
            function (errResponse) {
              vm.sendingEmail = false;
              vm.serverValidationError = errResponse.data;
            }
          );
      };

      vm.update = function () {
        if (isValid()) {
          vm.busy = true;

          if (!vm.user.isOwner)
            vm.user.selectedDepartments = _.filter(vm.departments, {
              enabled: true,
            });

          if (vm.user.samlConfigurationId == "null")
            vm.user.samlConfigurationId = null;

          //Set authorizable features
          _.each(vm.user.selectedDepartments, function (dept) {
            dept.authorizedFeatures = [];
            if (dept.segmentsAuthorized) {
              dept.authorizedFeatures.push("Segments");
            }

            if (dept.manualContactAddAuthorized) {
              dept.authorizedFeatures.push("ManualContactAdd");
            }
          });

          Restangular.all("admin/users/update")
            .post(vm.user)
            .then(
              function () {
                growl.success("Your user has been updated!");
                mixpanel.track("Updated User");
                $uibModalInstance.close();
              },
              function (errResponse) {
                vm.serverValidationError = errResponse.data;
                vm.busy = false;
              }
            );
        }
      };
    }
  );
