angular.module('mongoose').controller('ProfileSecurityController', function ($scope, $window, $state, $filter, profile, passwordService, Restangular, growl) {
    var initialize = function () {
        $scope.profile = profile;
        $scope.profile.forwardingNumber = $filter('phone')($scope.profile.forwardingNumber);
        resetPasswordFields();
    },
    resetPasswordFields = function() {

        $scope.passwordStrengthMessage = '';
        $scope.passwordStrengthScore = 0;
        $scope.passwordStrengthType = '';

        $scope.profile.oldPassword = '';
        $scope.profile.newPassword = '';
        $scope.profile.newPasswordConfirm = '';
    
    },
    validate = function () {
        $scope.validationMessage = '';
        if (!($scope.profile.oldPassword && $scope.profile.newPassword && $scope.profile.newPasswordConfirm)) {
            $scope.validationMessage = 'Old and new passwords are required.';
        } else if ($scope.profile.newPassword != $scope.profile.newPasswordConfirm) {
            $scope.validationMessage = 'New password and confirmation do not match.';
        } else if ($scope.passwordStrengthScore !== 100) {
            $scope.validationMessage = 'Password does not meet complexity requirements.';
        }
        return !$scope.validationMessage;
    };
    
    $scope.checkStrength = function() {
       var result = passwordService.validatePassword($scope.profile.newPassword);
        $scope.passwordStrengthMessage = result.feedback;
        $scope.passwordStrengthScore = result.score;
        $scope.passwordStrengthType = result.type;
    };

    $scope.updatePassword = function() {
        if (validate()) {
            Restangular.all("profile").customPOST({ oldPassword: $scope.profile.oldPassword, newPassword: $scope.profile.newPassword, newPasswordConfirm: $scope.profile.newPasswordConfirm }, "changePassword")
            .then(function() {
                growl.success('Password Change Successful!');
                $state.reload();
            },
            function (response) {
                if (response.status === 400) {
                    $scope.validationMessage = response.data;
                }
            });
        }
        resetPasswordFields();
    };

    initialize();
});