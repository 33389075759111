angular.module("mongoose").config(function ($stateProvider) {
  $stateProvider.state("app.profile", {
    url: "/profile",
    abstract: true,
    templateUrl: "profile/profile.html",
    pageTitle: "Profile",
    resolve: {
      redirect: function () {
        window.location.replace("/#/v2/profile/general");
      },
    },
  });

  $stateProvider.state("app.profile.general", {
    url: "/general",
    templateUrl: "profile/general.html",
    controller: "ProfileGeneralController",
    pageTitle: "Profile",
  });

  $stateProvider.state("app.profile.notifications", {
    url: "/notifications",
    templateUrl: "profile/notifications.html",
    controller: "ProfileNotificationsController",
    pageTitle: "Profile Notifications",
  });

  $stateProvider.state("app.profile.security", {
    url: "/security",
    templateUrl: "profile/security.html",
    controller: "ProfileSecurityController",
    pageTitle: "Profile Security",
  });

  $stateProvider.state("app.profile.out-of-office", {
    url: "/out-of-office",
    templateUrl: "profile/out-of-office.html",
    controller: "OutOfOfficeController as vm",
    pageTitle: "Out Of Office",
  });
});
