angular.module("mongoose").controller("MessagingInboxController", function ($rootScope, $state, inbox) {
  window.singleSpa.unloadApplication("cadenceAi");

  var inboxVm = this,
    profile = $rootScope.profile;

  inboxVm.activeInbox = inbox;
  inboxVm.isInboxView = $state.params.isInboxView;

  inboxVm.bulkReply = bulkReply;
  inboxVm.selectConversation = selectConversation;
  inboxVm.conversationLockedByCurrentStaff = conversationLockedByCurrentStaff;
  inboxVm.setLockedState = lockedState;

  function bulkReply(contacts) {
    $rootScope.openComposeModal({ contacts: contacts, inbox: inbox });
  }

  function lockedState(value) {
    $rootScope.lockedState = value;
  }

  function selectConversation(id) {
    var inboxState, conversationState;

    inboxVm.conversationId = id;

    if (inbox.type === "personal") {
      if (id) {
        conversationState = inboxVm.isInboxView
          ? "app.messages.main.inbox.conversation"
          : "app.messages.main.archived.conversation";
        $state.go(conversationState, { id: id });
      } else {
        inboxState = inboxVm.isInboxView ? "app.messages.main.inbox.list" : "app.messages.main.archived.list";
        $state.go(inboxState);
      }
    }

    if (inbox.type === "group") {
      if (id) {
        conversationState = inboxVm.isInboxView
          ? "app.shared.main.inbox.conversation"
          : "app.shared.main.archived.conversation";
        $state.go(conversationState, { id: id });
      } else {
        inboxState = inboxVm.isInboxView ? "app.shared.main.inbox.list" : "app.shared.main.archived.list";
        $state.go(inboxState);
      }
    }
  }

  function conversationLockedByCurrentStaff() {
    return $rootScope.lockedState ? $rootScope.lockedState.lockedForStaffId === profile.id : false;
  }
});
