/**
 * @desc specify which child input to autofocus on load
 * @example <div auto-focus="id-of-child-input"></div>
 */
angular
    .module('mongoose')
    .directive('autoFocus', autoFocus);

function autoFocus($timeout) {

    return {
        link: linkFunc,
        restrict: 'A'
    };

    function linkFunc(scope, el, attrs) {
        $timeout(function(){
            var input = document.getElementById(attrs.autoFocus);
            $timeout(function(){
                input.focus();
            }, 400);
        });
    }
}