
angular.module('mongoose').controller('RadiusController', function ($rootScope, $timeout, $state, Restangular, fieldService, config) {

    var vm = this;

    $rootScope.$on('validCredentials', activate);

    activate();

    function activate() {
        Restangular.one("radius/settings").get().then(function (configuration) {
            if (configuration
                && configuration.apiUrl
                && configuration.apiUsername
                && configuration.apiPassword) {
                var credentials = {
                    apiUrl: configuration.apiUrl,
                    apiUsername: configuration.apiUsername,
                    apiPassword: configuration.apiPassword
                };
                Restangular.all("radius/verifyCredentials").post(credentials).then(function(response) {
                        vm.authIsValid = response;
                        if(vm.authIsValid && config.customFields.setupRequired){
                            fieldService.initialize([]).then(function(response){
                                if(response == "Enabled"){
                                    $state.reload();
                                } else if(response == "InProgress"){
                                    $rootScope.$broadcast('startPollConfig');
                                }
                            });
                        }
                        selectTab();
                    }
                );
            } else {
                vm.authIsValid = false;
                selectTab();
            }
        });
    }

    function selectTab(){
        var tab = $rootScope.currentPage;
        $rootScope.currentPage = null;
        $timeout(function(){
            $rootScope.currentPage = tab;
        });
    }
});